import { Checkbox, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CommonButton } from 'components/common-button';
import { PasswordInput, TextInput } from 'components/form-control';
import { errorKeyWord, ISignIn } from './login';
import { login } from 'services/auth.service';
import { isEmpty } from 'utils/helper';
import { setUserInfo } from 'redux/auth/auth.slice';
import LabelRequire from 'components/label-require';
import './style.scss';
import { emailRegex } from 'utils/regex';
import { Link } from 'react-router-dom';
import { PATH_FORGOT_PASSWORD } from 'navigation/routes.path';
import { BlueonionBlackIcon } from 'components/icons/blueonion-logo-black-icon';

const LoginForm = ({ setErrorMessage }: any) => {
  const [formValidate] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleLogin = async (values: ISignIn) => {
    if (!values) return;
    const { email, password } = values;
    setLoadingLogin(true);
    try {
      const data = await login(email.trim(), password.trim());
      if (data) {
        setLoadingLogin(false);
        dispatch(setUserInfo(data));
      }
    } catch (error: any) {
      const messError = error?.response?.data?.message;
      if (isEmpty(error.response)) {
        message.error({
          content: 'Please check your internet connection and try again.',
          className: 'custom-class',
          style: {
            textAlign: 'center',
          },
        });
      } else if (messError.includes(errorKeyWord['deactivated'])) {
        setErrorMessage(messError);
        setTimeout(() => {
          setErrorMessage('');
        }, 4000);
      } else if (messError.includes(errorKeyWord['notExist'])) {
        formValidate.setFields([{ name: 'email', errors: [`${messError}`] }]);
      } else if (messError.includes(errorKeyWord['incorrectPassword'])) {
        formValidate.setFields([{ name: 'password', errors: [`${messError}`] }]);
      } else {
        message.error({
          content: error?.response?.data?.message,
          className: 'custom-class',
          style: {
            textAlign: 'center',
          },
        });
      }
    }
    setLoadingLogin(false);
  };

  useEffect(() => {
    forceUpdate({});
  }, []);
  return (
    <div className="form-area">
      <BlueonionBlackIcon style={{width:265, marginBottom:75 }} />
      <p className="welcome">Welcome!</p>
      <div className="note">
        <p>Welcome to BlueOnion Administration Dashboard</p>
      </div>
      <Form onFinish={handleLogin} form={formValidate}>
        <LabelRequire text="Email" require />
        <TextInput
          placeholder="Input your email"
          className="login-input"
          name="email"
          rules={[
            { required: true, message: 'This is a mandatory field' },
            {
              pattern: new RegExp(emailRegex),
              message: 'Please enter a valid email address',
            },
            { max: 100, message: 'You have reach the maximum character for this field: 100 characters' },
          ]}
        />
        <LabelRequire text="Password" require />
        <div className="password">
          <PasswordInput
            placeholder="Input your password"
            name="password"
            rules={[
              { required: true, message: 'This is a mandatory field' },
              { max: 100, message: 'You have reach the maximum character for this field: 100 characters' },
            ]}
          />
        </div>
        <div className="flex justify-between">
          <Form.Item required={false} name="staySignedIn" valuePropName="checked">
            <Checkbox>Stay signed in</Checkbox>
          </Form.Item>
          <Form.Item className="navigate-text">
            <Link to={PATH_FORGOT_PASSWORD}>Forgot Password?</Link>
          </Form.Item>
        </div>
        <Form.Item shouldUpdate>
          {() => (
            <CommonButton
              htmlType="submit"
              loading={loadingLogin}
              disabled={!!formValidate.getFieldsError().filter(({ errors }) => errors.length).length}
              block
              size="large"
              variant="primary"
            >
              Sign In
            </CommonButton>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
export default LoginForm;
