import { Col } from 'antd';
import DateInput from 'components/form-control/date-input';

import { namedMapping, roleType } from './role-tool';
import { BlueOnionHubRole } from './change-plan-form.type';

import { isDateInverted, warningText } from './warning-tool';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';

import { ModalBase } from 'components/modal';
import styles from './edit-date-modal.module.scss';
import LabelRequire from 'components/label-require';

type EditDateModalProps = {
  keyStr: string;
  varName: string;
  label: string;

  blueOnionHubRole: BlueOnionHubRole | any;
  blueOnionHubRoleFlag?: roleType | any;
  onRowUpdate?: (varName: string, newValue: any) => any;

  nowDayStr?: string;
  form?: FormInstance<any>;
  parentDetails?: any,

  groupVarName?: string,

  setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function EditDateModal({ keyStr, varName, label, blueOnionHubRole, onRowUpdate, blueOnionHubRoleFlag, groupVarName, ...props }: EditDateModalProps) {
  //const rowData = blueOnionHubRole?.[varName];
  var groupVarDisabled = !!(groupVarName && !props.form?.getFieldValue(groupVarName));

  useEffect(() => {
    props.form?.validateFields([varName + '_start_date', varName + '_end_date']);
  }, [props.form, varName]);

  const dateRule = blueOnionHubRole ? [
    { required: false, message: 'This field is mandatory' },
    ({ getFieldValue }: any) => ({
      validator(_: any, value: any) {
        //console.log(getFieldValue(varName + '_start_date'));
        if (!value ||
          !getFieldValue(varName + '_start_date') ||
          !getFieldValue(varName + '_end_date') ||
          (getFieldValue(varName + '_start_date').format('YYYY-MM-DD') <= getFieldValue(varName + '_end_date').format('YYYY-MM-DD'))) {
          props.form?.setFields([
            { name: varName, errors: [] },
          ]);
          return Promise.resolve();
        }
        props.form?.setFields([
          { name: varName, errors: ['Start date cannot be later than End date'] },
        ]);
        return Promise.reject(new Error('Start date cannot be later than End date'));
      },
    }),
  ] : void (0);

  const roleName = namedMapping[varName];
  const parentRole = props.parentDetails?.companyRole?.find((v: any) => {
    return v?.role?.name === roleName;
  });
  //console.log('parentRole', varName, roleName, parentRole, blueOnionHubRole?.[varName], !parentRole?.is_allow);

  const [, setUpdateWarning] = useState(0);
  const updateWarning = () => { setUpdateWarning((v) => v + 1); };

  const dtStart = props.form?.getFieldValue(varName + '_start_date')?._d;
  // console.log('dtStart', dtStart);

  const warningStart = props.form?.getFieldValue(varName) && warningText(
    isDateInverted(dtStart, props.nowDayStr) && 'Today is outside period',
    isDateInverted(props.form?.getFieldValue('start_date')?._d, dtStart) && 'Before the service start date',
    isDateInverted(props.form?.getFieldValue('plan_start_date')?._d, dtStart) && 'Before the total control start date',
    dtStart && props.parentDetails && !parentRole?.is_allow && 'Not allowed by company scope.',
    props.form?.getFieldValue(varName) && isDateInverted(parentRole?.start_date, dtStart) && 'Before the company permission start date',
  );
  // console.log('warningStart', warningStart, props.nowDayStr);

  const dtEnd = props.form?.getFieldValue(varName + '_end_date')?._d;
  const warningEnd = props.form?.getFieldValue(varName) && warningText(
    isDateInverted(props.nowDayStr, dtEnd) && 'Today is outside period',
    isDateInverted(dtEnd, props.form?.getFieldValue('expired_date')?._d) && 'After the service expired date',
    isDateInverted(dtEnd, props.form?.getFieldValue('plan_end_date')?._d) && 'After the total control end date',
    dtEnd && props.parentDetails && !parentRole?.is_allow && 'Not allowed by company scope.',
    props.form?.getFieldValue(varName) && isDateInverted(dtEnd, parentRole?.end_date) && 'After the company permission end date',
  );
  //console.log('warningStart', varName, warningStart,props.form?.getFieldValue('start_date')?._d,props.form?.getFieldValue('plan_start_date')?._d);


  const handleCancel = () => {
    props.setModalVisible?.(false);
  };


  return (
    <div>
      <ModalBase
        open={true}
        title='Edit Start/End Date'
        onCancel={handleCancel}
        className={styles.editDateModal}
        width={500}
      >

        <div style={{ display: 'flex' }} >

          <Col span={10}>
            <LabelRequire text="Start Date" warning={warningStart} />
            <DateInput
              name={varName + '_start_date'}
              id={keyStr + varName + '_start_date'}
              dependencies={[varName + '_start_date', varName + '_end_date']}
              rules={dateRule}
              dateInputProps={{
                id: keyStr + varName + '_start_date',

                onChange: (value, dateString) => { onRowUpdate?.(varName + '_start_date', dateString); updateWarning(); },
                disabled: !blueOnionHubRoleFlag?.[varName] || groupVarDisabled,
                placement: 'bottomLeft',
              }}
              enableAllDate={true}

            />
          </Col>
          <Col span={2}></Col>

          <Col span={10}>
            <LabelRequire text="End Date" warning={warningEnd} />
            <DateInput
              name={varName + '_end_date'}
              id={keyStr + varName + '_end_date'}
              dependencies={[varName + '_start_date', varName + '_end_date']}
              rules={dateRule}
              dateInputProps={{
                id: keyStr + varName + '_end_date',

                onChange: (value, dateString) => { onRowUpdate?.(varName + '_end_date', dateString); updateWarning(); },
                disabled: !blueOnionHubRoleFlag?.[varName] || groupVarDisabled,
                placement: 'bottomLeft',

              }}
              enableAllDate={true}

            />
          </Col>

        </div>

      </ModalBase>
    </div>
  );
}
