export const ValidatePhone = {
  pattern: new RegExp('^([0-9]{7,15}$)$'),
  message: 'Please enter a right phone number',
};
export const ValidateUsername = {
  pattern: new RegExp('^[_A-z0-9;./_:]{6,30}$'),
  message: 'Username must be between 6-30 characters with no space',
};
export const noSpace = '^[_A-z0-9;./_:]{6,30}$';
export const onlyLettersNumbersUnderscoresPeriods = '^[ A-Za-z0-9_.]*$';
export const onlyNumber = '^[0-9]*$';
export const phoneNumber = '^[0-9]{7,15}$';

export const nameRegex =
  /^[a-zA-Z0-9._ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]*$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\\"]{1,64}(\.[^<>()[\]\\.,;:\s@\\"]{1,64})*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
