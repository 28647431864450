import React, { useEffect, useState } from 'react';

import { Col, DatePickerProps, Form, FormInstance, Popover } from 'antd';
import SwitchInput from 'components/switch';

import DateInput from 'components/form-control/date-input';
import { ReturnPermissionDto } from 'services/api-permission.type';
import moment from 'moment';
import { isDateInverted, warningText } from './warning-tool';
import { WarningChar } from 'components/label-require/warning-char';
import { KioskTableData } from './kiosk-data';
import { DateIcon } from 'components/icons/date-icon';
import EditDateKioskModal from './edit-date-modal-kiosk';

type KioskTableRowProps = {
  name: string;
  rowData: ReturnPermissionDto;
  onUpdateRowData?: Function;
  disabled?: boolean;
  setDataError?: Function;

  nowDayStr?: string;
  form?: FormInstance<any>;
  parentKioskData?: KioskTableData | null | undefined;

  keyId?: string;
}

export default function KioskTableRow({ name, rowData, onUpdateRowData, disabled, parentKioskData, ...props }: KioskTableRowProps) {
  //console.log(rowData);

  function updateRowData(newRow: ReturnPermissionDto) {
    onUpdateRowData?.(newRow);
    updateWarning();
  }

  const [, setUpdateWarning] = useState(0);
  const updateWarning = () => { setUpdateWarning((v) => v + 1); };
  const [openDateModal, setOpenDateModal] = useState(false);

  let startDateProps: DatePickerProps = {
    disabled: !rowData.is_allow,
    onChange: (v: any) => {
      var mnt = moment(v);
      updateRowData({ ...rowData, start_date: mnt.isValid() ? mnt.format('YYYY-MM-DD') : null });
    },
  };
  if (rowData.start_date) {   //don't show current time
    let mntStart = moment(rowData.start_date);
    if (mntStart.isValid()) startDateProps.defaultValue = startDateProps.value = mntStart;
  }

  let endDateProps: DatePickerProps = {
    disabled: !rowData.is_allow,
    onChange: (v: any) => {
      var mnt = moment(v);
      updateRowData({ ...rowData, end_date: mnt.isValid() ? mnt.format('YYYY-MM-DD') : null });
    },
  };
  if (rowData.end_date) {   //don't show current time
    let mntEnd = moment(rowData.end_date);
    if (mntEnd.isValid()) endDateProps.defaultValue = endDateProps.value = mntEnd;
  }

  const switch_key = 'kiosk_' + rowData.permission_id + '_' + props.keyId;
  const start_date_key = switch_key + '_start_date';
  const end_date_key = switch_key + '_end_date';

  useEffect(() => {
    //console.log('newRow', rowData);
    if (!rowData.start_date || !rowData.end_date ||
      (rowData.start_date <= rowData.end_date)
    ) {
      props.form?.setFields([
        { name: start_date_key, errors: [] },
        { name: end_date_key, errors: [] },
        { name: switch_key, errors: [] },
      ]);
      props.setDataError?.(false);
    }
    else {
      props.form?.setFields([
        { name: start_date_key, errors: ['Start date cannot be later than End date'] },
        { name: end_date_key, errors: ['Start date cannot be later than End date'] },
        { name: switch_key, errors: ['Start date cannot be later than End date'] },
      ]);
      props.setDataError?.(true);
    }
  });

  const parentRowData = parentKioskData?.tableDataMapping[rowData.permission_name];
  //console.log('parentRowData', parentRowData);

  const dtStart = rowData.start_date;
  const warningStart = rowData.is_allow && warningText(
    isDateInverted(dtStart, props.nowDayStr) && 'Today is outside period',
    isDateInverted(props.form?.getFieldValue('start_date')?._d, dtStart) && 'Before the service start date',
    isDateInverted(props.form?.getFieldValue('plan_start_date')?._d, dtStart) && 'Before the total control start date',
    dtStart && rowData.is_allow && parentKioskData && !parentRowData?.is_allow && 'Not allowed by company scope.',
    isDateInverted(parentRowData?.start_date, dtStart) && 'Before the company permission start date',
  );

  const dtEnd = rowData.end_date;
  const warningEnd = rowData.is_allow && warningText(
    isDateInverted(props.nowDayStr, dtEnd) && 'Today is outside period',
    isDateInverted(dtEnd, props.form?.getFieldValue('expired_date')?._d) && 'After the service expired date',
    isDateInverted(dtEnd, props.form?.getFieldValue('plan_end_date')?._d) && 'After the total control end date',
    dtEnd && rowData.is_allow && parentKioskData && !parentRowData?.is_allow && 'Not allowed by company scope.',
    isDateInverted(dtEnd, parentRowData?.end_date) && 'After the company permission end date',
  );

  //console.log('rowData', rowData, startDateProps);
  //console.log('rowData', rowData);
  //console.log('parentKioskData', parentKioskData);

  let dateVisible = rowData.is_allow;
  let dateExisted = dateVisible && (dtStart || dtEnd);
  let startDateText = (dtStart || '-');
  let endDateText = (dtEnd || '-');

  let dateText = dateExisted
    ? (
      <>Start: {startDateText}<br />
        End: {endDateText}</>
    )
    : '';
  let dateWarning = dateExisted && (warningStart || warningEnd);
  let dateWarningText = dateWarning ? (
    ('Start: ' + startDateText + '\n' + (warningStart ? warningStart.replace(/^Warning\s+/i, '') : ((startDateText === '-') ? '' : '* OK')) + '\n' +
      ('End: ' + endDateText + '\n' + (warningEnd ? warningEnd.replace(/^Warning\s+/i, '') : ((endDateText === '-') ? '' : '* OK'))))
  ) : '';

  // console.log('dateWarningText', dateWarningText);

  let dateIconNode: JSX.Element | null = null;


  if (dateVisible) {
    dateIconNode = <DateIcon className='role-row-date-icon' width={20} height={20}
      style={{
        marginLeft: '0.3em',
        opacity: dateExisted ? 1 : 0.2,
        cursor: 'pointer',
      }}
      fill={dateWarning ? ('#FC9536') : '#888888'}
      onClick={() => {
        setOpenDateModal(true);
      }}
    />;

    if (dateWarning) {
      dateIconNode = <WarningChar warning={dateWarningText}>{dateIconNode}</WarningChar>;
    }
    else if (dateExisted) {
      dateIconNode = <Popover content={dateText} trigger='hover' >{dateIconNode}</Popover>;
    }
  }

  // console.log('switch_key', switch_key);

  return (<>

    <Col span={8}>
      <SwitchInput
        key={switch_key}
        text={name}
        checked={!!rowData.is_allow}
        onChange={(v) => {
          updateRowData({ ...rowData, is_allow: v });
        }}
        disabled={disabled}
        warning={rowData.is_allow && warningText(
          parentKioskData && !parentRowData?.is_allow && 'Not allowed by company scope.',
        )}
        appendNode={
          dateVisible && dateIconNode
        }
      />
      <Form.Item name={switch_key} ></Form.Item>
    </Col>
    <Col span={7} style={{ display: 'none' }}>
      <DateInput
        name={start_date_key}
        key={start_date_key}
        dateInputProps={startDateProps}
        enableAllDate={true}
      />
    </Col>
    <Col span={1} style={{ justifyContent: 'left', paddingLeft: '0px', paddingRight: '0px', display: 'none' }} >
      {warningStart ? <WarningChar warning={warningStart} /> : <></>}
    </Col>
    <Col span={7} style={{ display: 'none' }}>
      <DateInput
        name={end_date_key}
        key={end_date_key}
        dateInputProps={endDateProps}
        enableAllDate={true}
      />
    </Col>
    <Col span={1} style={{ justifyContent: 'left', paddingLeft: '0px', paddingRight: '0px', display: 'none' }} >
      {warningEnd ? <WarningChar warning={warningEnd} /> : <></>}
    </Col>


    {
      openDateModal && <EditDateKioskModal
        {...{
          ...props,
          name, rowData, onUpdateRowData, disabled, parentKioskData,
          varName: switch_key,
          setModalVisible: () => { setOpenDateModal(false); },
        }}
      />
    }
  </>
  );
}


