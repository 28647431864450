import React from 'react';

export const ThreeUsersOutlinedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.5957 10.9319C19.198 10.9319 20.4979 9.63298 20.4979 8.03064C20.4979 6.42831 19.198 5.12939 17.5957 5.12939"
        stroke="#4B4B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9297 14.0848C19.4082 14.1178 19.8839 14.1856 20.3524 14.291C21.0032 14.4184 21.786 14.6852 22.0647 15.2691C22.2425 15.6431 22.2425 16.0785 22.0647 16.4534C21.7869 17.0374 21.0032 17.3032 20.3524 17.437"
        stroke="#4B4B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.28889 10.9319C4.68655 10.9319 3.38672 9.63298 3.38672 8.03064C3.38672 6.42831 4.68655 5.12939 6.28889 5.12939"
        stroke="#4B4B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.95565 14.0848C4.47715 14.1178 4.0014 14.1856 3.53298 14.291C2.88215 14.4184 2.09931 14.6852 1.82156 15.2691C1.64281 15.6431 1.64281 16.0785 1.82156 16.4534C2.0984 17.0374 2.88215 17.3032 3.53298 17.437"
        stroke="#4B4B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9367 14.7095C15.1835 14.7095 17.9573 15.2009 17.9573 17.1671C17.9573 19.1325 15.2018 19.6421 11.9367 19.6421C8.68893 19.6421 5.91602 19.1508 5.91602 17.1845C5.91602 15.2183 8.67152 14.7095 11.9367 14.7095Z"
        stroke="#4B4B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9383 11.9049C9.79699 11.9049 8.08008 10.188 8.08008 8.04575C8.08008 5.90442 9.79699 4.1875 11.9383 4.1875C14.0797 4.1875 15.7966 5.90442 15.7966 8.04575C15.7966 10.188 14.0797 11.9049 11.9383 11.9049Z"
        stroke="#4B4B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
