import { SortAscIcon, SortUpIcon } from 'components/icons';
import { OrderByType, SortOrderEnum } from './sorting-arrow.const';

interface SortingArrowProps {
  order?: string;
  orderBy?: OrderByType | string;
  sortingFor?: OrderByType | string;
}

const SortingArrow = ({ order, orderBy, sortingFor }: SortingArrowProps) => {
  const checkedUpIcon = order === SortOrderEnum.DESC && orderBy === sortingFor;
  const checkedAscIcon = order === SortOrderEnum.ASC && orderBy === sortingFor;

  return (
    <div className="flex">
      <SortUpIcon stroke={checkedUpIcon ? '#27272A' : ''} />
      <SortAscIcon stroke={checkedAscIcon ? '#27272A' : ''} />
    </div>
  );
};

export default SortingArrow;
