import React from 'react';

export const NodataIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="374" height="200" viewBox="0 0 374 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9401_9056)">
        <ellipse cx="187" cy="175" rx="186.5" ry="73" fill="url(#paint0_linear_9401_9056)" />
        <path
          d="M237 89.0425H137V135.322C137 137.53 138.448 139.477 140.563 140.112C150.61 143.125 178.865 151.602 185.562 153.612C186.5 153.892 187.5 153.892 188.438 153.612C195.135 151.602 223.39 143.125 233.438 140.112C235.553 139.477 237 137.53 237 135.322C237 123.732 237 89.0425 237 89.0425Z"
          fill="url(#paint1_linear_9401_9056)"
        />
        <path
          d="M237.001 89.0425H187.001C187.001 89.0425 186.516 153.822 187.001 153.822C187.486 153.822 187.968 153.752 188.438 153.612C195.136 151.602 223.391 143.125 233.438 140.112C235.553 139.477 237.001 137.53 237.001 135.322C237.001 123.732 237.001 89.0425 237.001 89.0425Z"
          fill="url(#paint2_linear_9401_9056)"
        />
        <path
          d="M187.001 105.265L137.001 89.0425C137.001 89.0425 128.233 102.042 124.313 107.852C123.873 108.505 123.766 109.322 124.021 110.065C124.278 110.807 124.866 111.385 125.613 111.63C135.111 114.715 163.226 123.85 170.601 126.245C171.658 126.59 172.818 126.192 173.443 125.27C176.796 120.322 187.001 105.265 187.001 105.265Z"
          fill="url(#paint3_linear_9401_9056)"
        />
        <path
          d="M237 89.0425L187 105.265C187 105.265 197.205 120.322 200.558 125.27C201.183 126.192 202.343 126.59 203.4 126.245C210.775 123.85 238.89 114.715 248.388 111.63C249.135 111.385 249.723 110.807 249.98 110.065C250.235 109.322 250.128 108.505 249.688 107.852C245.768 102.042 237 89.0425 237 89.0425Z"
          fill="url(#paint4_linear_9401_9056)"
        />
        <path
          d="M174.898 54.965C174.273 54.0475 173.116 53.65 172.058 53.995C164.688 56.3875 136.566 65.525 127.068 68.6125C126.321 68.855 125.731 69.4325 125.476 70.175C125.221 70.9175 125.328 71.7375 125.768 72.3875C129.378 77.7425 137.001 89.0425 137.001 89.0425L187.001 72.765C187.001 72.765 178.021 59.5575 174.898 54.965Z"
          fill="url(#paint5_linear_9401_9056)"
        />
        <path
          d="M248.232 72.3875C248.673 71.7375 248.78 70.9175 248.525 70.175C248.27 69.4325 247.68 68.855 246.932 68.6125C237.435 65.525 209.313 56.3875 201.943 53.995C200.885 53.65 199.727 54.0475 199.102 54.965C195.98 59.5575 187 72.765 187 72.765L237 89.0425C237 89.0425 244.623 77.7425 248.232 72.3875Z"
          fill="url(#paint6_linear_9401_9056)"
        />
        <path d="M237 89.0425L187 72.765L137 89.0425L187 105.265L237 89.0425Z" fill="url(#paint7_linear_9401_9056)" />
        <path d="M187 105.265V72.765L137 89.0425L187 105.265Z" fill="url(#paint8_linear_9401_9056)" />
        <path
          d="M176.955 79.945C175.915 79.265 174.977 78.5375 174.145 77.775C173.13 76.8425 171.547 76.91 170.612 77.9275C169.68 78.9425 169.747 80.525 170.765 81.4575C171.787 82.3975 172.94 83.2925 174.22 84.13C175.375 84.885 176.925 84.56 177.68 83.405C178.435 82.25 178.11 80.7 176.955 79.945Z"
          fill="url(#paint9_linear_9401_9056)"
        />
        <path
          d="M169.165 72.265C168.422 71.2575 167.795 70.245 167.28 69.2325C166.655 68.0025 165.147 67.5125 163.917 68.1375C162.687 68.7625 162.197 70.27 162.822 71.5C163.457 72.745 164.227 73.9925 165.14 75.23C165.957 76.34 167.522 76.5775 168.635 75.76C169.745 74.94 169.982 73.375 169.165 72.265Z"
          fill="url(#paint10_linear_9401_9056)"
        />
        <path
          d="M165.51 62.0625C165.535 61.055 165.683 60.0775 165.953 59.14C166.333 57.815 165.565 56.4275 164.238 56.0475C162.913 55.6675 161.525 56.435 161.145 57.7625C160.763 59.1025 160.548 60.5 160.51 61.9375C160.478 63.3175 161.568 64.465 162.948 64.4975C164.328 64.5325 165.475 63.4425 165.51 62.0625Z"
          fill="url(#paint11_linear_9401_9056)"
        />
        <path
          d="M169.356 53.7125C169.999 53.1175 170.719 52.5675 171.506 52.07C172.674 51.3325 173.024 49.7875 172.289 48.6225C171.551 47.455 170.006 47.105 168.839 47.84C167.781 48.5075 166.821 49.245 165.959 50.045C164.946 50.9825 164.886 52.565 165.824 53.5775C166.761 54.59 168.344 54.65 169.356 53.7125Z"
          fill="url(#paint12_linear_9401_9056)"
        />
        <path
          d="M178.652 49.4C179.91 49.1675 181.242 49.0175 182.647 48.96C184.027 48.9 185.097 47.7325 185.04 46.355C184.982 44.9775 183.815 43.905 182.435 43.9625C180.785 44.0325 179.22 44.21 177.742 44.4825C176.387 44.735 175.487 46.04 175.74 47.395C175.99 48.7525 177.295 49.65 178.652 49.4Z"
          fill="url(#paint13_linear_9401_9056)"
        />
        <path
          d="M191.683 49.2525C193.816 49.2775 195.801 49.2025 197.643 49.04C199.018 48.9175 200.036 47.7025 199.913 46.3275C199.791 44.955 198.576 43.9375 197.201 44.06C195.513 44.2075 193.696 44.2775 191.741 44.2525C190.361 44.2375 189.228 45.345 189.213 46.7225C189.196 48.1025 190.303 49.2375 191.683 49.2525Z"
          fill="url(#paint14_linear_9401_9056)"
        />
        <path
          d="M205.861 47.5075C208.336 46.75 210.426 45.7801 212.176 44.6676C213.339 43.9251 213.684 42.38 212.941 41.215C212.201 40.0525 210.654 39.7075 209.489 40.45C208.081 41.345 206.394 42.115 204.399 42.725C203.081 43.13 202.336 44.5276 202.741 45.8476C203.144 47.1676 204.544 47.91 205.861 47.5075Z"
          fill="url(#paint15_linear_9401_9056)"
        />
        <path
          d="M218.586 37.67C219.766 35.23 220.218 32.65 220.103 30.1625C220.038 28.7825 218.866 27.7175 217.488 27.7825C216.111 27.8475 215.043 29.0175 215.108 30.395C215.188 32.085 214.883 33.8375 214.083 35.495C213.483 36.7375 214.003 38.235 215.246 38.835C216.488 39.435 217.986 38.9125 218.586 37.67Z"
          fill="url(#paint16_linear_9401_9056)"
        />
        <path
          d="M216.993 21.3225C215.171 18.86 212.698 17.145 210.003 16.5925C208.651 16.3175 207.328 17.1875 207.051 18.54C206.776 19.8925 207.648 21.215 208.998 21.4925C210.553 21.81 211.923 22.8775 212.976 24.3C213.798 25.4075 215.366 25.64 216.473 24.82C217.583 23.9975 217.816 22.43 216.993 21.3225Z"
          fill="url(#paint17_linear_9401_9056)"
        />
        <path d="M183 17.12C185.705 1.80247 209.147 2.79247 195.5 17.12H183Z" fill="url(#paint18_linear_9401_9056)" />
        <path d="M183 21C185.705 36.315 209.147 35.3275 195.5 21H183Z" fill="url(#paint19_linear_9401_9056)" />
        <path
          d="M184.107 21.5425H200.847C202.227 21.5425 203.347 20.4225 203.347 19.0425C203.347 17.6625 202.227 16.5425 200.847 16.5425H184.107C182.73 16.5425 181.607 17.6625 181.607 19.0425C181.607 20.4225 182.73 21.5425 184.107 21.5425Z"
          fill="url(#paint20_linear_9401_9056)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9401_9056"
          x1="187"
          y1="102"
          x2="187.519"
          y2="187.624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A6297" stopOpacity="0.08" />
          <stop offset="0.800735" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9401_9056"
          x1="137"
          y1="121.432"
          x2="237"
          y2="121.432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CADCF0" />
          <stop offset="1" stopColor="#A4BBDB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9401_9056"
          x1="186.786"
          y1="121.432"
          x2="237.001"
          y2="121.432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4BBDB" />
          <stop offset="1" stopColor="#8DA3BE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9401_9056"
          x1="137.333"
          y1="89.0225"
          x2="144.321"
          y2="135.256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC" />
          <stop offset="1" stopColor="#CADCF0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9401_9056"
          x1="187.203"
          y1="105.095"
          x2="239.912"
          y2="135.41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC" />
          <stop offset="1" stopColor="#CADCF0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9401_9056"
          x1="130.456"
          y1="79.34"
          x2="137.998"
          y2="43.7843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC" />
          <stop offset="1" stopColor="#CADCF0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9401_9056"
          x1="200.385"
          y1="53.3925"
          x2="203.082"
          y2="97.2793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC" />
          <stop offset="1" stopColor="#CADCF0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_9401_9056"
          x1="137"
          y1="89.015"
          x2="237"
          y2="89.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CADCF0" />
          <stop offset="1" stopColor="#A4BBDB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_9401_9056"
          x1="192.12"
          y1="72.0375"
          x2="193.198"
          y2="112.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4BBDB" />
          <stop offset="1" stopColor="#8DA3BE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_9401_9056"
          x1="174.021"
          y1="77.1177"
          x2="174.021"
          y2="84.5372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_9401_9056"
          x1="166.101"
          y1="67.8658"
          x2="166.101"
          y2="76.2463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_9401_9056"
          x1="163.28"
          y1="55.9503"
          x2="163.28"
          y2="64.4984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_9401_9056"
          x1="168.916"
          y1="47.4554"
          x2="168.916"
          y2="54.3781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_9401_9056"
          x1="180.37"
          y1="43.9603"
          x2="180.37"
          y2="49.4418"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_9401_9056"
          x1="194.568"
          y1="44.05"
          x2="194.568"
          y2="49.2573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_9401_9056"
          x1="207.982"
          y1="40.0583"
          x2="207.982"
          y2="47.617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_9401_9056"
          x1="216.977"
          y1="27.7797"
          x2="216.977"
          y2="39.0838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_9401_9056"
          x1="212.243"
          y1="16.5417"
          x2="212.243"
          y2="25.3102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_9401_9056"
          x1="183"
          y1="11.56"
          x2="199.737"
          y2="11.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC" />
          <stop offset="1" stopColor="#CADCF0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_9401_9056"
          x1="183"
          y1="26.56"
          x2="199.737"
          y2="26.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC" />
          <stop offset="1" stopColor="#CADCF0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_9401_9056"
          x1="192.477"
          y1="16.5425"
          x2="192.477"
          y2="21.5425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4D9FF" />
          <stop offset="1" stopColor="#B4D9FF" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_9401_9056">
          <rect width="373" height="200" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
