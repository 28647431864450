import { Switch, SwitchProps } from 'antd';
import clsx from 'clsx';

import styles from './switch-input.module.scss';
import { WarningChar } from 'components/label-require/warning-char';
import { useState } from 'react';

interface SwitchInputProps extends SwitchProps {
  text: string;
  className?: string;
  required?: boolean;
  title?: string;
  warning?: string | false | null | undefined;
  autoLabelWidth?: boolean;
  appendNode?: React.ReactNode;
}

const SwitchInput = ({ text, className, required = false, warning, autoLabelWidth, appendNode, ...props }: SwitchInputProps) => {
  const [changed, setChanged] = useState(false);

  const onSwitchChange = (checked: boolean, evt: React.MouseEvent<HTMLButtonElement>) => {
    setChanged(true);
    props.onChange?.(checked, evt);
  };

  return (
    <div className={clsx(className, styles.switch)}>
      <span className={clsx('text-switch', styles.text, props.checked ? styles.textChecked : styles.textDisabled)} {...(autoLabelWidth ? { style: { minWidth: 'auto' } } : {})}>
        <span
          style={{ display: 'inline-block', whiteSpace: 'pre-wrap' }}
          {...(props?.title ? { title: props.title } : null)}
        >{text.replaceAll('\\n', '\n')}</span>
        {required && <span style={{ color: '#ff424e', opacity: 0.4 }}>*</span>}
      </span>
      {warning
        ? <WarningChar warning={warning} defaultOpen={changed} ><Switch className='switch-warning' {...props} onChange={onSwitchChange} /></WarningChar>
        : <Switch {...props} onChange={onSwitchChange} />
      }
      {appendNode || <></>}
    </div>
  );
};

export default SwitchInput;
