export type StepsType = {
  key: number;
  title: string;
  content: string | React.ReactNode;
};

type StepClassNameType = {
  titleClassName: string;
  iconClassName: string;
};

export type RenderTypeOfStepType = (indexStep: number) => StepClassNameType;

export type HubsStatusType = {
  fundHub: string | undefined;
  portfolioHub: string | undefined;
  impactHub: string | undefined;
  companyHub: string | undefined;
  climateHub: string | undefined;
};

export type CompanyInfoType = {
  companyName: string;
  companyDomain: string;
  companyAddress: string;
  firstName: string;
  lastName: string;
  emailName: string;
  phoneNumber: string;
  shouldCreateCompanyAdminByEmail: boolean;
  userName: string;
};

export const SHOULD_CREATE_COMPANY_ADMIN_BY_EMAIL = 'shouldCreateCompanyAdminByEmail';

export type ConfigurePlanInfoType = {
  startDate: string;
  endDate: string;
  reportQuota: string;
  numberOfLicense: string;
};

export const errorKeyWordNewCompany = {
  domainExisted: '"Company Email Domain” already exist',
  emailExisted: 'User existed!',
  phoneExisted: 'Phone existed!',
  phoneError: 'Unprocessable Entity',
};

export const listBlueOnionHub = [
  {
    id: '1',
    name: 'Fund Hub',
  },
  {
    id: '2',
    name: 'Portfolio Hub',
  },
  {
    id: '3',
    name: 'Company Hub',
  },
  {
    id: '4',
    name: 'Climate Hub',
  },
  {
    id: '5',
    name: 'Impact Hub',
  },
];

export const listBlueOnionPremiumModules = [
  {
    id: '1',
    name: 'TCFD',
  },
  {
    id: '2',
    name: 'SFDR',
  },
  {
    id: '3',
    name: 'UN SDG',
  },
  {
    id: '4',
    name: 'SASB',
  },
];
