import { Modal, ModalProps } from 'antd';

import { ActivateIcon, DisableIcon } from 'components/icons';
import styles from './styles.module.scss';

interface ConfirmActiveModalProps extends ModalProps {
  modalType: ValueType;
}

export type ValueType = 'activate' | 'disable';

export const ConfirmActiveModal = ({ modalType, ...props }: ConfirmActiveModalProps) => {
  const type = {
    activate: {
      title: 'ACTIVE ACCOUNT',
      okText: 'Yes, Activate Now',
      icon: <ActivateIcon />,
      content:
        'Are you sure you want to activate this user account? User will be able to log in and collaborate on any hubs or modules which they have access within BlueOnion System.',
    },
    disable: {
      title: 'DISABLE ACCOUNT',
      okText: 'Yes, Disable Now',
      icon: <DisableIcon />,
      content:
        'Are you sure you want to temporary disable this user account? User will no longer be able to log in and collaborate on modules which they currently have access within BlueOnion System.',
    },
  };
  return (
    <Modal
      className={styles.activeModal}
      title={type[modalType].title}
      okText={type[modalType].okText}
      centered
      {...props}
    >
      <div className={styles.iconContainer}>{type[modalType].icon}</div>
      <p className={styles.content}>{type[modalType].content}</p>
    </Modal>
  );
};
