import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { Checkbox } from 'antd';

import styles from './select-filter.module.scss';
import { CloseIcon } from 'components/icons';

export type OptionSelect = {
  id: string;
  name: string;
};

interface CommonSelectProps extends SelectProps {
  optionSelect: OptionSelect[];
  values: string[];
  handleSelect: (value: string[]) => void;
}

const { Option } = Select;

const SelectFilter = ({ optionSelect, values, handleSelect }: CommonSelectProps) => {
  const optionSelectValue = optionSelect.map((item) => item.id);
  const [selectValue, setSelectValue] = useState<string[]>(values);

  const [isCheckSelectAll, setIsCheckSelectAll] = useState(false);

  const handleChange = (checked: boolean, value: string) => {
    const selectedValues = [...selectValue];
    if (!checked) {
      const idx = selectedValues.findIndex((ele) => ele === value);
      if (idx < 0) return;
      selectedValues.splice(idx, 1);
    } else {
      selectedValues.push(value);
    }
    handleSelect(selectedValues);
  };

  const handleSelectAll = () => {
    setIsCheckSelectAll(!isCheckSelectAll);
    setSelectValue(!isCheckSelectAll ? optionSelectValue : []);
    handleSelect(!isCheckSelectAll ? optionSelectValue : []);
  };

  useEffect(() => {
    setIsCheckSelectAll(selectValue.length === optionSelect.length);
  }, [optionSelect.length, selectValue.length]);
  useEffect(() => {
    const ids: string[] = [];
    if (values) {
      optionSelect.forEach((item) => {
        if (values.includes(item.id)) {
          ids.push(item.id);
        }
      });
    }
    setSelectValue(ids);
  }, [values, optionSelect]);

  return (
    <>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={selectValue.length === optionSelect.length ? 'All' : 'Select'}
        tagRender={(props) => (
          <span className={styles.tagPreview}>
            {props.label}
            <CloseIcon width={10} height={10} onClick={() => handleChange(false, props.value)} />
          </span>
        )}
        autoClearSearchValue
        showArrow
        showSearch={false}
        value={selectValue.length === optionSelect.length ? undefined : selectValue}
        maxTagCount="responsive"
        className={clsx(
          styles.commonSelect,
          selectValue.length === optionSelect.length ? styles.placeHolderAll : styles.placeHolderSelect,
        )}
        dropdownStyle={{ zIndex: 10 }}
      >
        <Option value="select-all">
          <Checkbox
            onChange={handleSelectAll}
            checked={selectValue.length === optionSelect?.length}
            className={styles.selectItem}
          >
            Select All
          </Checkbox>
        </Option>
        {optionSelect.map((item) => (
          <Option value={item.id} key={item.id}>
            <Checkbox
              checked={selectValue.includes(`${item.id}`)}
              onChange={(e) => handleChange(e.target.checked, item.id)}
              className={styles.selectItem}
            >
              {item.name} (
              {item.name.toLowerCase() === 'company hub'
                ? 'CoH'
                : item.name
                    .match(/\b(\w)/g)
                    ?.join('')
                    .slice(0, 4)}
              )
            </Checkbox>
          </Option>
        ))}
      </Select>
    </>
  );
};
export default memo(SelectFilter);
