export const PATH_LOGIN = '/login';
export const PATH_RESET_PASSWORD = '/reset-password';
export const PATH_CHANGE_PASSWORD = '/change-password';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_PLAN_CONFIGURATION = '/plan-configuration';
export const PATH_COMPANY_USER = '/plan-configuration/company-user';
export const PATH_INDIVIDUAL_USER = '/plan-configuration/individual-user';
export const PATH_USER_MANAGEMENT = '/user-management';
export const PATH_INTERNAL_USER = '/user-management/internal-user';
export const PATH_EXTERNAL_USER = '/user-management/external-user';
export const PATH_NEW_EXTERNAL_USER = '/user-management/external-user/add-user';
export const PATH_NEW_INTERNAL_USER = '/user-management/internal-user/add-user';
export const PATH_TOOLS = '/tools';
export const PATH_LOG = '/tools/log';
export const PATH_SETTINGS = '/tools/settings';
