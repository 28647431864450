export type FieldsAddNewUser = {
  username: string;
  userEmail: string;
  expiryDate: string;
  country: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  start_date: string;
  expired_date: string;
  plan_start_date: string;
  plan_end_date: string;
  is_admin: boolean;
  is_trial: boolean;
};

export const errorKeyWord = {
  userExisted: 'This username already exists',
  emailExisted: 'This email already exists',
  phoneExisted: 'This phone number already exists',
  phoneError: 'Unprocessable Entity',
};
