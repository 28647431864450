import React from 'react';

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 3.4488C6.10601 3.4488 2.94904 6.60577 2.94904 10.5001C2.94904 14.3944 6.10601 17.5514 10.0003 17.5514C13.8946 17.5514 17.0516 14.3944 17.0516 10.5001C17.0516 6.60577 13.8946 3.4488 10.0003 3.4488ZM1.66699 10.5001C1.66699 5.89771 5.39795 2.16675 10.0003 2.16675C14.6027 2.16675 18.3337 5.89771 18.3337 10.5001C18.3337 15.1025 14.6027 18.8334 10.0003 18.8334C5.39795 18.8334 1.66699 15.1025 1.66699 10.5001Z"
        fill="#4B4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 14.1326C10.3544 14.1326 10.6414 13.8456 10.6414 13.4915V10.0727C10.6414 9.7187 10.3544 9.43171 10.0003 9.43171C9.6463 9.43171 9.3593 9.7187 9.3593 10.0727V13.4915C9.3593 13.8456 9.6463 14.1326 10.0003 14.1326Z"
        fill="#4B4B4B"
      />
      <path
        d="M10.855 7.50863C10.855 7.03659 10.4724 6.65393 10.0003 6.65393C9.52829 6.65393 9.14563 7.03659 9.14563 7.50863C9.14563 7.98067 9.52829 8.36333 10.0003 8.36333C10.4724 8.36333 10.855 7.98067 10.855 7.50863Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};
