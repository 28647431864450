export const breadcrumbNameMap: Record<string, string> = {
  // '/plan-configuration': 'Plan Configuration',
  '/plan-configuration/company-user': 'Company User',
  '/plan-configuration/individual-user': 'Individual User',
  // '/user-management': 'User Management',
  '/user-management/internal-user': 'Internal Users Management',
  '/user-management/external-user': 'External Users Management',
  '/user-management/external-user/add-user': 'Add New User',
  '/user-management/internal-user/add-user': 'Add New User',
  '/user-management/log': 'Log',
};
