import { UploadFile } from 'antd/lib/upload/interface';

export type InfoFileType = {
  file: UploadFile;
  fileList: UploadFile[];
};

//export const checkEmailRegex = /^[a-zA-Z]{1}[\w]+@[a-z]{2,}(\.[a-z]+)+$/;

export const SIZE = 1024;

/*
export const messageError = {
  undefinedField: 'Your csv file has unidentified fields.',
  notMatchTemplateFile:
    'Error uploading file: Only files with the comply template are allowed. Please make sure your uploaded file match with the template',
  duplicatedEmail: (listEmail: string) => `Duplicated Email [${listEmail}]. Please review and re-upload the file`,
  notComplyEmail: (domain: string) =>
    `Some emails do not comply with the company domain (${domain}). You may need to review them again`,
};
*/

export enum ClassNameEnum {
  SelectItemOptionContent = 'ant-select-item-option-content',
  OptionPressedCustom = 'option-pressed-custom',
}

export const csvMineType = ['application/vnd.ms-excel', 'text/csv'];
export const xlsxMineType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
