import { Route, Switch } from 'react-router-dom';

import { DashboardLayout } from 'components/layouts';
import { PrivatePage } from 'components/private-page';
import { Login, NotFoundPage } from 'pages';
import { PATH_CHANGE_PASSWORD, PATH_FORGOT_PASSWORD, PATH_LOGIN, PATH_RESET_PASSWORD } from './routes.path';
import ForgotPasswordPage from 'pages/forgot-password';
import ChangePasswordPage from 'pages/change-password';
import ResetPasswordPage from 'pages/reset-password';


export default function Routes() {
  return (
    <Switch>
      <PrivatePage path={PATH_LOGIN}>
        <Login />
      </PrivatePage>
      <Route path={PATH_CHANGE_PASSWORD}>
        <ChangePasswordPage />
      </Route>
      <Route path={PATH_RESET_PASSWORD}>
        <ResetPasswordPage />
      </Route>
      <Route path={PATH_FORGOT_PASSWORD}>
        <ForgotPasswordPage />
      </Route>
      <PrivatePage path="/">
        <DashboardLayout />
      </PrivatePage>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
