import { Button, ModalProps } from 'antd';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import styles from './leave-popup.module.scss';
import { LeaveIcon } from './leave-icon';
import ModalBase from '../modal-base';

interface leavePopupProps extends ModalProps {
  togglePopup: () => void;
  redirectUrl?: string;
}

const LeavePopup = ({ togglePopup, redirectUrl, ...props }: leavePopupProps) => {
  const router = useHistory();

  const onLeave = () => {
    togglePopup();
    if (redirectUrl) {
      router.replace(redirectUrl);
    }
  };

  return (
    <ModalBase
      {...props}
      visible={true}
      onCancel={togglePopup}
      className={clsx(styles.leavePopup, 'common-modal')}
      centered
      closable={false}
      destroyOnClose
    >
      <LeaveIcon />
      <div className={styles.leavePopupContent}>
        <h2>Are you sure you want to leave?</h2>
        <p>Changes you have made may not be saved.</p>
      </div>

      <div className={styles.leavePopupBtn}>
        <Button className="submit-btn" type="default" onClick={onLeave}>
          Leave
        </Button>
        <Button className={styles.cancelBtn} type="primary" onClick={togglePopup}>
          Stay
        </Button>
      </div>
    </ModalBase>
  );
};

export default LeavePopup;
