import { useState } from 'react';
import { Avatar, Divider, Dropdown, Menu } from 'antd';
import jwt_decode from 'jwt-decode';

import { ChevronRightIcon, LogOutIcon } from 'components/icons';
import LogOutModal from './log-out-modal';
import { ChangePasswordModal } from '../change-password-modal';
import { getCurrentUser } from 'redux/auth/auth.selectors';
import { store } from 'redux/store';
import './style.scss';
import { isSysAdmin } from 'redux/has-permission';

export type LogOutProps = {
  collapsed: boolean;
};

interface accessTokenType {
  email: string;
  fullName: string;
  sub: string;
  iat: number;
  exp: number;
  subType?: string;
}
export const LogOut = () => {
  const [visibleLogoutModal, setVisibleLogoutModal] = useState<boolean>(false);
  const [visibleChangePasswordModal, setVisiblePasswordChangeModal] = useState<boolean>(false);

  const currentUser = getCurrentUser(store.getState());
  const currentUserInfo = currentUser && (jwt_decode(currentUser.accessToken) as accessTokenType);
  const menu = (
    <Menu className="my-profile-container">
      <Menu.Item>
        <div className="top-info">
          <div className="avatar">
            <Avatar size={64} style={{ backgroundColor: '#EFECE8' }}>
              {isSysAdmin() ? 'A' : 'C'}
            </Avatar>
            <div
              className="role"
              title={isSysAdmin() ? 'System Admin' : 'Company Admin'}
            >
              {isSysAdmin() ? 'admin' : 'company'}
            </div>
          </div>
          <div className="info">
            <div className="name">{isSysAdmin() ? 'Admin' : 'Company Admin'}</div>
            <div className="email">{currentUserInfo?.email || '-'}</div>
          </div>
        </div>
      </Menu.Item>
      {currentUserInfo?.sub !== '1' && currentUserInfo?.subType === 'user' && //the root user and members are not allowed to change password
        <>
          <Divider />
          <Menu.Item>
            <div className="profile-item-btn" onClick={() => setVisiblePasswordChangeModal(true)}>
              Change Password <ChevronRightIcon />
            </div>
          </Menu.Item>
        </>
      }
      <Divider />
      <Menu.Item onClick={() => setVisibleLogoutModal(true)}>
        <div className="profile-item-btn">
          Sign Out
          <LogOutIcon />
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        overlayStyle={{ zIndex: 950, position: 'fixed' }}
        trigger={['click']}
        placement="bottomRight"
        arrow={true}
      >
        <div className="information">
          <div className="name">{isSysAdmin() ? 'Admin' : 'Company Admin'}</div>
          <Avatar size={36}>{isSysAdmin() ? 'A' : 'C'}</Avatar>
        </div>
      </Dropdown>
      {visibleChangePasswordModal && <ChangePasswordModal
        width={516}
        open={true}
        setVisibleChangePasswordModal={setVisiblePasswordChangeModal}
      />
      }
      <LogOutModal visible={visibleLogoutModal} onClose={() => setVisibleLogoutModal(false)} />
    </>
  );
};
