import { RoleType } from 'services/api-external-user.type';
import { PermissionImportDto } from './company-user-modal/company-user-modal.const';
import { putCompanyMembersPermissionBatch } from 'services/api-permission.service';
import { namedMappingRev } from './change-plan-modal/role-tool';
//import { UpdateRolesDto } from 'services/api-permission.type';
import { importableRoles } from './importable-roles';
//import { isSysAdmin } from 'redux/has-permission';
//import prohibitedDomains from 'components/modal/import-batch-modal/prohibited-domains';

const checkEmailRegex = /^[a-z0-9A-Z]+[-\w.]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+[a-z]{2,}$/;

enum IDX {
  COMPANY = 0,
  EMAIL = 2,
  MODULE = 3,
  REGION = 4,
  STARTDATE = 5,
  ENDDATE = 6
};

const moduleMapping: { [key: string]: string; } = {
  KioskHomePage: 'home',
  KioskFundHub: 'fund',
  KioskPortfolioHub: 'portfolio',
};

function roleKeyFromName(name: string) {
  return name.toLowerCase().replace(/\W+/g, '');
}

function companyKeyFromName(name: string) {
  return name.toLowerCase().replace(/\W+/g, '');
}

//map some names different from role-name
var roleNameMapping: { [key: string]: string; } = {
  'Fund Screener': 'Fund Hub Screener',
  'Portfolio Plus': 'Portfolio Hub Plus',
  'Company Screener': 'Company Hub Screener',
};

Object.keys(roleNameMapping).forEach(
  (v) => {
    roleNameMapping[roleKeyFromName(v)] = roleNameMapping[v];
  },
);

//console.log(roleNameMapping);

/*
return the mapping small-case-no-spaces string to the RoleType
'fundhub' --> { id, name: 'Fund Hub' }
*/
function mapHubsRoles(hubsRoles: RoleType[] | null): { [key: string]: RoleType; } {
  return hubsRoles?.reduce((r: { [key: string]: RoleType; }, v) => {
    if (!(v.name in namedMappingRev)) return r;  //only support controllable roles
    if (!(v.name in importableRoles)) return r;  //only support controllable imported roles
    if (v.name.match(/^(kiosk|notify:)/i)) return r;  //skip old kiosk and notify group

    r[roleKeyFromName(v.name)] = v;
    return r;
  }, {}) || {};
}

export async function verifyData(
  companyDetails: any,
  regionMapping: any,
  hubsRoles: RoleType[] | null,
  rows: string[][],
  output?: PermissionImportDto[],
) {

  const hubsMapping = mapHubsRoles(hubsRoles);
  //console.log(hubsMapping);
  //console.log(companyDetails);

  if (rows[0].slice(2, 7).join() !== 'AccountID,Module,Region,StartDate,EndDate') {
    // console.log(rows[0].slice(2,7).join());
    return { result: false, message: 'header fail, ' + rows[0] };
  }
  if (rows.length < 2) {
    return { result: false, message: 'empty data' };
  }

  //var isCompanyAdmin = !isSysAdmin();
  const companyNameKey = companyKeyFromName(companyDetails.name);

  for (var i = 1; i < rows.length; i++) {
    //console.log(rows);
    var row = rows[i], v;
    var dto: PermissionImportDto = {};
    //email
    v = row[IDX.EMAIL];
    if (!v && !row.join('').trim())
      continue; //void line

    if (!v.match(checkEmailRegex)) {
      //console.log(row);
      return { result: false, line: i + 1, message: 'email format error: ' + row };
    }

    /*
      there should not be more checking on email when importing the kiosk permission,
      because the account is already existed and had been created by system admin.
    */

    /*
    if (companyDetails.domain && !v.endsWith('@' + companyDetails.domain)) {
      if (!companyDetails.is_accept_public_email) {
        return { result: false, line: i+1, message: 'require company domain ' + companyDetails.domain + ': ' + row };
      }

      if (!companyDetails.is_accept_prohibited_email || isCompanyAdmin) {
        const emailDomain = v.slice(v.lastIndexOf('@') + 1);
        //console.log('emailDomain', emailDomain);
        if (prohibitedDomains.includes('\n' + emailDomain + '\n')) {
          return { result: false, line: i+1, message: 'domain is not allow' + emailDomain + ': ' + row };
        }
      }
    }
    */

    dto.email = v;

    //company
    v = row[IDX.COMPANY];
    if (companyNameKey !== companyKeyFromName(v)) {
      return { result: false, line: i + 1, message: 'company name is not matched \'' + companyDetails.name + '\'' };
    }

    //module
    v = row[IDX.MODULE];
    v = roleNameMapping[roleKeyFromName(v)] || v;  //transfer to standard name

    if (v in moduleMapping) {
      dto.module = moduleMapping[v];
      dto.is_kiosk = true;
    }
    else {
      const rnk = roleKeyFromName(v);
      if (rnk in hubsMapping) {
        dto.module = hubsMapping[rnk].name;
        dto.is_kiosk = false;
      }
      else {
        return { result: false, line: i + 1, message: 'module error: ' + row };
      }
    }

    //region
    if (dto.is_kiosk) {
      v = row[IDX.REGION].toLowerCase();
      if (!(v in regionMapping))
        return { result: false, line: i + 1, message: 'region error: ' + row };
      dto.region = regionMapping[v];
    }
    else {
      dto.region = '-';
    }


    //start date
    v = row[IDX.STARTDATE];
    //console.log(v,typeof v);
    if (!v.match(/^\d{8}$/))
      return { result: false, line: i + 1, message: 'start-date format error: ' + row };

    dto.start_date = v.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');

    //end date
    v = row[IDX.ENDDATE];
    if (!v.match(/^\d{8}$/))
      return { result: false, line: i + 1, message: 'end-date format error: ' + row };

    if (row[IDX.STARTDATE] > row[IDX.ENDDATE])
      return { result: false, line: i + 1, message: 'start-date is after end-date: ' + row };

    dto.end_date = v.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');

    //output?.push(dto);

    if (dto.is_kiosk) {
      //sync kiosk module
      for (const mk in moduleMapping) {
        output?.push({ ...dto, module: moduleMapping[mk] });
      }
    }
    else {
      output?.push(dto);
    }

  }

  return { result: true };
}

export async function importData(companyDetails: any, regionMapping: any, hubsRoles: RoleType[] | null, rows: string[][]) {
  var dtos: PermissionImportDto[] = [];

  var r = await verifyData(companyDetails, regionMapping, hubsRoles, rows, dtos);
  if (!r.result)
    return r;

  //console.log(dtos);
  try {
    var res = await putCompanyMembersPermissionBatch(+companyDetails.id, dtos);
    if (res?.success) {
      //console.log(res);
      return { result: true, message: res.memberCount ? ('Success, member count: ' + res.memberCount) : '' };
    }
    else {
      return { result: false, message: JSON.stringify(res).slice(0, 255) };
    }
  }
  catch (ex: any) {
    //console.log(ex);
    return { result: false, message: '' + ex };
  }
}
