import { useCallback, useMemo, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { Menu, Spin } from 'antd';

import { TextInput } from 'components/form-control';
import { SearchIcon, XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
//import { CompanyUserModal } from './company-user-modal';
import SortingArrow from 'components/sorting-arrow';
import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { externalUserApi } from 'services/api-external-user.services';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
//import SelectFilter from './select-filter';
import { NoDataTable } from 'components/no-data-table';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from '../company/company.const';
import { isSysAdmin } from 'redux/has-permission';
import { UserCompanyType } from '../company/company-user-modal/company-user-modal.const';
import { isNil } from 'utils/helper';
import { DeactivatedDisplayer, DeactivatedType } from '../company/company-user-modal/status-displayer';
import moment from 'moment';
import styles from './style.module.scss';
// import { useDebounce } from 'hooks/use-debounce';
import { RowActionMenu, RowActionMenuProps } from '../company/row-action-menu';
import { EllipsisCell } from './ellipsis-cell';
//import LabelRequire from 'components/label-require';

const ACTION_LABEL = {
  CHANGE_PLAN: 'Change Plan',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
};

function ActionMenu({ row, className, action, ...props }: RowActionMenuProps) {
  // console.log(row);
  if (row.is_deactivated === null) {
    row.is_deactivated = 0;
  }
  const menu = (
    <Menu className={className}>
      <Menu.Item key='Enable' onClick={() => { action(ACTION_LABEL.ENABLE, row); }}
        disabled={parseInt(row.is_deactivated) !== 1
        }>Enable</Menu.Item>
      <Menu.Divider />
      <Menu.Item key='Disable' onClick={() => { action(ACTION_LABEL.DISABLE, row); }}
        disabled={parseInt(row.is_deactivated) !== 0}
      >Disable</Menu.Item>
    </Menu>
  );
  return <RowActionMenu {...{ row, className, action, ...props, menu }} />;
}

type AccountProps = {
  companyId?: string
};

export default function Account({ companyId, ...props }: AccountProps) {
  const history = useHistory();
  const location = useLocation();

  const { pageSize, page, search, sortOrder, sortBy, tabKey } = useParseParams();
  const [valueSearch, setValueSearch] = useState<string>(search);

  // Handle Search Parameters
  const setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 20,
          search,
          sortOrder: sortOrder ?? 'asc',
          sortBy: sortBy ?? 'id',
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, search, sortBy, sortOrder],
  );

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({ page: 1, search: e.target.value, tabKey });
    }, 700);
  };

  //console.log([location.pathname, pageSize, page, search, sortOrder, sortBy, tabKey]);

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<UserCompanyType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
      tabKey,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  var isCompanyAdmin = !isSysAdmin();

  async function onAccountAction(label: string, row: any) {
    await externalUserApi.setAccountAvailability(row.id, { is_deactivated: (label === ACTION_LABEL.DISABLE) ? 1 : 0 });
    refetch();
  }

  const columns: ColumnsType<UserCompanyType> = [
    {
      align: 'left',
      title: renderTitle('ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      //ellipsis: true,
    },
    {
      align: 'left',
      title: renderTitle('First Account', 'username'),
      dataIndex: 'username',
      key: 'username',
      width: 180,
      sorter: true,
      //ellipsis: true,
      render: (_, row) => <EllipsisCell textValue={row.username} />,
    },
    {
      align: 'left',
      title: renderTitle('Email', 'email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      //ellipsis: true,
      render: (_, row) => <EllipsisCell textValue={row.email} />,
    },
    {
      align: 'left',
      title: renderTitle('Status', 'is_deactivated'),
      dataIndex: 'is_deactivated',
      key: 'is_deactivated',
      sorter: true,
      render: (text: DeactivatedType) => (
        <div className={styles.statusColumn}>
          <DeactivatedDisplayer value={text} />
        </div>
      ),
    },
    {
      align: 'left',
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (created_at: string) => <div>{isNil(created_at) ? '-' : moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    {
      align: 'left',
      title: 'Members Count',
      dataIndex: 'members',
      key: 'members',
      sorter: true,
      width: 120,
      render: (members: any) => (
        <div className="numberOfUser" >
          {members.length > 0 ?
            <Link to={`/user-management/external-user?page=1&pageSize=20&sortOrder=asc&sortBy=id&search='${members[0].email}'&roleIds=5,6,7,8,10&tabKey=Member`}>
              {members.length}
            </Link>
            :
            <>
              {members.length}
            </>
          }
        </div>
      ),
    },
    {
      title: 'Action',  //'Actions',  //too wide
      dataIndex: 'changeAccountPlane',
      key: 'changeAccountPlane',
      width: 80,
      align: 'center',

      render: (_, data: UserCompanyType) => {
        return (
          (isCompanyAdmin && data.is_admin) ? '' :
            <ActionMenu row={data} className={'actionMenu'} action={onAccountAction} />
        );
      },

    },
  ];

  const ClearFilters = () => {
    setParamHandle({ page: 1, pageSize: 20, search: undefined, tabKey });
    setValueSearch('');
  };

  const onChangePage = (page: number, pageSize: number) => {
    setParamHandle({ page, pageSize, tabKey });
  };



  const {
    data: fetchListAccounts,
    isLoading,
    refetch } = useQuery(
      [
        'listAccount',
        {
          pageSize,
          page,
          search,
          sortBy,
          sortOrder,
        },
      ], () => {
        return externalUserApi.getAccountList({
          pageSize,
          page,
          search: search?.trim(),
          sortBy,
          sortOrder,
        });
      });


  const totalResult = useMemo(() => {
    return fetchListAccounts?.data?.total ?? 0;
  }, [fetchListAccounts?.data?.total]);

  return (
    <div key={'AccountPanel' + tabKey}>
      <p className="filter-hub-text">Search</p>
      <div className="filter-hub-flex">
        <div className="filter-hub-options">
          <TextInput
            placeholder={'Search by Email or User Name'}
            className="input"
            inputProps={{
              onChange: onChangeInput,
              value: valueSearch,
              prefix: <SearchIcon width={20} height={20} />,
              maxLength: 255,
            }}
          />
        </div>
        <div className="clear-filters" onClick={ClearFilters}>
          <span>Clear all filters</span>
          <XCircleIcon width={24} height={24} />
        </div>
      </div>
      {
        isLoading ?
          (
            <Spin className="spin" />
          ) : (
            <>
              {!fetchListAccounts?.data.data || fetchListAccounts?.data.data.length === 0 ? (
                <NoDataTable />
              ) : (
                <>
                  <Table
                    rowKey={'id'}
                    columns={columns}
                    dataSource={fetchListAccounts?.data.data || []}
                    pagination={false}
                    onChange={onChangeTable}
                    loading={isLoading}
                  />

                  <div className="pagination">
                    <div className="total-results-text">
                      Total results: <p className="total-results-number">{totalResult}</p>
                    </div>
                    <CustomPagination
                      pageSize={fetchListAccounts?.data?.pageSize ?? 20}
                      current={fetchListAccounts?.data?.page ?? 1}
                      total={totalResult}
                      onChange={onChangePage}
                      showSizeChanger={true}
                    />
                  </div>
                </>
              )}
            </>
          )
      }

    </div>
  );
}
