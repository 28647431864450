import React from 'react';

export const DeleteSolidIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2871 5.74297C20.6761 5.74297 21 6.06596 21 6.47696V6.85696C21 7.25795 20.6761 7.59095 20.2871 7.59095H3.71385C3.32386 7.59095 3 7.25795 3 6.85696V6.47696C3 6.06596 3.32386 5.74297 3.71385 5.74297H6.62957C7.22185 5.74297 7.7373 5.32197 7.87054 4.72798L8.02323 4.04598C8.26054 3.11699 9.0415 2.5 9.93527 2.5H14.0647C14.9488 2.5 15.7385 3.11699 15.967 3.99699L16.1304 4.72698C16.2627 5.32197 16.7781 5.74297 17.3714 5.74297H20.2871ZM18.8058 19.634C19.1102 16.7971 19.6432 10.0571 19.6432 9.98913C19.6626 9.78313 19.5955 9.58813 19.4623 9.43113C19.3193 9.28413 19.1384 9.19713 18.9391 9.19713H5.06852C4.86818 9.19713 4.67756 9.28413 4.54529 9.43113C4.41108 9.58813 4.34494 9.78313 4.35467 9.98913C4.35646 10.0016 4.37558 10.239 4.40755 10.6359C4.54958 12.3992 4.94517 17.3102 5.20079 19.634C5.38168 21.346 6.50498 22.422 8.13206 22.461C9.38763 22.49 10.6811 22.5 12.0038 22.5C13.2496 22.5 14.5149 22.49 15.8094 22.461C17.4929 22.432 18.6152 21.375 18.8058 19.634Z"
        fill="#FF424F"
      />
    </svg>
  );
};
