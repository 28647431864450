import styles from './label-require.module.scss';
import { Popover } from 'antd';

interface WarningCharProps {
  warning: string;
  charString?: React.ReactNode;
  message?: string;
  children?: React.ReactNode;
  defaultOpen?: boolean;
}

export const WarningChar = ({ warning, charString, message, children, defaultOpen }: WarningCharProps) => {
  if (!warning) return <>{children}</>;

  const popContent = warning.replace(/^warning\s*/i, '').split('\n').map((v, idx) => {
    return <p key={`WarningCharPop${idx}`}>{v}</p>;
  });

  //check taking effect by search 'today'
  const takeEffect = !!warning.match(/\btoday\b/i) && !warning.match(/\bNot allowed\b/i);

  const popTitle = <>Warning{!takeEffect && <br />}
    {!takeEffect && <span key='WarningCharPop-top' style={{ fontSize: '70%', color: 'gray' }}>
      {message || 'The data will not take effect, even it is saved.'}
    </span>
    }
  </>;

  return <Popover content={popContent} title={popTitle} trigger='hover' defaultOpen={defaultOpen}
    children={children || charString ||
      <span className={styles.warningChar}>i</span>}
  />;
};
