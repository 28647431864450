import axiosClient from 'utils/axios-client';
import { CompanyUserListType } from './api-company-user.type';

export const getCompanyUserList = (params: CompanyUserListType) => {
  return axiosClient.get('/members', { params: { ...params } }).then((res) => {
    if (res.data) {
      return res.data;
    } else {
      return {};
    }
  });
};
