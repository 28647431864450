import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { Spin } from 'antd';

import { TextInput } from 'components/form-control';
import { SearchIcon, XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
import { ExternalUserType } from '../external-user.const';
import { CompanyUserModal } from './company-user-modal';
import SortingArrow from 'components/sorting-arrow';
import { GetListCompanyParamsType, RoleType } from 'services/api-external-user.type';
import { externalUserApi } from 'services/api-external-user.services';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
import SelectFilter from './select-filter';
import ChangePlanModal from './change-plan-modal';
import { NoDataTable } from 'components/no-data-table';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from './company.const';
import { KioskTableData, loadCompanyKioskTable } from './change-plan-modal/kiosk-data';
import { namedMappingRev, roleDefault } from './change-plan-modal/role-tool';
import { isSysAdmin } from 'redux/has-permission';
import ImportByTemplateModal from 'components/modal/import-by-template-modal';
import { getKioskRegion } from 'services/api-permission.service';
import { RowActionMenu } from './row-action-menu';
import { verifyData, importData } from './import-kiosk-permission';
import { useRegionMapping } from './use-region-mapping';
import { loadCompanyObjectRole } from './change-plan-modal/blueonionhub-data';
import { keyBy } from 'lodash';
import { PATH_NEW_EXTERNAL_USER } from 'navigation/routes.path';
import { ObjectRolesModal } from 'modules/user-management/settings/object-roles';
import CompanyPropertyModal from './company-property-modal';

const ACTION_LABEL = {
  CHANGE_PLAN: 'Change Plan',
  EDIT_PROPERTY: 'Edit Property',
  ROLES_RELATIONSHIP: 'Roles Relationship',
  div1: '-',
  IMPORT_KIOSK_PERMISSIONS: 'Import Permissions',
  div2: '-',
  CREATE_USER: 'Create User',
  IMPORT_USER: 'Import Users',
};

export default function Company() {
  const history = useHistory();
  const location = useLocation();

  const { pageSize, page, search, sortOrder, sortBy, roleIds, tabKey } = useParseParams();
  //console.log([pageSize, page, search, sortOrder, sortBy, roleIds, tabKey]);

  const [visible, setVisible] = useState<boolean>(false);
  const [rendered, setRendered] = useState<boolean>(false);
  const [visibleCompany, setVisibleCompany] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>(search);
  const [isOpenImportKisokPopup, setOpenImportKisokPopup] = useState<boolean>(false);

  const [companyDetails, setCompanyDetails] = useState({
    id: '',
    name: '',
    domain: '',
    plan_start_date: '',
    plan_end_date: '',
    is_new_member_need_audit: false,
    is_accept_public_email: false,
    is_accept_prohibited_email: false,
    company_key: '',
    invitation_code: '',

    start_date: '',
    expired_date: '',

    ...roleDefault,

    report_quota: 0,
    number_of_license: 0,
    blueOnionHubRole: {},
  });

  //kisok data

  const [kioskTableData, setKioskTableData] = useState<KioskTableData | null>();
  function onKioskUpdateData(newKioskTableData: KioskTableData) {
    setKioskTableData(newKioskTableData);
  }

  const [isOpenObjectRolesModal, setOpenObjectRolesModal] = useState<boolean>(false);

  const [objectRolesModalArgs, setObjectRolesModalArgs] = useState<any>({
    modalKey: 'ObjectRolesModal-from-company',
    open: true,
    setModalVisible: setOpenObjectRolesModal,
  });

  const [isOpenCompanyPropertyModal, setOpenCompanyPropertyModal] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<CompanyPropertyModalInputProp | null>(null);


  const setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 50,
          search,
          sortOrder: sortOrder ?? 'asc',
          sortBy: sortBy ?? 'id',
          roleIds,
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, roleIds, search, sortBy, sortOrder],
  );

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({ tabKey, search: e.target.value });
    }, 700);
  };

  const fetchListCompany = useCallback(() => {
    return externalUserApi.getListCompany({
      page,
      pageSize,
      sortBy,
      sortOrder,
      search: search?.trim(),
      roleIds,
      tabKey,
    });
  }, [page, pageSize, roleIds, search, sortBy, sortOrder, tabKey]);

  const fetchListCompanyRoles = useCallback(() => {
    return externalUserApi.getListRoleCompany();
  }, []);

  const { data: listCompanyRolesData } = useQuery(['listCompanyRolesDataUserExternal'], fetchListCompanyRoles);

  const {
    data: listCompanyData,
    isLoading: isListCompanyLoading,
    refetch,
  } = useQuery(
    [
      ['listCompanyDataUserExternal', tabKey],
      {
        page,
        pageSize,
        sortBy,
        sortOrder,
        search: search?.trim(),
        roleIds,
      },
    ],
    fetchListCompany,
  );

  useEffect(() => {
    if (!listCompanyRolesData?.data.blueonionHubRoles) return;
    setRendered(true);
    //const listRoleIds = !rendered ? listCompanyRolesData.data.blueonionHubRoles.map((item) => item.id) : roleIds;
    if (listCompanyRolesData.data.blueonionHubRoles.map((item) => item.id).toString() === roleIds) return;
    !rendered &&
      setParamHandle({
        // roleIds: roleIds ?? listRoleIds?.toString(),
        tabKey,
      });
    return () => {
      setRendered(false);
    };
  }, [listCompanyRolesData?.data.blueonionHubRoles, rendered, roleIds, setParamHandle, tabKey]);

  /*
  const blueOnionHubList = useCallback((listItems: []) => {
    return blueOnionHubConfig.map(({ key, title, status, color, bg, acronym }) => {
      const items: string[] = [];
      listItems.forEach((item: any) => {
        items.push(item.role.name);
      });
      if (items.includes(title)) {
        return {
          key,
          status: true,
          color,
          bg,
          acronym,
        };
      }
      return {
        key,
        status,
        color,
        bg,
        acronym,
      };
    });
  }, []);
  */

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ExternalUserType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<ExternalUserType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<ExternalUserType>).order as string),
      tabKey,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  const handleChangePlan = (value: ExternalUserType) => {
    setCompanyDetails((prev) => ({
      ...prev,
      ...roleDefault,
    }));

    value.companyRole.forEach((item) => {
      if (item.role.name in namedMappingRev) {
        setCompanyDetails((prev) => ({
          ...prev,
          [namedMappingRev[item.role.name]]: true,
        }));
      }

    });
    //console.log('value', value);
    setCompanyDetails((prev) => ({
      ...prev,
      id: value.id,
      name: value.name,
      domain: value.domain,
      plan_start_date: value.plan_start_date,
      plan_end_date: value.plan_end_date,
      start_date: value.start_date,
      expired_date: value.expired_date,
      is_new_member_need_audit: value.is_new_member_need_audit,
      is_accept_public_email: value.is_accept_public_email,
      is_accept_prohibited_email: value.is_accept_prohibited_email,
      company_key: value.company_key,
      invitation_code: value.invitation_code,
      report_quota: value.report_quota,
      number_of_license: value.number_of_license,
    }));

    setKioskTableData(null);
    loadCompanyKioskTable(+value.id, kioskTableData).then((res) => {
      setKioskTableData(res);
      //console.log('company kiosk data loaded', res);

      setVisible(true);
    });

    loadCompanyObjectRole(+value.id).then((objectRole) => {
      const nameMappedCompanyObjectRole = objectRole.map((role) => {
        return { ...role, role_mapped_name: namedMappingRev[role.role_name] };
      });
      setCompanyDetails((prev) => ({
        ...prev,
        blueOnionHubRole: keyBy(nameMappedCompanyObjectRole, 'role_mapped_name'),
      }));
    });
  };
  const handleViewAll = (value: ExternalUserType) => {
    setCompanyDetails((prev) => ({
      ...prev,
      id: value.id,
      name: value.name,
      domain: value.domain,
      plan_start_date: value.plan_start_date,
      plan_end_date: value.plan_end_date,
      start_date: value.start_date,
      expired_date: value.expired_date,
      is_new_member_need_audit: value.is_new_member_need_audit,
      is_accept_public_email: value.is_accept_public_email,
      is_accept_prohibited_email: value.is_accept_prohibited_email,
      company_key: value.company_key,
      invitation_code: value.invitation_code,
      report_quota: value.report_quota,
      number_of_license: value.number_of_license,
    }));

    setVisibleCompany(true);
  };

  function getHubsRoles(): RoleType[] | null {
    return listCompanyRolesData?.data?.blueonionHubRoles?.concat(
      listCompanyRolesData?.data.blueonionHubPremiumRoles,
      listCompanyRolesData?.data.blueonionOtherRoles,
    ) || null;
  }

  const handleObjectRoles = (row: any) => {
    setObjectRolesModalArgs({
      ...objectRolesModalArgs,
      title: 'Roles for Company [' + row.name + ']',
      role_id: null,
      role_name: null,
      object_type: 'company',
      object_id: row.id,
      object_name: row.name,
    });
    setOpenObjectRolesModal(true);
  };

  const handleEditCompanyProperty = (row: companyData) => {
    setCompanyData({ companyData: row, setModalVisible: setOpenCompanyPropertyModal });
    setOpenCompanyPropertyModal(true);
  };

  async function onMemberAction(label: string, row: any) {
    // console.log(row);
    if (label === ACTION_LABEL.CHANGE_PLAN) {
      handleChangePlan(row);
    }
    else if (label === ACTION_LABEL.EDIT_PROPERTY) {
      handleEditCompanyProperty(row as companyData);
    }
    else if (label === ACTION_LABEL.IMPORT_KIOSK_PERMISSIONS) {
      setCompanyDetails(row);
      setOpenImportKisokPopup(true);
    }
    else if (label === ACTION_LABEL.CREATE_USER) {
      history.push(PATH_NEW_EXTERNAL_USER + '?CompanyId=' + row.id + '&CompanyName=' + encodeURIComponent(row.name));
    }
    else if (label === ACTION_LABEL.IMPORT_USER) {
      history.push(PATH_NEW_EXTERNAL_USER + '?CompanyId=' + row.id + '&CompanyName=' + encodeURIComponent(row.name) + '&openImport=1');
    }
    else if (label === ACTION_LABEL.ROLES_RELATIONSHIP) {
      handleObjectRoles(row);
    }
  }

  const columns: ColumnsType<ExternalUserType> = [
    {
      title: renderTitle('Company ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 150,
      sorter: true,
    },
    {
      title: renderTitle('Company Name', 'name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 380,
      render: (name: string) => <div className="whitespace-pre-wrap">{!name ? '-' : name}</div>,
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      width: 220,
      render: (domain: string) => <div className="whitespace-pre-wrap">{!domain ? '-' : domain}</div>,
    },
    /*
    {
      title: 'BlueOnion Hub',
      dataIndex: 'companyRole',
      key: 'companyRole',
      width: 290,
      render: (value) => {
        return (
          <div className="blueOnionHub">
            {blueOnionHubList(value).map((item, index) => {
              return (
                <div
                  key={index}
                  className="blueOnionHub-list"
                  style={{
                    color: item.status ? item.color : 'rgba(0, 0, 0, 0.15)',
                    backgroundColor: item.status ? item.bg : 'rgba(39, 39, 42, 0.1)',
                  }}
                >
                  {item.acronym}
                </div>
              );
            })}
          </div>
        );
      },
    },
    */
    {
      title: 'Hub',
      dataIndex: 'companyRole',
      key: 'companyRole',
      width: 80,
      render: (value) => {
        const foundAll = value?.filter((cr: any) => cr?.is_allow) || [];
        const found = foundAll.length > 0 && (foundAll.length > 1 || !foundAll[0]?.role.name.match(/Kiosk/i));

        return (
          <div className="blueOnionHub">

            <div
              key='hub'
              className="blueOnionHub-list"
              style={{
                color: found ? '#97D849' : 'rgba(0, 0, 0, 0.15)',
                backgroundColor: found ? 'rgba(151, 216, 73, 0.16)' : 'rgba(39, 39, 42, 0.1)',
              }}
            >
              HUB
            </div>

          </div>
        );
      },
    },
    {
      title: 'Kiosk',
      dataIndex: 'companyRole',
      key: 'companyRole',
      width: 80,
      render: (value) => {
        const found = value?.find((cr: any) => cr?.role?.name?.match(/Kiosk/i) && cr?.is_allow);

        return (
          <div className="blueOnionHub">

            <div
              key='kiosk'
              className="blueOnionHub-list"
              style={{
                color: found ? '#54BAB9' : 'rgba(0, 0, 0, 0.15)',
                backgroundColor: found ? 'rgba(84, 186, 185, 0.16)' : 'rgba(39, 39, 42, 0.1)',
              }}
            >
              MK
            </div>

          </div>
        );
      },
    },
    {
      title: 'API',
      dataIndex: 'companyRole',
      key: 'companyRole',
      width: 80,
      render: (value) => {
        const found = value?.find((cr: any) => cr?.role?.name?.match(/^(API Portal)$/));

        return (
          <div className="blueOnionHub">

            <div
              key='api'
              className="blueOnionHub-list"
              style={{
                color: found ? '#C082E5' : 'rgba(0, 0, 0, 0.15)',
                backgroundColor: found ? 'rgba(192, 130, 229, 0.16)' : 'rgba(39, 39, 42, 0.1)',
              }}
            >
              API
            </div>

          </div>
        );
      },
    },
    // {
    //   title: 'App',
    //   dataIndex: 'companyRole',
    //   key: 'companyRole',
    //   width: 80,
    //   render: (value) => {
    //     const found = value?.find((cr: any) => cr?.role?.name?.match(/^(App)$/));

    //     return (
    //       <div className="blueOnionHub">

    //         <div
    //           key='app'
    //           className="blueOnionHub-list"
    //           style={{
    //             color: found ? '#FF7272' : 'rgba(0, 0, 0, 0.15)',
    //             backgroundColor: found ? 'rgba(255, 114, 114, 0.16)' : 'rgba(39, 39, 42, 0.1)',
    //           }}
    //         >
    //           APP
    //         </div>

    //       </div>
    //     );
    //   },
    // },
    {
      title: 'Report Quota',
      dataIndex: 'report_quota',
      key: 'report_quota',
      render: (reportQuota) => (
        <div className="reportQuota">
          {[0, 1].includes(reportQuota) ? <>{reportQuota} report/ month</> : <>{reportQuota} reports/ month</>}
        </div>
      ),
    },
    {
      title: 'Number of User',
      dataIndex: 'number_of_license',
      key: 'number_of_license',
      render: (_, data: ExternalUserType) => (
        <div className="numberOfUser" >
          <Link to={`/user-management/external-user?page=1&pageSize=20&sortOrder=asc&sortBy=id&companyId=${data.id}&roleIds=5,6,7,8,10&tabKey=Member`}>
            {data.countMember}
          </Link>
          <span onClick={() => handleViewAll(data)} className="viewAll">View All</span>
        </div>
      ),
    },
    ...isSysAdmin() ? [{
      title: 'Action',
      dataIndex: 'changePlane',
      key: 'changePlane',
      width: 80,
      //align:'center',
      align: 'center' as const,
      render: (_: any, data: ExternalUserType) => {
        return (
          <RowActionMenu row={data} className={'actionMenu'} action={onMemberAction} actionLabels={ACTION_LABEL} width={200} />
        );
      },
    }] : [],
  ];
  const ClearFilters = () => {
    // const listRoleIds = listCompanyRolesData?.data.blueonionHubRoles.map((item) => item.id);
    setParamHandle({ page: 1, pageSize: 20, search: undefined, tabKey, roleIds: undefined });
    setValueSearch('');
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (page > pageSize) return;
    setParamHandle({ page, pageSize, roleIds: roleIds, tabKey });
  };

  const handleChange = (value: string[]) => {
    setParamHandle({ roleIds: value.toString(), tabKey });
  };

  const totalResult = useMemo(() => {
    if (!roleIds?.length) {
      // return 0;
    }
    return listCompanyData?.data.total ?? 0;
  }, [listCompanyData?.data.total, roleIds?.length]);

  if (listCompanyData?.data) {
    if (listCompanyData?.data.total > 0 && listCompanyData.data.page > listCompanyData.data.totalPage) {
      //console.log('listCompanyData.data.page',listCompanyData.data.totalPage, tabKey);
      setParamHandle({ page: listCompanyData.data.totalPage, tabKey });
    }
  }

  const [regionMapping, updateByRegionData] = useRegionMapping();
  const { data: regionData } = useQuery(['loadRegionForImport'], () => getKioskRegion());
  if (regionData) updateByRegionData(regionData);

  return (
    <>
      <p className="filter-hub-text">Filter Hub</p>
      <div className="filter-hub-flex">
        <div className="filter-hub-options">
          <div className="select">
            <SelectFilter
              key={'SelectFilter' + tabKey}
              handleSelect={handleChange}
              values={roleIds?.split(',') ?? []}
              optionSelect={listCompanyRolesData?.data.blueonionHubRoles ?? []}
            />
          </div>
          <TextInput
            placeholder="Search by Company Name"
            className="input"
            inputProps={{
              onChange: onChangeInput,
              value: valueSearch,
              prefix: <SearchIcon width={20} height={20} />,
              maxLength: 255,
            }}
          />
        </div>
        <div className="clear-filters" onClick={ClearFilters}>
          <span>Clear all filters</span>
          <XCircleIcon width={24} height={24} />
        </div>
      </div>
      {isListCompanyLoading ? (
        <Spin className="spin" />
      ) : (
        <>
          {!listCompanyData?.data.data || listCompanyData?.data.data.length === 0 ? (
            <NoDataTable />
          ) : (
            <>
              <Table
                rowKey={'id'}
                columns={columns}
                //dataSource={roleIds ? listCompanyData?.data?.data : []}
                dataSource={listCompanyData?.data?.data || []}
                pagination={false}
                onChange={onChangeTable}
                loading={isListCompanyLoading}
              />

              <div className="pagination">
                <div className="total-results-text">
                  Total results: <p className="total-results-number">{totalResult}</p>
                </div>
                <CustomPagination
                  pageSize={listCompanyData?.data.pageSize ?? 20}
                  current={listCompanyData?.data.page ?? 1}
                  total={totalResult}
                  onChange={onChangePage}
                  showSizeChanger={true}
                />
              </div>
            </>
          )}
        </>
      )}

      {visibleCompany &&
        <CompanyUserModal
          visible={visibleCompany}
          setVisible={setVisibleCompany}
          companyDetails={companyDetails}
          listCompanyRolesData={listCompanyRolesData}
        />
      }

      {isOpenImportKisokPopup && (
        <ImportByTemplateModal
          title={`Import Permission, for company '${companyDetails.name}'`}
          verifyData={async (rows: string[][]) => await verifyData(companyDetails, regionMapping, getHubsRoles(), rows)}
          closePopup={() => { setOpenImportKisokPopup(false); }}
          importData={async (rows: string[][]) => {
            var ret = await importData(companyDetails, regionMapping, getHubsRoles(), rows);
            await refetch();
            return ret;
          }}
          action='upload-import-permission'
          // templateData={[
          //   ['Email-','User name','Expiry date','Display name'],
          //   ['example@blueonion.today-','Elsa Pau','09/29/2022','Elsa Pau'],
          // ]}
          templateName='import-permission-template'
          xlsxTemplateUrl='/template/import-permission-template.xlsx'
          //xlsxSheet={'Sheet1'}    //for some xlsx, number index will cause fail
          csvTemplateUrl='/template/import-permission-template.csv'
        />
      )}

      {(visible && <ChangePlanModal
        visible={visible}
        setVisible={setVisible}
        companyDetails={companyDetails}
        listCompanyRoles={listCompanyRolesData}
        kioskTableData={kioskTableData}
        onKioskUpdateData={onKioskUpdateData}
        refetch={refetch}
        key={'company-change-plan'}
      />
      )}

      {isOpenObjectRolesModal && (
        <ObjectRolesModal
          {...objectRolesModalArgs}
        />
      )}

      {isOpenCompanyPropertyModal && (
        <CompanyPropertyModal {...companyData} />
      )}

    </>
  );
}

type role = {
  created_at: string;
  created_by: string;
  deleted_at: string;
  description: string;
  display_name: string;
  id: string;
  name: string;
  short_name: string;
  type: number;
  updated_at: string;
}

type companyRole = {
  created_at: string;
  created_by: string;
  deleted_at: string;
  end_date: string;
  id: string;
  is_allow: number;
  is_assign_other: number;
  is_prohibit: number;
  object_id: string;
  object_type: string;
  role: role;
  role_id: string;
  start_date: string;
  updated_at: string
}

export type companyData = {
  company_key: string;
  contact_email: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_phone: string;
  countMember: string;
  created_at: string;
  created_by: string;
  deleted_at: string;
  domain: string;
  expired_date: string;
  id: string;
  invitation_code: string;
  is_accept_prohibited_email: number;
  is_accept_public_email: number;
  is_new_member_need_audit: number;
  name: string;
  number_of_license: number;
  plan_end_date: string;
  plan_start_date: string;
  report_quota: number;
  start_date: string;
  updated_at: string;
  companyRole: companyRole[];
}

export type CompanyPropertyModalInputProp = {
  companyData?: companyData | undefined;
  setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}