import { SVGProps } from 'react';

export const WarningIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0C4.4898 0 0 4.4898 0 10C0 15.5102 4.4898 20 10 20C15.5102 20 20 15.5102 20 10C20 4.4898 15.5102 0 10 0ZM9.18367 6.42857C9.18367 6.02041 9.4898 5.61225 10 5.61225C10.5102 5.61225 10.8163 5.91837 10.8163 6.42857V10.5102C10.8163 10.9184 10.5102 11.3265 10 11.3265C9.4898 11.3265 9.18367 11.0204 9.18367 10.5102V6.42857ZM10 14.5918C9.4898 14.5918 8.97959 14.0816 8.97959 13.5714C8.97959 13.0612 9.4898 12.551 10 12.551C10.5102 12.551 11.0204 13.0612 11.0204 13.5714C11.0204 14.0816 10.5102 14.5918 10 14.5918Z"
        fill="white"
      />
    </svg>
  );
};
