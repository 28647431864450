import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { RootState } from 'redux/rootReducers';
import { getCurrentUser } from 'redux/auth/auth.selectors';
import { Login } from 'pages';
import { isSysAdmin } from 'redux/has-permission';
import { PATH_EXTERNAL_USER, PATH_INTERNAL_USER } from 'navigation/routes.path';


const ProtectedRouteView = ({ currentUser, ...props }: RouteProps & ConnectedProps<typeof connector>) => {
  const location = useLocation();
  if (currentUser) {
    //TO DO after have main content
    // return location.pathname === '/login' ? <Redirect to="/user-management/internal-user" /> : <Route {...props} />;
    return ['/login', '/'].includes(location.pathname) ? (
      <Redirect
        to={
          isSysAdmin() ? PATH_INTERNAL_USER : PATH_EXTERNAL_USER
        }
      />
    ) : (
      <Route {...props} />
    );
  }
  return <Login />;
};

const connector = connect((state: RootState) => ({
  currentUser: getCurrentUser(state),
}));

export const PrivatePage = connector(ProtectedRouteView);
