import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import Schema from 'async-validator';

import styles from './change-password-form.module.scss';
import { BlueonionBlackIcon } from 'components/icons/blueonion-logo-black-icon';
import LabelRequire from 'components/label-require';
import { PasswordInput } from 'components/form-control';
import { InfoCircleFilledIcon } from 'components/icons';
import { CommonButton } from 'components/common-button';
import { PATH_LOGIN } from 'navigation/routes.path';

Schema.warning = function () {};

type IChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ChangePasswordForm() {
  const [form] = Form.useForm();
  const [valueOldPassword, setValueOldPassword] = useState<string>('');

  const handleChangePassword = (value: IChangePassword) => {
    // console.log(value);
  };

  const handleChangeOldPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setValueOldPassword(e.target.value);
  };

  return (
    <div className={styles.formChangePassword}>
      <BlueonionBlackIcon style={{width:265, marginBottom:75}} />
      <p className={styles.changePasswordTitle}>Change your password</p>
      <div className={styles.note}>
        <p>
          You need to update your password because this is the first time you are signing in, or because your password
          has expired.
        </p>
      </div>
      <Form form={form} onFinish={handleChangePassword}>
        <LabelRequire text="Old Password" require />
        <PasswordInput
          placeholder="Input your old password"
          name="oldPassword"
          onChange={handleChangeOldPassword}
          rules={[
            { required: true, message: 'You must fill in this field' },
            {
              pattern: new RegExp(/^.{8,100}$/),
              message: 'Password must be 8-100 characters long',
            },
          ]}
        />
        <LabelRequire text="New Password" require />
        <PasswordInput
          placeholder="Input your new password"
          name="newPassword"
          rules={[
            { required: true, message: 'You must fill in this field' },
            {
              pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/),
              message: 'Your password is not strong enough. Make sure it follow the rule below',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if ((!value || getFieldValue('oldPassword') === value) && valueOldPassword !== '') {
                  return Promise.reject('New password must be different from old password');
                }
                return Promise.resolve();
              },
            }),
          ]}
        />
        <div className={styles.passwordRequired}>
          <InfoCircleFilledIcon />
          <div className={styles.info}>
            <p>Password must be:</p>
            <ul>
              <li>At least 8 characters</li>
              <li>Must contain at least an uppercase letter</li>
              <li>Must contain at least a number</li>
              <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
            </ul>
          </div>
        </div>
        <LabelRequire text="Confirm New Password" require />
        <PasswordInput
          placeholder="Confirm your new password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'You must fill in this field' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The password confirmation does not match. Please check again');
              },
            }),
          ]}
        />
        <div className={styles.updatePasswordButton}>
          <Link to={PATH_LOGIN}>
            <p>Not Now</p>
          </Link>
          <Form.Item shouldUpdate>
            {() => (
              <div className={styles.button}>
                <CommonButton
                  htmlType="submit"
                  block
                  size="large"
                  variant="primary"
                  disabled={
                    !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  Update Password
                </CommonButton>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
