import { DatePicker, DatePickerProps, Form, FormItemProps } from 'antd';
import moment from 'moment';

import { DateIcon } from 'components/icons';
import styles from './date-input.module.scss';

interface DateInputProps extends FormItemProps {
  initialValue?: string;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  dateInputProps?: DatePickerProps;
  enableAllDate?: boolean;
}

const DateInput = ({
  format = 'DD/MM/YYYY',
  placeholder = 'dd/mm/yyyy',
  initialValue,
  disabled,
  dateInputProps,
  enableAllDate,
  ...props
}: DateInputProps) => {
  return (
    <Form.Item {...props} className={styles.formItem}>
      <DatePicker
        format={format}
        placeholder={placeholder}
        disabledDate={(current) => enableAllDate ? false : current.isBefore(moment().subtract(1, 'day'))}
        dropdownClassName={styles.dropdownDateInputWrapper}
        className={styles.dateInputWrapper}
        suffixIcon={<DateIcon />}
        {...dateInputProps}
      />
    </Form.Item>
  );
};

export default DateInput;
