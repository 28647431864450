import { Link, useHistory } from 'react-router-dom';

import { CommonButton } from 'components/common-button';
import { PlusIcon } from 'components/icons';
import { PATH_EXTERNAL_USER, PATH_NEW_EXTERNAL_USER } from 'navigation/routes.path';
import './external-user.scss';
import TabCommon from 'components/tab-common';
import { INDEX_CompanyAdmin, listTab } from './external-user';
import { isSysAdmin } from 'redux/has-permission';
import BoAdmin from '../internal-user/bo-admin';
import { useState } from 'react';
import { CreateNewCompanyModal } from 'components/modal';
import Member from './member';
import Account from './account';

export interface IExternalUserProps { }

const BLUEONION_COMPANY_ID = '1';

export default function ExternalUser(props: IExternalUserProps) {
  const [isOpenCreateCompanyPopup, setOpenCreateCompanyPopup] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const tabKey = urlParams.get('tabKey');

  const listTabEx = isSysAdmin() ? [
    ...listTab.slice(0, INDEX_CompanyAdmin),
    {
      key: 'BlueonionMember',
      name: 'BlueOnion User',
      component: <Member companyId={BLUEONION_COMPANY_ID} />,
    },
    ...listTab.slice(INDEX_CompanyAdmin),
    {
      key: 'Account',
      name: 'Account',
      component: <Account />,
    },
    {
      key: 'SystemAdmin',
      name: 'Super Admin',
      component: <BoAdmin />,
    },
  ] : listTab;

  const toggleCreateCompanyPopup = () => {
    setOpenCreateCompanyPopup((prev) => !prev);
  };

  const history = useHistory();
  const refetch = () => {
    history.replace(PATH_EXTERNAL_USER + '?tabKey=' + (new Date()).getTime());  //refreh to default the company
  };

  return (
    <div className="external-user">
      <div className="heading">
        {!(tabKey === 'Account' || tabKey === 'SystemAdmin') &&
          <>
            <Link to={PATH_NEW_EXTERNAL_USER}>
              <CommonButton variant="primary">
                <PlusIcon fill="white" width={24} height={24} />
                <div className="button-text">Add new user</div>
              </CommonButton>
            </Link>
            {isOpenCreateCompanyPopup && <CreateNewCompanyModal togglePopup={toggleCreateCompanyPopup} refetch={refetch} />}
            <CommonButton variant="primary" onClick={toggleCreateCompanyPopup} hidden={!isSysAdmin()} style={{ right: 200 }}>
              <PlusIcon fill="white" width={24} height={24} />
              <div className="button-text">Add company</div>
            </CommonButton>
          </>
        }
      </div>
      <div className="content">
        <TabCommon listTab={listTabEx} />
      </div>
    </div>
  );
}
