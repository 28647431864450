import { SVGProps } from 'react';

export const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89155 5.05806C8.13563 4.81398 8.53136 4.81398 8.77544 5.05806L13.7754 10.0581C14.0195 10.3021 14.0195 10.6979 13.7754 10.9419L8.77544 15.9419C8.53136 16.186 8.13563 16.186 7.89155 15.9419C7.64748 15.6979 7.64748 15.3021 7.89155 15.0581L12.4496 10.5L7.89155 5.94194C7.64748 5.69786 7.64748 5.30214 7.89155 5.05806Z"
        fill="#6F7282"
      />
    </svg>
  );
};
