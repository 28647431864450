import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';

import { BlueonionIcon } from 'components/icons';
import { SideBar } from 'components/layouts/sidebars';

import './styles.scss';
import { IRoute, routes } from 'navigation/constants';
import { HeaderBreadcrumb } from './header-bread-crumb';
import { LogOut } from 'modules/Auth/Logout';

const { Header } = Layout;

export const DashboardLayout = () => {
  const { Content } = Layout;

  return (
    <Layout>
      <Header style={{ zIndex: 100 }} className="header">
        <BlueonionIcon width={180} />
        <div className="actions">
          {/* <SearchIcon fill="white" />
          <Badge className="notification">
            <NotificationIcon fill="white" />
          </Badge> */}
          <LogOut />
        </div>
      </Header>
      <Layout>
        <SideBar />
        <Layout className="site-layout">
          <Content>
            <HeaderBreadcrumb />
            <Switch>
              {routes.map((item: IRoute, index: number) => {
                return <Route path={item.path} exact={item.exact} component={item.element} key={index} />;
              })}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
