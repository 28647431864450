import { useState } from 'react';
import { PermissionEntity } from 'services/api-permission.type';

//return [regionMapping, updateByRegionData]
export function useRegionMapping(): [{ [key: string]: string; }, (regionData: PermissionEntity[]) => any] {
  const [regionMapping] = useState<{ [key: string]: string; }>({
    LOADED: '', //loaded flag
    //HK: 'hk',
    //TW: 'tw',
    //SG: 'sg',
  });

  const updateByRegionData = (regionData: PermissionEntity[]) => {
    if (regionData?.length) {
      if (!regionMapping.LOADED) {
        //console.log('regionMapping loading');
        regionData.forEach((v) => {
          const k = v.name.slice(v.name.lastIndexOf(':') + 1);
          regionMapping[k.toLowerCase()] = k;
          if (v.display_name)
            regionMapping[v.display_name.toLowerCase()] = k;
          if (v.short_name) {
            //for 3-letter abbr and the 'GBK/UK'
            v.short_name?.split('/').forEach((vi) => { regionMapping[vi.toLowerCase()] = k; });
          }
        });
        regionMapping.LOADED = '1';
        //console.log('regionMapping', regionMapping);
      }
    }
  };

  return [regionMapping, updateByRegionData];
}
