import { Link } from 'react-router-dom';

import { CommonButton } from 'components/common-button';
import { PATH_NEW_INTERNAL_USER } from 'navigation/routes.path';
import TabCommon from 'components/tab-common';
import { PlusIcon } from 'components/icons';
import styles from './styles.module.scss';
import { listTab } from './internal-user';

const InternalUser = () => {
  return (
    <div className={styles.containerInternal}>
      <div className={styles.titleInternal}>
        <div className={styles.addButton}>
          <Link to={PATH_NEW_INTERNAL_USER}>
            <CommonButton variant="primary">
              <PlusIcon fill="white" width={24} height={24} />
              <div className={styles.buttonText}>Add new user</div>
            </CommonButton>
          </Link>
        </div>
      </div>
      <div className={styles.tableInternal}>
        <TabCommon listTab={listTab} />
      </div>
    </div>
  );
};

export default InternalUser;
