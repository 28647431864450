import React, { useEffect, useState } from 'react';

import { Col, DatePickerProps, FormInstance } from 'antd';

import DateInput from 'components/form-control/date-input';
import { ReturnPermissionDto } from 'services/api-permission.type';
import moment from 'moment';
import { isDateInverted, warningText } from './warning-tool';

import { KioskTableData } from './kiosk-data';

import { ModalBase } from 'components/modal';
import styles from './edit-date-modal.module.scss';
import LabelRequire from 'components/label-require';

type EditDateKioskModalProps = {
  name: string;
  varName: string;
  rowData: ReturnPermissionDto;
  onUpdateRowData?: Function;
  disabled?: boolean;
  setDataError?: Function;

  nowDayStr?: string;
  form?: FormInstance<any>;
  parentKioskData?: KioskTableData | null | undefined;

  setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;

}

export default function EditDateKioskModal({ name, varName, rowData, onUpdateRowData, disabled, parentKioskData, ...props }: EditDateKioskModalProps) {
  //console.log(rowData);

  function updateRowData(newRow: ReturnPermissionDto) {
    onUpdateRowData?.(newRow);
    updateWarning();
  }

  const [, setUpdateWarning] = useState(0);
  const updateWarning = () => { setUpdateWarning((v) => v + 1); };

  let startDateProps: DatePickerProps = {
    disabled: !rowData.is_allow,
    onChange: (v: any) => {
      var mnt = moment(v);
      updateRowData({ ...rowData, start_date: mnt.isValid() ? mnt.format('YYYY-MM-DD') : null });
    },
  };
  if (rowData.start_date) {   //don't show current time
    let mntStart = moment(rowData.start_date);
    if (mntStart.isValid()) startDateProps.defaultValue = startDateProps.value = mntStart;
  }

  let endDateProps: DatePickerProps = {
    disabled: !rowData.is_allow,
    onChange: (v: any) => {
      var mnt = moment(v);
      updateRowData({ ...rowData, end_date: mnt.isValid() ? mnt.format('YYYY-MM-DD') : null });
    },
  };
  if (rowData.end_date) {   //don't show current time
    let mntEnd = moment(rowData.end_date);
    if (mntEnd.isValid()) endDateProps.defaultValue = endDateProps.value = mntEnd;
  }

  useEffect(() => {
    props.form?.validateFields([varName + '_start_date', varName + '_end_date']);
  }, [props.form, varName]);

  const start_date_key = varName + '_start_date';
  const end_date_key = varName + '_end_date';

  useEffect(() => {
    //console.log('newRow', rowData);
    if (!rowData.start_date || !rowData.end_date ||
      (rowData.start_date <= rowData.end_date)
    ) {
      props.form?.setFields([
        { name: start_date_key, errors: [] },
        { name: end_date_key, errors: [] },
        { name: varName, errors: [] },
      ]);

      props.setDataError?.(false);
    }
    else {
      props.form?.setFields([
        { name: start_date_key, errors: ['Start date cannot be later than End date'] },
        { name: end_date_key, errors: ['Start date cannot be later than End date'] },
        { name: varName, errors: ['Start date cannot be later than End date'] },
      ]);
      props.setDataError?.(true);
    }
  });

  const parentRowData = parentKioskData?.tableDataMapping[rowData.permission_name];
  //console.log('parentRowData', parentRowData);

  const dtStart = rowData.start_date;
  const warningStart = rowData.is_allow && warningText(
    isDateInverted(dtStart, props.nowDayStr) && 'Today is outside period',
    isDateInverted(props.form?.getFieldValue('start_date')?._d, dtStart) && 'Before the service start date',
    isDateInverted(props.form?.getFieldValue('plan_start_date')?._d, dtStart) && 'Before the total control start date',
    dtStart && rowData.is_allow && parentKioskData && !parentRowData?.is_allow && 'Not allowed by company scope.',
    isDateInverted(parentRowData?.start_date, dtStart) && 'Before the company permission start date',
  );

  const dtEnd = rowData.end_date;
  const warningEnd = rowData.is_allow && warningText(
    isDateInverted(props.nowDayStr, dtEnd) && 'Today is outside period',
    isDateInverted(dtEnd, props.form?.getFieldValue('expired_date')?._d) && 'After the service expired date',
    isDateInverted(dtEnd, props.form?.getFieldValue('plan_end_date')?._d) && 'After the total control end date',
    dtEnd && rowData.is_allow && parentKioskData && !parentRowData?.is_allow && 'Not allowed by company scope.',
    isDateInverted(dtEnd, parentRowData?.end_date) && 'After the company permission end date',
  );

  //console.log('rowData', rowData, startDateProps);
  //console.log('rowData', rowData);
  //console.log('parentKioskData', parentKioskData);

  const handleCancel = () => {
    props.setModalVisible?.(false);
  };

  return (
    <div>
      <ModalBase
        open={true}
        title='Edit Start/End Date'
        onCancel={handleCancel}
        className={styles.editDateModal}
        width={500}
      >
        <div style={{ display: 'flex' }} >

          {/* <Row gutter={[20, 20]} className='tableRow'> */}

          <Col span={10}>
            <LabelRequire text="Start Date" warning={warningStart || ''} />
            <DateInput
              name={start_date_key}
              key={start_date_key}
              dateInputProps={startDateProps}
              enableAllDate={true}
            />
          </Col>
          <Col span={2}></Col>

          <Col span={10}>
            <LabelRequire text="End Date" warning={warningEnd || ''} />
            <DateInput
              name={end_date_key}
              key={end_date_key}
              dateInputProps={endDateProps}
              enableAllDate={true}
            />
          </Col>

          {/* </Row> */}
        </div>

      </ModalBase>
    </div>
  );
}


