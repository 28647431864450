import { Modal, ModalProps } from 'antd';
import clsx from 'clsx';
import { CloseIcon } from 'components/icons';
import React from 'react';

import styles from './modal-base.module.scss';

interface ModalBaseProps extends ModalProps {
  children: React.ReactNode;
}

const ModalBase = ({ children, className, closeIcon, footer, ...props }: ModalBaseProps) => {
  return (
    <Modal
      {...props}
      width="100%"
      className={clsx(className, styles.modalBase)}
      closeIcon={closeIcon ?? <CloseIcon fill="#4B4B4B" />}
      footer={footer ?? null}
    >
      {children}
    </Modal>
  );
};

export default ModalBase;
