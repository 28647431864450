import { Col, Form, ModalProps, Row } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { CommonButton } from 'components/common-button';
import { TextArea, TextInput } from 'components/form-control';
import LabelRequire from 'components/label-require';
import { ModalBase } from 'components/modal';
import styles from './edit-item-modal.module.scss';

type IEditValues = {
  id?: number;
  keyName: string;
  displayName?: string;
  shortName?: string;
  description?: string;
};

interface EditItemModalProps extends ModalProps {
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  title?: string;
  values?: IEditValues;
  onModalOK?: (values: IEditValues) => {};
  keyNamePattern?: RegExp;
  keyNameMessage?: string;
}

export function EditItemModal({ setModalVisible, values, ...props }: EditItemModalProps) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFields([
      { name: 'keyName', value: values?.keyName, touched: false },
      { name: 'displayName', value: values?.displayName, touched: false },
      { name: 'shortName', value: values?.shortName, touched: false },
      { name: 'description', value: values?.description, touched: false },
    ]);
  }, [
    values,
    form,
  ]);

  const handleOnFinish = (value: IEditValues) => {
    // console.log(value);
    setModalVisible(false);
    form.resetFields();
    props?.onModalOK?.({ ...value, id: values?.id || undefined });
  };

  const onCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  return (
    <div>
      <ModalBase
        {...props}
        onCancel={onCancel}
        centered
        title={props?.title || 'Edit Item'}
        forceRender
        className={styles.formEditItemModal}
      >
        <Form form={form} onFinish={handleOnFinish}>
          <Row>
            <Col span={6} style={{ paddingTop: '0.5em' }}>
              <LabelRequire text="Key Name" require />
            </Col>
            <Col span={18}>
              <TextInput
                placeholder="Key Name"
                name="keyName"
                rules={[
                  { required: true, message: 'You must fill in this field' },
                  {
                    pattern: props.keyNamePattern || /^[\w:-]{1,255}$/,
                    message: props.keyNameMessage || 'Key Name must be 1-255 characters long, and include only (a-z A-Z 0-9 _ - :)',
                  },
                ]}
                disabled={values?.keyName ? true : false}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ paddingTop: '0.5em' }}>
              <LabelRequire text="Display Name" />
            </Col>
            <Col span={18}>
              <TextInput
                placeholder="Display Name"
                name="displayName"
                rules={[
                  { max: 255, message: 'You have reach the maximum character for this field: 255 character' },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ paddingTop: '0.5em' }}>
              <LabelRequire text="Short Name" />
            </Col>
            <Col span={18}>
              <TextInput
                placeholder="Short Name"
                name="shortName"
                rules={[
                  { max: 255, message: 'You have reach the maximum character for this field: 255 character' },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ paddingTop: '0.5em' }}>
              <LabelRequire text="Description" />
            </Col>
            <Col span={18}>
              <TextArea
                placeholder="Description"
                name="description"
                rules={[
                  { max: 255, message: 'You have reach the maximum character for this field: 255 character' },
                ]}
              />
            </Col>
          </Row>

          <div className={styles.alignButton}>
            <CommonButton onClick={onCancel} variant="default" idKey='edit-item-cancel'>
              Cancel
            </CommonButton>
            <Form.Item shouldUpdate className={styles.editItemButton}>
              {() => {
                // console.log('update btn');
                return (
                  <CommonButton
                    htmlType="submit"
                    block
                    size="large"
                    variant="primary"
                    disabled={
                      !form.isFieldsTouched(false) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    idKey='edit-item-save'
                  >
                    Save
                  </CommonButton>
                );
              }
              }
            </Form.Item>
          </div>
        </Form>
      </ModalBase>
    </div>
  );
}
