export const GarbageIcon = () => {
  return (
    <svg width="52" height="58" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.91174 43.4536L3.06202 28.9602C3.01659 28.6079 2.70373 28.3628 2.34288 28.4039C1.99059 28.4493 1.74116 28.7708 1.78659 29.1231L3.63631 43.6156C3.68088 43.9619 3.99459 44.2156 4.35545 44.1719C4.70774 44.1273 4.95716 43.8059 4.91174 43.4536Z"
        fill="#FF424F"
      />
      <path
        d="M5.03222 50.1153C5.42308 50.1153 5.71965 49.7733 5.67079 49.391L5.47022 47.8165C5.42479 47.465 5.10936 47.2199 4.75108 47.2602C4.39879 47.3056 4.14937 47.627 4.19479 47.9793L4.39537 49.5539C4.43651 49.8788 4.71336 50.1153 5.03222 50.1153Z"
        fill="#FF424F"
      />
      <path
        d="M38.1035 2.88212C38.1035 3.23697 38.3915 3.52497 38.7464 3.52497H45.4244C45.7792 3.52497 46.0672 3.23697 46.0672 2.88212C46.0672 2.52726 45.7792 2.23926 45.4244 2.23926H38.7464C38.3915 2.23926 38.1035 2.5264 38.1035 2.88212Z"
        fill="#FF424F"
      />
      <path
        d="M36.5256 3.52497C36.8804 3.52497 37.1684 3.23697 37.1684 2.88212C37.1684 2.52726 36.8804 2.23926 36.5256 2.23926H36.1253C35.7704 2.23926 35.4824 2.52726 35.4824 2.88212C35.4824 3.23697 35.7704 3.52497 36.1253 3.52497H36.5256Z"
        fill="#FF424F"
      />
      <path
        d="M50.1209 5.66871H31.3923V2.89157C31.3923 1.81157 30.5094 0.928711 29.4294 0.928711H22.5637C21.4837 0.928711 20.6094 1.81157 20.6094 2.89157V5.66871H1.88087C1.13516 5.66871 0.535156 6.26871 0.535156 7.00585V12.4401C0.535156 13.1859 1.13516 13.7859 1.88087 13.7859H3.28658L8.54944 55.0487C8.70373 56.2058 9.68944 57.0716 10.8466 57.0716H41.1466C42.3123 57.0716 43.298 56.2058 43.4437 55.0487L48.7152 13.7859H50.1209C50.858 13.7859 51.4666 13.1859 51.4666 12.4401V7.00585C51.4666 6.26871 50.858 5.66871 50.1209 5.66871ZM21.8952 2.89157C21.8952 2.51442 22.1952 2.21442 22.5637 2.21442H29.4294C29.8066 2.21442 30.1066 2.51442 30.1066 2.89157V5.66871H21.8952V2.89157ZM47.1809 15.6116H31.7352C31.3752 15.6116 31.0923 15.8944 31.0923 16.2544C31.0923 16.6059 31.3752 16.8973 31.7352 16.8973H47.018L42.1666 54.8858C42.1066 55.4001 41.6694 55.7858 41.1466 55.7858H10.8466C10.3323 55.7858 9.89515 55.4001 9.82658 54.8858L4.58087 13.7859H47.4123L47.1809 15.6116ZM50.1209 12.5001L1.82087 12.4401L1.88087 6.95442H50.1209C50.1552 6.95442 50.1809 6.98014 50.1809 7.00585L50.1209 12.5001Z"
        fill="#FF424F"
      />
      <path
        d="M50.1803 7.00553L50.1203 12.4998L1.82031 12.4398L1.88031 6.9541H50.1203C50.1546 6.9541 50.1803 6.97982 50.1803 7.00553Z"
        fill="#FF0000"
      />
      <path
        d="M29.0371 22.4368V47.1345C29.0371 47.4894 29.3251 47.7774 29.68 47.7774C30.0348 47.7774 30.3228 47.4894 30.3228 47.1345V22.4368C30.3228 22.0819 30.0348 21.7939 29.68 21.7939C29.3251 21.7939 29.0371 22.0819 29.0371 22.4368Z"
        fill="#FF424F"
      />
      <path
        d="M37.0393 47.7774C37.3942 47.7774 37.6822 47.4894 37.6822 47.1345V22.4368C37.6822 22.0819 37.3942 21.7939 37.0393 21.7939C36.6845 21.7939 36.3965 22.0819 36.3965 22.4368V47.1345C36.3965 47.4894 36.6845 47.7774 37.0393 47.7774Z"
        fill="#FF424F"
      />
      <path
        d="M22.3186 47.7774C22.6735 47.7774 22.9615 47.4894 22.9615 47.1345V22.4368C22.9615 22.0819 22.6735 21.7939 22.3186 21.7939C21.9638 21.7939 21.6758 22.0819 21.6758 22.4368V47.1345C21.6758 47.4894 21.9638 47.7774 22.3186 47.7774Z"
        fill="#FF424F"
      />
      <path
        d="M14.9593 47.7774C15.3141 47.7774 15.6021 47.4894 15.6021 47.1345V22.4368C15.6021 22.0819 15.3141 21.7939 14.9593 21.7939C14.6044 21.7939 14.3164 22.0819 14.3164 22.4368V47.1345C14.3164 47.4894 14.6044 47.7774 14.9593 47.7774Z"
        fill="#FF424F"
      />
      <path
        d="M27.9417 16.8951H29.2205C29.5754 16.8951 29.8634 16.6071 29.8634 16.2522C29.8634 15.8974 29.5754 15.6094 29.2205 15.6094H27.9417C27.5868 15.6094 27.2988 15.8974 27.2988 16.2522C27.2988 16.6071 27.5868 16.8951 27.9417 16.8951Z"
        fill="#FF424F"
      />
    </svg>
  );
};
