import React from 'react';

export const FileUploadIcon = ({ fill = '#94CDFF', ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="46" height="40" viewBox="0 0 46 40" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0214 0.500977C9.08145 0.500977 7.42363 1.76815 6.7931 3.5057H39.2846C38.6533 1.76815 36.9933 0.500976 35.0534 0.500977H11.0214ZM5.01198 6.51336C2.54104 6.51336 0.496094 8.55538 0.496094 11.0263V35.0582C0.496094 37.5292 2.54104 39.5741 5.01198 39.5741H41.0657C43.5367 39.5741 45.567 37.5292 45.567 35.0582V11.0263C45.567 8.55538 43.5367 6.51336 41.0657 6.51336H5.01198ZM23.011 12.5023C23.8628 12.4863 24.5558 13.1792 24.5398 14.031V28.4297L27.9905 24.9877C28.1252 24.8305 28.291 24.7028 28.4774 24.6126C28.6638 24.5224 28.8668 24.4718 29.0737 24.4638C29.2806 24.4558 29.4869 24.4907 29.6797 24.5662C29.8725 24.6418 30.0476 24.7563 30.194 24.9028C30.3404 25.0492 30.455 25.2243 30.5305 25.4171C30.6061 25.6099 30.641 25.8162 30.633 26.0231C30.625 26.23 30.5743 26.433 30.4842 26.6194C30.394 26.8058 30.2663 26.9716 30.1091 27.1063L24.0967 33.1187C23.8152 33.3985 23.4343 33.5556 23.0374 33.5556C22.6404 33.5556 22.2596 33.3985 21.9781 33.1187L15.9687 27.1063C15.7631 26.9068 15.6189 26.6527 15.5529 26.374C15.487 26.0953 15.5021 25.8035 15.5964 25.5331C15.6908 25.2626 15.8605 25.0248 16.0855 24.8476C16.3105 24.6704 16.5815 24.5612 16.8666 24.5329C17.0911 24.5123 17.3174 24.5424 17.5287 24.6211C17.7401 24.6999 17.9309 24.8252 18.0872 24.9877L21.535 28.4385V14.031C21.5195 13.201 22.1809 12.5193 23.011 12.5023Z"
      />
    </svg>
  );
};
