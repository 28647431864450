import React from 'react';
import { ChevronUpIcon } from 'components/icons';

type ExpandIconProps = {
  visible: boolean;
  expandState: boolean;
  setExpandState: Function | any;
};

export function ExpandIcon({ expandState, visible, setExpandState }: ExpandIconProps) {
  return <ChevronUpIcon
    className='expandIcon'
    style={{
      transform: `rotate(${(visible && expandState) ? '0deg' : '180deg'})`,
      visibility: visible ? 'visible' : 'hidden',
    }}
    onClick={() => {
      if (visible) setExpandState(!expandState); //toggle expand
    }} />;
}
