import { SVGProps } from 'react';

export const LeaveIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M40.392 36.12C40.0737 36.12 39.7685 36.2465 39.5435 36.4715C39.3184 36.6966 39.192 37.0018 39.192 37.32V51.456C39.192 51.7743 39.0656 52.0795 38.8405 52.3046C38.6155 52.5296 38.3103 52.656 37.992 52.656H9.6C9.28174 52.656 8.97652 52.5296 8.75147 52.3046C8.52643 52.0795 8.4 51.7743 8.4 51.456V8.47205C8.39017 8.30851 8.41393 8.14469 8.46982 7.99069C8.52571 7.83668 8.61254 7.69575 8.72496 7.57658C8.83739 7.45741 8.97303 7.36252 9.12352 7.29777C9.27401 7.23301 9.43617 7.19975 9.6 7.20005H37.992C38.3103 7.20005 38.6155 7.32648 38.8405 7.55152C39.0656 7.77656 39.192 8.08179 39.192 8.40005V22.608C39.192 22.9263 39.3184 23.2315 39.5435 23.4566C39.7685 23.6816 40.0737 23.808 40.392 23.808C40.7103 23.808 41.0155 23.6816 41.2405 23.4566C41.4656 23.2315 41.592 22.9263 41.592 22.608V8.47205C41.6016 7.99326 41.5155 7.51738 41.3389 7.07227C41.1623 6.62715 40.8986 6.22175 40.5634 5.87979C40.2281 5.53784 39.828 5.26621 39.3865 5.0808C38.945 4.8954 38.4709 4.79995 37.992 4.80005H9.6C8.64522 4.80005 7.72955 5.17933 7.05442 5.85446C6.37928 6.5296 6 7.44527 6 8.40005V51.456C6 52.4108 6.37928 53.3265 7.05442 54.0016C7.72955 54.6768 8.64522 55.056 9.6 55.056H37.992C38.9468 55.056 39.8624 54.6768 40.5376 54.0016C41.2127 53.3265 41.592 52.4108 41.592 51.456V37.32C41.592 37.0018 41.4656 36.6966 41.2405 36.4715C41.0155 36.2465 40.7103 36.12 40.392 36.12Z"
        fill="#3698FC"
      />
      <path
        d="M55.1998 30C55.1979 29.8316 55.1606 29.6656 55.0903 29.5126C55.02 29.3596 54.9183 29.2231 54.7918 29.112L46.5598 21C46.4482 20.8875 46.3155 20.7982 46.1693 20.7373C46.0231 20.6764 45.8662 20.645 45.7078 20.645C45.5494 20.645 45.3925 20.6764 45.2463 20.7373C45.1001 20.7982 44.9674 20.8875 44.8558 21C44.7441 21.1095 44.6553 21.2402 44.5947 21.3844C44.5341 21.5286 44.5028 21.6835 44.5028 21.84C44.5028 21.9964 44.5341 22.1513 44.5947 22.2956C44.6553 22.4398 44.7441 22.5705 44.8558 22.68L51.0478 28.8H29.9998C29.6815 28.8 29.3763 28.9264 29.1513 29.1514C28.9262 29.3765 28.7998 29.6817 28.7998 30C28.7998 30.3182 28.9262 30.6235 29.1513 30.8485C29.3763 31.0736 29.6815 31.2 29.9998 31.2H51.0478L44.8558 37.296C44.7441 37.4055 44.6553 37.5362 44.5947 37.6804C44.5341 37.8246 44.5028 37.9795 44.5028 38.136C44.5028 38.2924 44.5341 38.4473 44.5947 38.5916C44.6553 38.7358 44.7441 38.8665 44.8558 38.976C45.0774 39.2021 45.3793 39.3315 45.6958 39.336C45.8549 39.3401 46.0132 39.3125 46.1616 39.2548C46.3099 39.1971 46.4453 39.1105 46.5598 39L54.7918 30.888C54.9183 30.7769 55.02 30.6404 55.0903 30.4874C55.1606 30.3344 55.1979 30.1683 55.1998 30Z"
        fill="#3698FC"
      />
    </svg>
  );
};
