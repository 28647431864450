import { useCallback, useMemo } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { Select, Spin } from 'antd';

//import { TextInput, TextInputAutoComplete } from 'components/form-control';
import { XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
import { NoDataTable } from 'components/no-data-table';
import { useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from 'modules/user-management/external-user/company/company.const';
import { UserCompanyType } from 'modules/user-management/external-user/company/company-user-modal/company-user-modal.const';
import { isNil } from 'utils/helper';
import moment from 'moment';
import axiosClient from 'utils/axios-client';

import './style.module.scss';
import SortingArrow from 'components/sorting-arrow';

type LogDetailsProps = {
  companyId?: string
};

const logActions = [
  'loginAccount',
  'createMember',
  //'createPendingNewMember',
  'createBatchNewMember',
  //'createBatchPendingNewMember',
  'updateMember',
  'updateMemberRestriction',
  'updateMemberKioskTable',
  'updateMemberRole',
  'loginUser',
  'createUser',
  'createBatchUser',
  'updateUser',
  'updateUserRestriction',
  'updateUserRole',
  'createCompany',
  'updateCompany',
  'updateCompanyRole',
  'updateCompanyKioskTable',
  //'updateCompanyMemberKioskPermission',
  //'updateBlueonionProductDeviceLimit',
  'bulkUpdateBlueonionProductDeviceLimit',
  'changePassword',
  'addPermissions',
  'updatePermissions',
  'importPermissions',
  'deletePermissions',
  'addRoles',
  'updateRoles',
  'importRoles',
  'deleteRoles',
  'addRolePermission',
  'importRolePermission',
  'deleteRolePermission',
  'addObjectRole',
  'updateObjectRole',
  'deleteObjectRoles',
  'importRolesForMember',
  'importRolesForCompany',
  'importRolesForUser',
  'deleteMember',
  'changeUsername',
  'changeSelfUsername',
  'changeAdminPassword',
  'updateMemberDataPermission',
  'updateMemberRegion',
  'updateCompanyRegion',
  'addPermission',
  'updatePermission',
  'importPermission',
  'deletePermission',
  'updateMemberAsset',
  'updateCompanyAsset',
];

/*
[
  { label: 'Burns Bay Road', value: 'Burns Bay Road' },
  { label: 'Downing Street', value: 'Downing Street' },
  { label: 'Wall Street', value: 'Wall Street' },
];
*/
const actionOptions = logActions.sort().map((v) => { return { label: v, value: v }; });

export default function LogDetails({ companyId, ...props }: LogDetailsProps) {
  const history = useHistory();
  const location = useLocation();

  const { pageSize, page, search, sortOrder, sortBy, roleIds, tabKey, action } = useParseParams();

  //const [valueSearch, setValueSearch] = useState<string>(search);

  const setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 20,
          search,
          sortOrder: sortOrder ?? 'desc',
          sortBy: sortBy ?? 'id',
          roleIds,
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, roleIds, search, sortBy, sortOrder],
  );

  /*
  const onChangeInput = (value: string) => {
    setValueSearch(value);
    setTimeout(() => {
      setParamHandle({ search: value, tabKey });
    }, 700);
  };
  */

  const getLogDetailsList = async (params: any) => {
    return await axiosClient.get('/log', {
      params: {
        ...params,
        sortOrder: params.sortOrder || 'desc',
        sortBy: params.sortBy || 'id',
      },
    }).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return {};
      }
    });
  };

  const {
    data: listLogDetailsData,
    isLoading: isListLogDetailsLoading,
  } = useQuery(
    [
      'getListLogDetails',
      {
        pageSize,
        page,
        //status,
        search,
        sortBy,
        sortOrder,
        action,
      },
    ], () => {
      return getLogDetailsList({
        pageSize,
        page,
        // status: undefined,  //urlStatus !== 'active,disabled' ? urlStatus : undefined,
        search: search?.trim(),
        sortBy,
        sortOrder,
        action,
      });
    });

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<UserCompanyType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
      tabKey,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  const columns: ColumnsType<UserCompanyType> = [
    {
      align: 'left',
      title: renderTitle('ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      //ellipsis: true,
      sorter: true,
    },
    {
      align: 'left',
      title: renderTitle('Action', 'action'),
      dataIndex: 'action',
      key: 'action',
      width: 250,
      ellipsis: true,
      render: (_, data: any) => data.action ?? '-',
      sorter: true,
    },
    {
      align: 'left',
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: 100,
      ellipsis: true,
      render: (_, data: any) => data.key ? data.key : '-',
    },
    {
      align: 'left',
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (_, data: any) => (
        <div className="noticeTitle" onClick={() => {
          let dataJson = null;
          let brokenJson = '';
          if (data.data) {
            try {
              dataJson = JSON.parse(data.data);
            }
            catch (ex) {
              dataJson = '--JSON ERROR--';
              //try format the broken json
              brokenJson = '\n\nBroken JSON:\n' +
                data.data
                  .replace(/\},\{/g, '},\n{')
                  .replace(/\[\{/g, '[\n{')
                  .replace(/\],/g, '],\n');
              // console.log(dataJson);
            }
          }
          else {
            dataJson = data.data;
          }

          const w = window.open('about:blank', '_blank');
          w?.document.write('<div style="text-align:center;padding-top:3em;"><div style="text-align:left;width:612px;margin:auto;">Log detail:<pre>' +
            JSON.stringify({ ...data, data: dataJson }, null, '\t') + brokenJson +
            '</pre></div></div>');
        }}>
          {data.description}
        </div>
      ),
    },
    {
      align: 'left',
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 250,
      //sorter: true,
      ellipsis: true,
      render: (_, data: any) => {
        return (
          <div>{isNil(data.created_at) ? '-' : moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
        );
      },
    },
  ];

  const ClearFilters = () => {
    setParamHandle({ page: 1, pageSize: 20, search: undefined, tabKey });
    //setValueSearch('');
  };

  const onChangePage = (page: number, pageSize: number) => {
    setParamHandle({ page, pageSize, roleIds: roleIds, tabKey, action });
  };

  const totalResult = useMemo(() => {
    return listLogDetailsData?.total ?? 0;
  }, [listLogDetailsData?.total]);

  return (
    <div key={'LogDetailsPanel' + tabKey}>
      <p className="filter-hub-text">Filter Action</p>
      <div className="filter-hub-flex">
        <div className="filter-hub-options">
          <Select
            showSearch
            optionFilterProp='label'
            placeholder='Select Action'
            value={action ? action.split(',') : undefined}
            onChange={(v: string[]) => {
              setParamHandle({ page: 1, action: v.join(','), tabKey });
            }}
            options={actionOptions}
            maxTagCount="responsive"
            virtual={false}
            className='input'
            size='large'
            // mode="multiple"
            mode='tags'
            showArrow
            allowClear
          />
        </div>
        <div className="clear-filters" onClick={ClearFilters}>
          <span>Clear all filters</span>
          <XCircleIcon width={24} height={24} />
        </div>
      </div>
      {isListLogDetailsLoading ? (
        <Spin className="spin" />
      ) : (
        <>
          {!listLogDetailsData?.data || listLogDetailsData?.data.length === 0 ? (
            <NoDataTable />
          ) : (
            <>
              <Table
                rowKey={'id'}
                columns={columns}
                //dataSource={roleIds ? listCompanyData?.data?.data : []}
                dataSource={listLogDetailsData?.data || []}
                pagination={false}
                onChange={onChangeTable}
                loading={isListLogDetailsLoading}
              />

              <div className="pagination">
                <div className="total-results-text">
                  Total results: <p className="total-results-number">{totalResult}</p>
                </div>
                <CustomPagination
                  pageSize={listLogDetailsData?.pageSize ?? 20}
                  current={listLogDetailsData?.page ?? 1}
                  total={totalResult}
                  onChange={onChangePage}
                  showSizeChanger={true}
                />
              </div>
            </>
          )}
        </>
      )}

    </div>
  );
}
