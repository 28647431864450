import { Form, Modal, ModalProps } from 'antd';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

import { CommonButton } from 'components/common-button';
import { PasswordInput } from 'components/form-control';
import { InfoCircleFilledIcon } from 'components/icons';
import LabelRequire from 'components/label-require';
import { ModalBase } from 'components/modal';
import styles from './change-password-modal.module.scss';
import { IChangePassword } from 'types/internal-user/internal-user-table.type';
import { internalUserApi } from 'services/internal-user/internal-user.service';

interface ChangePasswordModalProps extends ModalProps {
  setVisibleChangePasswordModal: Dispatch<SetStateAction<boolean>>;
}

export function ChangePasswordModal({ setVisibleChangePasswordModal, ...props }: ChangePasswordModalProps) {
  const [form] = Form.useForm();
  const [valueOldPassword, setValueOldPassword] = useState<string>('');

  const handleChangePassword = (value: IChangePassword) => {
    // console.log(value);
    internalUserApi.changePassword(value).then(() => {
      Modal.success({
        content: 'Password changed successfully',
        onOk: () => {
          setVisibleChangePasswordModal(false);
          form.resetFields();
        },
      });

    });
  };

  const handleChangeOldPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setValueOldPassword(e.target.value);
  };
  const onCancel = () => {
    setVisibleChangePasswordModal(false);
    form.resetFields();
  };

  return (
    <div>
      <ModalBase
        {...props}
        onCancel={onCancel}
        centered
        title="CHANGE PASSWORD"
        forceRender
        className={styles.formChangePasswordModal}
      >
        <Form form={form} onFinish={handleChangePassword}>
          <LabelRequire text="Old Password" require />
          <PasswordInput
            placeholder="Input your old password"
            name="oldPassword"
            onChange={handleChangeOldPassword}
            rules={[
              { required: true, message: 'You must fill in this field' },
              {
                pattern: new RegExp(/^.{8,100}$/),
                message: 'Password must be 8-100 characters long',
              },
            ]}
          />
          <LabelRequire text="New Password" require />
          <PasswordInput
            placeholder="Input your new password"
            name="newPassword"
            rules={[
              { required: true, message: 'You must fill in this field' },
              {
                pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/),
                message: 'Your password is not strong enough. Make sure it follow the rule below',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if ((!value || getFieldValue('oldPassword') === value) && valueOldPassword !== '') {
                    return Promise.reject('New password must be different from old password');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />
          <div className={styles.passwordRequired}>
            <InfoCircleFilledIcon />
            <div className={styles.info}>
              <p>Password must be:</p>
              <ul>
                <li>At least 8 characters</li>
                <li>Must contain at least an uppercase letter</li>
                <li>Must contain at least a number</li>
                <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
              </ul>
            </div>
          </div>
          <LabelRequire text="Confirm New Password" require />
          <PasswordInput
            placeholder="Confirm your new password"
            name="confirmPassword"
            rules={[
              { required: true, message: 'You must fill in this field' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The password confirmation does not match. Please check again');
                },
              }),
            ]}
          />
          <div className={styles.alignButton}>
            <CommonButton onClick={onCancel} variant="default">
              Cancel
            </CommonButton>
            <Form.Item shouldUpdate className={styles.updatePasswordButton}>
              {() => (
                <CommonButton
                  htmlType="submit"
                  block
                  size="large"
                  variant="primary"
                  disabled={
                    !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  Save
                </CommonButton>
              )}
            </Form.Item>
          </div>
        </Form>
      </ModalBase>
    </div>
  );
}
