import { useCallback, useMemo, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { Menu, Modal, Spin, message } from 'antd';

//import { TextInput } from 'components/form-control';
import { SearchIcon, XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
import { NoDataTable } from 'components/no-data-table';
import { useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from 'modules/user-management/external-user/company/company.const';
import { UserCompanyType } from 'modules/user-management/external-user/company/company-user-modal/company-user-modal.const';
//import { isNil } from 'utils/helper';
import moment from 'moment';
import axiosClient from 'utils/axios-client';
import { TextInput } from 'components/form-control';
import { isNil } from 'utils/helper';

import './style.module.scss';
import SortingArrow from 'components/sorting-arrow';
import { CommonButton } from 'components/common-button';
import { EditItemModal } from '../edit-item-modal';
import { RowActionMenu, RowActionMenuProps } from 'modules/user-management/external-user/company/row-action-menu';
import dayjs from 'dayjs';
import ImportByTemplateModal from 'components/modal/import-by-template-modal';
import { ImportPermissionDto, regPermissionName } from './permission.const';
import { arrayToCsv } from '../roles/tools';
// import { RolePermissionModal } from '../role-permission';

async function verifyImportData(rows: string[][], dtos: ImportPermissionDto[] | null = null) {
  if (rows[0].slice(0, 5).join() !== 'table,name,display_name,short_name,description') {
    return { result: false, message: 'header fail, ' + rows[0] };
  }
  if (rows.length < 2) {
    return { result: false, message: 'empty data' };
  }

  var cnt = 0;

  for (var i = 1; i < rows.length; i++) {
    //console.log(rows);
    var row = rows[i];

    if (row[0] !== 'permission') continue;

    if (!row[1].match(regPermissionName)) {
      //console.log(row);
      return { result: false, line: i + 1, message: 'key name fail: ' + row };
    }

    if (dtos) {
      dtos.push({ table: row[0], name: row[1], display_name: row[2], short_name: row[3], description: row[4] });
    }

    cnt++;
  }

  if (cnt < 1) {
    return { result: false, message: 'empty data for table \'permission\'' };
  }

  return { result: true };
}

async function importData(rows: string[][]) {
  var dtos: ImportPermissionDto[] = [];

  var r = await verifyImportData(rows, dtos);
  if (!r.result)
    return r;

  // console.log(dtos);
  try {
    var res = await axiosClient.post('/permission/import', { list: dtos.filter(row => row.table === 'permission').map((v) => ({ ...v, table: undefined })) });
    if (res?.data.success) {
      return { result: true, message: 'Import success, add ' + res.data.add + ', update ' + res.data.update + ', skip ' + res.data.skip + '.' };
    }
    else {
      return { result: false, message: JSON.stringify(res).slice(0, 255) };
    }
  }
  catch (ex: any) {
    //console.log(ex);
    return { result: false, message: '' + ex };
  }
}

const ACTION_LABEL = {
  EDIT: 'Edit',
  OBJECT_PERMISSION: 'Object_Permission',
  DELETE: 'Delete',
};

function ActionMenu({ row, className, action, ...props }: RowActionMenuProps) {
  const menu = (
    <Menu className={className}>
      <Menu.Item key='edit permission' onClick={() => { action(ACTION_LABEL.EDIT, row); }}>Edit</Menu.Item>
      {/* <Menu.Item key='object-permission for permission' onClick={() => { action(ACTION_LABEL.OBJECT_PERMISSION, row); }}>Object Permission</Menu.Item> */}
      <Menu.Divider />
      <Menu.Item key='delte permission' onClick={() => { action(ACTION_LABEL.DELETE, row); }}>Delete</Menu.Item>
    </Menu>
  );
  return <RowActionMenu {...{ row, className, action, ...props, menu }} />;
}

export default function Permission() {
  const history = useHistory();
  const location = useLocation();

  const { pageSize, page, search, sortOrder, sortBy, tabKey, action } = useParseParams();

  const [valueSearch, setValueSearch] = useState<string>(search);

  const [isItemEditing, setIsItemEditing] = useState(false);
  const [isOpenImportPopup, setOpenImportPopup] = useState<boolean>(false);
  // const [isOpenRolePermissionModal, setOpenRolePermissionModal] = useState<boolean>(false);

  const setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 50,
          search,
          sortOrder: sortOrder ?? 'desc',
          sortBy: sortBy ?? 'id',
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, search, sortBy, sortOrder],
  );

  const [editItemArgs, setEditItemArgs] = useState<any>({
    title: '',
    values: undefined,
    onOK: null,
  });

  /*
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({ search: e.target.value, tabKey });
    }, 700);
  };
  */

  const getPermissionList = (params: any) => {
    return axiosClient.get('/permission', {
      params: {
        ...params,
        sortOrder: params.sortOrder || 'desc',
        sortBy: params.sortBy || 'id',
        pageSize: params?.pageSize ?? 50,
      },
    }).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return {};
      }
    });
  };

  //console.log([location.pathname, pageSize, page, search, sortOrder, sortBy, roleIds, tabKey]);

  const {
    data: listPermissionData,
    isLoading: isListPermissionLoading,
    refetch: refetchList,
  } = useQuery(
    [
      'getListPermission',
      {
        pageSize,
        page,
        //status,
        search,
        sortBy,
        sortOrder,
        action,
      },
    ], () => {
      return getPermissionList({
        pageSize,
        page,
        status: undefined,  //urlStatus !== 'active,disabled' ? urlStatus : undefined,
        search: search?.trim(),
        sortBy,
        sortOrder,
        action,
      });
    });

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<UserCompanyType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
      tabKey,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  const columns: ColumnsType<UserCompanyType> = [
    {
      align: 'left',
      title: renderTitle('ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true,
      //ellipsis: true,
    },
    {
      align: 'left',
      title: renderTitle('Name', 'name'),
      dataIndex: 'name',
      key: 'name',
      width: 160,
      // ellipsis: true,
      sorter: true,
    },
    {
      align: 'left',
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      width: 200,
      ellipsis: true,
      // render: (_, data: any) => (data.receiver_name ? (data.receiver_name + ' / ') : '') + data.receiver_list,
    },
    {
      align: 'left',
      title: 'Short Name',
      dataIndex: 'short_name',
      key: 'short_name',
      width: 120,
      ellipsis: true,
    },
    {
      align: 'left',
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      ellipsis: true,
    },
    {
      align: 'left',
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      //sorter: true,
      ellipsis: true,
      render: (_, data: any) => {
        return (
          <div>{isNil(data.created_at) ? '-' : moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
        );
      },
    },
    {
      align: 'left',
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 150,
      //sorter: true,
      ellipsis: true,
      render: (_, data: any) => {
        return (
          <div>{isNil(data.updated_at) ? '-' : moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss')}</div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'permissionAction',
      key: 'permissionAction',
      width: 80,
      align: 'center',
      fixed: 'right',

      render: (_, data: UserCompanyType) => {
        return (
          <ActionMenu row={data} className={'actionMenu'} action={onPemissionsAction} />
        );
      },

    },
  ];

  const ClearFilters = () => {
    setValueSearch('');
    setParamHandle({ page: 1, pageSize: 50, search: undefined, tabKey });
  };

  const onChangePage = (page: number, pageSize: number) => {
    setParamHandle({ page, pageSize, tabKey, action });
  };

  const totalResult = useMemo(() => {
    return listPermissionData?.total ?? 0;
  }, [listPermissionData?.total]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({ tabKey, search: e.target.value, page: 1 });
    }, 700);
  };

  const onAddPermissionOk = (values: any) => {
    // console.log(values);
    axiosClient.post('/permission', {
      name: values.keyName,
      display_name: values.displayName,
      short_name: values.shortName,
      description: values.description,
    }).then((res) => {
      // console.log(res);
      message.success('Success to create a permission');
      refetchList();
    }).catch((ex) => {
      // console.log(ex);
      message.error('Error: ' + (ex?.response?.data?.message || ex));
    });
  };

  const onAddPermission = () => {
    setEditItemArgs({
      title: 'Add Permission',
      values: {
        keyName: '',
        displayName: '',
        shortName: '',
        description: '',
      },
      onModalOK: onAddPermissionOk,
    });
    setIsItemEditing(true);
    // console.log('open add dlg');
  };

  const onEditPermissionOk = (values: any) => {
    // console.log(values);
    axiosClient.put('/permission/' + values.id, {
      display_name: values.displayName,
      short_name: values.shortName,
      description: values.description,
    }).then((res) => {
      // console.log(res);
      message.success('Success to update a permission');
      refetchList();
    }).catch((ex) => {
      // console.log(ex);
      message.error('Error: ' + (ex?.response?.data?.message || ex));
    });
  };

  const handleEditPermission = (row: any) => {
    setEditItemArgs({
      title: 'Edit Permission',
      values: {
        id: row.id,
        keyName: row.name,
        displayName: row.display_name,
        shortName: row.short_name,
        description: row.description,
      },
      onModalOK: onEditPermissionOk,
    });
    setIsItemEditing(true);
    // console.log('open add dlg');
  };

  const handleDeletePermission = (row: any) => {
    var value: any = '';
    var modal = Modal.confirm({
      title: 'Confirm Deleting',
      content: <p>To confirm the deleting of permission <span style={{ color: 'green' }}>{row.name}</span>,
        please type or paste the permission's name below.<br />
        <br />
        The permission &nbsp;
        <input onChange={(evt) => {
          value = evt.target.value;
          modal.update({ okButtonProps: { disabled: value !== row.name && value !== 'skip for batch' } });
        }} />
        &nbsp; will be deleted.
      </p>,
      width: 600,
      okButtonProps: { disabled: true },
      closable: true,
      maskClosable: true,
      onOk: () => {
        // console.log(value);
        axiosClient.delete('/permission/' + row.id).then((res) => {
          // console.log(res);
          message.success('Success to delete a permission');
          refetchList();
        }).catch((ex) => {
          // console.log(ex);
          message.error('Error: ' + (ex?.response?.data?.message || ex));
        });

      },
    });

  };

  // const [rolePermissionModalArgs, setRolePermissionModalArgs] = useState<any>({
  //   modalKey: 'RolePermissionModal-from-permission',
  //   open: true,
  //   setModalVisible: setOpenRolePermissionModal,
  // });

  // const handleObjectPermission = (row: any) => {
  //   setRolePermissionModalArgs({
  //     ...rolePermissionModalArgs,
  //     title: 'Roles for Permission [' + row.name + ']',
  //     role_id: '',
  //     role_name: '',
  //     permission_id: row.id,
  //     permission_name: row.name,
  //   });
  //   setOpenRolePermissionModal(true);
  // };

  async function onPemissionsAction(label: string, row: any) {
    if (label === ACTION_LABEL.EDIT) handleEditPermission(row);
    // else if (label === ACTION_LABEL.OBJECT_PERMISSION) handleObjectPermission(row);
    else if (label === ACTION_LABEL.DELETE) handleDeletePermission(row);
  }

  const onExportPermission = () => {
    const num = listPermissionData.data.length || 0;
    if (!(num > 0)) {
      message.info('no permission to export in current page.');
      return;
    }

    Modal.confirm({
      title: 'Export Confirmation',
      content: <p>Please confirm to export the <b style={{ color: 'green' }}>{num}</b> permission{(num > 1) ? 's' : ''}  in current page?</p>,
      closable: true,
      maskClosable: true,
      onOk() {
        var exData = [
          ['table', 'name', 'display_name', 'short_name', 'description'],
        ];
        listPermissionData.data.forEach((row: any) => {
          exData.push(['permission', row.name, row.display_name, row.short_name, row.description]);
        });

        //sort
        exData = [
          exData[0],
          ...exData.slice(1).sort((a, b) => {
            return a[1].localeCompare(b[1], undefined, { sensitivity: 'base' });
          }),
        ];

        const csvContent =
          'data:text/csv;charset=utf-8,' + arrayToCsv(exData);
        const encodedUri = encodeURI(csvContent);

        const link = document.createElement('a');
        link.href = encodedUri;
        link.download = 'permission-' + dayjs().format('YYYYMMDD-HHmmss') + '.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };


  return (
    <>
      <div key={'PermissionPanel' + tabKey}>
        <p className="filter-hub-text">Filter Permission</p>
        <div className="filter-hub-flex">
          <div className="filter-hub-options">
            <TextInput
              placeholder="Search names"
              className="input"
              inputProps={{
                onChange: onChangeInput,
                value: valueSearch,
                prefix: <SearchIcon width={20} height={20} />,
                maxLength: 255,
              }}
            />
          </div>
          <div className="filter-hub-btns" style={{ display: 'flex' }}>
            <div className="clear-filters" onClick={ClearFilters}>
              <span>Clear all filters</span>
              <XCircleIcon width={24} height={24} />
            </div>
            <CommonButton variant="link"
              key="add-permission"
              onClick={onAddPermission}
              disabled={false}
              style={{ marginLeft: '2em' }}
            >
              Add
            </CommonButton>
            <CommonButton variant="link"
              key="export-permission"
              onClick={onExportPermission}
              disabled={false}
              style={{ marginLeft: '0.5em' }}
            >
              Export
            </CommonButton>
            <CommonButton variant="link"
              key="import-permission"
              onClick={() => { setOpenImportPopup(true); }}
              disabled={false}
              style={{ marginLeft: '0.5em' }}
            >
              Import
            </CommonButton>
          </div>
        </div>
        {isListPermissionLoading ? (
          <Spin className="spin" />
        ) : (
          <>
            {!listPermissionData?.data || listPermissionData?.data.length === 0 ? (
              <NoDataTable />
            ) : (
              <>
                <Table
                  rowKey={'id'}
                  columns={columns}
                  dataSource={listPermissionData?.data || []}
                  pagination={false}
                  onChange={onChangeTable}
                  loading={isListPermissionLoading}
                />

                <div className="pagination">
                  <div className="total-results-text">
                    Total results: <p className="total-results-number">{totalResult}</p>
                  </div>
                  <CustomPagination
                    pageSize={listPermissionData?.pageSize ?? 50}
                    current={listPermissionData?.page ?? 1}
                    total={totalResult}
                    onChange={onChangePage}
                    showSizeChanger={true}
                  />
                </div>
              </>
            )}
          </>
        )}

      </div>

      {isItemEditing && (
        <EditItemModal
          open={true}
          // onCancel={() => { setIsItemEditing(false); }}
          setModalVisible={setIsItemEditing}
          {...editItemArgs}
        />
      )}

      {isOpenImportPopup && (
        <ImportByTemplateModal
          title={'Setting - Import Permission'}
          verifyData={async (rows: string[][]) => await verifyImportData(rows)}
          closePopup={() => { setOpenImportPopup(false); }}
          importData={async (rows: string[][]) => {
            var ret = await importData(rows);
            await refetchList();
            return ret;
          }}
          action='upload-import-permission'
          templateName='setting-import-permission-template'
          xlsxTemplateUrl='/template/setting-import-permission-template.xlsx'
          csvTemplateUrl='/template/setting-import-permission-template.csv'
        />
      )}

      {/* {isOpenRolePermissionModal && (
        <RolePermissionModal
          {...rolePermissionModalArgs}
        />
      )} */}

    </>
  );
}
