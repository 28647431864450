import React from 'react';

export function InfoCircleFilledIcon({ fill = '#4B4B4B', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 2.9488C6.10601 2.9488 2.94904 6.10577 2.94904 10.0001C2.94904 13.8944 6.10601 17.0514 10.0003 17.0514C13.8946 17.0514 17.0516 13.8944 17.0516 10.0001C17.0516 6.10577 13.8946 2.9488 10.0003 2.9488ZM1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 13.6326C10.3544 13.6326 10.6414 13.3456 10.6414 12.9915V9.57273C10.6414 9.2187 10.3544 8.93171 10.0003 8.93171C9.6463 8.93171 9.3593 9.2187 9.3593 9.57273V12.9915C9.3593 13.3456 9.6463 13.6326 10.0003 13.6326Z"
        fill={fill}
      />
      <path
        d="M10.855 7.00863C10.855 6.53659 10.4724 6.15393 10.0003 6.15393C9.52829 6.15393 9.14563 6.53659 9.14563 7.00863C9.14563 7.48067 9.52829 7.86333 10.0003 7.86333C10.4724 7.86333 10.855 7.48067 10.855 7.00863Z"
        fill={fill}
      />
    </svg>
  );
}
