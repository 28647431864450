import { Select, SelectProps } from 'antd';
import { useMemo, useState } from 'react';

import { TextInput } from 'components/form-control';
import { SearchIcon } from 'components/icons';
import { useDebounce } from 'hooks/use-debounce';
import styles from './select-search.module.scss';
import { SelectType } from '../create-internal-user.constants';
import { CheckIcon } from './check-icon';

const { Option } = Select;

interface SelectSearchProps extends SelectProps {
  optionsSelect?: SelectType[];
}

const SelectSearch = ({ optionsSelect, ...props }: SelectSearchProps) => {
  const [valueSearch, setValueSearch] = useState<string>('');
  const debounceValueSearch = useDebounce<string>(valueSearch.trim());

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
  };

  const onDropdownVisibleChange = () => {
    setValueSearch('');
  };

  const renderDropdown = (options: React.ReactElement) => {
    return (
      <>
        <TextInput
          placeholder="Search"
          inputProps={{
            prefix: <SearchIcon fill="#6F7282" />,
            value: valueSearch,
            onChange: onChangeInput,
            maxLength: 255,
          }}
        />
        {options}
      </>
    );
  };

  const filterOptions = useMemo(
    () =>
      optionsSelect?.filter((option) => {
        const lowerCaseOption = option.label.toLowerCase();
        const lowerDebounceValueSearch = debounceValueSearch.toLowerCase();
        return lowerCaseOption.startsWith(lowerDebounceValueSearch);
      }),
    [debounceValueSearch, optionsSelect],
  );

  return (
    <Select
      {...props}
      className={styles.selectSearch}
      placeholder="Select"
      allowClear
      dropdownRender={renderDropdown}
      dropdownClassName={styles.selectSearchDropdown}
      onDropdownVisibleChange={onDropdownVisibleChange}
      defaultActiveFirstOption
    >
      {filterOptions?.map((option, index) => (
        <Option key={`${option.value}-${index}`} value={option.value}>
          <span>{option.label}</span>
          <CheckIcon />
        </Option>
      ))}
    </Select>
  );
};

export default SelectSearch;
