import React from 'react';

export const SortAscIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.83919 1.5415L3.83919 14.0415"
        stroke={props.stroke ? '#27272A' : '#959CB6'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.26086 4.99951L3.89876 1.21481L6.53711 4.99951"
        stroke={props.stroke ? '#27272A' : '#959CB6'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
