export const configs = {
  authUrl: process.env.AUTH_URL as string,
  apiBaseUrl: process.env.API_BASE_URL as string,
};

export const initFilter = {
  page: 1,
  pageSize: 12,
};
export const listSizePage = ['12', '24'];
