import { WarningIcon } from 'assets/img/login/icon/warning-icon';
import './style.scss';

type LoginNotiType = {
  content: string;
  disabled?: boolean;
};

export const LoginNoti = ({ content }: LoginNotiType) => {
  return (
    <div className="noti-container">
      <WarningIcon />
      <div className={'noti-content'}>{content}</div>
    </div>
  );
};
