import { NodataIcon } from 'components/icons';
import styles from './no-data-table.module.scss';

export const NoDataTable = () => {
  return (
    <div className={styles.nodatInternal}>
      <div className={styles.nodataIcon}>
        <NodataIcon />
      </div>
      <span className={styles.nodataTitle}>No data found with current filters.</span>
      <span className={styles.nodataText}>Try removing or changing your filters criteria to see more data. </span>
    </div>
  );
};
