export type SelectType = {
  label: string;
  value: string;
};

export type InternalUserDataType = {
  name: string;
  email: string;
  expired_date: string;
  country?: string;
  city?: string;
  postalCode?: number;
  phone?: string;
};

export const errorKeyWord = {
  userExisted: 'us00006',
  emailExisted: 'us00004',
  phoneExisted: 'us00007',
  phoneError: 'Unprocessable Entity',
};
