import clsx from 'clsx';
import { Pagination, PaginationProps } from 'antd';

import './pagination.scss';

export const CustomPagination = ({
  onChange,
  pageSize,
  current,
  total,
  onShowSizeChange,
  className,
  pageSizeOptions = [10, 20, 50, 100],
  showSizeChanger = true,
  ...props
}: PaginationProps) => {
  return (
    <Pagination
      {...props}
      className={clsx('customPagination', className)}
      total={total}
      current={current}
      pageSize={pageSize}
      onChange={onChange}
      pageSizeOptions={pageSizeOptions}
      showSizeChanger={showSizeChanger}
      onShowSizeChange={onShowSizeChange}
      locale={{ items_per_page: ' records/page' }}
    />
  );
};
