import '../external-user/external-user.scss';
import TabCommon from 'components/tab-common';
// import { listTab } from '../external-user';
// import BoAdmin from '../internal-user/bo-admin';
import Notice from './notice';
import LogDetails from './logDetails';

export interface IExternalUserProps { }


export default function Log(props: IExternalUserProps) {

  const listTabEx = [
    {
      key: 'Log',
      name: 'Log',
      component: <LogDetails />,
    },
    {
    key: 'Notice',
    name: 'Notice',
    component: <Notice />,
  },
  ];

  return (
    <div className="external-user">
      <div className="content">
        <TabCommon listTab={listTabEx} />
      </div>
    </div>
  );
}
