import { Form, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { ChangeEvent, useState } from 'react';
import { postDataNoInterceptor } from 'utils/api';
import { TextInput } from 'components/form-control';
import { ArrowLeftTailIcon } from 'components/icons';
import { PATH_LOGIN } from 'navigation/routes.path';
import { BlueonionBlackIcon } from 'components/icons/blueonion-logo-black-icon';
import styles from './forgot-password-form.module.scss';
import { CommonButton } from 'components/common-button';
import { configs } from 'constant';
import 'antd/dist/antd.css';


type IFormReset = {
  email: string;
};

export default function ForgotPassword() {
  const [form] = Form.useForm();

  const [isValidResetButton, setIsValidResetButton] = useState<boolean>(false);
  const [isValueInput, setIsValueInput] = useState<string>('');
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = async (value: IFormReset) => {
    setLoadingButton(true);
    const params = {
      email: isValueInput,
    };
    await postDataNoInterceptor<IFormReset>(`${configs.apiBaseUrl + '/user/reset-password-email'}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res: any) => {
      if (res?.data) {
        if (res?.data?.success === true) {
          setIsValidResetButton(true);
          setLoadingButton(false);

        }
      }
      return {} as any;
    })
      .catch((error) => {
        setError(error?.response?.data?.message);
        setIsModalOpen(true);
        setLoadingButton(false);

      });

    //setIsValidResetButton(true);


  };
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsValueInput(e.target.value);
  };


  return (
    <div className={styles.formForgotPassword}>
      <BlueonionBlackIcon style={{ width: 265, marginBottom: 75 }} />
      <Link to={PATH_LOGIN}>
        <div className={styles.back}>
          <ArrowLeftTailIcon stroke="#4b4b4b" fill="#4b4b4b" />
          <span>Back</span>
        </div>
      </Link>
      <Modal title="Error" open={isModalOpen} onCancel={handleCancel} footer={[]}>
        <p className={styles.errorMessage} >{errorMessage + (errorMessage.match(/try again/i) ? '' : '. Please Try Again!')}</p>
      </Modal>
      {isValidResetButton ? (
        <div className={styles.emailCheck}>
          <p className={styles.title}>Check email for reset link</p>
          <div className={styles.note}>
            <p>
              You’re almost there! We sent an email to <strong>{isValueInput}</strong>
            </p>
            <p>Check the inbox of the email and click on the reset link provided.</p>
          </div>
          <div className={styles.emailCheckButton}>
            <Link to={PATH_LOGIN}>
              <CommonButton
                htmlType="submit"
                block
                size="large"
                variant="primary"
              >
                Go to Login
              </CommonButton>
            </Link>
          </div>
        </div>
      ) : (
        <>
          <p className={styles.title}>Check email for reset link</p>
          <div className={styles.note}>
            <p>
              Forget your password? Please enter your email address. You will receive a link to create new password via
              email
            </p>
          </div>
          <Form form={form} onFinish={handleChange}>
            <TextInput
              inputProps={{ onChange: handleChangeInput }}
              className={styles.inputEmail}
              placeholder="Enter your email"
              name="email"
              rules={[
                { required: true, message: 'This is a mandatory field' },
                {
                  pattern: new RegExp(/^(?=[^\s@]{1,64}@)[^\s@]+@[^\s@]+(\.[^\s@.]{2,})+$/),
                  message: 'Please enter a valid email address.',
                },
                //check email
                { max: 100, message: 'The email you entered is incorrect. Please try again.' },
              ]}
            />
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.resetPasswordButton}>
                  <CommonButton
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    block
                    loading={loadingButton}
                    size="large"
                    variant="primary"
                  >
                    Reset Password
                  </CommonButton>
                </div>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
}
