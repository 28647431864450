import { SVGProps } from 'react';

export const ThirdLoginIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="60" cy="60" r="60" fill="#FBFBFF" />
      <g clipPath="url(#clip0_8132_17763)">
        <path
          d="M91.1511 67.3401C91.0159 67.9196 90.4944 68.3253 89.8995 68.3253H86.5759L59.9999 64.1634L33.4239 68.3253H30.1002C29.5054 68.3253 28.9837 67.9196 28.8486 67.3414C28.2949 64.9836 28 62.5266 28 60.0001C28 42.3958 42.2151 28.1123 59.7925 28.0016C77.5064 27.8896 91.8619 42.0365 91.9984 59.7491C92.019 62.3631 91.7241 64.905 91.1511 67.3401Z"
          fill="#62A4FB"
        />
        <path
          d="M91.9988 60.1402C91.9872 62.6179 91.6937 65.0271 91.1502 67.3399C91.015 67.9194 90.4935 68.325 89.8986 68.325H86.575L81.3546 67.5072C81.9353 65.0992 82.2431 62.5869 82.2431 59.9999C82.2431 43.9857 70.4785 30.7184 55.1211 28.3696C56.7115 28.1262 58.3403 28 59.999 28C77.6961 28 92.0747 42.4444 91.9988 60.1402Z"
          fill="#5392F9"
        />
        <path
          d="M83.6431 58.7747C83.3462 52.9113 80.9215 47.4409 76.7399 43.2593C72.2682 38.7878 66.323 36.3252 59.9994 36.3252C53.6757 36.3252 47.7305 38.7878 43.2589 43.2592C43.2589 43.2592 43.2589 43.2592 43.2587 43.2593C39.0772 47.4409 36.6525 52.9114 36.3556 58.7747C36.3209 59.4606 35.7574 59.9998 35.0705 59.9998H32.1621C32.1621 62.8658 32.594 65.6649 33.4239 68.3249H86.575C87.4047 65.6649 87.8367 62.8657 87.8367 59.9998H84.9282C84.2414 59.9998 83.6779 59.4606 83.6431 58.7747Z"
          fill="#E6F7FE"
        />
        <path
          d="M84.9291 60C84.2424 60 83.6788 59.4607 83.644 58.7748C83.4023 53.9997 81.7489 49.4853 78.8848 45.7153C81.0339 50.0151 82.244 54.866 82.244 60C82.244 62.88 81.8613 65.6703 81.1479 68.3251H86.5758C87.4055 65.6651 87.8375 62.8658 87.8375 60H84.9291Z"
          fill="#D3EFFD"
        />
        <path
          d="M40.3154 40.3159C35.0576 45.5738 32.1621 52.5644 32.1621 59.9999H35.0705C35.7572 59.9999 36.3209 59.4607 36.3556 58.7748C36.6525 52.9114 39.0772 47.441 43.2587 43.2594L40.3154 40.3159Z"
          fill="#E175A5"
        />
        <path
          d="M79.6836 40.3159L76.7402 43.2593C80.9219 47.4408 83.3466 52.9113 83.6435 58.7747C83.6782 59.4605 84.2417 59.9998 84.9286 59.9998H87.837C87.837 52.5644 84.9415 45.5738 79.6836 40.3159Z"
          fill="#88F2A9"
        />
        <path
          d="M79.6843 40.3158C74.4265 35.0581 67.436 32.1626 60.0004 32.1626C52.5648 32.1626 45.5743 35.0581 40.3164 40.3158L43.2598 43.2592C43.2598 43.2592 43.2598 43.2592 43.2599 43.2591C47.7315 38.7877 53.6767 36.3251 60.0004 36.3251C66.3242 36.3251 72.2693 38.7877 76.7409 43.2592C78.1514 41.8488 78.4669 41.5332 79.6843 40.3158Z"
          fill="#F7E365"
        />
        <path
          d="M51.9355 87.3172V90.9699C51.9355 91.5389 52.3968 92 52.9657 92H67.0353C67.6043 92 68.0654 91.5388 68.0654 90.9699V87.3172C68.0654 84.7309 65.9688 82.6343 63.3825 82.6343H56.6183C54.0322 82.6342 51.9355 84.7308 51.9355 87.3172Z"
          fill="#A2F5C3"
        />
        <path
          d="M63.3821 82.6343H60C62.5863 82.6343 64.6829 84.7309 64.6829 87.3172V92H67.0348C67.6038 92 68.0649 91.5388 68.0649 90.9699V87.3172C68.065 84.7309 65.9684 82.6343 63.3821 82.6343Z"
          fill="#88F2A9"
        />
        <path
          d="M60.0001 82.634C62.8738 82.634 65.2034 80.3045 65.2034 77.4308C65.2034 74.5571 62.8738 72.2275 60.0001 72.2275C57.1264 72.2275 54.7969 74.5571 54.7969 77.4308C54.7969 80.3045 57.1264 82.634 60.0001 82.634Z"
          fill="#FED2A4"
        />
        <path
          d="M62.4471 72.8384C62.8363 73.5676 63.0577 74.4001 63.0577 75.2846C63.0577 78.1583 60.7281 80.4879 57.8545 80.4879C56.97 80.4879 56.1375 80.2665 55.4082 79.8773C56.2837 81.5175 58.0116 82.6343 60.0008 82.6343C62.8745 82.6343 65.2041 80.3046 65.2041 77.431C65.2041 75.4418 64.0873 73.7139 62.4471 72.8384Z"
          fill="#FFBD86"
        />
        <path
          d="M68.0645 87.3172V90.9699C68.0645 91.5389 68.5257 92 69.0946 92H83.1642C83.7332 92 84.1943 91.5388 84.1943 90.9699V87.3172C84.1943 84.7309 82.0977 82.6343 79.5115 82.6343H72.7472C70.1611 82.6342 68.0645 84.7308 68.0645 87.3172Z"
          fill="#E175A5"
        />
        <path
          d="M79.511 82.6343H76.1289C78.7152 82.6343 80.8118 84.7309 80.8118 87.3172V92H83.1637C83.7327 92 84.1938 91.5388 84.1938 90.9699V87.3172C84.1939 84.7309 82.0973 82.6343 79.511 82.6343Z"
          fill="#DE5791"
        />
        <path
          d="M76.129 82.634C79.0027 82.634 81.3323 80.3045 81.3323 77.4308C81.3323 74.5571 79.0027 72.2275 76.129 72.2275C73.2554 72.2275 70.9258 74.5571 70.9258 77.4308C70.9258 80.3045 73.2554 82.634 76.129 82.634Z"
          fill="#FED2A4"
        />
        <path
          d="M78.576 72.8384C78.9652 73.5676 79.1866 74.4001 79.1866 75.2846C79.1866 78.1583 76.857 80.4879 73.9834 80.4879C73.0989 80.4879 72.2664 80.2665 71.5371 79.8773C72.4126 81.5175 74.1405 82.6343 76.1297 82.6343C79.0034 82.6343 81.333 80.3046 81.333 77.431C81.333 75.4418 80.2162 73.7139 78.576 72.8384Z"
          fill="#FFBD86"
        />
        <path
          d="M35.8047 87.3172V90.9699C35.8047 91.5389 36.2659 92 36.8348 92H50.9044C51.4734 92 51.9346 91.5388 51.9346 90.9699V87.3172C51.9346 84.7309 49.8379 82.6343 47.2517 82.6343H40.4876C37.9013 82.6342 35.8047 84.7308 35.8047 87.3172Z"
          fill="#E175A5"
        />
        <path
          d="M47.2513 82.6343H43.8691C46.4554 82.6343 48.552 84.7309 48.552 87.3172V92H50.9039C51.4729 92 51.934 91.5388 51.934 90.9699V87.3172C51.9341 84.7309 49.8375 82.6343 47.2513 82.6343Z"
          fill="#DE5791"
        />
        <path
          d="M43.8693 82.634C46.7429 82.634 49.0725 80.3045 49.0725 77.4308C49.0725 74.5571 46.7429 72.2275 43.8693 72.2275C40.9956 72.2275 38.666 74.5571 38.666 77.4308C38.666 80.3045 40.9956 82.634 43.8693 82.634Z"
          fill="#FED2A4"
        />
        <path
          d="M46.3162 72.8384C46.7055 73.5676 46.9268 74.4001 46.9268 75.2846C46.9268 78.1583 44.5972 80.4879 41.7236 80.4879C40.8391 80.4879 40.0066 80.2665 39.2773 79.8773C40.1528 81.5175 41.8807 82.6343 43.87 82.6343C46.7436 82.6343 49.0732 80.3046 49.0732 77.431C49.0732 75.4418 47.9565 73.7139 46.3162 72.8384Z"
          fill="#FFBD86"
        />
        <path
          d="M62.1925 62.2233L57.7773 57.8082L71.1746 48.1067C71.649 47.7632 72.2373 48.3517 71.8938 48.826L62.1925 62.2233Z"
          fill="#4981F8"
        />
        <path
          d="M60.0005 64.1627C62.2995 64.1627 64.1631 62.299 64.1631 60C64.1631 57.7011 62.2995 55.8374 60.0005 55.8374C57.7016 55.8374 55.8379 57.7011 55.8379 60C55.8379 62.299 57.7016 64.1627 60.0005 64.1627Z"
          fill="#62A4FB"
        />
        <path
          d="M61.7135 56.2061C61.9499 56.7287 62.0825 57.3082 62.0825 57.9189C62.0825 60.2178 60.2189 62.0816 57.9199 62.0816C57.309 62.0816 56.7295 61.9489 56.207 61.7126C56.8602 63.1568 58.3125 64.1622 60.0007 64.1622C62.2995 64.1622 64.1633 62.2986 64.1633 59.9996C64.1632 58.3116 63.1578 56.8593 61.7135 56.2061Z"
          fill="#5392F9"
        />
        <path
          d="M41.789 60.9375H40.4883C39.9705 60.9375 39.5508 60.5179 39.5508 60C39.5508 59.4821 39.9705 59.0625 40.4883 59.0625H41.789C42.3068 59.0625 42.7265 59.4821 42.7265 60C42.7265 60.5179 42.3068 60.9375 41.789 60.9375Z"
          fill="#62A4FB"
        />
        <path
          d="M42.4097 56.224C42.3293 56.224 42.2477 56.2136 42.1665 56.1917L40.91 55.8551C40.4098 55.7212 40.1131 55.207 40.247 54.7069C40.381 54.2067 40.8956 53.9097 41.3952 54.044L42.6517 54.3806C43.1518 54.5145 43.4486 55.0287 43.3147 55.5289C43.2023 55.9477 42.8236 56.224 42.4097 56.224Z"
          fill="#62A4FB"
        />
        <path
          d="M44.2276 51.8318C44.0684 51.8318 43.9073 51.7913 43.7596 51.706L42.6329 51.0555C42.1846 50.7966 42.0309 50.2233 42.2898 49.7749C42.5488 49.3264 43.1222 49.1733 43.5706 49.4318L44.6972 50.0823C45.1456 50.3411 45.2992 50.9145 45.0403 51.3629C44.8666 51.6638 44.5514 51.8318 44.2276 51.8318Z"
          fill="#62A4FB"
        />
        <path
          d="M47.1228 48.0598C46.883 48.0598 46.643 47.9683 46.46 47.7853L45.5402 46.8655C45.1741 46.4993 45.1741 45.9058 45.5402 45.5396C45.9063 45.1736 46.5 45.1736 46.866 45.5396L47.7857 46.4594C48.1518 46.8256 48.1518 47.4191 47.7857 47.7853C47.6027 47.9683 47.3628 48.0598 47.1228 48.0598Z"
          fill="#62A4FB"
        />
        <path
          d="M72.8769 48.0598C72.637 48.0598 72.397 47.9683 72.214 47.7853C71.8479 47.419 71.8479 46.8255 72.214 46.4594L73.1338 45.5396C73.4999 45.1736 74.0935 45.1736 74.4595 45.5396C74.8257 45.9059 74.8257 46.4994 74.4595 46.8655L73.5398 47.7853C73.3568 47.9683 73.1169 48.0598 72.8769 48.0598Z"
          fill="#62A4FB"
        />
        <path
          d="M50.8955 45.1658C50.5715 45.1658 50.2564 44.9976 50.0828 44.6969L49.4324 43.5704C49.1735 43.122 49.3271 42.5486 49.7755 42.2898C50.2238 42.031 50.7973 42.1845 51.0561 42.6329L51.7065 43.7594C51.9654 44.2078 51.8118 44.7811 51.3634 45.04C51.2159 45.1253 51.0546 45.1658 50.8955 45.1658Z"
          fill="#62A4FB"
        />
        <path
          d="M55.2855 43.3466C54.8715 43.3466 54.4928 43.0703 54.3805 42.6515L54.0439 41.395C53.9099 40.8948 54.2066 40.3807 54.7069 40.2467C55.2066 40.1122 55.7211 40.4095 55.8551 40.9096L56.1918 42.1661C56.3258 42.6662 56.029 43.1803 55.5288 43.3143C55.4475 43.3362 55.3659 43.3466 55.2855 43.3466Z"
          fill="#62A4FB"
        />
        <path
          d="M60 42.7262C59.4822 42.7262 59.0625 42.3065 59.0625 41.7887V40.4878C59.0625 39.9699 59.4822 39.5503 60 39.5503C60.5178 39.5503 60.9375 39.9699 60.9375 40.4878V41.7885C60.9375 42.3064 60.5178 42.7262 60 42.7262Z"
          fill="#62A4FB"
        />
        <path
          d="M64.7138 43.3465C64.6334 43.3465 64.5518 43.3362 64.4705 43.3143C63.9704 43.1804 63.6736 42.6662 63.8075 42.166L64.1441 40.9095C64.2781 40.4094 64.793 40.1123 65.2924 40.2467C65.7925 40.3805 66.0893 40.8948 65.9554 41.3949L65.6188 42.6514C65.5065 43.0703 65.1276 43.3465 64.7138 43.3465Z"
          fill="#62A4FB"
        />
        <path
          d="M69.1047 45.1658C68.9457 45.1658 68.7845 45.1253 68.6368 45.0401C68.1885 44.7812 68.0348 44.2078 68.2937 43.7595L68.9441 42.633C69.203 42.1845 69.7762 42.031 70.2247 42.2898C70.6731 42.5487 70.8267 43.1221 70.5678 43.5705L69.9175 44.697C69.7438 44.9977 69.4287 45.1658 69.1047 45.1658Z"
          fill="#62A4FB"
        />
        <path
          d="M75.7725 51.8319C75.4485 51.8319 75.1335 51.6638 74.9597 51.363C74.7009 50.9146 74.8545 50.3413 75.3029 50.0824L76.4294 49.4319C76.8777 49.1731 77.451 49.3265 77.7101 49.775C77.969 50.2234 77.8154 50.7968 77.367 51.0556L76.2405 51.7061C76.0929 51.7914 75.9316 51.8319 75.7725 51.8319Z"
          fill="#62A4FB"
        />
        <path
          d="M77.5895 56.2241C77.1755 56.2241 76.7968 55.9478 76.6845 55.5289C76.5505 55.0288 76.8473 54.5147 77.3475 54.3807L78.604 54.0441C79.1039 53.9096 79.6183 54.2068 79.7523 54.7069C79.8863 55.2071 79.5895 55.7212 79.0893 55.8552L77.8328 56.1918C77.7515 56.2137 77.6699 56.2241 77.5895 56.2241Z"
          fill="#62A4FB"
        />
        <path
          d="M79.5117 60.9375H78.2109C77.6932 60.9375 77.2734 60.5179 77.2734 60C77.2734 59.4821 77.6932 59.0625 78.2109 59.0625H79.5117C80.0294 59.0625 80.4492 59.4821 80.4492 60C80.4492 60.5179 80.0294 60.9375 79.5117 60.9375Z"
          fill="#62A4FB"
        />
      </g>
      <defs>
        <clipPath id="clip0_8132_17763">
          <rect width="64" height="64" fill="white" transform="translate(28 28)" />
        </clipPath>
      </defs>
    </svg>
  );
};
