import React from 'react';

export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 3.4488C6.10503 3.4488 2.94807 6.60577 2.94807 10.5001C2.94807 14.3944 6.10503 17.5514 9.99935 17.5514C13.8937 17.5514 17.0506 14.3944 17.0506 10.5001C17.0506 6.60577 13.8937 3.4488 9.99935 3.4488ZM1.66602 10.5001C1.66602 5.89771 5.39698 2.16675 9.99935 2.16675C14.6017 2.16675 18.3327 5.89771 18.3327 10.5001C18.3327 15.1025 14.6017 18.8334 9.99935 18.8334C5.39698 18.8334 1.66602 15.1025 1.66602 10.5001Z"
        fill="#4B4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 14.1326C10.3534 14.1326 10.6404 13.8456 10.6404 13.4915V10.0727C10.6404 9.7187 10.3534 9.43171 9.99935 9.43171C9.64532 9.43171 9.35833 9.7187 9.35833 10.0727V13.4915C9.35833 13.8456 9.64532 14.1326 9.99935 14.1326Z"
        fill="#4B4B4B"
      />
      <path
        d="M10.8541 7.50863C10.8541 7.03659 10.4714 6.65393 9.99935 6.65393C9.52731 6.65393 9.14465 7.03659 9.14465 7.50863C9.14465 7.98067 9.52731 8.36333 9.99935 8.36333C10.4714 8.36333 10.8541 7.98067 10.8541 7.50863Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};
