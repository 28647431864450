import React from 'react';

export const SortUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.16081 13.7598V1.25977"
        stroke={props.stroke ? '#27272A' : '#959CB6'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.73914 10.3018L4.10124 14.0865L1.46289 10.3018"
        stroke={props.stroke ? '#27272A' : '#959CB6'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
