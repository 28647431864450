import { useQuery } from 'react-query';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, Spin, message } from 'antd';

import { CommonSelect } from 'components/common-select';
import { TextInput } from 'components/form-control';
import { SearchIcon, XCircleIcon } from 'components/icons';
import { ConfirmActiveModal } from 'components/modal/confirm-activate-modal';
import { CustomPagination } from 'components/pagination';
import SortingArrow from 'components/sorting-arrow';
import Table from 'components/table';
import { useParseParams } from 'hooks/use-params';
import { internalUserApi } from 'services/internal-user/internal-user.service';
import { InternalUserType } from '../internal-user.const';
import {
  convertStatus,
  ErrorType,
  optionSelect,
  PaginationDataType,
  pushParamHandle,
  renderSorterOrder,
  UserStatusType,
} from './bo-admin.const';
import styles from './styles.module.scss';
import { NoDataTable } from 'components/no-data-table';
import { isEmpty, isNil } from 'utils/helper';
import moment from 'moment';
import ChangePlanModal from 'modules/user-management/external-user/company/change-plan-modal';
import { namedMappingRev, roleDefault } from 'modules/user-management/external-user/company/change-plan-modal/role-tool';
import { externalUserApi } from 'services/api-external-user.services';
import { RowActionMenu, RowActionMenuProps } from 'modules/user-management/external-user/company/row-action-menu';
import { ObjectRolesModal } from 'modules/user-management/settings/object-roles';

const mappingStatus = {
  disabled: '0',
  active: '1',
};

type StatusType = keyof typeof mappingStatus;

const ACTION_LABEL = {
  CHANGE_PLAN: 'Change Plan',
  ROLES_RELATIONSHIP: 'Roles Relationship',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
};

function ActionMenu({ row, className, action, ...props }: RowActionMenuProps) {
  const menu = (
    <Menu className={className}>
      <Menu.Item key='Change Plan' onClick={() => { action(ACTION_LABEL.CHANGE_PLAN, row); }}>Change Plan</Menu.Item>
      <Menu.Item key='Roles Relationship' onClick={() => { action(ACTION_LABEL.ROLES_RELATIONSHIP, row); }}>Roles Relationship</Menu.Item>
      <Menu.Divider />
      <Menu.Item key='Enable' onClick={() => { action(ACTION_LABEL.ENABLE, row); }} disabled={parseInt(row.status) !== 0}>Enable</Menu.Item>
      <Menu.Divider />
      <Menu.Item key='Disable' onClick={() => { action(ACTION_LABEL.DISABLE, row); }} disabled={parseInt(row.status) !== 1}>Disable</Menu.Item>
    </Menu>
  );
  return <RowActionMenu {...{ row, className, action, ...props, menu }} />;
}

function BoAdmin() {
  const history = useHistory();
  const location = useLocation();
  const { pageSize, page, status, search, sortBy, sortOrder, tabKey } = useParseParams();
  const urlStatus = mappingStatus[status as StatusType];
  const [searchKeyword, setSearchKeyword] = useState<string>(search);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [rendered, setRendered] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userStatus, setUserStatus] = useState<UserStatusType>();

  const [visibleChangePlan, setVisibleChangePlan] = useState<boolean>(false);

  const [userDetails, setUserDetails] = useState({
    id: '',
    name: '',

    ...roleDefault,

    expired_date: '',
    notifyMemberExpiry: false,

  });

  const handleUserChangePlan = (value: InternalUserType) => {
    setUserDetails((prev) => ({
      ...prev,
      ...roleDefault,
    }));


    value.userRoles.forEach((item) => {
      if (item.is_allow && !item.is_prohibit && item.role.name in namedMappingRev) {
        setUserDetails((prev) => ({
          ...prev,
          [namedMappingRev[item.role.name]]: true,
        }));
      }
    });

    setUserDetails((prev) => ({
      ...prev,
      id: '' + value.id,
      name: value.email?.replace?.(/@.*/, '') || value.name || '',
      expired_date: value.expired_date,
    }));

    /*
    setKioskTableData(null);
    loadMembersKioskTable(+value.id, kioskTableData).then((res) => {
      setKioskTableData(res);
      //console.log('members kiosk data loaded', res);

    });
    */
    setVisibleChangePlan(true);

  };

  const fetchListCompanyRoles = useCallback(() => {
    return externalUserApi.getListRoleCompany();
  }, []);

  const { data: listCompanyRolesData } = useQuery(['listCompanyRolesDataUserExternal_fromUser'], fetchListCompanyRoles);

  const [isOpenObjectRolesModal, setOpenObjectRolesModal] = useState<boolean>(false);

  const [objectRolesModalArgs, setObjectRolesModalArgs] = useState<any>({
    modalKey: 'ObjectRolesModal-from-user',
    open: true,
    setModalVisible: setOpenObjectRolesModal,
  });

  const setParamHandle = useCallback(
    (data: PaginationDataType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 20,
          search,
          sortOrder: sortOrder ?? 'asc',
          sortBy: sortBy ?? 'id',
          status,
          tabKey,
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, search, sortBy, sortOrder, tabKey, status],
  );

  const {
    data: listInternal,
    isLoading,
    refetch,
  } = useQuery(
    [
      'user-internal',
      {
        pageSize,
        page,
        status,
        search,
        sortBy,
        sortOrder,
      },
    ],

    () =>
      internalUserApi.getListTable({
        pageSize,
        page,
        status: urlStatus !== 'active,disabled' ? urlStatus : undefined,
        search: search?.trim(),
        sortBy,
        sortOrder,
      }),
  );

  useEffect(() => {
    setRendered(true);
    const listRoleIds = !rendered ? 'active,disabled' : status;
    !rendered &&
      setParamHandle({
        status: status ?? listRoleIds,
      });
    return () => {
      setRendered(false);
    };
  }, [history, location.pathname, sortBy, sortOrder, status, rendered, pageSize, page, search, setParamHandle]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKeyword(value);
    setTimeout(() => {
      setParamHandle({
        search: value,
      });
    }, 700);
  };

  const handleSetPageSize = (currentPage: number, pageSize: number) => {
    setParamHandle({
      page: currentPage,
      pageSize: pageSize,
    });
  };

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InternalUserType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<InternalUserType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<InternalUserType>).order as string),
    });
  };

  const handleSelect = (value: string[]) => {
    const statusValue = convertStatus(value);
    setParamHandle({
      status: statusValue,
    });
  };

  const clearFilters = () => {
    setParamHandle({ page: 1, pageSize: 20, status: 'active,disabled', search: undefined });
    setSearchKeyword('');
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className={styles.renderTitle}>
      <div>{title}</div>
      <SortingArrow sortingFor={sortingFor} order={sortOrder} orderBy={sortBy} />
    </div>
  );

  const showConfirmActiveModal = (id: number, status: number) => {
    setIsModalVisible(true);
    setUserStatus({ id, status: status === 1 ? 0 : 1 });
  };

  const handleOk = async () => {
    try {
      setLoadingUpdate(true);
      if (userStatus) await internalUserApi.changeStatusUser(userStatus.id, userStatus.status);
      await refetch();
      setLoadingUpdate(false);
      setIsModalVisible(false);
    } catch (err) {
      const error = err as ErrorType;
      setLoadingUpdate(false);
      setIsModalVisible(false);
      const messageError = error?.response?.data?.message;
      if (isEmpty(error?.response)) {
        message.error({
          content: 'Please check your internet connection and try again.',
          className: 'custom-class',
          style: {
            textAlign: 'center',
          },
        });
        return;
      }
      message.error({
        content: messageError,
        className: 'custom-class',
        style: {
          textAlign: 'center',
        },
      });
    }
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleObjectRoles = (row: any) => {
    setObjectRolesModalArgs({
      ...objectRolesModalArgs,
      title: 'Roles for Admin [' + row.name + ']',
      role_id: null,
      role_name: null,
      object_type: 'user',
      object_id: row.id,
      object_name: row.name,
    });
    setOpenObjectRolesModal(true);
  };

  async function onMemberAction(label: string, row: any) {
    if (label === ACTION_LABEL.CHANGE_PLAN) handleUserChangePlan(row);
    else if (label === ACTION_LABEL.ROLES_RELATIONSHIP) handleObjectRoles(row);
    else if (label === ACTION_LABEL.DISABLE || label === ACTION_LABEL.ENABLE) {
      //await internalUserApi.changeStatusUser(row.id, label === ACTION_LABEL.DISABLE ? 0 : 1 );
      showConfirmActiveModal(row.id, row.status);
      refetch();
    }
  }

  const columns: ColumnsType<InternalUserType> = [
    {
      title: renderTitle('User ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 140,
      align: 'left',
      sorter: true,
    },
    {
      title: renderTitle('User Name', 'name'),
      dataIndex: 'name',
      key: 'name',
      width: 220,
      render: (name: string) => <div className="whitespace-pre-wrap">{!name ? '-' : name}</div>,
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 365,
      render: (email: string) => <div className="whitespace-pre-wrap">{!email ? '-' : email}</div>,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 180,
      render: (country: string) => <>{isNil(country) ? '-' : country}</>,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 180,
      render: (city: string) => <>{isNil(city) ? '-' : city}</>,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      width: 180,
      render: (phone: string) => <>{isNil(phone) ? '-' : phone}</>,
    },
    {
      title: renderTitle('Status', 'status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      sorter: true,
      render: (status: number) => (
        <div>
          <span className={clsx(status === 1 ? styles.active : styles.disable)}>
            {status === 1 ? 'Active' : 'Disabled'}
          </span>
        </div>
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'last_login',
      width: 180,
      sorter: true,
      render: (last_login: string) => <div>{isNil(last_login) ? '-' : moment(last_login).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'changePlane',
      width: 140,
      align: 'center',
      render: (_, record: InternalUserType) => (
        <>
          {Number(record.id) === 1 ? '' :
            <ActionMenu row={record} className={'actionMenu'} action={onMemberAction} />
          }
        </>
      ),
    },

  ];

  const totalResult = useMemo(() => {
    if (!status) {
      return 0;
    }
    return listInternal?.data.total ?? 0;
  }, [listInternal?.data.total, status]);
  return (
    <div className={styles.BoAdminMain}>
      <ConfirmActiveModal
        modalType={userStatus?.status === 1 ? 'activate' : 'disable'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loadingUpdate}
      />
      <div className={styles.filtersInternal}>
        <p>Account Status</p>
        <div className={styles.filtersBox}>
          <div className={styles.selectBox}>
            <div className={styles.select}>
              <CommonSelect optionSelect={optionSelect} handleSelect={handleSelect} />
            </div>
            <TextInput
              placeholder="Search by Email or User Name"
              className={styles.input}
              inputProps={{
                onChange: handleSearch,
                value: searchKeyword,
                prefix: <SearchIcon width={20} height={20} />,
                maxLength: 255,
              }}
            />
          </div>
          <div className={styles.clearFilters} onClick={clearFilters}>
            <span>Clear all filters</span>
            <XCircleIcon width={24} height={24} />
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spin className={styles.spin} />
      ) : (
        <>
          {!listInternal?.data.data || listInternal?.data.data.length === 0 ? (
            <NoDataTable />
          ) : (
            <>
              <Table
                // dataSource={status ? listInternal?.data.data : []}
                dataSource={listInternal?.data.data || []}
                columns={columns}
                loading={isLoading}
                pagination={false}
                onChange={onChangeTable}
                rowKey="id"
              />
              <div className={styles.pagination}>
                <div className={styles.totalResults}>
                  Total results: <p className={styles.totalNumber}>{totalResult}</p>
                </div>

                {listInternal?.data?.data && (
                  <CustomPagination
                    pageSize={listInternal?.data.pageSize}
                    current={Number(page) || 1}
                    total={totalResult}
                    showSizeChanger={true}
                    onChange={handleSetPageSize}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}

      <ChangePlanModal
        visible={visibleChangePlan}
        setVisible={setVisibleChangePlan}
        userDetails={userDetails}
        listCompanyRoles={listCompanyRolesData}
        kioskTableData={null}
        //onKioskUpdateData={null}
        //refetch={refetch}
        onUpdateData={() => { return refetch(); }}
        key={'company-change-plan'}
      />

      {isOpenObjectRolesModal && (
        <ObjectRolesModal
          {...objectRolesModalArgs}
        />
      )}

    </div>
  );
}

export default BoAdmin;
