import { CityType, CountryType, ImportBatchBodyType } from './create-internal-user.type';
import axiosClient from 'utils/axios-client';
import { InternalUserDataType } from 'modules/user-management/internal-user/create-internal-user/create-internal-user.constants';

export const getCountries = async (): Promise<CountryType[]> => {
  const res = await axiosClient.get('/countries');
  return res.data;
};
export const getCities = async (country: string): Promise<CityType[]> => {
  const res = await axiosClient.get(`/countries/cities?country=${country}`);
  return res.data;
};

export const createInternalUser = async (internalUserData: InternalUserDataType) => {
  const res = await axiosClient.post('user', internalUserData);
  return res.data;
};

export const importBatchUserList = (body: ImportBatchBodyType): Promise<any> => {
  return axiosClient.post('/user/batch', body);
};
