import { Checkbox, Form, Modal, ModalProps, Table, message } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';

import { CommonButton } from 'components/common-button';
import styles from './edit-role-permission-table-modal.module.scss';

import { useQuery } from 'react-query';
import axiosClient from 'utils/axios-client';
import { ColumnsType } from 'antd/lib/table';
// import { CheckCircleIcon, CloseIcon } from 'components/icons';

export interface EditRolePermissionTableModalProps extends ModalProps {
  modalKey: string;
  setModalVisible: Dispatch<SetStateAction<boolean>> | undefined;
  title?: string;

  rolePrefix: string,
  roleList: string[];
  permissionPrefix: string,
  permissionList: string[];

  permissionWidth?: number,
  roleWidth?: number,

}

export function EditRolePermissionTableModal({ setModalVisible, rolePrefix, roleList, permissionPrefix, permissionList, ...props }: EditRolePermissionTableModalProps) {
  const [form] = Form.useForm();
  var [tableData, setTableData] = useState<any>(null);
  var [queryData, setQueryData] = useState<any>(null);

  //load data
  useQuery(
    ['getEditRolePermissionData' + props.modalKey],
    async () => {
      if (tableData) return;
      // setTableData(null);
      // console.log('props.modalKey', props.modalKey);

      //load roles
      let res = await axiosClient.get('/roles', {
        params: { page: 1, pageSize: 20, search: rolePrefix },
      });
      const roleData = res.data?.data;
      const roleMap: any = {}; //map name to role row
      roleData?.forEach((row: any) => { roleMap[row.name] = row; });

      //load permission
      res = await axiosClient.get('/permissions', {
        params: { page: 1, pageSize: 100, search: permissionPrefix },
      });
      const permissionData = res.data?.data;
      const permissionMap: any = {}; //map name to role row
      permissionData?.forEach((row: any) => { permissionMap[row.name] = row; });

      //load role-permission
      res = await axiosClient.get('/role-permission', {
        params: { page: 1, pageSize: 2000, rolePrefix: rolePrefix, permissionPrefix: permissionPrefix },
      });
      const rpData = res.data?.data;
      const rpMap: any = {}; //map R+P name to row
      rpData?.forEach((row: any) => { rpMap[row.role.name + '+' + row.permission.name] = row; });

      //prepare data
      let data: any = [];
      permissionList.forEach((p: any, pidx) => {
        var data_row: any = [];
        roleList.forEach((r: any, ridx) => {
          let bound = rpMap[r + '+' + p] ? 1 : 0;
          form.setFieldValue('chk' + pidx + '_' + ridx, bound ? true : false);
          // if (pidx === 0) console.log('chk' + pidx + '_' + ridx, bound ? true : false);

          data_row.push({
            permission: p,
            role: r,
            bound: bound,
            bound_current: bound,
            enable: (p in permissionMap) && (r in roleMap),
            valid: p[0] !== '[' && r[0] !== '[',
          });
        });
        data.push(data_row);
      });

      setTableData(data);

      setQueryData({ roleData, roleMap, permissionData, permissionMap, rpData, rpMap });
    });

  // console.log(tableData);

  const onCancel = () => {
    setModalVisible?.(false);
    // form.resetFields();
  };

  const handleOnFinish = async () => {
    // console.log(value);
    setModalVisible?.(false);

    let postAddData: any = [];
    let postDeleteData: any = [];

    permissionList.forEach((p: any, pidx) => {
      roleList.forEach((r: any, ridx) => {
        let v = form.getFieldValue('chk' + pidx + '_' + ridx) ? 1 : 0;
        let data = tableData[pidx][ridx];
        if (v !== data.bound_current) {
          // console.log(r + '+' + p + ': ' + v);
          if (v) postAddData.push({ role_name: r, permission_name: p });
          else postDeleteData.push({ role_name: r, permission_name: p, id: queryData?.rpMap[r + '+' + p].id });
        }
      });
    });

    if (!postAddData.length && !postDeleteData.length) {
      message.info({
        content: 'No data changed.',
      });
      return;
    }

    //submit add

    var success_message = '';
    var error_message = '';

    if (postAddData.length > 0) {
      try {
        let res = await axiosClient.post('/role-permission/import', { list: postAddData });

        if (res?.data.success) {
          success_message = 'Update success, add ' + res.data.add + (res.data.skip ? (', skip ' + res.data.skip) : '');
        }
        else {
          error_message = JSON.stringify(res).slice(0, 255);
        }
      }
      catch (ex: any) {
        error_message = '' + ex;
      }

      if (error_message) {
        message.error({
          content: error_message,
        });
      }
    }

    //submit delete
    error_message = '';

    if (postDeleteData.length > 0) {
      try {
        let res = await axiosClient.delete('/role-permission/delete-by-list', { data: { list: postDeleteData } });

        if (res?.data.success) {
          if (success_message) success_message += ', delete ' + res.data.delete + '.';
          else success_message = 'Update success, delete ' + res.data.delete + '.';
        }
        else {
          error_message = JSON.stringify(res).slice(0, 255);
        }
      }
      catch (ex: any) {
        error_message = '' + ex;
      }

      if (error_message) {
        message.error({
          content: error_message,
        });
      }
    }

    if (success_message) {
      message.success({
        content: success_message,
      });
    }

  };

  const columns: ColumnsType<any> = [
    {
      align: 'left',
      title: <b>Permission</b>,
      dataIndex: 'permission',
      key: 'permission',
      width: props.permissionWidth || 80,
      render: (_, row: any, idx) => {
        let p = permissionList[idx];
        // console.log(p);
        if (p.charAt(0) === '[') return <strong>{p.slice(1, -1)}</strong>;
        return queryData?.permissionMap[p]?.short_name || p;
      },
    },

    ...roleList?.map((r, idx) => {
      return {
        align: 'center' as 'center',
        title: queryData?.roleMap[r]?.short_name || r,
        dataIndex: r,
        key: r,
        width: props.roleWidth || 80,
        render: (_: any, row: any, rowIdx: any) => {
          let data = row[idx];
          if (!data.valid) return '';
          return <Form.Item name={'chk' + rowIdx + '_' + idx} valuePropName='checked' style={{ marginBottom: 0 }}>
            <Checkbox style={{ zoom: '120%' }} disabled={!data.enable} />
          </Form.Item>;
        },

      };
    }),
  ];

  return (
    <div className={styles.box}>
      <Modal
        {...props}
        key={props.modalKey}
        onCancel={onCancel}
        centered
        title={props?.title || 'Add Object Role'}
        forceRender
        className={styles.formAddObjectRoleModal}
        width={'50%'}
        closable={true}
        maskClosable={true}
        footer={''}
      >
        <Form onFinish={handleOnFinish} form={form}>
          {tableData
            ? <Table
              rowKey={(record, index) => '' + index}
              columns={columns}
              dataSource={tableData || []}
              pagination={false}
              // onChange={onChangeTable}
              loading={!tableData}
              scroll={{ y: 500 }}
            />
            : 'Loading data ...'
          }

          <div className={styles.alignButton}>
            <CommonButton onClick={onCancel} variant="default" idKey='edit-rp-cancel'>
              Cancel
            </CommonButton>
            <Form.Item shouldUpdate className={styles.addObjectRoleButton}>
              {() => {
                // console.log(form.getFieldsError().filter(({ errors }) => errors.length).length);
                // console.log(allowValue, values?.is_allow);
                return (
                  <CommonButton
                    htmlType="submit"
                    block
                    size="large"
                    variant="primary"
                    disabled={!form.isFieldsTouched()}
                    idKey='edit-rp-save'
                  >
                    Save
                  </CommonButton>
                );
              }
              }
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
