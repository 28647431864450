import { ITab } from 'components/tab-common/tab-common';
import BoAdmin from './bo-admin';

export const listTab: ITab[] = [
  {
    key: '1',
    name: 'BO Admin',
    component: <BoAdmin />,
  },
];
