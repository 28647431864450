import clsx from 'clsx';
import { Breadcrumb } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { breadcrumbNameMap } from './header-bread-crumb';
import styles from './header-bread-crumb.module.scss';
import { ArrowLeftIcon } from 'components/icons';

export function HeaderBreadcrumb() {
  const history = useHistory();
  const location = useLocation();

  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const breadcrumbSnippets = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  function BackPage() {
    history.push(`${breadcrumbSnippets[breadcrumbSnippets.length - 2].key}`);
  }

  return (
    <>
      <div className={styles.breadcrumb}>
        <Breadcrumb className={clsx(breadcrumbSnippets.length > 2 ? '' : styles.title)}>
          {breadcrumbSnippets}
        </Breadcrumb>
        {breadcrumbSnippets.length >= 3 && (
          <div className={styles.backPage} onClick={BackPage}>
            <ArrowLeftIcon width={20} height={15} />
            {breadcrumbNameMap[`${breadcrumbSnippets[breadcrumbSnippets.length - 1].key}`]}
          </div>
        )}
      </div>
    </>
  );
}
