
export function arrayToCsv(arr: string[][]) {
  return arr.map(row => row.map(value => {
    // 如果值包含逗号、双引号、换行符或回车符，需要将其包裹在双引号内并转义内嵌的双引号
    if (/[,"\r\n]/.test(value)) {
      value = `"${value.replace(/"/g, '""')}"`;
    }
    return value;
  }).join(','),
  ).join('\r\n');
}
