import { useState } from 'react';

import { LoginNoti } from 'components/login-noti';
import LoginForm from 'modules/Auth/LoginForm';
import { IntroCommon } from 'pages/intro';

export const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  return (
    <IntroCommon>
      <LoginForm setErrorMessage={setErrorMessage} />
      {/* <p className="sign-up">
        Don't have an account?
        <Link to="#" className="ml-5">
          Sign up now
        </Link>
      </p> */}
      {!!errorMessage && <LoginNoti content={errorMessage} />}
    </IntroCommon>
  );
};
