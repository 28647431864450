import { Col, Form, Popover } from 'antd';
import DateInput from 'components/form-control/date-input';
import SwitchInput from 'components/switch';
import { namedMapping, roleType } from './role-tool';
import { BlueOnionHubRole } from './change-plan-form.type';
import { WarningChar } from 'components/label-require/warning-char';
import { isDateInverted, warningText } from './warning-tool';
import { FormInstance } from 'antd/es/form/Form';
import { useState } from 'react';
import { DateIcon } from 'components/icons/date-icon';
import { EditDateModal } from './edit-date-modal';

type HubsRoleRowProps = {
  keyStr: string;
  varName: string;
  label: string;

  blueOnionHubRole: BlueOnionHubRole | any;
  blueOnionHubRoleFlag?: roleType | any;
  onRowUpdate?: (varName: string, newValue: any) => any;

  nowDayStr?: string;
  form?: FormInstance<any>;
  parentDetails?: any,

  groupVarName?: string,
};

export function HubsRoleRow({ keyStr, varName, label, blueOnionHubRole, onRowUpdate, blueOnionHubRoleFlag, groupVarName, ...props }: HubsRoleRowProps) {
  //const rowData = blueOnionHubRole?.[varName];
  var groupVarDisabled = !!(groupVarName && !props.form?.getFieldValue(groupVarName));

  const dateRule = blueOnionHubRole ? [
    { required: false, message: 'This field is mandatory' },
    ({ getFieldValue }: any) => ({
      validator(_: any, value: any) {
        //console.log(getFieldValue(varName + '_start_date'));
        if (!value ||
          !getFieldValue(varName + '_start_date') ||
          !getFieldValue(varName + '_end_date') ||
          (getFieldValue(varName + '_start_date').format('YYYY-MM-DD') <= getFieldValue(varName + '_end_date').format('YYYY-MM-DD'))) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Start date cannot be later than End date'));
      },
    }),
  ] : void (0);

  const roleName = namedMapping[varName];
  const parentRole = props.parentDetails?.companyRole?.find((v: any) => {
    return v?.role?.name === roleName;
  });
  //console.log('parentRole', varName, roleName, parentRole, blueOnionHubRole?.[varName], !parentRole?.is_allow);

  const [, setUpdateWarning] = useState(0);
  const updateWarning = () => { setUpdateWarning((v) => v + 1); };
  const [openDateModal, setOpenDateModal] = useState(false);

  const dtStart = props.form?.getFieldValue(varName + '_start_date')?._d;
  const warningStart = props.form?.getFieldValue(varName) && warningText(
    isDateInverted(dtStart, props.nowDayStr) && 'Today is outside period',
    isDateInverted(props.form?.getFieldValue('start_date')?._d, dtStart) && 'Before the service start date',
    isDateInverted(props.form?.getFieldValue('plan_start_date')?._d, dtStart) && 'Before the total control start date',
    dtStart && props.parentDetails && !parentRole?.is_allow && 'Not allowed by company scope.',
    props.form?.getFieldValue(varName) && isDateInverted(parentRole?.start_date, dtStart) && 'Before the company permission start date',
  );

  const dtEnd = props.form?.getFieldValue(varName + '_end_date')?._d;
  const warningEnd = props.form?.getFieldValue(varName) && warningText(
    isDateInverted(props.nowDayStr, dtEnd) && 'Today is outside period',
    isDateInverted(dtEnd, props.form?.getFieldValue('expired_date')?._d) && 'After the service expired date',
    isDateInverted(dtEnd, props.form?.getFieldValue('plan_end_date')?._d) && 'After the total control end date',
    dtEnd && props.parentDetails && !parentRole?.is_allow && 'Not allowed by company scope.',
    props.form?.getFieldValue(varName) && isDateInverted(dtEnd, parentRole?.end_date) && 'After the company permission end date',
  );
  //console.log('warningStart', varName, warningStart,props.form?.getFieldValue('start_date')?._d,props.form?.getFieldValue('plan_start_date')?._d);

  let dateVisible = blueOnionHubRoleFlag?.[varName] && !groupVarDisabled;
  let dateExisted = dateVisible && (dtStart || dtEnd);
  let startDateText = (props.form?.getFieldValue(varName + '_start_date')?.format?.('YYYY-MM-DD') || '-');
  let endDateText = (props.form?.getFieldValue(varName + '_end_date')?.format?.('YYYY-MM-DD') || '-');

  let dateText = dateExisted
    ? (
      <>Start: {startDateText}<br />
        End: {endDateText}</>
    )
    : '';
  let dateWarning = dateExisted && (warningStart || warningEnd);
  let dateWarningText = dateWarning ? (
    ('Start: ' + startDateText + '\n' + (warningStart ? warningStart.replace(/^Warning\s+/i, '') : ((startDateText === '-') ? '' : '* OK')) + '\n' +
      ('End: ' + endDateText + '\n' + (warningEnd ? warningEnd.replace(/^Warning\s+/i, '') : ((endDateText === '-') ? '' : '* OK'))))
  ) : '';

  // console.log('dateWarningText', dateWarningText);

  let dateIconNode: JSX.Element | null = null;


  if (dateVisible) {
    dateIconNode = <DateIcon className='role-row-date-icon' width={20} height={20}
      style={{
        marginLeft: '0.3em',
        opacity: dateExisted ? 1 : 0.2,
        cursor: 'pointer',
      }}
      fill={dateWarning ? ('#FC9536') : '#888888'}
      onClick={() => {
        setOpenDateModal(true);
      }}
    />;

    if (dateWarning) {
      dateIconNode = <WarningChar warning={dateWarningText}>{dateIconNode}</WarningChar>;
    }
    else if (dateExisted) {
      dateIconNode = <Popover content={dateText} trigger='hover' >{dateIconNode}</Popover>;
    }
  }


  return (
    <>
      <Col span={8}>
        <Form.Item name={varName} valuePropName="checked">
          <SwitchInput
            text={label}
            id={keyStr + varName}
            onChange={(checked: boolean) => { onRowUpdate?.(varName, checked); }}
            disabled={!blueOnionHubRole || groupVarDisabled}
            warning={props.form?.getFieldValue(varName) && warningText(
              props.parentDetails && !parentRole?.is_allow && 'Not allowed by company scope.',
            )}
            {
            ...groupVarDisabled ? { checked: false } : {}
            }
            appendNode={
              dateVisible && dateIconNode
            }
          />
        </Form.Item>
      </Col>
      <Col span={7} style={{ display: 'none' }}>
        <DateInput
          name={varName + '_start_date'}
          id={keyStr + varName + '_start_date'}
          dependencies={[varName + '_start_date', varName + '_end_date']}
          rules={dateRule}
          dateInputProps={{
            id: keyStr + varName + '_start_date',

            onChange: (value, dateString) => { onRowUpdate?.(varName + '_start_date', dateString); updateWarning(); },
            disabled: !blueOnionHubRoleFlag?.[varName] || groupVarDisabled,
          }}
          enableAllDate={true}
        />
      </Col>
      <Col span={1} style={{ justifyContent: 'left', paddingLeft: '0px', paddingRight: '0px', display: 'none' }} >
        {warningStart ? <WarningChar warning={warningStart} /> : <></>}
      </Col>
      <Col span={7} style={{ display: 'none' }}>
        <DateInput
          name={varName + '_end_date'}
          id={keyStr + varName + '_end_date'}
          dependencies={[varName + '_start_date', varName + '_end_date']}
          rules={dateRule}
          dateInputProps={{
            id: keyStr + varName + '_end_date',

            onChange: (value, dateString) => { onRowUpdate?.(varName + '_end_date', dateString); updateWarning(); },
            disabled: !blueOnionHubRoleFlag?.[varName] || groupVarDisabled,
          }}
          enableAllDate={true}
        />
      </Col>
      <Col span={1} style={{ justifyContent: 'left', paddingLeft: '0px', paddingRight: '0px', display: 'none' }} >
        {warningEnd ? <WarningChar warning={warningEnd} /> : <></>}
      </Col>

      {openDateModal && <EditDateModal
        {...{
          ...props,
          keyStr, varName, label, blueOnionHubRole, onRowUpdate, blueOnionHubRoleFlag, groupVarName,
          setModalVisible: () => { setOpenDateModal(false); },
        }}
      />}
    </>
  );
}
