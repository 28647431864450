import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { mergeParam } from 'utils/helper';

interface IHistory {
  push: (newUrl: string) => void;
}
export const pushParamHandle = (pathname: string, param: GetListCompanyParamsType, history: IHistory) => {
  const newUrl = mergeParam(pathname, param);
  history.push(newUrl);
};
