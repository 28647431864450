import { getCurrentUser } from './auth/auth.selectors';
import { store } from './store';
import jwt_decode from 'jwt-decode';
import { PERMISSION } from './permission.const';

export { PERMISSION } from './permission.const';

var lastAccessToken: string = '';
var lastScopes: { [key: string]: boolean } = {};

interface accessTokenType {
  email: string;
  fullName: string;
  sub: string;
  iat: number;
  exp: number;

  scopes?: any;
  subType?: string;
  isSubAdmin?: boolean;
}

export function hasPermission(name?: string): boolean {
  const currentUser = getCurrentUser(store.getState());
  if (!currentUser?.accessToken) return false;

  if (currentUser.accessToken !== lastAccessToken) {
    //decode and cache last token info
    lastAccessToken = currentUser.accessToken;
    const decodeValue = jwt_decode(lastAccessToken) as accessTokenType;

    lastScopes = {};

    if (decodeValue.subType) lastScopes['subType:' + decodeValue.subType] = true;
    if (decodeValue.isSubAdmin) lastScopes['subType:' + decodeValue.subType + ':admin'] = true;

    decodeValue.scopes?.forEach((v: string) => {
      lastScopes[v] = true;
    });
  }

  return name ? lastScopes[name] : true;
}

export function isSysAdmin() {
  return hasPermission(PERMISSION.SUBTYPE_USER);
}
