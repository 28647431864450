import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import { Checkbox, Col, Form, FormInstance, Input, message, Segmented, Select, Tag } from 'antd';

import LabelRequire from 'components/label-require';

import { PermissionEntity, ReturnPermissionDto, UpdatePermissionDto } from 'services/api-permission.type';

import { useQuery } from 'react-query';
import { getCommonRegion, getCompanyRegion, getMembersRegion, putCompanyRegion, putMembersRegion } from 'services/api-permission.service';
import { companyDetailsProps, memberDetailsProps } from './change-plan-form.type';
import { regionOptions, regionSet } from './region-set';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { WarningChar } from 'components/label-require/warning-char';
import GlobalOutlined from '@ant-design/icons/lib/icons/GlobalOutlined';
import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined';


export type RegionEditorProps = {
  companyDetails?: companyDetailsProps;
  memberDetails?: memberDetailsProps;
  form: FormInstance<any>;
  setFieldsTouchedStatus: (status: boolean) => void;
};

const favouriteSet: { [key: string]: number } = {
  'hk': 1, 'tw': 2, 'sg': 3, 'gb': 4, 'th': 5, 'jp': 6,
};

const matchFilterAndCatalog = (row: PermissionEntity, filterValue: string, catalogValue: string, catalogRegionSet: any) => {
  const nameKey = row.name.slice(7);

  let matchFilter = !filterValue || (
    row.display_name?.toLowerCase().includes(filterValue) ||
    nameKey.toLowerCase().includes(filterValue) ||
    row.description?.toLowerCase().includes(filterValue) ||
    row.short_name?.toLowerCase().includes(filterValue)
  );

  if (matchFilter) {
    matchFilter = !catalogValue || catalogRegionSet[catalogValue]?.has(nameKey);
  }

  return matchFilter;
};

function titleFromRow(row: PermissionEntity) {
  let titleArr = [];
  if (row.display_name) titleArr.push(row.display_name);
  titleArr.push(row.name.slice(7));
  if (row.short_name) titleArr.push(row.short_name);
  if (row.description) titleArr.push(row.description);

  return titleArr.join(', ');
}

export const RegionEditor = forwardRef(({ companyDetails, memberDetails, form, setFieldsTouchedStatus, ...props }: RegionEditorProps, ref) => {
  const [initRegion, setInitRegion] = useState(false);
  const [favouriteRegion, setFavouriteRegion] = useState<JSX.Element[]>([]);
  const [allRegion, setAllRegion] = useState<JSX.Element[]>([]);
  // const [selectedRegion, setSelectedRegion] = useState<JSX.Element[]>([]);
  // const [fullView, setFullView] = useState(false);
  const [listObjectRegion, setListObjectRegion] = useState<ReturnPermissionDto[] | undefined>(undefined);
  const [listParentRegion, setListParentRegion] = useState<ReturnPermissionDto[] | undefined>(undefined);

  const [favAll, setFavAll] = useState(false);
  const [favNone, setFavNone] = useState(false);
  const [fullAll, setFullAll] = useState(false);
  const [fullNone, setFullNone] = useState(false);

  const [filterValue, setFilterValue] = useState('');
  const [catalogValue, setCatalogValue] = useState('');

  const [updateRegionStatus, setUpdateRegionStatus] = useState(0);

  const [selectedShowCount, setSelectedShowCount] = useState(0);
  const [favShowCount, setFavShowCount] = useState(0);
  const [fullShowCount, setFullShowCount] = useState(0);

  const [catalogOptions, setCatalogOptions] = useState(regionOptions);
  const [catalogRegionSet, setCatalogRegionSet] = useState(regionSet);

  const [viewMode, setViewMode] = useState('selected');
  const [fullViewOnce, setFullViewOnce] = useState(false);

  const [updated, setUpdated] = useState(false);

  const updateRegion = () => {
    setUpdateRegionStatus(prev => prev + 1);
  };

  //interface for ref
  useImperativeHandle(ref, () => ({
    setSameAsCompany: () => {
      //console.log('setSameAsCompany');
      let updateData: any = {};
      let updateCount = 0;

      listParentRegion?.forEach((row) => {
        if (row.is_allow && !form.getFieldValue(`region_${row.permission_id}`)) {
          updateData[`region_${row.permission_id}`] = true;
          updateCount++;
        }
      });

      // console.log('updateData.length', updateCount);
      if (updateCount > 0) {
        form.setFieldsValue(updateData);
        updateRegion();
        setFieldsTouchedStatus(true);
        setUpdated(true);
        message.success('added ' + updateCount + ' region' + (updateCount > 1 ? 's' : '') + ' from company');
      }
      else {
        message.info('No region is added');
      }
    },
    saveRegion: async (value: any) => {
      if (!updated) return;

      // save regoin
      if (companyDetails || memberDetails) {
        const regionData: UpdatePermissionDto[] = [];
        listAllRegion?.forEach((row) => {
          if (value[`region_${row.id}`]) {
            regionData.push({ permission_id: +row.id, is_allow: true });
          }
        });
        if (regionData.length >= 0) {   // 0 to clear all
          if (companyDetails) {
            await putCompanyRegion(+companyDetails.id, regionData);
          } else if (memberDetails) {
            await putMembersRegion(+memberDetails.id, regionData);
          }
        }
      }
    },
  }));

  // console.log('main region editor');

  // load all region
  const { data: listAllRegion } = useQuery(['getAllRegion'],
    async () => {
      let ret = await getCommonRegion();
      ret.sort((a, b) => {
        return (a.display_name || '') > (b.display_name || '') ? 1 : -1;
      });
      return ret;
    },
    { staleTime: Infinity },
  );

  // load region data
  useQuery([
    'getObjectRegion',
    companyDetails?.id,
    memberDetails?.id,
    //form,
  ], async () => {
    // console.log('getObjectRegion');
    let ret: ReturnPermissionDto[] = [];
    if (companyDetails?.id) ret = await getCompanyRegion(+companyDetails.id);
    else if (memberDetails?.id) ret = await getMembersRegion(+memberDetails.id);

    setListObjectRegion(ret);

    return ret;
  }, {
    enabled: !!(companyDetails || memberDetails) && !initRegion,
  });

  // load parent region data
  useQuery([
    'getObjectParentRegion',
    companyDetails?.id,
    memberDetails?.id,
    //form,
  ], async () => {
    // console.log('memberDetails?.company_id', memberDetails?.company?.id);
    let ret: ReturnPermissionDto[] = [];
    if (memberDetails?.company?.id) {
      ret = await getCompanyRegion(+memberDetails?.company?.id);
    }

    setListParentRegion(ret);

    let keyArr = ret.filter(row => row.is_allow).map((row) => row.permission_name.slice(7));
    // console.log('keyArr', keyArr);

    setCatalogRegionSet({ ...catalogRegionSet, '_company_scope': new Set(keyArr) });
    setCatalogOptions([...regionOptions, { label: '# Company Scope', value: '_company_scope' }]);

    setInitRegion(false);

    return ret;
  }, {
    enabled: !!(memberDetails?.company?.id) && !companyDetails && !initRegion,
  });

  const onChangeCheckbox = (e: any, filter_value: string, catalog_value: string) => {
    // console.log('onChangeCheckbox', e.target.value);
    setFieldsTouchedStatus(true);
    updateRegion();
    setUpdated(true);
    updateStaticRegion(filter_value, catalog_value);
  };

  function updateStaticRegion(filterValue: string, catalogValue: string) {
    //set static region checkbox

    const fav_region: JSX.Element[] = [];

    let fav_show_count = 0;
    let full_show_count = 0;

    const all_region = listAllRegion?.map((row, idx) => {
      let parentAllow = !listParentRegion ||
        listParentRegion.find((parent) => parent.permission_id === row.id)?.is_allow;

      let matchFilter = matchFilterAndCatalog(row, filterValue, catalogValue, catalogRegionSet);

      if (matchFilter) full_show_count++;

      let name = row.display_name || row.name;
      let selected = form.getFieldValue(`region_${row.id}`) || false;

      let titleText = titleFromRow(row);

      if (row.name.slice(7) in favouriteSet) {
        if (matchFilter) fav_show_count++;

        fav_region[favouriteSet[row.name.slice(7)] - 1] =
          <Col key={`col_fav_region_${row.id}`} span={6} title={titleText}
            style={{ borderTop: '1px solid #eeeeee', display: matchFilter ? '' : 'none' }}>
            <Form.Item name={`region_${row.id}`} valuePropName="checked">
              <Checkbox key={'fav' + row.id} onChange={(e: any) => { onChangeCheckbox(e, filterValue, catalogValue); }} style={{ color: parentAllow ? '' : (selected ? '#FC9536' : '#aaaaaa') }}>
                {(parentAllow || !selected) ? name : <WarningChar warning='* Not allowed by company scope.'>{name}</WarningChar>}
              </Checkbox>
            </Form.Item>
          </Col>;
      }

      return <Col key={`col_all_region_${row.id}`} span={6} title={titleText}
        style={{ borderTop: '1px solid #eeeeee', display: matchFilter ? '' : 'none' }}>
        <Form.Item name={`region_${row.id}`} valuePropName="checked">
          <Checkbox key={'all' + row.id} onChange={(e: any) => { onChangeCheckbox(e, filterValue, catalogValue); }} style={{ color: parentAllow ? '' : (selected ? '#FC9536' : '#aaaaaa') }}>
            {(parentAllow || !selected) ? name : <WarningChar warning='* Not allowed by company scope.'>{name}</WarningChar>}
          </Checkbox>
        </Form.Item>
      </Col>;
    }) || [];

    setFavouriteRegion(fav_region);
    setAllRegion(all_region);
    setFavShowCount(fav_show_count);
    setFullShowCount(full_show_count);
  }

  if (listAllRegion && listObjectRegion && !initRegion) {
    // console.log('initRegion');

    //set fields value for region
    // console.log('set form value');
    // let hasPermission = false;
    let fieldChanged: any = {};
    listObjectRegion.forEach((row) => {
      if (row.is_allow) {
        // form.setFieldsValue({ [`region_${row.permission_id}`]: row.is_allow });
        fieldChanged[`region_${row.permission_id}`] = !!row.is_allow;
        // hasPermission = true;
      }
    });
    // form.setFieldsValue(fieldChanged);


    //set static region checkbox
    updateStaticRegion('', '');

    setInitRegion(true);

    // if (!hasPermission) {
    //   setViewMode('fullview');
    //   setFullViewOnce(true);
    // }

    setTimeout(() => {    // timer to remove warning message
      form.setFieldsValue(fieldChanged);

      updateRegion();
    }, 100);
  }

  const selectedRegion = useMemo(
    () => {
      if (!updateRegionStatus) return [];   //hack warning message

      const selected_region: JSX.Element[] = [];
      //let selected_fav_count = 0;
      let selected_show_fav_count = 0;
      let selected_show_full_count = 0;
      let selected_show_count = 0;

      // console.log('filterValue', filterValue);

      // console.log('dyanmic region tag');
      listAllRegion?.forEach((row, idx) => {
        let name = row.display_name || row.name;

        if (form.getFieldValue(`region_${row.id}`)) {

          let parentAllow = !listParentRegion ||
            listParentRegion.find((parent) => parent.permission_id === row.id)?.is_allow;

          let matchFilter = matchFilterAndCatalog(row, filterValue, catalogValue, catalogRegionSet);

          if (matchFilter) {
            selected_show_count++;
            selected_show_full_count++;
          }

          if (row.name.slice(7) in favouriteSet) {
            //selected_fav_count++;
            if (matchFilter) selected_show_fav_count++;
          }

          let titleText = titleFromRow(row);

          selected_region.push(
            <Tag key={'tag_' + row.id} color={parentAllow ? 'blue' : ''} title={titleText}
              style={{ margin: '5px 5px 0 0', display: matchFilter ? '' : 'none', fontSize: '14px' }} closable
              onClose={() => {
                form.setFieldValue('region_' + row.id, false);
                // console.log('onClose', row.id, name);
                setFieldsTouchedStatus(true);
                setUpdated(true);
                setUpdateRegionStatus(prev => prev + 1);
              }}
            >
              {parentAllow ? name :
                <WarningChar warning='* Not allowed by company scope.'><span style={{ color: '#FC9536' }}>{name}</span></WarningChar>
              }
            </Tag>,
          );
        }
      });

      setFavAll(selected_show_fav_count > 0 && selected_show_fav_count === favShowCount);
      setFavNone(selected_show_fav_count === 0);
      setFullAll(selected_show_full_count > 0 && selected_show_full_count === fullShowCount);
      setFullNone(selected_show_full_count === 0);

      setSelectedShowCount(selected_show_count);
      // setSelectedFavShowCount(selected_fav_show_count);

      return selected_region;
    },
    [form, listAllRegion, listParentRegion, filterValue, catalogValue, updateRegionStatus, favShowCount, fullShowCount,
      catalogRegionSet, setFieldsTouchedStatus],
  );

  const onChangeFavAll = (e: any) => {
    let currentValue = !!favAll;
    // if (!currentValue) {

    if (filterValue || catalogValue) {
      setFieldsByDisplay(favouriteRegion, !currentValue);
    }
    else {
      Object.keys(favouriteSet).forEach((key) => {
        form.setFieldsValue({ [`region_${listAllRegion?.find((row) => row.name.slice(7) === key)?.id}`]: !currentValue });
      });
    }
    setFavAll(!currentValue);
    setFavNone(currentValue);

    updateRegion();
    setFieldsTouchedStatus(true);
    setUpdated(true);
    updateStaticRegion(filterValue, catalogValue);
  };

  const onChangeFullAll = (e: any) => {
    let currentValue = !!fullAll;
    // if (!currentValue) {

    if (filterValue || catalogValue) {
      setFieldsByDisplay(allRegion, !currentValue);
    }
    else {
      let newValues = listAllRegion?.reduce((acc: any, row) => {
        acc[`region_${row.id}`] = !currentValue;
        return acc;
      }, {});

      form.setFieldsValue(newValues);
    }
    setFullAll(!currentValue);
    setFullNone(currentValue);

    updateRegion();
    setFieldsTouchedStatus(true);
    setUpdated(true);
    updateStaticRegion(filterValue, catalogValue);
  };

  const setFieldsByDisplay = (regions: JSX.Element[], value: boolean) => {
    regions.forEach((el) => {
      if (el?.props?.style?.display !== 'none') {
        let matchId = parseInt(('' + el?.key).match?.(/_(\d+)$/)?.[1] || '');
        if (matchId) {
          form.setFieldsValue({ [`region_${matchId}`]: value });
        }
      }
    });
  };

  const onClearAll = (e: any) => {
    if (filterValue || catalogValue) {
      setFieldsByDisplay(selectedRegion, false);
    }
    else {
      form.setFieldsValue(listAllRegion?.reduce((acc: any, row) => {
        acc[`region_${row.id}`] = false;
        return acc;
      }, {}));
    }
    updateRegion();
    setFieldsTouchedStatus(true);
    setUpdated(true);
    updateStaticRegion(filterValue, catalogValue);
  };

  const onFilterChange = (e: any) => {
    // console.log('onFilterChange', e.target.value);
    let str = e.target.value.toLowerCase();
    setFilterValue(str);
    // console.log('filterValue after set', filterValue);

    updateStaticRegion(str, catalogValue);
    updateRegion();
  };

  const onChangeCatalog = (value: any) => {
    // console.log('onChangeCatalog', value);
    setCatalogValue(value);
    updateStaticRegion(filterValue, value);
    updateRegion();
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <LabelRequire text="Country & Region" />
        <div style={{ marginLeft: '2em', fontSize: '14px' }}>
          {/* {selectedRegion.length ? (<>
          {'(' + ((filterValue || catalogValue) ? (selectedShowCount + '/') : '') + selectedRegion.length + ')'}
          <CloseOutlined className='linkText' onClick={onClearAll} title='Clear selected regions' />
          </>) : ''} */}
          {/* {selectedRegion.length ? (<>
            {((filterValue || catalogValue) ? (selectedShowCount + '/') : '') + selectedRegion.length}
            {!!selectedShowCount && <CloseOutlined style={{ marginLeft: '0.5em' }} className='linkText' onClick={onClearAll} title={`Clear ${selectedShowCount} selected`} />}
          </>) : ''} */}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div style={{ marginLeft: '0em', fontSize: '14px', marginTop: '0.5em' }}>
            <Segmented
              options={[
                { label: <span style={{ fontSize: '14px' }}>Full View</span>, value: 'fullview' },
                {
                  label: <span style={{ fontSize: '14px' }}>Selected{
                    selectedRegion.length ? (<>&nbsp;(
                      {((filterValue || catalogValue) ? (selectedShowCount + '/') : '') + selectedRegion.length})
                    </>) : ''
                  }</span>,
                  value: 'selected',
                },
              ]}
              onChange={(value) => { setViewMode('' + value); if (value === 'fullview' && !fullViewOnce) setFullViewOnce(true); }}
              size='small' value={viewMode}
            />
            {!!selectedShowCount && <CloseOutlined style={{ marginLeft: '0.2em' }} className='linkText' onClick={onClearAll} title={`Clear ${selectedShowCount} selected`} />}
            {/* <span className='linkText' style={{}} onClick={onClearAll} title={`Clear ${selectedShowCount} selected`}>Clear{(filterValue || catalogValue) ? (': ' + selectedShowCount) : ''}</span> */}
            {/* {selectedRegion.length ? (<>
              Selected:&nbsp;
              {((filterValue || catalogValue) ? (selectedShowCount + '/') : '') + selectedRegion.length}
              {!!selectedShowCount && <CloseOutlined style={{ marginLeft: '0.5em' }} className='linkText' onClick={onClearAll} title={`Clear ${selectedShowCount} selected`} />}
            </>) : ''} */}
          </div>
        </div>
        <div style={{ paddingLeft: '1em', verticalAlign: 'bottom' }}>
          {/* <span className='linkText' style={{}} onClick={onClearAll} title='Clear selected regions'>Clear{(filterValue || catalogValue) ? (': ' + selectedShowCount) : ' All'}</span> */}

          {/* <span className='linkText' onClick={onClearAll} title='Clear selected regions'>Clear{filterValue ? (': ' + selectedShowCount) : ''}</span> */}
          <FilterOutlined style={{ color: '#3698fc' }} title='Filter by region ISO code, display name, short name etc.' />
          <label style={{ fontSize: '14px', color: '#3698fc', marginLeft: '0em' }} title='Filter by region ISO code, display name, short name etc.'>
            <Input style={{ width: '6em', marginBottom: '3px', borderBottom: '1px solid #3698fc' }} size="small" value={filterValue}
              bordered={false} onChange={onFilterChange} />
          </label>
          <GlobalOutlined style={{ color: '#3698fc' }} title='Catalog by continents, group, or other criteria.' />
          <label style={{ fontSize: '14px', color: '#3698fc', marginLeft: '0em' }} title='Catalog by continents, group, or other criteria.'>
            <Select dropdownMatchSelectWidth={false} defaultValue="" showArrow={false} placeholder='Catalog' value={catalogValue || { value: '', label: '' }}
              style={{ width: 100, fontSize: '14px', borderBottom: '1px solid #3698fc' }} bordered={false} options={catalogOptions} size='small'
              onChange={onChangeCatalog}
            />
            <CloseOutlined className='linkText' style={{ color: (filterValue || catalogValue) ? '' : 'lightgrey' }}
              onClick={() => { setFilterValue(''); setCatalogValue(''); updateStaticRegion('', ''); updateRegion(); }} title={'Clear filter and catalog'} />
          </label>

        </div>
      </div>

      <div key='region_editor' style={{
        maxHeight: '20em',
        overflowY: 'auto', border: '1px solid #eeeeee', borderRadius: '4px', padding: '10px', //paddingBottom: '0px',
        fontSize: '14px',
        height: fullViewOnce ? '20em' : 'auto',
      }}>

        {/* selected region */}
        <div key='selected_region' style={{
          display: (viewMode === 'selected') ? ((selectedRegion.length && selectedShowCount) ? 'flex' : '') : 'none', flexWrap: 'wrap',
        }}>
          {(selectedRegion.length && selectedShowCount)
            ? <>{selectedRegion}
              {(selectedRegion.length - selectedShowCount)
                ? <div style={{ color: 'lightgrey', paddingTop: '0.4em', paddingLeft: '1em' }}><i>{selectedRegion.length - selectedShowCount} hidden</i></div>
                : ''
              }
              {/* {!!selectedShowCount &&
                <div style={{ marginTop: '0.5em', marginLeft: 'auto' }}>
                  <span className='linkText' style={{}} onClick={onClearAll} title='Clear selected regions'>Clear All{(filterValue || catalogValue) ? (': ' + selectedShowCount) : ''}</span>
                </div>
              } */}
              <span className='linkText' style={{ paddingTop: '0.4em', paddingLeft: '2em' }} onClick={() => { setViewMode('fullview'); setFullViewOnce(true); }}>+Add</span>
            </>
            : <div style={{ textAlign: 'center', color: 'lightgrey', lineHeight: '200%' }}>{
              selectedRegion.length ? <span><i>{selectedRegion.length} hidden</i></span>
                : <><i>No region selected</i><br />
                  <span className='linkText' onClick={() => { setViewMode('fullview'); setFullViewOnce(true); }}>+Add</span>
                </>
            }</div>}
        </div>

        {/* <div key='full_view_toggle' style={{ marginTop: '1em', textAlign: 'right' }}>
          <span className='linkText' onClick={() => { setFullView(!fullView); }}>{fullView ? '> Hide Full View' : '< Show Full View'}</span>
        </div> */}

        {/* favourite region */}
        <div key='full_view' style={(viewMode === 'fullview') ? {} : { display: 'none' }}>
          <div key='favourite_region_label' style={{ marginTop: '0em', borderBottom: '1px solid #aaaaaa', display: 'flex', justifyContent: 'space-between' }}>
            <div>Frequently used: {((filterValue || catalogValue) ? (favShowCount + '/') : '') + favouriteRegion.length}</div>
            <Checkbox indeterminate={!favAll && !favNone} onChange={onChangeFavAll} checked={favAll}
              style={{ color: '#3698fc' }}
            >
              <span title='Toggle frequently used regions'>All{(filterValue || catalogValue) ? (': ' + favShowCount) : ''}</span>
            </Checkbox>
          </div>
          <div key='favourite_region' style={{
            display: favShowCount ? 'flex' : '', flexWrap: 'wrap',
          }}>
            {favShowCount ? <>{favouriteRegion}
              {(favouriteRegion.length - favShowCount)
                ? <div style={{ color: 'lightgrey', paddingTop: '0.3em', paddingLeft: '1em' }}><i>{favouriteRegion.length - favShowCount} hidden</i></div>
                : ''
              }
            </> : <div style={{ textAlign: 'center', color: 'lightgrey', lineHeight: '200%' }}><i>{favouriteRegion.length} hidden</i></div>}
          </div>

          {/* all region */}
          <div key='all_region_label' style={{ marginTop: '1em', borderBottom: '1px solid #aaaaaa', display: 'flex', justifyContent: 'space-between' }}>
            <div >
              All, A-Z: {((filterValue || catalogValue) ? (fullShowCount + '/') : '') + listAllRegion?.length}
            </div>
            <Checkbox indeterminate={!fullAll && !fullNone} onChange={onChangeFullAll} checked={fullAll}
              style={{ color: '#3698fc' }}
            >
              <span title='Toggle regions'>All{(filterValue || catalogValue) ? (': ' + fullShowCount) : ''}</span>
            </Checkbox>
          </div>
          <div key='all_region' style={{
            display: fullShowCount ? 'flex' : '', flexWrap: 'wrap',
          }}>
            {fullShowCount ? <>{allRegion}
              {(allRegion.length - fullShowCount)
                ? <div style={{ color: 'lightgrey', paddingTop: '0.3em', paddingLeft: '1em' }}><i>{allRegion.length - fullShowCount} hidden</i></div>
                : ''
              }
            </> : <div style={{ textAlign: 'center', color: 'lightgrey', lineHeight: '200%' }}><i>{allRegion.length} hidden</i></div>}
          </div>

        </div>
      </div>
    </>
  );
});
