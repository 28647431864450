import ResetPasswordForm from 'modules/Auth/reset-password';
import { IntroCommon } from 'pages/intro';

export default function ResetPasswordPage() {
  return (
    <IntroCommon>
      <ResetPasswordForm />
    </IntroCommon>
  );
}
