import { Switch, SwitchProps } from 'antd';
import clsx from 'clsx';

import styles from './switch-input.module.scss';

interface SwitchInputProps extends SwitchProps {
  text: string;
  className?: string;
}

const SwitchInput = ({ text, className, ...props }: SwitchInputProps) => {
  return (
    <div className={clsx(className, styles.switch)}>
      <span className={clsx('text-switch', styles.text, props.checked ? styles.textChecked : styles.textDisabled)}>
        {text}
      </span>
      <Switch {...props} />
    </div>
  );
};

export default SwitchInput;
