import React from 'react';

export const NoteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99984 1.94868C5.10552 1.94868 1.94856 5.10564 1.94856 8.99996C1.94856 12.8943 5.10552 16.0512 8.99984 16.0512C12.8942 16.0512 16.0511 12.8943 16.0511 8.99996C16.0511 5.10564 12.8942 1.94868 8.99984 1.94868ZM0.666504 8.99996C0.666504 4.39759 4.39747 0.666626 8.99984 0.666626C13.6022 0.666626 17.3332 4.39759 17.3332 8.99996C17.3332 13.6023 13.6022 17.3333 8.99984 17.3333C4.39747 17.3333 0.666504 13.6023 0.666504 8.99996Z"
        fill="#4B4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99984 12.6324C9.35387 12.6324 9.64086 12.3454 9.64086 11.9914V8.57261C9.64086 8.21858 9.35387 7.93158 8.99984 7.93158C8.64581 7.93158 8.35881 8.21858 8.35881 8.57261V11.9914C8.35881 12.3454 8.64581 12.6324 8.99984 12.6324Z"
        fill="#4B4B4B"
      />
      <path
        d="M9.85454 6.00851C9.85454 5.53647 9.47188 5.15381 8.99984 5.15381C8.5278 5.15381 8.14514 5.53647 8.14514 6.00851C8.14514 6.48055 8.5278 6.86321 8.99984 6.86321C9.47188 6.86321 9.85454 6.48055 9.85454 6.00851Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};
