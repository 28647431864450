import { mergeParam } from 'utils/helper';

export type PaginationDataType = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: string;
  status?: number | string;
  search?: string;
  selectAll?: string;
  tabKey?: string;
};

export enum SortOrderEnum {
  DESC = 'desc',
  ASC = 'asc',
}

export const renderSorterOrder = (sortBy: string) => {
  if (sortBy === 'descend') return SortOrderEnum.DESC;
  if (sortBy === 'ascend') return SortOrderEnum.ASC;
  return sortBy;
};

interface IHistory {
  push: (newUrl: string) => void;
}

export const pushParamHandle = (pathname: string, param: PaginationDataType, history: IHistory) => {
  const newUrl = mergeParam(pathname, param);
  history.push(newUrl);
};

export const convertStatus = (value: string[]) => {
  if (value.length === 1 && value[0] === '1') {
    return 'active';
  }
  if (value.length === 1 && value[0] === '0') {
    return 'disabled';
  }
  if (value.length > 1) {
    return 'active,disabled';
  }
  if (value.length === 0) {
    return;
  }
};

export const optionSelect = [
  { label: 'Active User', value: '1' },
  { label: 'Disabled User', value: '0' },
];

export type UserStatusType = {
  id: number;
  status: number;
};

export type ErrorType = {
  response?: {
    data?: {
      message: string;
    };
  };
};
