import { HorizontalStick } from 'assets/img/login/icon/horizontal-stick';
import { FirstLoginIcon } from 'assets/img/login/icon/login-icon-1';
import { SecondLoginIcon } from 'assets/img/login/icon/login-icon-2';
import { ThirdLoginIcon } from 'assets/img/login/icon/login-icon-3';
import styles from './intro.module.scss';

interface IIntroCommon {
  children?: React.ReactNode;
}

export const IntroCommon = ({ children }: IIntroCommon) => {
  return (
    <div className={styles.adminForm}>
      <div className={styles.background}>
        <div className={styles.content}>
          <div className={styles.introContainer}>
            <div className={styles.iconRow}>
              <div className={styles.horizontalStick}>
                <HorizontalStick />
              </div>
              <div className={styles.iconContainer}>
                <FirstLoginIcon />
                <SecondLoginIcon />
                <ThirdLoginIcon />
              </div>
            </div>
            <div className={styles.sloganRow}>
              <div className={styles.horizontalStick}>
                <HorizontalStick />
              </div>
              <span>Manage your team</span>
              <div></div>
              <span className={styles.fontPrata}>perfectly.</span>
            </div>
            <div className={styles.noteRow}>
              <div className={styles.horizontalStick}>
                <HorizontalStick />
              </div>
              <span>
                The Administration Panel is the primary tool for you to manage the user and their access to your
                products. Here you can have user management, access configuration, enable or disable an account and do
                much more.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.form}>{children}</div>
    </div>
  );
};
