import { useHistory } from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';

import { authActions } from 'redux/auth/auth.slice';

interface logOutModalProps {
  visible: boolean;
  onClose: () => void;
}
export default function LogOutModal({ visible, onClose }: logOutModalProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(authActions.logout());
    history.push('/login');
  };
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onClose}
      width={352}
      className="log-out-modal"
      closable={false}
      destroyOnClose
    >
      <h2>SIGN OUT</h2>
      <p>Are you sure you want to sign out?</p>

      <div className="group-btn">
        <Button className="cancel-btn" type="default" onClick={onClose}>
          Cancel
        </Button>
        <Button className="submit-btn" type="primary" onClick={handleLogout}>
          Yes
        </Button>
      </div>
    </Modal>
  );
}
