export enum SortOrderEnum {
  DESC = 'desc',
  ASC = 'asc',
}

export const renderSorterOrder = (order: string) => {
  if (order === 'descend') return SortOrderEnum.DESC;
  if (order === 'ascend') return SortOrderEnum.ASC;
  return order;
};
