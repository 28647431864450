import { useCallback, useState } from 'react';
import { Button, Dropdown, ModalProps, notification, UploadFile } from 'antd';
import * as XLSX from 'xlsx';
import clsx from 'clsx';

import readXlsxFile from 'read-excel-file';

import {
  CheckCircleIcon,
  CloseIcon,
  DownloadIcon,
  FileCsvIcon,
  FileXlsxIcon,
  InfoCircleFilledIcon,
} from 'components/icons';
import styles from './import-by-template-modal.module.scss';
import DraggerUpload from './dragger-upload';
import PopupDeleteConfirm from './popup-delete-confirm';
import { ModalBase } from 'components/modal';
import { csvMineType, xlsxMineType } from './popup-import.consts';

import * as csvSync from 'csv-parse/lib/sync';

type callbackResult = {
  result: boolean;
  line?: any;
  message?: string;
};

interface ImportByTemplateModalProps extends ModalProps {
  title: string;
  closePopup: () => void;
  verifyData: (rows: string[][]) => Promise<callbackResult>;
  importData: (rows: string[][]) => Promise<callbackResult>;
  action?: string;
  xlsxTemplateUrl?: string;
  //xlsxSheet?: number | string;
  csvTemplateUrl?: string;
  templateData?: string[][];
  templateName?: string;
}

const ImportByTemplateModal = ({ title, closePopup, verifyData, importData, ...props }: ImportByTemplateModalProps) => {

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errorImport, setErrorImport] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const [fileData, setFileData] = useState<string[][][]>([]);

  const onSubmitImport = async () => {
    //console.log('onSubmitImport');

    if (!fileData.length || !fileList.length) return;

    setLoading(true);

    var r = await importData(fileData[0]);  //only 1 file
    if (!r.result) {
      setErrorMessage(r.message || 'import data error');
    }
    else {
      notification.success({
        message: 'Import successfully.',
        description: r.message || 'Import successfully.',
        placement: 'bottomRight',
        closeIcon: <CloseIcon fill="#ffffff" width="12" height="12" />,
        icon: <CheckCircleIcon />,
        className: styles.importBatchSuccessNoti,
      });
      closeThisPopup();
    }

    setLoading(false);
  };

  async function setNewData(rows: string[][]) {
    //console.log('rows', rows);
    var vr = await verifyData(rows);

    if (vr.result) setFileData([rows]);
    else {
      setErrorMessage((vr.line ? ('line ' + vr.line + ', ') : '') + (vr.message || 'Verify data error'));
    }
  }

  const onBeforeUpload = async (file: File) => {
    //console.log('onBeforeUpload');
    setFileData([]);

    if (file.type === xlsxMineType) {
      setLoading(true);
      try {
        var rows = (await readXlsxFile(file)) as string[][];
        //var rows = (await readXlsxFile(file, { sheet: props.xlsxSheet })) as string[][];

        //bug?
        // var sheetNames = await readSheetNames(file);
        // console.log(sheetNames);
        // var rows = (await readXlsxFile(file, { sheet: sheetNames[0] })) as string[][];

        rows = rows.map((v) => v.map((v2) => (v2 ? ('' + v2) : '')));  //all to string

        await setNewData(rows);
      }
      catch (ex: any) {
        //console.log(ex);
        setErrorMessage('exception: ' + ex);
      }

      setLoading(false);
    }
    else if (csvMineType.includes(file.type)) {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function (e) {
        const data = e.target?.result as string;

        //const rows = data.split('\r\n').map((v) => v.split(','));

        // string will contain values with comma characters
        const rows = csvSync.parse(data, { columns: false, relax_column_count: true });
        // console.log('rows', rows);

        setNewData(rows).then(() => {
          setLoading(false);
        });
      };
    }
    else return false;  //don't accept other type
  };

  const downloadFileCSV = useCallback(() => {
    if (props.csvTemplateUrl) {
      window.open(props.csvTemplateUrl, '_blank');
    }
    else if (props.templateData) {
      const csvContent =
        'data:text/csv;charset=utf-8,' + props.templateData.join('\r\n');
      const encodedUri = encodeURI(csvContent);

      const link = document.createElement('a');
      link.href = encodedUri;
      link.download = (props.templateName || 'example-template') + '.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => URL.revokeObjectURL(encodedUri), 5000);
    }
  }, [props.csvTemplateUrl, props.templateData, props.templateName]);

  const downloadFileXLSX = useCallback(() => {
    if (props.xlsxTemplateUrl) {
      window.open(props.xlsxTemplateUrl, '_blank');
    }
    else if (props.templateData) {
      const worksheet = XLSX.utils.aoa_to_sheet(props.templateData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet);
      XLSX.writeFile(workbook, (props.templateName || 'example-template') + '.xlsx');
    }
  }, [props.xlsxTemplateUrl, props.templateData, props.templateName]);

  const closeThisPopup = () => {
    if (isLoading) return;
    setFileList([]);
    setFileData([]);
    closePopup();
  };

  const customizeFooter = () => {
    return [
      <Button
        key="back"
        className={clsx(styles.buttonCancel, styles.mt9, styles.mb7)}
        onClick={closeThisPopup}
        disabled={isLoading}
      >
        Cancel
      </Button>,
      <Button
        key="submit"
        className={clsx(styles.buttonImport, styles.ml3, styles.mt9, styles.mb7, styles.mr7)}
        disabled={errorImport || !!errorMessage || !(!!fileList.length && fileList[0]?.originFileObj)}
        onClick={onSubmitImport}
        loading={isLoading}
      >
        Import
      </Button>,
    ];
  };

  return (
    <>
      <ModalBase
        {...props}
        visible={true}
        footer={customizeFooter()}
        title={title}
        centered
        onCancel={closeThisPopup}
      >
        <div className={clsx(styles.noticeInfo, styles.flex)}>
          <div className={styles.flex}>
            <InfoCircleFilledIcon />
            <div className={styles.noticeText}>
              You are only allowed to import 1 .csv or 1 .xlsx file at a time to the database. Maximum upload file size:
              1.0 MB. Only files with the comply template are allowed.
            </div>
          </div>
          <Dropdown
            overlay={
              <div className={styles.dropdownDownloadTemplate}>
                <div className={clsx(styles.pointer, styles.fileCSV)} onClick={downloadFileCSV}>
                  <FileCsvIcon />
                  <div>CSV Template</div>
                  <DownloadIcon />
                </div>
                <div className={clsx(styles.pointer, styles.fileXLSX)} onClick={downloadFileXLSX}>
                  <FileXlsxIcon />
                  <div>XLSX Template</div>
                  <DownloadIcon />
                </div>
              </div>
            }
            trigger={['click']}
          >
            <div className={styles.pointer}>
              <a className={styles.textDownload}>Download Template</a>
            </div>
          </Dropdown>
        </div>
        <DraggerUpload
          fileList={fileList}
          setFileList={setFileList}
          errorImport={errorImport}
          setErrorImport={setErrorImport}
          errorMessage={errorMessage}
          setOpenConfirmDelete={setOpenConfirmDelete}
          beforeUpload={onBeforeUpload}
          action={props.action || 'upload-file'}
        />
      </ModalBase>
      {openConfirmDelete && (
        <PopupDeleteConfirm
          fileName={!!fileList.length ? fileList[0].name : ''}
          setOpenConfirmDelete={setOpenConfirmDelete}
          setFileList={setFileList}
          setErrorImport={setErrorImport}
          setErrorMessage={setErrorMessage}
        />
      )}
    </>
  );
};

export default ImportByTemplateModal;
