import React, { SetStateAction } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { Button, ModalProps } from 'antd';
import clsx from 'clsx';

import styles from './import-by-template-modal.module.scss';
import { GarbageIcon } from 'components/icons';
import ModalBase from 'components/modal/modal-base';

interface PopupDeleteConfirmProps extends ModalProps {
  setOpenConfirmDelete: React.Dispatch<SetStateAction<boolean>>;
  fileName: string;
  onOk?: () => void;
  setErrorImport?: React.Dispatch<SetStateAction<boolean>>;
  setFileList?: React.Dispatch<SetStateAction<UploadFile[]>>;
  setErrorMessage?: React.Dispatch<SetStateAction<string>>;
}

const PopupDeleteConfirm = ({
  setOpenConfirmDelete,
  setErrorImport,
  setFileList,
  fileName,
  setErrorMessage,
  onOk,
  ...props
}: PopupDeleteConfirmProps) => {
  const onClosePopup = () => {
    setOpenConfirmDelete(false);
  };

  const onDelete = () => {
    if (setFileList && setErrorImport && setErrorMessage) {
      setFileList([]);
      setErrorImport(false);
      setErrorMessage('');
    }
    setOpenConfirmDelete(false);
  };

  const customizeFooter = () => {
    return [
      <Button
        key="back"
        onClick={onClosePopup}
        className={clsx(styles.buttonCancel, styles.mt9, styles.mb7, styles.w50)}
      >
        No
      </Button>,
      <Button
        key="submit"
        onClick={onOk ? onOk : onDelete}
        className={clsx(styles.buttonDelete, styles.ml5, styles.mt9, styles.mb7, styles.mr7, styles.w50)}
      >
        Yes
      </Button>,
    ];
  };

  return (
    <ModalBase
      {...props}
      visible
      onCancel={onClosePopup}
      onOk={onOk}
      footer={customizeFooter()}
      centered
      className={styles.deletePopup}
      closable={false}
    >
      <div className={styles.wrapperContentPopup}>
        <GarbageIcon />
        <div className={clsx(styles.fontRegular, styles.textBW2, 'mt-5')}>Are you sure you want to delete</div>
        <div className={clsx(styles.textBW2, styles.textCenter, styles.wFull)}>{fileName} ?</div>
      </div>
    </ModalBase>
  );
};

export default PopupDeleteConfirm;
