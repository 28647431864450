import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Collapse, Divider, Form, message, Modal, Row } from 'antd';
import { useInfiniteQuery, useQuery } from 'react-query';

import { CreateNewCompanyModal, ImportBatchModal, LeavePopup, SuccessPopup, WarningModal } from 'components/modal';
import styles from './create-external-user.module.scss';
import { ChevronUpIcon, InfoIcon, PlusIcon } from 'components/icons';
import LabelRequire from 'components/label-require';
import { TextInput } from 'components/form-control';
import DateInput from 'components/form-control/date-input';
import { CommonButton } from 'components/common-button';
import SelectSearch, { OptionsSelectType } from 'components/select-search/select-search';
import { PATH_EXTERNAL_USER } from 'navigation/routes.path';
import { externalUserApi } from 'services/api-external-user.services';
import { useDebounce } from 'hooks/use-debounce';
import { errorKeyWord, FieldsAddNewUser } from './create-external-user.types';
import { isEmpty, isNil } from 'utils/helper';
import { UserType } from 'services/api-external-user.type';
import { emailRegex, nameRegex } from 'utils/regex';
import SwitchInput from 'components/switch';
import { isSysAdmin } from 'redux/has-permission';
import { ExternalUserType } from '../external-user.const';
import ChangePlanModal from '../company/change-plan-modal';
import { roleDefault } from '../company/change-plan-modal/role-tool';
import { KioskTableData, loadCompanyKioskTable, loadMembersKioskTable } from '../company/change-plan-modal/kiosk-data';
import prohibitedDomains from '../../../../components/modal/import-batch-modal/prohibited-domains';
import { useParseParams } from 'hooks/use-params';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const { Panel } = Collapse;
const PAGE_SIZE = 7;

export default function CreateExternalUser() {
  const [isOpenCreateCompanyPopup, setOpenCreateCompanyPopup] = useState<boolean>(false);
  const [isOpenImportBatchPopup, setOpenImportBatchPopup] = useState<boolean>(false);
  const [isOpenWarningPopup, setOpenWarningPopup] = useState<boolean>(false);
  const [isOpenSuccessPopup, setOpenSuccessPopup] = useState<boolean>(false);
  const [isOpenLeavePopup, setOpenLeavePopup] = useState<boolean>(false);

  const [companyDomain, setCompanyDomain] = useState<string>('');
  const [isAcceptPublicEmail, setIsAcceptPublicEmail] = useState<boolean>(false);
  const [isAcceptProhibitedEmail, setIsAcceptProhibitedEmail] = useState<boolean>(false);

  const [companySelected, setCompanySelected] = useState<ExternalUserType | null>(null);

  const { CompanyId: CompanyIdParam, CompanyName: CompanyNameParam, openImport: openImportParam } = useParseParams();

  const [openImport, setOpenImport] = useState<string>(openImportParam);

  const [companyName, setCompanyName] = useState<string>(CompanyNameParam ? decodeURIComponent(CompanyNameParam) : '');
  const [companyId, setCompanyId] = useState<string>(CompanyIdParam || '');
  const [countryName, setCountryName] = useState<string>('');
  const [countryId, setCountryId] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const [, setCityId] = useState<string>('');
  const [isLoadingAddUser, setLoadingAddUser] = useState<boolean>(false);

  const debounceValueCompanySearch = useDebounce<string>(companyName.trim());
  const debounceValueCountrySearch = useDebounce<string>(countryName.trim());
  const debounceValueCitySearch = useDebounce<string>(cityName.trim());
  const [form] = Form.useForm();

  if (CompanyIdParam) form.setFieldValue('companyName', companyName);

  const [isOpenChangePlan, setIsOpenChangePlan] = useState<boolean>(false);
  const [memberDetails, setMemberDetails] = useState({
    id: '',
    name: '',
    username: '',
    email: '',

    ...roleDefault,

    start_date: '',
    expired_date: '',
    plan_start_date: '',
    plan_end_date: '',
    is_isolate_role: false,
    is_admin: false,
    is_trial: false,

    blueOnionHubRole: {},

    account_id: '',
    handleKey: 0,

    company: {},
  });

  const fetchListRoleCompany = () => externalUserApi.getListRoleCompany();
  const { data: listRoleCompanyData } = useQuery(
    ['listRoleCompanyAddNewUserExternal'],
    fetchListRoleCompany,
  );

  //kiosk
  const { data: queryKioskTableData } = useQuery(['loadKioskTableForNewMember'], (): Promise<KioskTableData> => {
    return loadMembersKioskTable(0, kioskTableData);
  });
  //console.log('queryKioskTableData', queryKioskTableData);

  const [kioskTableData, setKioskTableData] = useState<KioskTableData | undefined>(queryKioskTableData);

  function onKioskUpdateData(newKioskTableData: KioskTableData) {
    //console.log('newKioskTableData',newKioskTableData);
    setKioskTableData(newKioskTableData);
  }

  if (!kioskTableData && queryKioskTableData) setKioskTableData(queryKioskTableData);

  const [companyKioskTableData, setCompanyKioskTableData] = useState<KioskTableData | null>();

  //company list
  const fetchListCompany = useCallback(
    ({ pageParam = 1 }) => {
      return externalUserApi.getListCompany({
        page: pageParam,
        pageSize: PAGE_SIZE,
        search: debounceValueCompanySearch,
      });
    },
    [debounceValueCompanySearch],
  );
  const fetchListCountries = () => externalUserApi.getListCountries();
  const fetchListCities = () => externalUserApi.getListCities(countryId);

  const {
    data: listCompanyData,
    isLoading: isListCompanyLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(['listCompanyDataAddNewUserExternal', debounceValueCompanySearch], fetchListCompany, {
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page < lastPage.data.totalPage) return lastPage.data.page + 1;
      return undefined;
    },
  });
  const { data: listCountriesData, isLoading: isListCountriesLoading } = useQuery(
    ['listCountriesDataAddNewUserExternal'],
    fetchListCountries,
  );
  const { data: listCitiesData, isLoading: isListCitiesLoading } = useQuery(
    ['listCitiesDataAddNewUserExternal', countryId],
    fetchListCities,
  );

  const handleScrollToBottom = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLElement;
      const isAtBottom = Math.abs(target.scrollHeight - (target.scrollTop + target.clientHeight)) <= 1;
      if (!isAtBottom || !hasNextPage) return;

      fetchNextPage();
    },
    [fetchNextPage, hasNextPage],
  );

  const onCompanyDropdownVisibleChange = () => {
    fetchListCompany({ pageParam: 1 });
  };
  const onChangeCompanyInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setCompanyName(e.target.value);
  };

  const loadCompanyProperty = (idCompany: string) => {
    listCompanyData?.pages.forEach((page) => {
      page.data.data.forEach((row) => {
        if (row.id === idCompany) {
          setCompanyDomain(row.domain);

          //company admin allow to create other emails
          //setIsAcceptPublicEmail(row.is_accept_public_email && isSysAdmin());
          setIsAcceptPublicEmail(row.is_accept_public_email);

          //company admin not allow to create prohibited emails
          setIsAcceptProhibitedEmail(row.is_accept_prohibited_email && isSysAdmin());

          setCompanySelected(row);
          return;
        }
      });
    });
  };

  if (companyId && !companyDomain) {
    loadCompanyProperty(companyId);   //load company property for url params
  }

  const onCompanySelect = (idCompany: string) => {
    form.setFields([{ name: 'userEmail', errors: [] }]);
    setCompanyId(idCompany);

    loadCompanyProperty(idCompany);

    if (companySelected && companySelected?.id !== idCompany) {
      //not found then clear
      setCompanySelected(null);
    }
  };
  const onCompanyClear = () => {
    form.setFields([{ name: 'userEmail', errors: [] }]);
    setCompanyId('');
  };

  const onCountrySelect = (id: string) => {
    //reset city when reselect country
    if (form.getFieldsValue(['city'])?.city) {
      form.resetFields(['city']);
    }
    setCountryId(id);
  };
  const onChangeCountryInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setCountryName(e.target.value);
  };

  const onDropdownVisibleChangeCountryName = () => {
    setCountryName('');
  };

  const onDropdownVisibleChangeCityName = () => {
    setCityName('');
  };

  const onCountryClear = () => {
    setCountryId('');
  };

  const onCitySelect = (id: string) => {
    setCityId(id);
  };
  const onChangeCityInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setCityName(e.target.value);
  };
  const onCityClear = () => {
    setCityId('');
  };

  const optionsListCompany = useMemo(() => {
    const result: OptionsSelectType[] = [];
    if (!listCompanyData) return [];

    listCompanyData.pages.forEach((page) => {
      page.data.data.forEach(({ id, name }) => {
        result.push({ id, name });
      });
    });

    return result;
  }, [listCompanyData]);

  const optionsListCountries = useMemo(() => {
    if (!listCountriesData) return [];

    const formattedDebounceValueCountrySearch = debounceValueCountrySearch.toLowerCase();
    return listCountriesData.data
      .map((country) => ({ id: country.iso2, name: country.name }))
      .filter((country) =>
        country.name.toLowerCase().includes(formattedDebounceValueCountrySearch),
      ) as OptionsSelectType[];
  }, [debounceValueCountrySearch, listCountriesData]);

  const optionsListCities = useMemo(() => {
    if (!listCitiesData) return [];

    const formattedDebounceValueCountrySearch = debounceValueCitySearch.toLowerCase();
    return listCitiesData.data
      .map((city) => ({ id: city.name, name: city.name }))
      .filter((city) => city.name.toLowerCase().includes(formattedDebounceValueCountrySearch)) as OptionsSelectType[];
  }, [debounceValueCitySearch, listCitiesData]);

  const toggleCreateCompanyPopup = () => {
    setOpenCreateCompanyPopup((prev) => !prev);
  };

  const toggleWarningPopup = () => {
    setOpenWarningPopup((prev) => !prev);
  };

  const router = useHistory();

  const toggleImportBatchPopup = () => {
    if (!companyId) {
      toggleWarningPopup();
      return;
    }

    if (isOpenImportBatchPopup && openImportParam) {
      //close at closing importing by cancel when in url import mode
      router.goBack();
    }

    setOpenImportBatchPopup((prev) => !prev);
  };

  if (openImport === '1' && !isOpenImportBatchPopup) {
    //open the importing only once
    setOpenImport('0');
    toggleImportBatchPopup();
  }

  const toggleSuccessPopup = () => {
    setOpenSuccessPopup((prev) => !prev);
  };

  const toggleLeavePopup = () => {
    setOpenLeavePopup((prev) => !prev);
  };

  const onFinishAddUserForm = async (values: FieldsAddNewUser) => {

    // check if the email is existed.
    var existedMembers = await externalUserApi.getListMembers({ page: 1, pageSize: 100, search: '\'' + values.userEmail + '\'' });
    if (existedMembers.data.data.length > 0) {
      // console.log('existedMembers',existedMembers);
      const nowDayStr = dayjs().format('YYYY-MM-DD');
      let invalidMembers: ExternalUserType[] = [];

      existedMembers.data.data.forEach((member) => {
        if (('' + member.status) !== '1' || (member.expired_date && member.expired_date < nowDayStr)) {
          invalidMembers.push(member);
        }
      });

      const hasActiveMembers = invalidMembers.length < existedMembers.data.data.length;
      let checkingUrl = `/user-management/external-user?page=1&pageSize=20&search='${values.userEmail}'&tabKey=Member`;

      let selectAction = await new Promise((resolve, reject) => {
        let modal = Modal.confirm({
          wrapClassName: styles.modalConfirm,
          centered: true,
          title: 'Select Action for Existing Members',
          content: <p>
            Found existing members for<br />
            <a type="link" onClick={() => { modal.destroy(); resolve('check'); }} title='Click to pause and manually check/delete the existing'>{values.userEmail}</a>.
            ({!hasActiveMembers
              ? ('All Invalid, ' + invalidMembers.length)
              : invalidMembers.length
                ? ('Some Active, ' + (existedMembers.data.data.length - invalidMembers.length) + '/' + existedMembers.data.data.length)
                : ('All Active, ' + existedMembers.data.data.length)})
            <br />
            <br />
            Please select an action.<br />
            <Button type="primary" block disabled={hasActiveMembers} size='small' onClick={() => { modal.destroy(); resolve('delete'); }} style={{ marginTop: '0.8em' }}>
              Delete the invalid then create new user/password
            </Button>
            <Button type="primary" block size='small' onClick={() => { modal.destroy(); resolve('check'); }} style={{ marginTop: '0.8em' }}>
              Pause and manually check/delete the existing
            </Button>
            <Button type="primary" block size='small' onClick={() => { modal.destroy(); resolve('create'); }} style={{ marginTop: '0.8em' }}
              title='There will be no password information in the welcome email for the new member.'>
              Create another new without changing the password
            </Button>
          </p>,
          width: 600,
          closable: true,
          maskClosable: true,
          onOk: () => { reject(); },
          onCancel: () => { reject(); },
          okButtonProps: { disabled: true, style: { display: 'none' } },
          cancelButtonProps: { size: 'small', style: { paddingLeft: '1em', paddingRight: '1em' } },
        });
      });

      // alert('selectAction ' + selectAction);
      if (selectAction === 'check') { setTimeout(() => { window.open(checkingUrl, '_blank'); }, 800); return; }
      if (selectAction === 'delete') {
        let imax = invalidMembers.length;
        for (let i = 0; i < imax; i++) {
          let member = invalidMembers[i];
          await externalUserApi.deleteMember(member.id, '' + member.email);
          message.success(`Deleted ${member.email} / ${member.username}`);
        }
      }
    }

    // create new user
    setLoadingAddUser(true);
    const requiredFields = ['username', 'userEmail'/*, 'expiryDate'*/];
    let isError = false;

    Object.entries(values).forEach(([field, value]) => {
      if (isEmpty(value) && requiredFields.includes(field)) {
        isError = true;
        form.setFields([{ name: field, errors: ['This field is mandatory'] }]);
      } else {
        form.setFields([{ name: field, errors: [] }]);
      }
    });

    if (!companyId) {
      isError = true;
      form.setFields([{ name: 'companyName', errors: ['This field is mandatory'] }]);
    } else {
      form.setFields([{ name: 'companyName', errors: [] }]);
    }

    /*
    const { userEmail } = form.getFieldsValue();
    const [emailDomain] = (userEmail as string).split('@').reverse();
    if (!isError && companyDomain !== emailDomain && !(isAcceptPublicEmail && isSysAdmin())) {
      isError = true;
      form.setFields([
        { name: 'userEmail', errors: [`User mail does not comply with the company domain [${companyDomain}], please contact BlueOnion to create this account.`] },
      ]);
    }
    */

    const valueCountry = listCountriesData?.data.find((item) => item.iso2 === values?.country);

    if (isError) {
      setLoadingAddUser(false);
      return;
    }
    const infoUser = {
      companyId: Number(companyId),
      username: values.username.trim(),
      email: values.userEmail,
      country: valueCountry?.name,
      city: values?.city,
      postalCode: isNil(values.postalCode) ? undefined : Number(values.postalCode),
      phone: values?.phoneNumber,
      start_date: values.start_date?.trim() || null,
      expired_date: values.expired_date?.trim() || null,
      plan_start_date: values.plan_start_date?.trim() || null,
      plan_end_date: values.plan_end_date?.trim() || null,
      is_admin: values?.is_admin,
      is_trial: values?.is_trial,
    };

    try {
      var newRowRet = await externalUserApi.createNewUser(infoUser);
      toggleSuccessPopup();
      setLoadingAddUser(false);

      //console.log('newRowRet', newRowRet);
      if (newRowRet?.data?.company) {
        //created
        let newRow = newRowRet.data;
        setMemberDetails({
          ...memberDetails,
          id: newRow.id,
          name: newRow.name || newRow.username || newRow.email?.replace?.(/@.*/, '') || '',
          email: newRow.email,
          start_date: newRow.start_date,
          expired_date: newRow.expired_date,
          plan_start_date: newRow.plan_start_date,
          plan_end_date: newRow.plan_end_date,
          is_admin: newRow.is_admin,
          account_id: newRow.account_id,
          handleKey: (new Date()).getTime(),
          company: newRow.company,
        });
        //setIsOpenChangePlan(true);

        loadCompanyKioskTable(+newRow.company_id, companyKioskTableData || kioskTableData).then((res) => {
          setCompanyKioskTableData(res);
          //setChangePlanVisible(true);
        });
      }

    } catch (error: any) {
      setLoadingAddUser(false);
      const messageError = error?.response?.data?.message;
      if (isEmpty(error?.response)) {
        message.error({
          content: 'Please check your internet connection and try again.',
          className: 'custom-class',
          style: {
            textAlign: 'center',
          },
        });
        return;
      }
      if (messageError.includes(errorKeyWord.userExisted)) {
        form.setFields([{ name: 'username', errors: ['This username already exists. Please input a new username'] }]);
        return;
      }
      if (messageError.includes(errorKeyWord.emailExisted)) {
        form.setFields([{ name: 'userEmail', errors: ['This email already exists. Please input a new email'] }]);
        return;
      }
      if (messageError.includes(errorKeyWord.phoneExisted)) {
        form.setFields([
          { name: 'phoneNumber', errors: ['This phone number already exists. Please input a different phone number'] },
        ]);
        return;
      }
      if (messageError.includes(errorKeyWord.phoneError)) {
        form.setFields([{ name: 'phoneNumber', errors: ['Phone format is not valid. Please check again'] }]);
        return;
      }

      message.error({
        content: error?.response?.data?.message,
        className: 'custom-class',
        style: {
          textAlign: 'center',
        },
      });
    }
  };

  const onImportFile = (colsCsv: UserType[]) => {
    const formattedColsCsv: UserType[] = colsCsv.map((colCsv) => ({ ...colCsv, companyId: Number(companyId) }));
    return externalUserApi.importBatchUserList({ users: formattedColsCsv });
  };

  //console.log('isOpenChangePlan',isOpenChangePlan);
  //console.log('memberDetails', memberDetails);
  //console.log([companyId, companyName, companyDomain]);
  //console.log('memberDetails.company', memberDetails.company);

  if (+memberDetails?.id > 0 && !isOpenSuccessPopup && !isOpenChangePlan) setIsOpenChangePlan(true);

  return (
    <div className={styles.box}>
      <Row gutter={[20, 20]}>
        <Col className="gutter-row" span={18}>
          <Form
            form={form}
            onFinish={(value) => onFinishAddUserForm({ ...value, expired_date: value.expiryDate.format('DD/MM/YYYY') })}
          >
            <Row gutter={[20, 20]}>
              <Col className="gutter-row" span={24}>
                <div className={styles.addNewUser}>
                  <Collapse
                    accordion
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => (
                      <ChevronUpIcon style={{ transform: `rotate(${isActive ? '0deg' : '180deg'})` }} />
                    )}
                  >
                    <Panel header="Select Company" key="1">
                      <div className="select-company" style={CompanyIdParam ? { display: 'none' } : {}}>
                        <div className="flex items-center">
                          <InfoIcon />
                          <span className="text">
                            You can only add company user to an existed Company. Please create new Company if you first
                            create new company user
                          </span>
                        </div>
                        <div className="create-new-company" onClick={toggleCreateCompanyPopup} hidden={!isSysAdmin()}>
                          Create New Company
                        </div>
                      </div>
                      <LabelRequire className="pb-1" text="Select Company" require />
                      <Form.Item
                        className="mb-0"
                        name="companyName"
                        rules={[{ required: true, message: 'This field is mandatory' }]}
                      >
                        <SelectSearch
                          optionsSelect={optionsListCompany}
                          loading={isListCompanyLoading}
                          onPopupScroll={handleScrollToBottom}
                          onDropdownVisibleChange={onCompanyDropdownVisibleChange}
                          onSelect={onCompanySelect}
                          onClear={onCompanyClear}
                          inputSearchProps={{
                            value: companyName,
                            defaultValue: companyName,
                            onChange: onChangeCompanyInputSearch,
                          }}
                          value={companyName}
                          defaultValue={companyName}
                          disabled={!!CompanyIdParam}
                        />
                      </Form.Item>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
              <Col className="gutter-row" span={24}>
                <div className={styles.companyUserDetail}>
                  <Collapse
                    accordion
                    defaultActiveKey={['3']}
                    expandIcon={({ isActive }) => (
                      <ChevronUpIcon style={{ transform: `rotate(${isActive ? '0deg' : '180deg'})` }} />
                    )}
                  >
                    <Panel header="Company User Detailed Information" key="3">
                      <div className="select-company">
                        <div className="flex items-center">
                          <InfoIcon />
                          <span className="text">
                            BO admin account can onboard the user ourself by importing the batch user list or manually
                            enter the user information.
                          </span>
                        </div>
                        <div className="create-new-company" onClick={toggleImportBatchPopup}>
                          Import Batch User List
                        </div>
                      </div>
                      <LabelRequire className="pb-1" text="User Name" require />
                      <TextInput
                        placeholder="Input User Name"
                        name="username"
                        rules={[
                          { max: 100, message: 'The maximum length for User Name is: 100 characters' },
                          {
                            message: 'This field only allow alphabet characters, numeric, underscore and dot',
                            validator: (_, value) => {
                              if (nameRegex.test(value)) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  'This field only allow alphabet characters, numeric, underscore and dot',
                                );
                              }
                            },
                          },
                          { required: true, message: 'This field is mandatory' },
                        ]}
                      />
                      <LabelRequire className="pb-1" text="User EMail" require />
                      <TextInput
                        placeholder="Input User EMail"
                        name="userEmail"
                        rules={[
                          { max: 100, message: 'The maximum length for User Name is: 100 characters' },
                          {
                            pattern: new RegExp(emailRegex),
                            message: 'Email format is not valid. Please check again',
                          },
                          { required: true, message: 'This field is mandatory' },
                          {
                            //message: 'This email is not allowed. Please contact BlueOnion to create this account1111',
                            validator: (_, value) => {
                              if (!companyDomain) {
                                return Promise.reject('Please select a company firstly.');
                              } else if (!isAcceptPublicEmail && value.slice(value.lastIndexOf('@') + 1) !== companyDomain) {
                                return Promise.reject(
                                  `This email is not at company domain '@${companyDomain}'. Please contact BlueOnion to create this account`,
                                );
                              } else if (!isAcceptProhibitedEmail && prohibitedDomains.includes('\n' + value.slice(value.lastIndexOf('@') + 1) + '\n')) {
                                return Promise.reject(
                                  'This email domain is prohibited. Please contact BlueOnion to create this account',
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                        dependencies={['companyName']}
                      />
                      <LabelRequire className="pb-1" text="Expiry Date" require />
                      <DateInput name="expiryDate" rules={[{ required: true, message: 'This field is mandatory' }]} />
                      <Row gutter={[20, 0]}>
                        <Col className="gutter-row" span={12}>
                          <LabelRequire className="pb-1" text="Country" />
                          <Form.Item className="mb-0" name="country">
                            <SelectSearch
                              loading={isListCountriesLoading}
                              optionsSelect={optionsListCountries}
                              onSelect={onCountrySelect}
                              onClear={onCountryClear}
                              inputSearchProps={{
                                value: countryName,
                                onChange: onChangeCountryInputSearch,
                              }}
                              onDropdownVisibleChange={onDropdownVisibleChangeCountryName}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <LabelRequire className="pb-1" text="Postal Code" />
                          <TextInput
                            placeholder="Input Postal Code"
                            name="postalCode"
                            inputProps={{
                              onKeyDown: (e) => {
                                if (
                                  !/^[0-9]*$/.test(e.key) &&
                                  e.key !== 'Backspace' &&
                                  e.key !== 'ArrowRight' &&
                                  e.key !== 'ArrowLeft' &&
                                  !(e.ctrlKey && e.key === 'v') &&
                                  !(e.ctrlKey && e.key === 'c') &&
                                  !(e.ctrlKey && e.key === 'a') &&
                                  !(e.ctrlKey && e.key === 'x')
                                )
                                  e.preventDefault();
                              },
                            }}
                            rules={[
                              {
                                max: 15,
                                message: 'You have reach the maximum character for Postal Code: 15 characters',
                              },
                            ]}
                          />
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <LabelRequire className="pb-1" text="City" />
                          <Form.Item className="mb-0" name="city">
                            <SelectSearch
                              loading={isListCitiesLoading}
                              optionsSelect={optionsListCities}
                              onSelect={onCitySelect}
                              onClear={onCityClear}
                              inputSearchProps={{
                                value: cityName,
                                onChange: onChangeCityInputSearch,
                              }}
                              onDropdownVisibleChange={onDropdownVisibleChangeCityName}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <LabelRequire className="pb-1" text="Phone Number" />
                          <TextInput
                            placeholder="Input Phone Number"
                            name="phoneNumber"
                            rules={[
                              {
                                max: 15,
                                message: 'You have reach the maximum character for Phone Number: 15 characters',
                              },
                              {
                                min: 8,
                                message: 'You have reach the minimum character for Phone Number: 8 characters',
                              },
                              {
                                pattern: new RegExp(/^[+]*[(]{0,1}[+]{0,1}[0-9]{1,}[)]{0,1}[0-9]*$/g),
                                message: 'This field only allow input number and these special character: ()+',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <Form.Item name="is_admin" valuePropName="checked" style={isSysAdmin() ? {} : { display: 'none' }}>
                            <SwitchInput
                              text="Is User Admin? "
                              disabled={!isSysAdmin()}
                              //required
                              //className={styles.switchInputDisabled}
                              defaultChecked={false}
                              autoLabelWidth={true}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name="is_trial" valuePropName="checked" >
                            <SwitchInput
                              text="Is Trial? "
                              defaultChecked={false}
                              autoLabelWidth={true}
                            />
                          </Form.Item>
                        </Col>
                      </Row>


                    </Panel>
                  </Collapse>
                </div>
              </Col>
            </Row>
            <div className="group-btn">
              <CommonButton variant="default" onClick={toggleLeavePopup} idKey='create-member-cancel'>
                Cancel
              </CommonButton>

              <Form.Item shouldUpdate>
                {({ getFieldsValue, getFieldsError }) => {
                  const { username, userEmail, expiryDate, companyName } = getFieldsValue();
                  const isDisabledAddUser =
                    !username ||
                    !userEmail ||
                    !expiryDate ||
                    !companyName ||
                    !!getFieldsError().filter(({ errors }) => errors.length).length;
                  return (
                    <CommonButton
                      variant="primary"
                      htmlType="submit"
                      disabled={isDisabledAddUser}
                      loading={isLoadingAddUser}
                      idKey='createMemberAdd'
                    >
                      <PlusIcon fill="white" height={18} width={18} />
                      Add User
                    </CommonButton>
                  );
                }}
              </Form.Item>
            </div>
          </Form>
        </Col>
        <Col className="gutter-row" span={6}>
          <div className={styles.externalUserGuide}>
            <Collapse
              // accordion={false}
              defaultActiveKey={['2']}
              expandIcon={({ isActive }) => (
                <ChevronUpIcon style={{ transform: `rotate(${isActive ? '0deg' : '180deg'})` }} />
              )}
            >
              <Panel header="External User Guide" key="2">
                <div className={styles.note1}>
                  <span className="note">Note 1</span>
                  <p className="text">
                    In the Batch user import template file, in this phase only includes column User name and User email.
                    Does not include other columns such as Country, Postal code...etc
                  </p>
                </div>
                <Divider />
                <div>
                  <span className="note">Note 2</span>
                  <p className="text">
                    You can create the company directly when adding the user. The newly created company will be shown up
                    in the list for selection
                  </p>
                </div>
                <Divider />

                <div style={{ display: 'none' }}>
                  <span className="note">Note 3</span>
                  <p className="text">
                    In MVP version will not have role Company Admin yet. As a result, button for enable "Is User Admin"
                    is currently disabled from editing
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>

      {isOpenChangePlan && (<ChangePlanModal
        open={isOpenChangePlan}
        setVisible={setIsOpenChangePlan}
        //companyDetails={companyDetails}
        memberDetails={memberDetails}
        parentDetails={memberDetails.company}

        listCompanyRoles={listRoleCompanyData}
        kioskTableData={kioskTableData}
        onKioskUpdateData={onKioskUpdateData}
        parentKioskData={companyKioskTableData}
        //onUpdateData={onUpdateData}
        //refetch={refetch}
        key={'new-member-change-plan'}
        redirectUrl={PATH_EXTERNAL_USER}
      />
      )}

      {isOpenSuccessPopup && (
        <SuccessPopup
          togglePopup={toggleSuccessPopup}
          content={'User account has been created successfully!'}
        //redirectUrl={PATH_EXTERNAL_USER}
        />
      )}
      {isOpenLeavePopup && <LeavePopup togglePopup={toggleLeavePopup} redirectUrl={PATH_EXTERNAL_USER} />}
      {isOpenCreateCompanyPopup && <CreateNewCompanyModal togglePopup={toggleCreateCompanyPopup} refetch={refetch} />}
      {isOpenImportBatchPopup && (
        <ImportBatchModal
          onImportFile={onImportFile}
          togglePopup={toggleImportBatchPopup}
          companyDomain={companyDomain}
          isAcceptPublicEmail={isAcceptPublicEmail}
          isAcceptProhibitedEmail={isAcceptProhibitedEmail}
          typeUser="external"
        />
      )}
      {isOpenWarningPopup && <WarningModal togglePopup={toggleWarningPopup} />}
    </div>
  );
}
