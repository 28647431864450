export const DownloadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.1837 12.2051H15.9796L13.6327 14.5641C13.4286 14.7692 13.1224 14.9744 12.9184 15.0769C12.6122 15.1795 12.3061 15.2821 12 15.2821C11.6939 15.2821 11.3878 15.1795 11.0816 15.0769C10.7755 14.9744 10.5714 14.7692 10.3673 14.5641L8.02041 12.2051H2.81633C2.40816 12.2051 2 12.5128 2 13.0256V16.8205C2 18.2564 3.12245 19.4872 4.55102 19.5897C6.69388 19.7949 9.65306 20 12 20C14.3469 20 17.3061 19.7949 19.449 19.5897C20.8776 19.4872 22 18.2564 22 16.8205V13.0256C22 12.6154 21.5918 12.2051 21.1837 12.2051Z"
        fill="#4B4B4B"
      />
      <path
        d="M10.1633 12.2051L11.4898 13.5385C11.5918 13.641 11.6939 13.641 11.6939 13.7436C11.7959 13.7436 11.898 13.8462 12 13.8462C12.102 13.8462 12.2041 13.8462 12.3061 13.7436C12.4082 13.7436 12.5102 13.641 12.5102 13.5385L15.5714 10.4615C15.8776 10.1538 15.8776 9.64103 15.5714 9.33333C15.3673 9.23077 15.2653 9.12821 15.0612 9.12821C14.8571 9.12821 14.6531 9.23077 14.551 9.33333L12.8163 11.0769V4.82051C12.8163 4.41026 12.5102 4 12 4C11.4898 4 11.1837 4.41026 11.1837 4.82051V11.1795L9.44898 9.4359C9.34694 9.23077 9.14286 9.12821 8.93878 9.12821C8.73469 9.12821 8.53061 9.23077 8.42857 9.4359C8.12245 9.74359 8.12245 10.2564 8.42857 10.5641L10.1633 12.2051Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};
