import React, { useState } from 'react';

import { Col, Divider, Form, FormInstance, Row, Select } from 'antd';
import SwitchInput from 'components/switch-input';

import styles from './kiosk-table-editor.module.scss';
import LabelRequire from 'components/label-require';
import KioskTableRow from './kiosk-table-row';
import { ReturnPermissionDto } from 'services/api-permission.type';
import { KioskTableData } from './kiosk-data';
import { ExpandIcon } from './expand-icon';

const sortRegion: { [key: string]: number } = {
  'kiosk:region:hk': 1,
  'kiosk:region:tw': 2,
  'kiosk:region:sg': 3,
  'kiosk:region:gb': 4,
  'kiosk:region:th': 5,
  'kiosk:region:jp': 6,
};

const sortModule: { [key: string]: number } = {
  'kiosk:module:home': 1,
  'kiosk:module:fund': 2,
  'kiosk:module:portfolio': 3,
};

const moduleList = ['home', 'fund', 'portfolio'];

const ModuleList = Object.keys(sortModule).map((v) => v.slice(v.lastIndexOf(':') + 1));
//console.log(ModuleList);

export type KioskTableEditorProps = {
  //companyId: number,
  kioskTableData: KioskTableData | null | undefined,
  onUpdateData?: Function,
  isActive?: any,
  productKiosk?: boolean | any,
  onUpdateProductKiosk: Function | any,
  keyId: string,

  nowDayStr?: string;
  form?: FormInstance<any>;
  parentKioskData: KioskTableData | null | undefined;
}

export default function KioskTableEditor({ kioskTableData, onUpdateData, keyId, ...props }: KioskTableEditorProps) {
  const { kioskRegion, kioskModule, kioskTableMapping, tableDataMapping } = kioskTableData || {};

  // console.log('keyId', keyId);
  //state

  //region tabs state
  const [region, setRegion] = useState(kioskRegion?.[0]?.name);

  const [expand, setExpand] = useState(true);

  const [lastKeyId, setLastKeyId] = useState('');

  const [dataError, setDataError] = useState(false);
  //console.log('dataError', dataError);

  if (!kioskTableData?.tableData) {
    return <span key={'1'}>loading data ...</span>;
  }

  //region tabs

  //let items: TabsProps['items'] = [];
  let itemsOptions: any[] = [];

  if (kioskRegion && kioskRegion.length > 0) {
    //prepare list
    var regionList = kioskRegion.map((v) => {
      var rg = v.name.slice(v.name.lastIndexOf(':') + 1);
      var isAllow = ModuleList.find((v) => tableDataMapping?.['kiosk:table:' + rg + ':' + v]?.is_allow);

      return {
        name: v.name,
        display_name: v.display_name,
        sortNum: sortRegion[v.name],
        is_allow: isAllow,
      };
    });

    //allow
    let allowOptions: any[] = regionList.filter((v) => v.is_allow)
      .map((v) => {
        return {
          value: v.name,
          label: v.display_name + ' *',
          style: { backgroundColor: '#e6f7ff' },
        };
      })
      .sort((v1, v2) => { return v1.label > v2.label ? 1 : -1; });

    //console.log('allowOptions', allowOptions);

    //favourite
    let favouriteOptions: any[] = regionList.filter((v) => v.sortNum)
      .map((v) => {
        return {
          value: v.name + (v.is_allow ? '*' : ''),  //make key different
          label: v.display_name + (v.is_allow ? ' *' : ''),
          //...v.is_allow ? { style: { backgroundColor: '#e6f7ff' } } : null,
        };
      })
      .sort((v1, v2) => { return v1.label > v2.label ? 1 : -1; });

    //all
    let allOptions: any[] = regionList
      .map((v) => {
        return {
          value: v.name + ((v.is_allow || v.sortNum) ? '**' : ''),  //make key different
          label: v.display_name + (v.is_allow ? ' *' : ''),
          //...v.is_allow ? { style: { backgroundColor: '#e6f7ff' } } : null,
        };
      })
      .sort((v1, v2) => { return v1.label > v2.label ? 1 : -1; });

    //combine
    if (allowOptions.length > 0) {
      itemsOptions.push(
        {
          label: 'Allowed',
          options: allowOptions,
        },
      );
    }

    itemsOptions.push(
      {
        label: 'Favourites',
        options: favouriteOptions,
      },
      {
        label: 'A-Z',
        options: allOptions,
      },
    );

    if (keyId !== lastKeyId) {
      //console.log('setLastKeyId', keyId);
      setLastKeyId(keyId);
      setRegion((allowOptions.length > 0) ? allowOptions[0].value : '');

    }
  }
  else {
    if (region) setRegion('');
  }

  //rows
  function onUpdateRowData(v: any) {
    //onUpdateData?.({ ...tableDataMapping, [v.permission_name]: v });

    //console.log(v);
    const prefix = v.permission_name.replace(/[^:]+$/, '');  //remove module part
    const newMapping = { ...tableDataMapping };
    //console.log('newMapping', newMapping);

    for (var m of moduleList) {
      const k = prefix + m;
      const oldV = newMapping?.[k];
      if (oldV) {
        newMapping[k] = { ...v, id: oldV.id, permission_name: k, permission_id: kioskTableMapping?.[k]?.id };
      }
      else {
        newMapping[k] = { ...v, permission_name: k, permission_id: kioskTableMapping?.[k]?.id };
      }
    }
    //console.log(newMapping);
    onUpdateData?.(newMapping);
  }

  //console.log('props.parentKioskData', props.parentKioskData);

  var rows: JSX.Element[] = [];
  if (kioskModule && kioskModule.length > 0) {
    [...kioskModule]
      .sort((v1, v2) => { return sortModule[v1.name] - sortModule[v2.name]; })
      .forEach((v) => {
        var ks = 'kiosk:table' + region?.slice(region.lastIndexOf(':')) + v.name?.slice(v.name.lastIndexOf(':'));
        var rowData: ReturnPermissionDto | any = tableDataMapping?.[ks];
        if (!rowData && tableDataMapping) rowData = { permission_id: kioskTableMapping?.[ks]?.id, permission_name: ks };
        //console.log('new', ks, rowData?.is_allow);
        rows.push(
          <KioskTableRow
            name={(v.display_name || v.name).replace(/^Kiosk /, '')}
            key={ks}
            rowData={rowData}
            onUpdateRowData={onUpdateRowData}
            disabled={!region}
            setDataError={setDataError}

            nowDayStr={props.nowDayStr}
            form={props.form}
            parentKioskData={props.parentKioskData}

            keyId={keyId}
          />);
      });
  }
  else {
    //rows = [<span key={'1'}>loading...</span>];
  }

  //console.log('regine', region);

  return (
    <div className={styles.checkboxPanel}>
      <LabelRequire text='Kiosk Hub' />
      <Form.Item name="productKiosk" valuePropName="checked" className='mainCheckbox'>
        <SwitchInput
          text=''
          checked={!!props.productKiosk}
          onChange={(v) => {
            props.onUpdateProductKiosk?.(v);
            if (v) setExpand(true);
          }}
        />
      </Form.Item>
      <ExpandIcon
        key='kiosk-expand-icon'
        visible={props.productKiosk}
        expandState={props.productKiosk && expand}
        setExpandState={setExpand}
      />
      <div style={{ display: (props.productKiosk && expand) ? '' : 'none' }}>
        <Divider className='panelDivider' />

        {/*
        <Tabs activeKey={region} defaultActiveKey={region} items={items} onChange={(v) => setRegion(v)} />
        <LabelRequire text="Select Region" />
        */}
        <Select
          showSearch
          optionFilterProp='label'
          placeholder='Select Region'
          style={{ width: '343px' }}
          value={region || undefined}
          defaultValue={region || undefined}
          onChange={(v: string) => {
            setRegion(v.replace(/\*+$/, ''));
          }}
          options={itemsOptions}
          //dropdownStyle =
          //getPopupContainer={trigger => trigger.parentElement}
          virtual={false}
          className='region-selector'
          disabled={dataError}
        />

        <Row className='tableHeader' gutter={[20, 20]} style={{ display: 'none' }}>
          <Col span={8}>Pages</Col>
          <Col span={8}>Start Date</Col>
          <Col span={8}>End Date</Col>
        </Row>
        <div style={{ display: 'flex' }}  >
          {rows}
        </div>
      </div>
    </div>

  );
}


