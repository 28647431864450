import { useEffect, useState } from 'react';
import { Modal, Form } from 'antd';
import Schema from 'async-validator';

import styles from '../change-password-form/change-password-form.module.scss';
import { BlueonionBlackIcon } from 'components/icons/blueonion-logo-black-icon';
import LabelRequire from 'components/label-require';
import { PasswordInput } from 'components/form-control';
import { InfoCircleFilledIcon } from 'components/icons';
import { CommonButton } from 'components/common-button';
import { resetPassword } from 'services/auth.service';
import { PATH_LOGIN, PATH_FORGOT_PASSWORD } from 'navigation/routes.path';
import { Link } from 'react-router-dom';
 
Schema.warning = function () {};

type IChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ResetPasswordForm() {
  const [form] = Form.useForm();
  const [expire, setExpire] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const expiredTime = queryParams.get('expiredTime');
  const currentTime = new Date().getTime();
  useEffect(() => {
    if (currentTime >= Number(expiredTime)) {
      setExpire(true);
    }
  }, [currentTime, expiredTime]);
  


  const handleChangePassword = async (value: IChangePassword) => {
    setLoading(true);
    const data = await resetPassword(token, value.newPassword, value.confirmPassword);
    if(data.success === true){
      setIsModalOpen(true);
      setTimeout(() => {
        window.location.replace(PATH_LOGIN);      
      }, 3000);
    }else{
      setExpire(true);
    }
  };

  return (
    expire ? (
    <div className={styles.formChangePassword} >
      <BlueonionBlackIcon style={{width:265, marginBottom:75}} />
      <div className={styles.note}>
        <p>Link has been Expired, Please request a new reset password link <Link to={PATH_FORGOT_PASSWORD}>here</Link>.</p>
      </div>
    </div>
    ) : (
    <div className={styles.formChangePassword}>
      <BlueonionBlackIcon style={{width:265, marginBottom:75}} />
      <p className={styles.changePasswordTitle}>Reset Password</p>
      <div className={styles.note}>
        <p>
        Use at least 8 characters. Don’t use a password from another site, or something too obvious like your birthday.
        </p>
        <div className={styles.passwordRequired}>
          <InfoCircleFilledIcon />
          <div className={styles.info}>
            <p>Password must be:</p>
            <ul>
              <li>At least 8 characters</li>
              <li>Must contain at least an uppercase letter</li>
              <li>Must contain at least a number</li>
              <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
            </ul>
          </div>
        </div>
      </div>
      <Form form={form} onFinish={handleChangePassword}>
        <LabelRequire text="New Password" require />
        <PasswordInput
          placeholder="Input your new password"
          name="newPassword"
          rules={[
            { required: true, message: 'You must fill in this field' },
            {
              pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/),
              message: 'Your password is not strong enough. Make sure it follow the rule below',
            },
          ]}
        />

        <LabelRequire text="Confirm New Password" require />
        <PasswordInput
          placeholder="Confirm your new password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'You must fill in this field' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The password confirmation does not match. Please check again');
              },
            }),
          ]}
        />
        <div className={styles.updatePasswordButton}>
          {/* <Link to={PATH_LOGIN}>
            <p>Not Now</p>
          </Link> */}
          <Form.Item shouldUpdate>
            {() => (
              <div className={styles.button}>
                <CommonButton
                  htmlType="submit"
                  block
                  size="large"
                  variant="primary"
                  disabled={
                    !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length || loading
                  }
                >
                  Update Password
                </CommonButton>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
      <Modal title="Password Reset" open={isModalOpen} footer={null}>
        <p>Your Password Has been reset, to Login.</p>
        <p>Redirecting to Login...</p>
      </Modal>
    </div>
  )
  );
}
