
export const crmRoleList = [
  // 'Test:not:exist',
  'CRM: Viewer',
  'CRM: Editor',
  'CRM: Approver',
  'CRM: Admin',
];

export const crmPermissionList = [
  // 'test:not:exist',
  'crm:admin',
  'crm:approver',
  'crm:editor',
  'crm:viewer',

  '[Project Management]',
  'crm:account:manage',
  'crm:disclosure:create',
  'crm:disclosure:rename',
  'crm:disclosure:delete',
  'crm:disclosure:duplicate',

  '[Regulatory Module]',
  'crm:regulatory:overview:edit',
  'crm:regulatory:holding:edit',
  'crm:regulatory:materiality',
  'crm:regulatory:carbonfootprint',
  'crm:regulatory:view',
  'crm:regulatory:edit',
  'crm:regulatory:submit',
  'crm:regulatory:review',
  'crm:regulatory:comment',
  // 'crm:regulatory:comment:read',
  'crm:regulatory:report:download',
  'crm:regulatory:report:preview',

  // '[Scenario Module]',
  // 'crm:scenario:fund:search',
  // 'crm:scenario:portfolio:create',
  // 'crm:scenario:portfolio:rename',
  // 'crm:scenario:portfolio:delete',
  // 'crm:scenario:portfolio:duplicate',
  // 'crm:scenario:holding:edit',
  // 'crm:scenario:holding:analysis',
  // 'crm:scenario:holding:analysis:reset',
  // 'crm:scenario:view',
  // 'crm:scenario:parameter',
  // 'crm:scenario:comment',
];
