

export const regRoleName = /^[\w :-]{1,255}$/;

export type ImportRoleDto = {
  table?: string;

  name: string;
  display_name?: string;
  short_name?: string;
  description?: string;
};

export type ImportObjectRolesDto = {
  table?: string;

  role_name: string;
  is_allow?: string;
  start_date?: string;
  end_date?: string;
};
