import { useCallback, useMemo } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { Select, Spin } from 'antd';

//import { TextInput } from 'components/form-control';
import { XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
import { NoDataTable } from 'components/no-data-table';
import { useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from 'modules/user-management/external-user/company/company.const';
import { UserCompanyType } from 'modules/user-management/external-user/company/company-user-modal/company-user-modal.const';
//import { isNil } from 'utils/helper';
import moment from 'moment';
import axiosClient from 'utils/axios-client';

import './style.module.scss';
import SortingArrow from 'components/sorting-arrow';

type NoticeProps = {
  companyId?: string
};

const noticeActions = [
  'COOLING_EMAIL',
  'LOG_CREATE_USER',
  'LOG_USER_FORGOT_PASSWORD',
  'LOG_BATCH_CREATE_USER',
  'LOG_FIRST_EXPIRED',
  'LOG_SECOND_EXPIRED_TO_MEMBER',
  'LOG_SECOND_EXPIRED_TO_AUDITOR',
  //'LOG_AUDIT_REQUEST_FOR_NEW_USER',
  'LOG_CREATE_MEMBER',
  'LOG_BATCH_CREATE_MEMBER',
  //'LOG_AUDIT_REQUEST_FOR_BATCH_NEW_USER',
  'LOG_MEMBER_FORGOT_PASSWORD',
  //'LOG_APPROVE_NEW_MEMBER',
  //'LOG_REJECT_NEW_MEMBER',
  'LOG_RESEND_NEW_MEMBER_NOTIFICATION_EMAIL',
];

/*
[
  { label: 'Burns Bay Road', value: 'Burns Bay Road' },
  { label: 'Downing Street', value: 'Downing Street' },
  { label: 'Wall Street', value: 'Wall Street' },
];
*/
const noticeActionsOptions = noticeActions.sort().map((v) => { return { label: v, value: v }; });

export default function Notice({ companyId, ...props }: NoticeProps) {
  const history = useHistory();
  const location = useLocation();

  const { pageSize, page, search, sortOrder, sortBy, roleIds, tabKey, action } = useParseParams();

  //const [valueSearch, setValueSearch] = useState<string>(search);

  const setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 20,
          search,
          sortOrder: sortOrder ?? 'desc',
          sortBy: sortBy ?? 'id',
          roleIds,
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, roleIds, search, sortBy, sortOrder],
  );

  /*
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({ search: e.target.value, tabKey });
    }, 700);
  };
  */

  const getNoticeList = (params: any) => {
    return axiosClient.get('/notice', {
      params: {
        ...params,
        sortOrder: params.sortOrder || 'desc',
        sortBy: params.sortBy || 'id',
      },
    }).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return {};
      }
    });
  };

  //console.log([location.pathname, pageSize, page, search, sortOrder, sortBy, roleIds, tabKey]);

  const {
    data: listNoticeData,
    isLoading: isListNoticeLoading,
  } = useQuery(
    [
      'getListNotice',
      {
        pageSize,
        page,
        //status,
        search,
        sortBy,
        sortOrder,
        action,
      },
    ], () => {
      return getNoticeList({
        pageSize,
        page,
        status: undefined,  //urlStatus !== 'active,disabled' ? urlStatus : undefined,
        search: search?.trim(),
        sortBy,
        sortOrder,
        action,
      });
    });

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<UserCompanyType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
      tabKey,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  const columns: ColumnsType<UserCompanyType> = [
    {
      align: 'left',
      title: renderTitle('ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true,
      //ellipsis: true,
    },
    {
      align: 'left',
      title: renderTitle('Action', 'notify_type'),
      dataIndex: 'notify_type',
      key: 'notify_type',
      width: 160,
      ellipsis: true,
      sorter: true,
    },
    {
      align: 'left',
      title: 'Reciever',
      dataIndex: 'receiver_name',
      key: 'receiver_name',
      width: 200,
      ellipsis: true,
      render: (_, data: any) => (data.receiver_name ? (data.receiver_name + ' / ') : '') + data.receiver_list,
    },
    {
      align: 'left',
      title: 'Source',
      dataIndex: 'sender_name',
      key: 'sender_name',
      width: 200,
      ellipsis: true,
    },
    {
      align: 'left',
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      render: (_, data: any) => (
        <div className="noticeTitle" onClick={() => {
          const w = window.open('about:blank', '_blank');
          w?.document.write(data.message + '<div style="text-align:center;padding-top:3em;"><div style="text-align:left;width:612px;margin:auto;">Log detail:<pre>' + JSON.stringify({ ...data, message: undefined }, null, '\t') + '</pre></div></div>');
        }}>
          {data.title}
        </div>
      ),
    },
    {
      align: 'left',
      title: 'Sent',
      dataIndex: 'is_sent',
      key: 'is_sent',
      width: 180,
      //sorter: true,
      ellipsis: true,
      render: (_, data: any) => {
        return (
          <div>{(data.is_sent && data.sent_at) ? moment(data.sent_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</div>
        );
      },
    },
  ];

  const ClearFilters = () => {
    setParamHandle({ page: 1, pageSize: 20, search: undefined, tabKey });
    //setValueSearch('');
  };

  const onChangePage = (page: number, pageSize: number) => {
    setParamHandle({ page, pageSize, roleIds: roleIds, tabKey, action });
  };

  const totalResult = useMemo(() => {
    return listNoticeData?.total ?? 0;
  }, [listNoticeData?.total]);

  return (
    <div key={'NoticePanel' + tabKey}>
      <p className="filter-hub-text">Filter Action</p>
      <div className="filter-hub-flex">
        <div className="filter-hub-options">
          {/*
          <TextInput
            placeholder={'Search by Email or User Name'}
            className="input"
            inputProps={{
              onChange: onChangeInput,
              value: valueSearch,
              prefix: <SearchIcon width={20} height={20} />,
              maxLength: 255,
            }}
          />
          */}
          <Select
            showSearch
            optionFilterProp='label'
            placeholder='Select Action'
            value={action ? action.split(',') : undefined}
            onChange={(v: string[]) => {
              setParamHandle({ page: 1, action: v.join(','), tabKey });
            }}
            options={noticeActionsOptions}
            maxTagCount="responsive"
            virtual={false}
            className='input'
            size='large'
            // mode="multiple"
            mode='tags'
            showArrow
            allowClear
          />

        </div>
        <div className="clear-filters" onClick={ClearFilters}>
          <span>Clear all filters</span>
          <XCircleIcon width={24} height={24} />
        </div>
      </div>
      {isListNoticeLoading ? (
        <Spin className="spin" />
      ) : (
        <>
          {!listNoticeData?.data || listNoticeData?.data.length === 0 ? (
            <NoDataTable />
          ) : (
            <>
              <Table
                rowKey={'id'}
                columns={columns}
                //dataSource={roleIds ? listCompanyData?.data?.data : []}
                dataSource={listNoticeData?.data || []}
                pagination={false}
                onChange={onChangeTable}
                loading={isListNoticeLoading}
              />

              <div className="pagination">
                <div className="total-results-text">
                  Total results: <p className="total-results-number">{totalResult}</p>
                </div>
                <CustomPagination
                  pageSize={listNoticeData?.pageSize ?? 20}
                  current={listNoticeData?.page ?? 1}
                  total={totalResult}
                  onChange={onChangePage}
                  showSizeChanger={true}
                />
              </div>
            </>
          )}
        </>
      )}

    </div>
  );
}
