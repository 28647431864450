import { PermissionImportDto, UpdateMaxDeviceParamDto } from 'modules/user-management/external-user/company/company-user-modal/company-user-modal.const';
import axiosClient from 'utils/axios-client';
import { ConcurrentMaxEntity, ConcurrentProductEntity, PermissionEntity, ReturnObjectRolesDto, ReturnPermissionDto, ReturnRolesDto, UpdatePermissionDto, UpdateRolesDto } from './api-permission.type';
import { UpdateBlueOnionHubPlanExpiryDate } from '../modules/user-management/external-user/company/change-plan-modal/blueonionhub-data';

export const getKioskRegion = async (): Promise<PermissionEntity[]> => {
  return axiosClient.get('/permission/kiosk-region').then((res) => {
    return res.data;
  });
};

export const getCommonRegion = async (): Promise<PermissionEntity[]> => {
  return axiosClient.get('/permission/region').then((res) => {
    return res.data;
  });
};

export const getKioskModule = async (): Promise<PermissionEntity[]> => {
  return axiosClient.get('/permission/kiosk-module').then((res) => {
    return res.data;
  });
};

export const getKioskTable = async (): Promise<PermissionEntity[]> => {
  return axiosClient.get('/permission/kiosk-table').then((res) => {
    return res.data;
  });
};

export const getCompanyKioskTable = async (companyId: number): Promise<ReturnPermissionDto[]> => {
  return axiosClient.get(`/company-permission/kiosk-table/${companyId}`).then((res) => {
    return res.data;
  });
};

export const putCompanyKioskTable = async (companyId: number, data: UpdatePermissionDto[]): Promise<ReturnPermissionDto[]> => {
  data = data.filter((v) => {
    if (!v.permission_id) throw Error('update company permission table without permission_id');
    return v.permission_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
  });
  return axiosClient.put(`/company-permission/kiosk-table/${companyId}`, data).then((res) => {
    return res.data;
  });
};

export const putCompanyRegion = async (companyId: number, data: UpdatePermissionDto[]): Promise<ReturnPermissionDto[]> => {
  data = data.filter((v) => {
    if (!v.permission_id) throw Error('update company permission table without permission_id');
    return v.permission_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
  });
  return axiosClient.put(`/company-permission/region/${companyId}`, data).then((res) => {
    return res.data;
  });
};

export const getMembersKioskTable = async (memberId: number): Promise<ReturnPermissionDto[]> => {
  return axiosClient.get(`/members-permission/kiosk-table/${memberId}`).then((res) => {
    return res.data;
  });
};

export const getMembersRegion = async (memberId: number): Promise<ReturnPermissionDto[]> => {
  return axiosClient.get(`/members-permission/region/${memberId}`).then((res) => {
    return res.data;
  });
};

export const getCompanyRegion = async (companyId: number): Promise<ReturnPermissionDto[]> => {
  return axiosClient.get(`/company-permission/region/${companyId}`).then((res) => {
    return res.data;
  });
};

export const getCompanyObjectRoles = async (companyId: number): Promise<ReturnObjectRolesDto[]> => {
  return axiosClient.get(`/company-roles/${companyId}`).then((res) => {
    return res.data;
  });
};

export const getMemberObjectRoles = async (memberId: number): Promise<ReturnObjectRolesDto[]> => {
  return axiosClient.get(`/member-roles/${memberId}`).then((res) => {
    return res.data;
  });
};

export const putMembersKioskTable = async (memberId: number, data: UpdatePermissionDto[]): Promise<ReturnPermissionDto[]> => {
  data = data.filter((v) => {
    if (!v.permission_id) throw Error('update member permission table without permission_id');
    return v.permission_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
  });
  return axiosClient.put(`/members-permission/kiosk-table/${memberId}`, data).then((res) => {
    return res.data;
  });
};

export const putMembersRegion = async (memberId: number, data: UpdatePermissionDto[]): Promise<ReturnPermissionDto[]> => {
  data = data.filter((v) => {
    if (!v.permission_id) throw Error('update member permission table without permission_id');
    return v.permission_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
  });
  return axiosClient.put(`/members-permission/region/${memberId}`, data).then((res) => {
    return res.data;
  });
};

export const putMembersRoles = async (memberId: number, data: UpdateRolesDto[], force?: boolean, partial = false): Promise<ReturnRolesDto[]> => {
  if (!force) {
    data = data.filter((v) => {
      if (!v.role_id) throw Error('update members role table without role_id');
      return v.role_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
    });
  }
  return axiosClient.put(`/member-roles/${memberId}` + (partial ? '?partial=1' : ''), data).then((res) => {
    return res.data;
  });
};

export const putUserRoles = async (userId: number, data: UpdateRolesDto[], force?: boolean, partial = false): Promise<ReturnRolesDto[]> => {
  if (!force) {
    data = data.filter((v) => {
      if (!v.role_id) throw Error('update users role table without role_id');
      return v.role_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
    });
  }
  return axiosClient.put(`/user-roles/${userId}` + (partial ? '?partial=1' : ''), data).then((res) => {
    return res.data;
  });
};

/*
export const putCompanyMembersPermissionKioskBatch = async (compnayId: number, data: KioskImportDto[]): Promise<any> => {
  return axiosClient.put(`/company-members-permission/kiosk-batch/${compnayId}`, data).then((res) => {
    return res.data;
  });
};
*/

export const putCompanyMembersPermissionBatch = async (compnayId: number, data: PermissionImportDto[]): Promise<any> => {
  return axiosClient.put(`/company-members-permission/batch/${compnayId}`, data).then((res) => {
    return res.data;
  });
};

export const putBlueOnionPlanExpiryDate = async (companyId: string, params: UpdateBlueOnionHubPlanExpiryDate[], partial = false): Promise<any> => {
  return axiosClient.put(`/company-roles/${companyId}` + (partial ? '?partial=1' : ''), params);
};

export const getAllConcurrentProduct = async (): Promise<ConcurrentProductEntity[]> => {
  //get all
  return axiosClient.get('/blueonion-product?page=1&pageSize=9999').then((res) => {
    return res.data?.data;
  });
};

export const getConcurrentMax = async (accountId: string): Promise<ConcurrentMaxEntity[]> => {
  //get all
  return axiosClient.get(`/blueonion-product-max-device/${accountId}?page=1&pageSize=9999`).then((res) => {
    return res.data?.data;
  });
};

export const patchConcurrentMax = async (accountId: number, data: UpdateMaxDeviceParamDto): Promise<any> => {
  return axiosClient.patch(`/blueonion-product-max-device/${accountId}`, data).then((res) => {
    return res.data;
  });
};

export const patchMultipleConcurrentMax = async (data: UpdateMaxDeviceParamDto[]): Promise<any> => {
  return axiosClient.patch('/blueonion-product-max-device', { data }).then((res) => {
    return res.data;
  });
};

// asset
export const getCommonAsset = async (): Promise<PermissionEntity[]> => {
  return axiosClient.get('/permission/asset').then((res) => {
    return res.data;
  });
};


export const putCompanyAsset = async (companyId: number, data: UpdatePermissionDto[]): Promise<ReturnPermissionDto[]> => {
  data = data.filter((v) => {
    if (!v.permission_id) throw Error('update company permission table without permission_id');
    return v.permission_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
  });
  return axiosClient.put(`/company-permission/asset/${companyId}`, data).then((res) => {
    return res.data;
  });
};

export const getMembersAsset = async (memberId: number): Promise<ReturnPermissionDto[]> => {
  return axiosClient.get(`/members-permission/asset/${memberId}`).then((res) => {
    return res.data;
  });
};

export const getCompanyAsset = async (companyId: number): Promise<ReturnPermissionDto[]> => {
  return axiosClient.get(`/company-permission/asset/${companyId}`).then((res) => {
    return res.data;
  });
};

export const putMembersAsset = async (memberId: number, data: UpdatePermissionDto[]): Promise<ReturnPermissionDto[]> => {
  data = data.filter((v) => {
    if (!v.permission_id) throw Error('update member permission table without permission_id');
    return v.permission_id && (v.is_allow || v.is_prohibit || v.is_assign_other);
  });
  return axiosClient.put(`/members-permission/asset/${memberId}`, data).then((res) => {
    return res.data;
  });
};
