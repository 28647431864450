import { ComponentType } from 'react';

import CreateExternalUser from 'modules/user-management/external-user/create-external-user';
import { NotFoundPage } from 'pages';
import CompanyUser from 'pages/plan-configuration/company-user-page';
import IndividualUser from 'pages/plan-configuration/individual-user-page';
import ExternalUser from 'pages/user-management/external-user-page';
import InternalUser from 'pages/user-management/internal-user-page';
import Log from 'pages/user-management/log';
import Settings from 'pages/user-management/settings';

import {
  PATH_COMPANY_USER,
  PATH_EXTERNAL_USER,
  PATH_INDIVIDUAL_USER,
  PATH_INTERNAL_USER,
  PATH_NEW_EXTERNAL_USER,
  PATH_NEW_INTERNAL_USER,
  PATH_LOG,
  PATH_SETTINGS,
} from './routes.path';
import CreateInternalUser from 'modules/user-management/internal-user/create-internal-user';

export interface IRoute {
  path: string;
  display?: string;
  exact?: boolean;
  element: ComponentType;
}

export const routes: IRoute[] = [
  {
    path: PATH_COMPANY_USER,
    display: 'Company User',
    exact: true,
    element: CompanyUser,
  },
  {
    path: PATH_INDIVIDUAL_USER,
    display: 'Individual User',
    exact: false,
    element: IndividualUser,
  },
  {
    path: PATH_INTERNAL_USER,
    display: 'Internal User',
    exact: true,
    element: InternalUser,
  },
  {
    path: PATH_EXTERNAL_USER,
    display: 'External User',
    exact: true,
    element: ExternalUser,
  },
  {
    path: PATH_LOG,
    display: 'Log',
    exact: true,
    element: Log,
  },
  {
    path: PATH_SETTINGS,
    display: 'Settings',
    exact: true,
    element: Settings,
  },
  {
    path: PATH_NEW_EXTERNAL_USER,
    exact: false,
    element: CreateExternalUser,
  },
  {
    path: PATH_NEW_INTERNAL_USER,
    exact: false,
    element: CreateInternalUser,
  },
  {
    path: '*',
    exact: false,
    element: NotFoundPage,
  },
];
