import styles from './company-user-modal.module.scss';

type StatusProp = {
  value: StatusType;
};

export type StatusType = 1 | 0;

export const StatusDisplayer = ({ value }: StatusProp) => {
  const status = {
    1: {
      title: 'Active',
      color: 'rgba(0, 171, 86, 0.08)',
      textColor: '#00AB56',
      width: '68px',
    },
    0: {
      title: 'Disable',
      color: 'rgba(245, 245, 250, 0.9)',
      textColor: '#6f7282',
      width: '86px',
    },
    null: {
      title: 'Disable',
      color: 'rgba(245, 245, 250, 0.9)',
      textColor: '#6f7282',
      width: '86px',
    },
  };
  return (
    <div className={styles.statusContainer} style={{ background: status[value]?.color, width: status[value]?.width }}>
      <p style={{ color: status[value]?.textColor, fontWeight: 500 }}>{status[value]?.title}</p>
    </div>
  );
};

export type DeactivatedType = 1 | 0;

export const DeactivatedDisplayer = ({ value }: StatusProp) => {
  const status = {
    0: {
      title: 'Active',
      color: 'rgba(0, 171, 86, 0.08)',
      textColor: '#00AB56',
      width: '68px',
    },
    1: {
      title: 'Disable',
      color: 'rgba(245, 245, 250, 0.9)',
      textColor: '#6f7282',
      width: '86px',
    },
    null: {
      title: 'Active',
      color: 'rgba(0, 171, 86, 0.08)',
      textColor: '#00AB56',
      width: '68px',
    },
  };
  return (
    <div className={styles.statusContainer} style={{ background: status[value]?.color, width: status[value]?.width }}>
      <p style={{ color: status[value]?.textColor, fontWeight: 500 }}>{status[value]?.title}</p>
    </div>
  );
};
