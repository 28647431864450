import { ReactNode } from 'react';
import { Radio, RadioChangeEvent, RadioProps } from 'antd';
import { DisabledType } from 'antd/lib/config-provider/DisabledContext';

import styles from './user-department.module.scss';

interface UserDepartmentTagsProps extends RadioProps {
  icon: ReactNode;
  name: string;
  value: string;
  onChange: (e: RadioChangeEvent) => void;
  disabled?: DisabledType;
  checked?: boolean;
}

function UserDepartmentTags({ icon, name, value, onChange, checked, disabled }: UserDepartmentTagsProps) {
  return (
    <div className={styles.userDepartment} style={disabled ? { opacity: '0.5' } : { opacity: 'unset' }}>
      <Radio value={value} onChange={onChange} checked={checked} disabled={disabled}>
        <div className={styles.userDepartmentTitle}>
          <span className={styles.icons}>{icon}</span>
          <span className={styles.title}>{name}</span>
        </div>
      </Radio>
    </div>
  );
}

export default UserDepartmentTags;
