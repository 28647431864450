import React from 'react';

export const DateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7 13C7 13.5523 6.55228 14 6 14C5.44772 14 5 13.5523 5 13C5 12.4477 5.44772 12 6 12C6.55228 12 7 12.4477 7 13Z'
        fill={props?.fill || '#6F7282'}
      />
      <path
        d='M7 9C7 9.55229 6.55228 10 6 10C5.44772 10 5 9.55229 5 9C5 8.44771 5.44772 8 6 8C6.55228 8 7 8.44771 7 9Z'
        fill={props?.fill || '#6F7282'}
      />
      <path
        d='M11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13C9 12.4477 9.44771 12 10 12C10.5523 12 11 12.4477 11 13Z'
        fill={props?.fill || '#6F7282'}
      />
      <path
        d='M11 9C11 9.55229 10.5523 10 10 10C9.44771 10 9 9.55229 9 9C9 8.44771 9.44771 8 10 8C10.5523 8 11 8.44771 11 9Z'
        fill={props?.fill || '#6F7282'}
      />
      <path
        d='M15 9C15 9.55229 14.5523 10 14 10C13.4477 10 13 9.55229 13 9C13 8.44771 13.4477 8 14 8C14.5523 8 15 8.44771 15 9Z'
        fill={props?.fill || '#6F7282'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 2.75C7.88333 2.75 4.99144 2.93342 3.1865 3.06548C2.59249 3.10894 2.12445 3.57311 2.07621 4.16562C1.93699 5.87574 1.75 8.54172 1.75 10.5C1.75 12.4583 1.93699 15.1243 2.07621 16.8344C2.12445 17.4269 2.59249 17.8911 3.1865 17.9345C4.99145 18.0666 7.88333 18.25 10 18.25C12.1167 18.25 15.0086 18.0666 16.8135 17.9345C17.4075 17.8911 17.8756 17.4269 17.9238 16.8344C18.063 15.1243 18.25 12.4583 18.25 10.5C18.25 8.54172 18.063 5.87574 17.9238 4.16562C17.8756 3.57312 17.4075 3.10894 16.8135 3.06548C15.0086 2.93342 12.1167 2.75 10 2.75ZM3.07704 1.56948C4.8828 1.43736 7.82315 1.25 10 1.25C12.1769 1.25 15.1172 1.43736 16.923 1.56948C18.2533 1.66682 19.3105 2.71328 19.4188 4.04391C19.5582 5.75539 19.75 8.47498 19.75 10.5C19.75 12.525 19.5582 15.2446 19.4188 16.9561C19.3105 18.2867 18.2533 19.3332 16.923 19.4305C15.1172 19.5626 12.1769 19.75 10 19.75C7.82315 19.75 4.8828 19.5626 3.07704 19.4305C1.7467 19.3332 0.689481 18.2867 0.581157 16.9561C0.441828 15.2446 0.25 12.525 0.25 10.5C0.25 8.47498 0.441828 5.75539 0.581157 4.04391C0.689481 2.71328 1.7467 1.66682 3.07704 1.56948Z'
        fill={props?.fill || '#6F7282'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 0.25C7.41421 0.25 7.75 0.585787 7.75 1V3.5C7.75 3.91421 7.41421 4.25 7 4.25C6.58579 4.25 6.25 3.91421 6.25 3.5V1C6.25 0.585786 6.58579 0.25 7 0.25Z'
        fill={props?.fill || '#6F7282'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 0.25C13.4142 0.25 13.75 0.585787 13.75 1L13.75 3.5C13.75 3.91421 13.4142 4.25 13 4.25C12.5858 4.25 12.25 3.91421 12.25 3.5L12.25 1C12.25 0.585786 12.5858 0.25 13 0.25Z'
        fill={props?.fill || '#6F7282'}
      />
    </svg>
  );
};
