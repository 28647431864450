import { getCompanyKioskTable, getKioskModule, getKioskRegion, getKioskTable, getMembersKioskTable } from 'services/api-permission.service';
import { PermissionEntity, ReturnPermissionDto } from 'services/api-permission.type';


export type KioskTableData = {
  object_type: string;
  object_id: number;

  kioskRegion?: PermissionEntity[];
  kioskModule?: PermissionEntity[];
  kioskTable?: PermissionEntity[];
  kioskTableMapping?: { [key: string]: PermissionEntity; }; //map permission_name to table item

  tableData?: ReturnPermissionDto[] | null;
  tableDataMapping: { [key: string]: ReturnPermissionDto | any; }; //map permission_name to data item
};

export async function loadObjectKioskTable(objectType: string, objectId: number, lastData?: KioskTableData | null): Promise<KioskTableData> {
  //console.log('start loadCompanyKioskTable');
  let data: KioskTableData = {
    ...lastData, object_type: objectType, object_id: objectId,
    tableData: null, tableDataMapping: {},
  };

  //use previous as cache
  if (!data.kioskRegion)
    data.kioskRegion = await getKioskRegion();
  if (!data.kioskModule)
    data.kioskModule = await getKioskModule();
  if (!data.kioskTable) {
    data.kioskTable = await getKioskTable();
    //build map
    data.kioskTableMapping = {};
    data?.kioskTable?.forEach((v) => {
      if (data.kioskTableMapping)
        data.kioskTableMapping[v.name] = v;
    });
  }

  if (objectId > 0) {
    if (objectType === 'company') {
      data.tableData = await getCompanyKioskTable(objectId);
    }
    else if (objectType === 'members') {
      data.tableData = await getMembersKioskTable(objectId);
    }
    else throw Error(`unsupport kiosk type ${objectType}`);

    if (data.tableData) {
      //build map
      data.tableData.forEach((v) => { data.tableDataMapping[v.permission_name] = v; });
    }
  }
  else {
    //for creating new company or member
    data.tableData = [];
  }


  return data;
}

export async function loadCompanyKioskTable(companyId: number, lastData?: KioskTableData | null): Promise<KioskTableData> {
  return loadObjectKioskTable('company', companyId, lastData);
}

export async function loadMembersKioskTable(memberId: number, lastData?: KioskTableData | null): Promise<KioskTableData> {
  return loadObjectKioskTable('members', memberId, lastData);
}
