import { differenceInMinutes } from 'date-fns';
import dayjs from 'dayjs';

export const checkDateIsValid = (dateStart: Date, dateEnd = new Date(), distance: number) => {
  return differenceInMinutes(dateStart, dateEnd) > distance;
};

export const formatDate = (inputDate: string, format = 'DD/MM/YYYY') => {
  return dayjs(inputDate).format(format);
};

export const convertStandardDateTimeString = (dateTime: string, symbol: string = '/') => {
  const [date, time] = dateTime.split(' ');
  const [day, month, year] = date.split(symbol);
  return [`${month}/${day}/${year}`, time].join(' ');
};
