export const WarningPopupIcon = ({ fill = '#FFBA4F', stroke = '#FFBA4F', ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31 61C47.5685 61 61 47.5685 61 31C61 14.4315 47.5685 1 31 1C14.4315 1 1 14.4315 1 31C1 47.5685 14.4315 61 31 61Z"
        stroke={stroke}
        strokeWidth="2"
        stroke-miterlimit="10"
      />
      <path d="M31.002 16.6953V29.8303" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path
        d="M31.104 41.7588C30.8195 41.7588 30.5752 41.6567 30.3711 41.4526C30.1732 41.2485 30.0742 41.0042 30.0742 40.7197C30.0742 40.4352 30.1732 40.1909 30.3711 39.9868C30.5752 39.7765 30.8195 39.6714 31.104 39.6714C31.3947 39.6714 31.6421 39.7765 31.8462 39.9868C32.0503 40.1909 32.1523 40.4352 32.1523 40.7197C32.1523 41.0042 32.0503 41.2485 31.8462 41.4526C31.6421 41.6567 31.3947 41.7588 31.104 41.7588Z"
        fill={fill}
      />
    </svg>
  );
};
