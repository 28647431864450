import { Button, ModalProps } from 'antd';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import styles from './success-popup.module.scss';
import { CloseIcon } from 'components/icons';
import { SuccessIcon } from './success-icon';
import { ModalBase } from 'components/modal';

interface SuccessPopupProps extends ModalProps {
  togglePopup: () => void;
  content?: React.ReactNode;
  redirectUrl?: string;
}
const SuccessPopup = ({ togglePopup, redirectUrl, content, ...props }: SuccessPopupProps) => {
  const router = useHistory();

  const onOk = () => {
    togglePopup();
    if (redirectUrl) {
      router.replace(redirectUrl);
    }
  };

  return (
    <ModalBase
      {...props}
      visible={true}
      onCancel={onOk}
      className={clsx(styles.successPopup, 'common-modal')}
      centered
      closeIcon={<CloseIcon />}
      destroyOnClose
    >
      <SuccessIcon />
      <div className={styles.successPopupContent}>{content ?? 'Success'}</div>
      <Button type="primary" onClick={onOk} className={styles.successPopupBtn}>
        OK
      </Button>
    </ModalBase>
  );
};

export default SuccessPopup;
