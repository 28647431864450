import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { Checkbox } from 'antd';

import styles from './select.module.scss';
import { useParseParams } from 'hooks/use-params';
import { CloseIcon } from 'components/icons';

export type OptionSelect = {
  label: string;
  value: string;
};

interface CommonSelectProps extends SelectProps {
  optionSelect: OptionSelect[];
  handleSelect: (value: string[]) => void;
}

const { Option } = Select;

export const CommonSelect = ({ optionSelect, handleSelect }: CommonSelectProps) => {
  const { status, selectAll } = useParseParams();

  const optionSelectValue = optionSelect.map((item) => item.value);

  const [selectValue, setSelectValue] = useState<string[]>([]);

  const [isCheckSelectAll, setIsCheckSelectAll] = useState(false);

  const handleChange = (checked: boolean, value: string) => {
    const selectedValues = [...selectValue];
    if (!checked) {
      const idx = selectedValues.findIndex((ele) => ele === value);
      if (idx < 0) return;
      selectedValues.splice(idx, 1);
    } else {
      selectedValues.push(value);
    }

    setSelectValue(selectedValues);
    handleSelect(selectedValues);
  };

  const handleSelectAll = () => {
    setIsCheckSelectAll(!isCheckSelectAll);
    setSelectValue(!isCheckSelectAll ? optionSelectValue : []);
    handleSelect(!isCheckSelectAll ? optionSelectValue : []);
  };

  useEffect(() => {
    setIsCheckSelectAll(selectValue.length === optionSelect.length);

    if (status === 'active') {
      setSelectValue(['1']);
    } else if (status === 'disabled') {
      setSelectValue(['0']);
    } else if (status === 'active,disabled') {
      setSelectValue(['1', '0']);
    } else {
      setSelectValue([]);
    }
  }, [optionSelect.length, selectAll, selectValue.length, status]);

  return (
    <>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={selectValue.length === optionSelect.length ? 'All' : 'Select'}
        tagRender={(props) => (
          <span className={styles.tagPreview}>
            {props.label}
            <CloseIcon width={10} height={10} onClick={() => handleChange(false, props.value)} />
          </span>
        )}
        autoClearSearchValue
        showArrow
        showSearch={false}
        value={selectValue.length === optionSelect.length ? undefined : selectValue}
        maxTagCount="responsive"
        className={clsx(
          styles.commonSelect,
          selectValue.length === optionSelect.length ? styles.placeHolderAll : styles.placeHolderSelect,
        )}
        dropdownStyle={{ zIndex: 10 }}
      >
        <Option value="select-all">
          <Checkbox
            onChange={handleSelectAll}
            checked={selectValue.length === optionSelect?.length}
            className={styles.selectItem}
          >
            Select All
          </Checkbox>
        </Option>
        {optionSelect.map((item) => (
          <Option value={item.value} key={item.value}>
            <Checkbox
              checked={selectValue.includes(`${item.value}`)}
              onChange={(e) => handleChange(e.target.checked, item.value)}
              className={styles.selectItem}
            >
              {item.label}
            </Checkbox>
          </Option>
        ))}
      </Select>
    </>
  );
};
