import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import clsx from 'clsx';

import styles from './import-batch.module.scss';
import './dragger-upload.styles.scss';
import { csvMineType, InfoFileType, SIZE, xlsxMineType } from './popup-import.consts';
import { formatDecimalNumber } from 'utils/helper';
import { DeleteSolidIcon, FileCsvIcon, FileUploadIcon, FileXlsxIcon, InfoCircleFilledIcon } from 'components/icons';

interface DraggerUploadProps extends UploadProps {
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  errorImport: boolean;
  setErrorImport: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  setOpenConfirmDelete: Dispatch<SetStateAction<boolean>>;
}

const DraggerUpload = ({
  fileList,
  setFileList,
  errorImport,
  setErrorImport,
  errorMessage,
  setOpenConfirmDelete,
  ...props
}: DraggerUploadProps) => {
  const onChange = ({ file, fileList }: InfoFileType) => {
    const csvOrExcelFileType = [...csvMineType, xlsxMineType];
    if (
      (file.status === 'done' || file.status === 'uploading') &&
      file.type &&
      csvOrExcelFileType.includes(file.type) &&
      fileList.length < 2
    ) {
      setFileList(fileList);
    }
  };

  const onRemove = () => {
    setOpenConfirmDelete(true);
  };

  const convertFileSizeToMB = (fileSize: number | undefined) => {
    return fileSize ? formatDecimalNumber(fileSize / Math.pow(SIZE, 2), 5) : '';
  };

  const renderErrorMessage = () => {
    const file = fileList[0];
    const fileSizeMB = convertFileSizeToMB(file.size);

    if (Number(fileSizeMB) > 1) {
      return (
        <div className="rounded py-2 px-3 bg-FF424F14 mt-3">
          <div className="flex flex-row items-center">
            <InfoCircleFilledIcon fill="#FF424F" />
            <div className="ml-3 text-14 font-regular text-FF424F leading-150">
              {`Error uploading file: File is too big (${convertFileSizeToMB(
                fileList[0].size,
              )} MB). Max file size: 1 MB`}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="rounded py-2 px-3 bg-FF424F14 mt-3">
        <div className="flex items-start">
          <div>
            <InfoCircleFilledIcon fill="#FF424F" />
          </div>
          <div className="text-14 font-regular text-FF424F ml-2 overflow-hidden leading-150">{errorMessage}</div>
        </div>
      </div>
    );
  };

  const renderItemFile = useCallback(
    (originNode: React.ReactElement, file: UploadFile, currentFileList: UploadFile[]) => {
      const fileSizeMB = convertFileSizeToMB(file.size);
      const FileIcon = file.type === xlsxMineType ? FileXlsxIcon : FileCsvIcon;

      setErrorImport(false);
      if (file.status === 'error' || Number(fileSizeMB) > 1) {
        setErrorImport(true);
      }
      setFileList(currentFileList);

      return (
        !!fileList.length && (
          <div className={clsx(styles.itemFile, (errorImport || errorMessage) && styles.itemFileError)}>
            <div className={styles.wrapperFileIcon}>
              <FileIcon className={styles.fileIcon} />
            </div>
            <div className={clsx(styles.minWFull, 'file-list-container')}>
              <div>{originNode}</div>
              <span className={clsx(styles.textBW4)}>{fileSizeMB} MB</span>
            </div>
          </div>
        )
      );
    },
    [errorImport, errorMessage, fileList.length, setErrorImport, setFileList],
  );

  return (
    <div className="custom-import">
      <Upload.Dragger
        {...props}
        name="file"
        accept=".csv, .xlsx"
        className={fileList.length > 0 ? styles.draggerUploadDisabled : styles.draggerUpload}
        fileList={fileList}
        onChange={onChange}
        onRemove={onRemove}
        itemRender={renderItemFile}
        showUploadList={{
          showRemoveIcon: true,
          removeIcon: <DeleteSolidIcon />,
        }}
      >
        <p className={clsx(styles.textCenter, 'ant-upload-drag-icon')}>
          <FileUploadIcon fill={fileList.length > 0 ? '#00000026' : '#94CDFF'} />
        </p>
        <p className={clsx(styles.textCenter, 'ant-upload-text')}>
          <span className={clsx(fileList.length > 0 ? styles.text00000026 : styles.textBW2)}>
            Drop your files (.csv or .xlsx) here
          </span>
        </p>
        <p className={clsx(fileList.length > 0 ? styles.text00000026 : styles.textBW3, styles.pb1)}>or</p>
        <p className="ant-upload-hint">
          <span
            className={clsx(
              `${fileList.length > 0 ? styles.text00000026Opacity : styles.text3698FC}`,
              'font-medium text-14',
            )}
          >
            Browse for Files...
          </span>
        </p>
      </Upload.Dragger>
      {(errorImport || errorMessage) && !!fileList.length && renderErrorMessage()}
    </div>
  );
};

export default DraggerUpload;
