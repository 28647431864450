import {
  ChangeStatusResponseType,
  IChangePassword,
  InternalUserTableParams,
  ListInternalUserResponseType,
} from 'types/internal-user/internal-user-table.type';
import axiosClient from 'utils/axios-client';

export const internalUserApi = {
  getListTable(params: InternalUserTableParams): Promise<ListInternalUserResponseType> {
    return axiosClient.get('/user', {
      params: params,
    });
  },

  changeStatusUser(id: number, status: number): Promise<ChangeStatusResponseType> {
    return axiosClient.patch(`/user/${id}`, { status });
  },

  changePassword(changePasswordParams: IChangePassword): Promise<ChangeStatusResponseType> {
    return axiosClient.patch('/user/change-password', changePasswordParams);
  },
};
