import { useCallback, useState } from 'react';
import { Button, Dropdown, ModalProps, notification, UploadFile } from 'antd';
import * as XLSX from 'xlsx';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import dayjs from 'dayjs';
import {
  CheckCircleIcon,
  CloseIcon,
  DownloadIcon,
  FileCsvIcon,
  FileXlsxIcon,
  InfoCircleFilledIcon,
} from 'components/icons';
import styles from './import-batch.module.scss';
import DraggerUpload from './dragger-upload';
import PopupDeleteConfirm from './popup-delete-confirm';
import { ModalBase } from 'components/modal';
import { csvMineType, messageError, xlsxMineType } from './popup-import.consts';
import { UserType } from 'services/api-external-user.type';
import { isNil, isEmpty } from 'utils/helper';
import { PATH_EXTERNAL_USER, PATH_INTERNAL_USER } from 'navigation/routes.path';
import prohibitedDomains from './prohibited-domains';

interface ImportBatchModalProps extends ModalProps {
  togglePopup: () => void;
  onImportFile: (colsCsv: any) => any;
  companyDomain?: string;
  isAcceptPublicEmail?: boolean;
  isAcceptProhibitedEmail?: boolean;
  typeUser?: 'internal' | 'external';
}

const ImportBatchModal = ({ companyDomain, togglePopup, onImportFile, typeUser, ...props }: ImportBatchModalProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errorImport, setErrorImport] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [colsFileCsvOrXlsx, setColFileCsvOrXlsx] = useState<UserType[]>([]);

  const router = useHistory();

  const onSubmitImport = async () => {
    setLoading(true);
    try {
      if (!!fileList.length && fileList[0]?.originFileObj) {
        const res = await onImportFile(colsFileCsvOrXlsx);
        if (!res) {
          throw new Error(messageError.undefinedField);
        }
        notification.success({
          message: 'Import successfully.',
          description: `${res.data?.totalSuccess || 0} records have been successfully uploaded.` +
            ((res.data?.totalError > 0) ? `\n${res.data?.totalError || 0} records have details` : ''),
          placement: 'bottomRight',
          closeIcon: <CloseIcon fill="#ffffff" width="12" height="12" />,
          icon: <CheckCircleIcon />,
          className: styles.importBatchSuccessNoti,
          onClick: (res.data?.totalError > 0) ? () => {
            notification.info({
              message: 'Details.',
              description: res.data?.dataError.map((v: any) => JSON.stringify(v)).join('\n'),
              placement: 'bottomRight',
              closeIcon: <CloseIcon fill="#ffffff" width="12" height="12" />,
              icon: <CheckCircleIcon />,
              className: styles.importBatchFailNoti,
              duration: 0,
            });
          } : undefined,
          //duration: (res.data?.totalError > 0) ? 0 : undefined,
        });
        router.replace(typeUser === 'external' ? PATH_EXTERNAL_USER : PATH_INTERNAL_USER);
        toggleImportBatchPopup();
      }
    } catch (err: any) {
      // console.log('err', err);
      if (err?.response?.data?.message) {
        notification.error({
          message: 'Error',
          description: err.response.data.message,
          placement: 'bottomRight',
          closeIcon: <CloseIcon fill="#ffffff" width="12" height="12" />,
          icon: <CheckCircleIcon />,
          className: styles.importBatchFailNoti,
        });
        toggleImportBatchPopup();
      }
    }
    setLoading(false);
  };

  const onBeforeUpload = async (file: File) => {
    const detected_prohibited_domains: any = [];
    if (file.type === xlsxMineType) {
      const rows = (await readXlsxFile(file)) as string[][];

      if (rows[0].length !== 6) {
        setErrorMessage(messageError.notMatchTemplateFile);
        return;
      }

      const [email, name, expiryDate, displayName, password, welcomeEmail] = rows[0];
      //console.log('rows[0]', rows[0]);
      if (
        email?.trim()?.toLowerCase() !== 'email' ||
        name?.trim()?.toLowerCase() !== 'user name' ||
        expiryDate?.trim()?.toLowerCase() !== 'expiry date' ||
        displayName?.trim()?.toLowerCase() !== 'display name' ||
        password?.trim()?.toLowerCase() !== 'password' ||
        welcomeEmail?.trim()?.toLowerCase() !== 'welcome email'
      ) {
        setErrorMessage(messageError.notMatchTemplateFile);
        return;
      }

      let countEmailDuplicated = 0;
      let listDuplicatedEmail = '';
      const hashMap: { [key: string]: number } = {};
      const results: UserType[] = [];

      while (!isNil(rows[rows.length - 1]) && !rows[rows.length - 1].length) {
        rows.pop();
      }
      if (rows.slice(1).length === 0) {
        setErrorMessage(messageError.notMatchTemplateFile);
        return;
      }
      let notComplyEmailList: any[] = [];

      rows.slice(1).forEach(([email, name, expiryDate, displayName, password, welcomeEmail]) => {
        if (isEmpty(email) || isEmpty(name) || dayjs(expiryDate).format('DD/MM/YYYY') === 'Invalid Date') {
          setErrorMessage(messageError.notMatchTemplateFile);
          return;
        }
        hashMap[email] = !hashMap[email] ? 1 : hashMap[email] + 1;

        if (hashMap[email] > 1) {
          countEmailDuplicated++;
        }

        if (hashMap[email] === 2 && countEmailDuplicated !== 9) {
          listDuplicatedEmail += !listDuplicatedEmail.length ? `${email}` : `, ${email}`;
        }

        const [domain] = email.split('@').reverse();
        if (!companyDomain) {
          //skip for internal user
        } else if (!props.isAcceptPublicEmail && domain !== companyDomain) {
          notComplyEmailList.push(email);
          setErrorMessage(messageError.notComplyEmail(companyDomain, notComplyEmailList));
          return;
        } else if (!props.isAcceptProhibitedEmail && prohibitedDomains.includes('\n' + domain + '\n')) {
          detected_prohibited_domains.push(domain);
        }

        password = isEmpty(password) ? '' : ('' + password).trim();
        welcomeEmail = isEmpty(welcomeEmail) ? '' : ('' + welcomeEmail).trim();

        if (typeUser === 'external') {
          results.push({
            email,
            username: name,
            expired_date: dayjs(expiryDate).format('DD/MM/YYYY'),
            name: displayName || '',
            password,
            sendWelcomeEmail: (welcomeEmail === 'no') ? 'false' : '',
          });
        } else {
          results.push({
            email,
            name,
            expired_date: dayjs(expiryDate).format('DD/MM/YYYY'),
            password,
            sendWelcomeEmail: (welcomeEmail === 'no') ? 'false' : '',
          });
        }
      });
      if (detected_prohibited_domains.length > 0) {
        setErrorMessage(messageError.domainsNotAllwed(detected_prohibited_domains));
      }

      if (countEmailDuplicated > 0) {
        setErrorMessage(messageError.duplicatedEmail(listDuplicatedEmail));
        return;
      }
      setColFileCsvOrXlsx(results);
    } else if (csvMineType.includes(file.type)) {
      const reader = new FileReader();

      reader.readAsText(file);
      reader.onload = function (e) {
        const data = e.target?.result as string;
        const newData = data.split('\r\n');

        if (newData[0].split(',').length !== 6) {
          setErrorMessage(messageError.notMatchTemplateFile);
          return;
        }
        const [email, name, expiryDate, displayName, password, welcomeEmail] = newData[0].split(',');
        if (
          email?.trim()?.toLowerCase() !== 'email' ||
          name?.trim()?.toLowerCase() !== 'user name' ||
          expiryDate?.trim()?.toLowerCase() !== 'expiry date' ||
          displayName?.trim()?.toLowerCase() !== 'display name' ||
          password?.trim()?.toLowerCase() !== 'password' ||
          welcomeEmail?.trim()?.toLowerCase() !== 'welcome email'
        ) {
          setErrorMessage(messageError.notMatchTemplateFile);
          return;
        }

        let countEmailDuplicated = 0;
        let listDuplicatedEmail = '';
        const hashMap: { [key: string]: number } = {};
        const results: UserType[] = [];

        while (!isNil(newData[newData.length - 1]) && !newData[newData.length - 1].length) {
          newData.pop();
        }

        if (newData.slice(1).length === 0) {
          setErrorMessage(messageError.notMatchTemplateFile);
          return;
        }

        let notComplyEmailList: any[] = [];

        newData.slice(1).forEach((item) => {
          let [email, name, expiryDate, displayName, password, welcomeEmail] = item.split(',');
          if (isEmpty(email) || isEmpty(name) || dayjs(expiryDate).format('DD/MM/YYYY') === 'Invalid Date') {
            setErrorMessage(messageError.notMatchTemplateFile);
            return;
          }
          hashMap[email] = !hashMap[email] ? 1 : hashMap[email] + 1;

          if (hashMap[email] > 1) {
            countEmailDuplicated++;
          }

          if (hashMap[email] === 2 && countEmailDuplicated !== 9) {
            listDuplicatedEmail += !listDuplicatedEmail.length ? `${email}` : `, ${email}`;
          }

          const [domain] = email.split('@').reverse();
          if (!companyDomain) {
            //skip for internal user
          } else if (!props.isAcceptPublicEmail && domain !== companyDomain) {
            notComplyEmailList.push(email);
            setErrorMessage(messageError.notComplyEmail(companyDomain, notComplyEmailList));
            return;
          } else if (!props.isAcceptProhibitedEmail && prohibitedDomains.includes('\n' + domain + '\n')) {
            detected_prohibited_domains.push(domain);
          }

          password = isEmpty(password) ? '' : ('' + password).trim();
          welcomeEmail = isEmpty(welcomeEmail) ? '' : ('' + welcomeEmail).trim();

          if (typeUser === 'external') {
            results.push({
              email,
              username: name,
              expired_date: dayjs(expiryDate).format('DD/MM/YYYY'),
              name: displayName || '',
              password,
              sendWelcomeEmail: (welcomeEmail === 'no') ? 'false' : '',
            });
          } else {
            results.push({
              email,
              name: name,
              expired_date: dayjs(expiryDate).format('DD/MM/YYYY'),
              password,
              sendWelcomeEmail: (welcomeEmail === 'no') ? 'false' : '',
            });
          }
        });
        if (detected_prohibited_domains.length > 0) {
          setErrorMessage(messageError.domainsNotAllwed(detected_prohibited_domains));
        }
        if (countEmailDuplicated > 0) {
          setErrorMessage(messageError.duplicatedEmail(listDuplicatedEmail));
          return;
        }
        setColFileCsvOrXlsx(results);
      };
    }
  };

  const downloadFileCSV = useCallback(() => {
    const csvContent =
      'data:text/csv;charset=utf-8,Email,User name,Expiry date,Display name,Password,Welcome email\r\nexample@blueonion.today,Elsa Pau,09/29/2022,Elsa Pau,,';
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'example-template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(encodedUri), 5000);
  }, []);

  const downloadFileXLSX = useCallback(() => {
    const xlsxRows = [{ Email: 'example@blueonion.today', 'User name': 'Elsa Pau', 'Expiry date': '09/29/2022', 'Display name': 'Elsa Pau', 'Password': '', 'Welcome email': '' }];

    const worksheet = XLSX.utils.json_to_sheet(xlsxRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, 'example-template.xlsx');
  }, []);

  const toggleImportBatchPopup = () => {
    if (isLoading) return;
    setFileList([]);
    togglePopup();
  };

  const customizeFooter = () => {
    return [
      <Button
        key="back"
        className={clsx(styles.buttonCancel, styles.mt9, styles.mb7)}
        onClick={toggleImportBatchPopup}
        disabled={isLoading}
      >
        Cancel
      </Button>,
      <Button
        key="submit"
        className={clsx(styles.buttonImport, styles.ml3, styles.mt9, styles.mb7, styles.mr7)}
        disabled={errorImport || !!errorMessage || !(!!fileList.length && fileList[0]?.originFileObj)}
        onClick={onSubmitImport}
        loading={isLoading}
      >
        Import
      </Button>,
    ];
  };

  return (
    <>
      <ModalBase
        {...props}
        visible={true}
        footer={customizeFooter()}
        title="IMPORT BATCH USER"
        centered
        onCancel={toggleImportBatchPopup}
      >
        <div className={clsx(styles.noticeInfo, styles.flex)}>
          <div className={styles.flex}>
            <InfoCircleFilledIcon />
            <div className={styles.noticeText}>
              You are only allowed to import 1 .csv or 1 .xlsx file at a time to the database. Maximum upload file size:
              1.0 MB. Only files with the comply template are allowed.
            </div>
          </div>
          <Dropdown
            overlay={
              <div className={styles.dropdownDownloadTemplate}>
                <div className={clsx(styles.pointer, styles.fileCSV)} onClick={downloadFileCSV}>
                  <FileCsvIcon />
                  <div>CSV Template</div>
                  <DownloadIcon />
                </div>
                <div className={clsx(styles.pointer, styles.fileXLSX)} onClick={downloadFileXLSX}>
                  <FileXlsxIcon />
                  <div>XLSX Template</div>
                  <DownloadIcon />
                </div>
              </div>
            }
            trigger={['click']}
          >
            <div className={styles.pointer}>
              <a className={styles.textDownload}>Download Template</a>
            </div>
          </Dropdown>
        </div>
        <DraggerUpload
          fileList={fileList}
          setFileList={setFileList}
          errorImport={errorImport}
          setErrorImport={setErrorImport}
          errorMessage={errorMessage}
          setOpenConfirmDelete={setOpenConfirmDelete}
          beforeUpload={onBeforeUpload}
        />
      </ModalBase>
      {openConfirmDelete && (
        <PopupDeleteConfirm
          fileName={!!fileList.length ? fileList[0].name : ''}
          setOpenConfirmDelete={setOpenConfirmDelete}
          setFileList={setFileList}
          setErrorImport={setErrorImport}
          setErrorMessage={setErrorMessage}
        />
      )}
    </>
  );
};

export default ImportBatchModal;
