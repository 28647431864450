import { Col, Divider, Form, Input, InputNumber, message, Popover, Row } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import { CommonButton } from 'components/common-button';
import { TextInput } from 'components/form-control';
import DateInput from 'components/form-control/date-input';
import styles from './change-plan-modal.module.scss';
import SwitchInput from 'components/switch';
import LabelRequire from 'components/label-require';
import { externalUserApi } from 'services/api-external-user.services';
import { changeRestrictionParams } from 'services/api-external-user.type';
import KioskTableEditor from './kiosk-table-editor';
import { getAllConcurrentProduct, getConcurrentMax, patchMultipleConcurrentMax, putBlueOnionPlanExpiryDate, putCompanyKioskTable, putMembersKioskTable, putMembersRoles, putUserRoles } from 'services/api-permission.service';
import { rolePart, namedMapper, roleDefault, ROLES_NAME, start_end_date_object, rolePartByHubRole, roleType, namedMappingRev } from './role-tool';
import { UpdateRolesDto } from 'services/api-permission.type';
import { isSysAdmin } from 'redux/has-permission';
import { BlueOnionHubRole, ChangePlanFormProps, ChangePlanNewData } from './change-plan-form.type';
import { ArrowLeftTailIcon } from 'components/icons';
import { useHistory } from 'react-router-dom';
import { UpdateBlueOnionHubPlanExpiryDate } from './blueonionhub-data';
import { HubsRoleRow } from './hubs-role-row';
import { useQuery } from 'react-query';
import { UpdateMaxDeviceParamDto } from '../company-user-modal/company-user-modal.const';
import dayjs from 'dayjs';
import { warningText, isDateInverted } from './warning-tool';
import { ExpandIcon } from './expand-icon';
import { KioskTableData } from './kiosk-data';
import { RegionEditor } from './region-editor';
import { AssetEditor } from './asset-editor';


const userRoleSet = {
  crm: ['crmAdminRole', 'crmApproverRole', 'crmEditorRole', 'crmViewerRole'],
  stewardship: ['stewardshipAdmin', 'stewardshipApprover', 'stewardshipEditor', 'stewardshipViewer'],
  scenario: ['scenarioAdmin', 'scenarioEditor', 'scenarioViewer'],
};

const allUserRoles = Object.values(userRoleSet).flat();

function isSameDto(o1: any, o2: any): boolean {
  return (
    (o1.start_date || null) === (o2.start_date || null) &&
    (o1.end_date || null) === (o2.end_date || null) &&
    !!o1.is_allow === !!o2.is_allow &&
    !!o1.is_prohibit === !!o2.is_prohibit &&
    !!o1.is_assign_other === !!o2.is_assign_other
  );
}

function isRoleChanged(dto: UpdateRolesDto, blueOnionHubRole: any) {
  if (!blueOnionHubRole) return true;

  var r0: UpdateRolesDto;
  for (var i in blueOnionHubRole) {
    r0 = blueOnionHubRole[i];
    if (r0.role_id == dto.role_id) return !isSameDto(r0, dto);  // eslint-disable-line eqeqeq
  }
  return true;
}

//check empty kiosk
function isKioskExist(productKiosk: boolean, kioskTableData: KioskTableData | null | undefined) {
  if (productKiosk) {
    if (kioskTableData?.tableDataMapping) {
      for (let i in kioskTableData?.tableDataMapping) {
        let v = kioskTableData?.tableDataMapping[i];
        if (v.is_allow || v.is_prohibit || v.is_assign_other) {
          //console.log('isKioskExist true');
          return true;
        }
      }
    }
  }
  return false;
}

//return item name if changed
function isDtoChanged(dto: any, oldData: any) {
  for (var i in dto) {
    var v1 = dto[i];
    var v2 = oldData[i];
    if (v1) {
      if (v1 != v2) return i;  // eslint-disable-line eqeqeq
    }
    else {
      if (v2) return i;
    }
  }
  return false;
}

function isFormItemChanged(formValue: any, blueOnionHubRole: BlueOnionHubRole | undefined, keyName: string) {
  let oldRole = (blueOnionHubRole as any)?.[keyName];

  if (!!formValue[keyName] !== !!oldRole?.is_allow) return true;

  if ((formValue?.[`${keyName}_start_date`]?.format?.('YYYY-MM-DD') || null) !== (oldRole?.start_date || null)) return true;
  if ((formValue?.[`${keyName}_end_date`]?.format?.('YYYY-MM-DD') || null) !== (oldRole?.end_date || null)) return true;

  return false;
}

function getDateString(dt: Date): string | null {
  var mntDate = dt ? moment(dt) : null;
  return mntDate?.isValid() ? mntDate.format('YYYY-MM-DD') : null;
}

function ChangePlanForm({ setVisible, companyDetails, memberDetails, userDetails, parentDetails, listCompanyRoles, kioskTableData, refetch, onUpdateData, ...props }: ChangePlanFormProps) {
  const [form] = Form.useForm();
  const router = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [ValueAllSwitchBlueOnionHub, setValueAllSwitchBlueOnionHub] = useState({
    ...roleDefault,
  });

  const [isolatedRole, setIsolatedRole] = useState(false);

  const [basExpand, setBasExpand] = useState(true);

  const [basStore, setBasStore] = useState({});
  const [planTag, setPlanTag] = useState('module');

  const keyStr = companyDetails ? ('company' + companyDetails.id) : ('member' + memberDetails?.id);

  //for the Form.isFieldsTouched() not work well, do it manually
  //  https://github.com/ant-design/ant-design/issues/33991

  const [fieldsTouchedStatus, setFieldsTouchedStatus] = useState(false);

  const regionRef: any = useRef();
  const assetRef: any = useRef();

  function resetFieldsTouchedStatus() {
    setFieldsTouchedStatus(false);
  }

  //kisok data status

  const [kioskUpdated, setKioskUpdated] = useState(false);

  async function onKioskUpdateData(newTableDataMapping: any) {
    await props.onKioskUpdateData?.({ ...kioskTableData, tableDataMapping: newTableDataMapping });
    setKioskUpdated(true);
  }

  function resetKiosk() {
    setKioskUpdated(false);
  }

  //console.log('expired_date', memberDetails?.expired_date);
  //console.log('plan_start_date', companyDetails?.plan_start_date);
  //console.log('useEffect');

  const blueOnionHubRole = (companyDetails || memberDetails)?.blueOnionHubRole;

  const blueOnionHubRoleAllowed: BlueOnionHubRole = {};
  var brKey: keyof (roleType);
  for (brKey in blueOnionHubRole) {
    if (blueOnionHubRole?.[brKey]?.is_allow) blueOnionHubRoleAllowed[brKey] = blueOnionHubRole[brKey];
  }

  useEffect(() => {
    var mnt_expired = moment(memberDetails?.expired_date || userDetails?.expired_date || companyDetails?.expired_date || null);
    var mnt_start = moment(memberDetails?.start_date || companyDetails?.start_date || null);
    var mnt_plan_start = moment(memberDetails?.plan_start_date || companyDetails?.plan_start_date || null);
    var mnt_plan_end = moment(memberDetails?.plan_end_date || companyDetails?.plan_end_date || null);

    //cooling_start_date may be undefined when creating a new user, set to null to avoid the default today
    var mnt_cooling = moment(memberDetails?.cooling_start_date || null);

    form.setFieldsValue({
      plan_start_date: mnt_plan_start.isValid() ? mnt_plan_start : null,
      plan_end_date: mnt_plan_end.isValid() ? mnt_plan_end : null,

      is_new_member_need_audit: companyDetails?.is_new_member_need_audit,
      is_accept_public_email: companyDetails?.is_accept_public_email,
      is_accept_prohibited_email: companyDetails?.is_accept_prohibited_email,
      company_key: companyDetails?.company_key,
      invitation_code: companyDetails?.invitation_code,

      ...start_end_date_object(blueOnionHubRole),

      ...(blueOnionHubRole ? rolePartByHubRole(blueOnionHubRole) : rolePart(userDetails)),

      report_quota: String(companyDetails?.report_quota),
      number_of_license: String(companyDetails?.number_of_license),

      start_date: mnt_start.isValid() ? mnt_start : null,
      expired_date: mnt_expired.isValid() ? mnt_expired : null,

      is_isolate_role: memberDetails?.is_isolate_role,
      is_admin: memberDetails?.is_admin,
      is_receive_expiry_notify: memberDetails?.is_receive_expiry_notify,
      cooling_start_date: mnt_cooling.isValid() ? mnt_cooling : null,

      notifyMemberExpiry: userDetails?.notifyMemberExpiry,
    });
    setValueAllSwitchBlueOnionHub(
      rolePartByHubRole(blueOnionHubRole),
    );

    setIsolatedRole(!!memberDetails?.is_isolate_role);

  }, [
    companyDetails,
    memberDetails,
    userDetails,

    companyDetails?.number_of_license,
    companyDetails?.report_quota,

    memberDetails?.expired_date,
    memberDetails?.start_date,
    memberDetails?.plan_start_date,
    memberDetails?.plan_end_date,

    memberDetails?.is_isolate_role,
    memberDetails?.is_admin,
    memberDetails?.is_receive_expiry_notify,
    memberDetails?.cooling_start_date,

    userDetails?.expired_date,

    companyDetails?.expired_date,
    companyDetails?.start_date,
    companyDetails?.plan_start_date,
    companyDetails?.plan_end_date,

    //...rolePartArray(companyDetails),   //don't use spread
    //! Warn Message from console:
    //  React Hook useEffect has a spread element in its dependency array. 
    //    This means we can't statically verify whether you've passed the correct dependencies

    blueOnionHubRole,
    blueOnionHubRole?.fundHub,
    blueOnionHubRole?.portfolioHub,
    blueOnionHubRole?.companyHub,
    blueOnionHubRole?.climateHub,
    blueOnionHubRole?.impactHub,
    blueOnionHubRole?.kioskHomepage,
    blueOnionHubRole?.kioskFundhub,
    blueOnionHubRole?.kioskPortfoliohub,
    blueOnionHubRole?.fundSearch,
    blueOnionHubRole?.fundHubScreener,
    blueOnionHubRole?.companyHubScreener,
    blueOnionHubRole?.productKiosk,
    blueOnionHubRole?.portfolioHubPlus,

    blueOnionHubRole?.stewardshipHub,

    blueOnionHubRole?.stpReviewPro,
    blueOnionHubRole?.stpDueDVault,
    blueOnionHubRole?.stpFundConnect,
    blueOnionHubRole?.stpPRIMonitor,

    // blueOnionHubRole?.stewardshipICBC,
    // blueOnionHubRole?.stewardshipSCB,
    // blueOnionHubRole?.stewardshipCCBA,

    blueOnionHubRole?.stewardshipAdmin,
    blueOnionHubRole?.stewardshipApprover,
    blueOnionHubRole?.stewardshipEditor,
    blueOnionHubRole?.stewardshipViewer,

    blueOnionHubRole?.crmAdminRole,
    blueOnionHubRole?.crmApproverRole,
    blueOnionHubRole?.crmEditorRole,
    blueOnionHubRole?.crmViewerRole,
    blueOnionHubRole?.crmMasType,
    blueOnionHubRole?.crmSfcType,

    blueOnionHubRole?.crmScenario,
    blueOnionHubRole?.scenarioAdmin,
    blueOnionHubRole?.scenarioEditor,
    blueOnionHubRole?.scenarioViewer,

    blueOnionHubRole?.premiumTcfd,
    blueOnionHubRole?.premiumSfdr,
    blueOnionHubRole?.sfdrPortfolio,
    blueOnionHubRole?.premiumUnSdg,
    blueOnionHubRole?.premiumSasb,

    blueOnionHubRole?.companyComparison,
    blueOnionHubRole?.fundComparison,
    blueOnionHubRole?.portfolioComparison,

    userDetails?.notifyNewMemberAuditor,
    userDetails?.notifyMemberExpiry,
    userDetails?.notifyCoolingPeriod,

    form,
  ]);

  const isEverySwitchFalse = useMemo(
    //() => Object.values(ValueAllSwitchBlueOnionHub).every((item) => !item),
    () => {
      var obj = ValueAllSwitchBlueOnionHub as any;
      for (var i in obj) {
        if (obj[i] && i !== 'productBas' && i !== 'productKiosk') {
          //console.log('isKioskExist false, hubs');
          return false;
        }
      }

      if (isKioskExist(obj.productKiosk, kioskTableData)) {
        //console.log('isKioskExist false, kiosk');
        return false;
      }
      //console.log('isKioskExist true');
      return true;
    },
    [ValueAllSwitchBlueOnionHub, kioskTableData],
  );

  //load Concurrent Product
  const {
    data: listAllConcurrentProduct,
  } = useQuery(
    'getAllConcurrentProduct',
    () =>
      getAllConcurrentProduct(),
  );
  //console.log('listAllConcurrentProduct', listAllConcurrentProduct);

  //load Concurrent Max of an account
  const {
    data: listConcurrentMax,
  } = useQuery(
    [
      'getConcurrentMax',
      memberDetails,
      memberDetails?.account_id,
      memberDetails?.handleKey,
    ],
    () => {
      if (!memberDetails?.account_id) return null;

      return getConcurrentMax(memberDetails?.account_id);
    },
  );
  //console.log('listConcurrentMax', memberDetails?.account_id, listConcurrentMax);


  const [concurrent, setConcurrent] = useState('');

  //check loading state to load once to ui
  const [concurrentState, setConcurrentState] = useState(0);

  var ccState = (listAllConcurrentProduct ? 1 : 0) + (listConcurrentMax ? 1 : 0);
  if (ccState !== concurrentState) {
    if (listAllConcurrentProduct) {
      var ca: number[] = [];
      listAllConcurrentProduct.forEach((vProduct) => {
        let maxRow = listConcurrentMax?.find((vMax) => vMax.blueonion_product_id === vProduct.id);
        ca.push(((maxRow?.max_device && maxRow?.max_device >= 0) ? maxRow.max_device : 0) || 0);
      });
      var cas = ca.join(',');
      if (cas !== concurrent) setConcurrent(cas);
    }
    setConcurrentState(ccState);
  }

  const [concurrentUpdated, setConcurrentUpdated] = useState(false);

  function resetConcurrent() {
    setConcurrentUpdated(false);
    setConcurrent('');
    setConcurrentState(0);
  }

  const [concurrentPopover, setConcurrentPopover] = useState(false);

  function tryRedirectUrl() {
    //console.log('tryRedirectUrl', props.redirectUrl)
    if (props.redirectUrl) {
      setTimeout(function () { if (props.redirectUrl) router.replace(props.redirectUrl); }, 200);
    }
  }

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    resetFieldsTouchedStatus();
    resetKiosk();
    tryRedirectUrl();
    resetConcurrent();
  };

  const onFinish = async (value: any) => {
    setIsLoading(true);

    //check duplicate company key
    if (companyDetails && value.company_key && companyDetails.company_key !== value.company_key) {
      const ret = await externalUserApi.isCompanyKeyExist(value.company_key);
      if (ret?.data?.success !== false) {
        setIsLoading(false);

        form.setFields([
          { name: 'company_key', errors: ['Already in use, try other key.'] },
        ]);
        //setTimeout(() => { form.scrollToField('company_key'); }, 1500);   //not work?

        return;
      }
    }

    //check duplicate invitation code
    if (companyDetails && value.invitation_code && companyDetails.invitation_code !== value.invitation_code) {
      const ret = await externalUserApi.isInvitationCodeExist(value.invitation_code);
      if (ret?.data?.success !== false) {
        setIsLoading(false);

        form.setFields([
          { name: 'invitation_code', errors: ['Already in use, try other key.'] },
        ]);
        //setTimeout(() => { form.scrollToField('invitation_code'); }, 1500);   //not work?

        return;
      }
    }

    //clear all sub if climate is closed
    if (!value.climateHub) {
      value.crmAdminRole = false;
      value.crmApproverRole = false;
      value.crmEditorRole = false;
      value.crmViewerRole = false;
      value.crmMasType = false;
      value.crmSfcType = false;
    }
    if (!value.stewardshipHub) {

      value.stpReviewPro = false;
      value.stpDueDVault = false;
      value.stpFundConnect = false;
      value.stpPRIMonitor = false;

      // value.stewardshipICBC = false;
      // value.stewardshipSCB = false;
      // value.stewardshipCCBA = false;

      value.stewardshipAdmin = false;
      value.stewardshipApprover = false;
      value.stewardshipEditor = false;
      value.stewardshipViewer = false;
    }
    if (!value.crmScenario) {
      value.scenarioAdmin = false;
      value.scenarioEditor = false;
      value.scenarioViewer = false;
    }

    // save regoin
    regionRef.current.saveRegion(value);
    assetRef.current.saveAsset(value);

    const updateBlueOnionHubPlanExpiryDate: UpdateBlueOnionHubPlanExpiryDate[] = [];
    // console.log('h');
    const arrRee = listCompanyRoles?.data.blueonionHubRoles.reduce((arr, cur) => {
      namedMapper.forEach((item) => {
        let keyName = Object.keys(item)[0];
        if (cur.name === Object.values(item)[0]) {
          if (value[`${Object.keys(item)}`]) {
            arr.push(Number(cur.id));
          }

          if (isFormItemChanged(value, blueOnionHubRole, keyName)) {
            const mappedName = `${Object.keys(item)}`;
            updateBlueOnionHubPlanExpiryDate.push({
              'role_id': cur.id,
              'start_date': value?.[`${mappedName}_start_date`]?.format?.('YYYY-MM-DD') || null,
              'end_date': value?.[`${mappedName}_end_date`]?.format?.('YYYY-MM-DD') || null,
              'is_allow': value[`${Object.keys(item)}`] ? 1 : 0,
            });
          }
        }
      });
      return arr;
    }, [] as Array<number>) || [] as Array<number>;

    var mapRole: { [key: string]: string } = {};   //map name to id

    //check empty kiosk
    var kioskExist = isKioskExist(value.productKiosk, kioskTableData);

    const arrRee2 = listCompanyRoles?.data.blueonionOtherRoles.reduce((arr, cur) => {
      mapRole[cur.name] = cur.id;
      namedMapper.forEach((item) => {
        let keyName = Object.keys(item)[0];
        if (cur.name === Object.values(item)[0]) {
          if (cur.name !== ROLES_NAME.PRODUCT_KIOSK || kioskExist) {
            if (value[`${Object.keys(item)}`]) {
              arr.push(Number(cur.id));
            }

            if (isFormItemChanged(value, blueOnionHubRole, keyName)) {
              const mappedName = `${Object.keys(item)}`;
              updateBlueOnionHubPlanExpiryDate.push({
                'role_id': cur.id,
                'start_date': value?.[`${mappedName}_start_date`]?.format?.('YYYY-MM-DD') || null,
                'end_date': value?.[`${mappedName}_end_date`]?.format?.('YYYY-MM-DD') || null,
                'is_allow': value[`${Object.keys(item)}`] ? 1 : 0,
              });
            }
          }
          else if (cur.name === ROLES_NAME.PRODUCT_KIOSK && !kioskExist) {
            //for PRODUCT_KIOSK
            if (value[`${Object.keys(item)}`] || isFormItemChanged(value, blueOnionHubRole, keyName)) {
              updateBlueOnionHubPlanExpiryDate.push({
                'role_id': cur.id,
                'start_date': '',
                'end_date': '',
                'is_allow': 0,
              });
            }
          }
        }
      });
      return arr;
    }, [] as Array<number>);

    if (arrRee2) arrRee2.forEach(v => arrRee.push(v));
    //console.log('arrRee', arrRee, isEverySwitchFalse);

    //premium roles
    const arrRee3 = listCompanyRoles?.data.blueonionHubPremiumRoles.reduce((arr, cur) => {
      mapRole[cur.name] = cur.id;
      namedMapper.forEach((item) => {
        let keyName = Object.keys(item)[0];
        if (cur.name === Object.values(item)[0]) {
          if (value[`${Object.keys(item)}`]) {
            arr.push(Number(cur.id));
          }

          if (isFormItemChanged(value, blueOnionHubRole, keyName)) {
            const mappedName = `${Object.keys(item)}`;
            updateBlueOnionHubPlanExpiryDate.push({
              'role_id': cur.id,
              'start_date': value?.[`${mappedName}_start_date`]?.format?.('YYYY-MM-DD') || null,
              'end_date': value?.[`${mappedName}_end_date`]?.format?.('YYYY-MM-DD') || null,
              'is_allow': value[`${Object.keys(item)}`] ? 1 : 0,
            });
          }
        }
      });
      return arr;
    }, [] as Array<number>);

    // console.log('updateBlueOnionHubPlanExpiryDate', updateBlueOnionHubPlanExpiryDate);

    if (arrRee3) arrRee3.forEach(v => arrRee.push(v));

    var newData: ChangePlanNewData = {};
    var newCompanyId = '';

    if ((arrRee !== undefined && arrRee.length > 0 && isEverySwitchFalse === false) || userDetails) {
      if (companyDetails) {
        //console.log('plan_start_date', value.plan_start_date, value.plan_start_date?._d);

        var submitData = {
          plan_start_date: value.plan_start_date ? moment(value.plan_start_date._d).format('YYYY-MM-DD') : '',
          plan_end_date: value.plan_end_date ? moment(value.plan_end_date._d).format('YYYY-MM-DD') : '',
          start_date: getDateString(value.start_date?._d) || '',
          expired_date: getDateString(value.expired_date?._d) || '',
          is_new_member_need_audit: !!value.is_new_member_need_audit,
          is_accept_public_email: !!value.is_accept_public_email,
          is_accept_prohibited_email: !!value.is_accept_prohibited_email,
          company_key: value.company_key || null,
          invitation_code: value.invitation_code || null,
          role_ids: arrRee ?? undefined,
          report_quota: Number(value.report_quota),
          number_of_license: Number(value.number_of_license),
        };

        if (companyDetails.id === '') {
          if (props.onStepSave) {
            //console.log('call onStepSave');
            try {
              var newRow = await props.onStepSave(submitData);
            }
            catch (ex) {
              //console.log('exception', ex);
            }
            //console.log('newRow', newRow);
            if (!newRow?.data.id) {
              setIsLoading(false);
              return;
            }
            newCompanyId = newRow?.data.id;
          }
        }

        var changedCompanyConfiguration = isDtoChanged({ ...submitData, role_ids: undefined }, companyDetails);
        //console.log('changedCompanyConfiguration', changedCompanyConfiguration);

        if (changedCompanyConfiguration) {
          await externalUserApi.changeCompanyConfiguration(companyDetails.id || newCompanyId, submitData);
        }

        var changedCompanyRoles = false;
        updateBlueOnionHubPlanExpiryDate.forEach((v) => {
          const dto: UpdateRolesDto = { ...v, role_id: Number(v.role_id), is_allow: !!v.is_allow };
          if (!changedCompanyRoles && isRoleChanged(dto, blueOnionHubRoleAllowed)) changedCompanyRoles = true;
        });

        if (!changedCompanyRoles && updateBlueOnionHubPlanExpiryDate.length !== Object.keys(blueOnionHubRoleAllowed || {}).length) changedCompanyRoles = true;
        //console.log('changedCompanyRoles', changedCompanyRoles);

        if (changedCompanyRoles && updateBlueOnionHubPlanExpiryDate?.length > 0) {
          await putBlueOnionPlanExpiryDate(companyDetails.id || newCompanyId, updateBlueOnionHubPlanExpiryDate, true);
        }
      }
      else if (memberDetails) {
        var changedMembersRoles = false;

        if (value.is_isolate_role) {
          //console.log('arrRee', arrRee);
          //console.log('updateBlueOnionHubPlanExpiryDate', updateBlueOnionHubPlanExpiryDate);
          var updateRolesDto: UpdateRolesDto[] = updateBlueOnionHubPlanExpiryDate.map((v) => {
            const dto: UpdateRolesDto = { ...v, role_id: Number(v.role_id), is_allow: !!v.is_allow };

            if (!changedMembersRoles && isRoleChanged(dto, blueOnionHubRoleAllowed)) changedMembersRoles = true;

            return dto;
          });

          if (!changedMembersRoles && updateRolesDto.length !== Object.keys(blueOnionHubRoleAllowed || {}).length) changedMembersRoles = true;

          //console.log('updateRolesDto', updateRolesDto);
          //console.log('changedMembersRoles', changedMembersRoles);

          if (changedMembersRoles && updateRolesDto?.length > 0) {
            newData.roles = await putMembersRoles(+memberDetails.id, updateRolesDto, true, true);
          }
        }
      }
      else if (userDetails) {
        var updateUserRolesDto: UpdateRolesDto[] = [
          { role_id: +mapRole[ROLES_NAME.NOTIFY_NEW_MEMBER_AUDITOR], is_allow: value.notifyNewMemberAuditor },
          { role_id: +mapRole[ROLES_NAME.NOTIFY_MEMBER_EXPIRY], is_allow: value.notifyMemberExpiry },
          { role_id: +mapRole[ROLES_NAME.NOTIFY_COOLING_PERIOD], is_allow: value.notifyCoolingPeriod },
        ];

        var oldVa = [
          userDetails.notifyNewMemberAuditor,
          userDetails.notifyMemberExpiry,
          userDetails.notifyCoolingPeriod,
        ];

        var changedUserRoles = false;
        updateUserRolesDto.forEach((v, idx) => {
          if (!changedUserRoles && ((v.is_allow ? 1 : 0) ^ (oldVa[idx] ? 1 : 0))) changedUserRoles = true;
        });
        //console.log('updateRolesDto', updateRolesDto);
        //console.log('changedUserRoles', changedUserRoles);

        if (changedUserRoles && updateUserRolesDto?.length > 0) {
          newData.roles = await putUserRoles(+userDetails.id, updateUserRolesDto, true, true);
        }
      }
    }

    if (memberDetails) {
      var mntCooling = value.cooling_start_date?._d ? moment(value.cooling_start_date._d) : null;

      var params: changeRestrictionParams = {
        start_date: getDateString(value.start_date?._d) || '',
        expired_date: getDateString(value.expired_date?._d) || '',
        plan_start_date: value.plan_start_date ? moment(value.plan_start_date._d).format('YYYY-MM-DD') : '',
        plan_end_date: value.plan_end_date ? moment(value.plan_end_date._d).format('YYYY-MM-DD') : '',
        is_isolate_role: !!value.is_isolate_role,
        is_admin: !!value.is_admin,
        is_receive_expiry_notify: value.is_receive_expiry_notify ? 1 : 0,
        cooling_start_date: mntCooling?.isValid() ? mntCooling.format('YYYY-MM-DD') : null,
      };
      // console.log('params: changeRestrictionParams', params);

      var isMemberRestrictionChanged = isDtoChanged(params, memberDetails);
      //console.log('isMemberRestrictionChanged', isMemberRestrictionChanged);

      if (isMemberRestrictionChanged) {
        await externalUserApi.changeMemberRestriction(memberDetails.id, params);
        newData.memberRestriction = true;
      }
    }
    else if (userDetails) {
      var userParams: changeRestrictionParams = {
        expired_date: moment(value.expired_date._d).format('YYYY-MM-DD'),
      };
      //console.log('params: changeRestrictionParams', params);

      if (userDetails.expired_date != userParams.expired_date) {  // eslint-disable-line eqeqeq
        await externalUserApi.changeUserRestriction(userDetails.id, userParams);
        newData.memberRestriction = true;
      }
    }

    //console.log('kioskUpdated', kioskUpdated);
    if (kioskUpdated) {
      if (companyDetails) {
        //console.log(kioskTableData?.tableDataMapping);
        newData.companyPermission = await putCompanyKioskTable(+companyDetails?.id || +newCompanyId, Object.values((value.productKiosk && kioskTableData?.tableDataMapping) || {}));
      }
      else if (memberDetails) {
        newData.memberPermission = await putMembersKioskTable(+memberDetails?.id, Object.values((value.productKiosk && kioskTableData?.tableDataMapping) || {}));
      }
    }

    //update concurrent
    if (concurrentUpdated && memberDetails?.account_id) {
      /*
      const cca = concurrent.split(',');
      var i, imax = cca.length;
      for (i = 0; i < imax; i++) {
        if (!listAllConcurrentProduct?.[i]?.id) continue;
        await patchConcurrentMax(
          +memberDetails?.account_id,
          {
            max_device: parseInt(cca[i]),
            blueonion_product_id: +listAllConcurrentProduct?.[i]?.id,
          },
        );
      }
      */
      const cca = concurrent.split(',');
      var i, imax = cca.length;
      var dataConcurrent: UpdateMaxDeviceParamDto[] = [];
      for (i = 0; i < imax; i++) {
        if (!listAllConcurrentProduct?.[i]?.id) continue;
        dataConcurrent.push({
          max_device: parseInt(cca[i]),
          blueonion_product_id: +listAllConcurrentProduct?.[i]?.id,
          account_id: +(memberDetails?.account_id) || undefined,
        });
      };
      await patchMultipleConcurrentMax(dataConcurrent);
    }

    if (refetch) {
      await refetch?.();
      //setVisible(false);
    }
    else {
      await onUpdateData?.(newData);
    }

    //console.log('setVisible');
    setVisible(false);

    resetFieldsTouchedStatus();
    resetKiosk();
    resetConcurrent();

    setIsLoading(false);

    tryRedirectUrl();
  };

  //console.log('memberDetails', memberDetails);
  //console.log('companyDetails', companyDetails);
  //console.log('isSysAdmin', isSysAdmin());
  //console.log('kioskTableData', kioskTableData);

  function checkGroupSwitch(switchObject: any, groupVarName: string, varList: string[]) {
    if (switchObject[groupVarName]) {
      if (!varList.some((v) => switchObject[v])) {
        switchObject[groupVarName] = false;
        //console.log('switch group off', groupVarName);
      }
    }
    else {
      if (varList.some((v) => switchObject[v])) {
        switchObject[groupVarName] = true;
        //console.log('switch group on', groupVarName);
      }
    }
  }

  const nowDayStr = dayjs().format('YYYY-MM-DD');

  const hubsRoleRowArgs = {
    keyStr,
    blueOnionHubRole,
    blueOnionHubRoleFlag: ValueAllSwitchBlueOnionHub,
    onRowUpdate: (varName: string, newValue: any) => {
      if (!varName.match(/_date$/)) setValueAllSwitchBlueOnionHub((prev) => {
        var newSwitch = { ...prev, [varName]: newValue };
        checkGroupSwitch(newSwitch, 'productBas', ['basFundhouse', 'basJuror', 'basAdmin']);
        return newSwitch;
      });
      setFieldsTouchedStatus(true);
    },
    nowDayStr,
    form,
    parentDetails,
  };

  const onSingleRoleRowUpdate = (varName: string, newValue: any, varList: string[]) => {
    hubsRoleRowArgs.onRowUpdate(varName, newValue);
    if (varList.indexOf(varName) < 0) return;

    if (companyDetails) {
      varList.forEach((v) => { form.setFieldValue(v, newValue); });

      let vals: { [key: string]: any } = {};
      varList.forEach((v) => { vals[v] = newValue; });

      setValueAllSwitchBlueOnionHub((prev) => {
        // console.log('setValueAllSwitchBlueOnionHub for single role for company');
        var newSwitch = { ...prev, ...vals };
        return newSwitch;
      });
    }
    else if (memberDetails) {
      // console.log('member single role');
      varList.forEach((v) => { if (varName !== v) form.setFieldValue(v, (varName === v)); });

      let vals: { [key: string]: any } = {};
      varList.forEach((v) => { if (varName !== v) vals[v] = (varName === v); });

      setValueAllSwitchBlueOnionHub((prev) => {
        // console.log('setValueAllSwitchBlueOnionHub for single role for member');
        var newSwitch = { ...prev, ...vals };
        return newSwitch;
      });
    }
  };

  const onCrmRowUpdate = (varName: string, newValue: any) => {
    return onSingleRoleRowUpdate(varName, newValue, userRoleSet.crm);
  };

  const onStewardshipRowUpdate = (varName: string, newValue: any) => {
    return onSingleRoleRowUpdate(varName, newValue, userRoleSet.stewardship);
  };

  const onScenarioRowUpdate = (varName: string, newValue: any) => {
    return onSingleRoleRowUpdate(varName, newValue, userRoleSet.scenario);
  };

  const crmHubsRoleRowArgs = { ...hubsRoleRowArgs, onRowUpdate: onCrmRowUpdate };
  const stewardshipHubsRoleRowArgs = { ...hubsRoleRowArgs, onRowUpdate: onStewardshipRowUpdate };
  const scenarioHubsRoleRowArgs = { ...hubsRoleRowArgs, onRowUpdate: onScenarioRowUpdate };

  const kioskRowArgs = {
    nowDayStr,
    form,
    parentKioskData: props.parentKioskData,
  };

  //console.log('form.getFieldValue()', form.getFieldValue('start_date'));
  const [, setUpdateWarning] = useState(0);
  const updateWarning = () => { setUpdateWarning((v) => v + 1); };

  //console.log('parentDetails', parentDetails);

  function updateGroupStore(groupValue: boolean, groupStore: any, setGroupStore: Function | any, varList: string[]) {
    if (!groupValue) {
      var data: { [key: string]: any; } = {};
      varList.forEach((v) => {
        data[v] = form.getFieldValue(v);
      });
      setGroupStore(data);
      varList.forEach((v) => {
        hubsRoleRowArgs.onRowUpdate(v, false);
        form.setFieldValue(v, false);
      });
    }
    else {
      varList.forEach((v) => {
        if (v in groupStore) {
          hubsRoleRowArgs.onRowUpdate(v, groupStore[v]);
          form.setFieldValue(v, groupStore[v]);
        }
      });
      setGroupStore({});
    }
  }

  function setSameAsCompany() {
    if (parentDetails && memberDetails) {
      // console.log('parentDetails', parentDetails);
      let changed = 0, changeSet: any = {};
      parentDetails?.companyRole?.forEach((v: any) => {
        if (v.is_allow) {

          let varName = namedMappingRev[v?.role?.name];
          if (varName && !form.getFieldValue(varName) && !allUserRoles.includes(varName)) {
            form.setFieldValue(varName, true);
            // console.log('setSameAsCompany', varName, true);
            changeSet[varName] = true;
            changed++;
          }
        }
      });
      if (changed) {
        setFieldsTouchedStatus(true);
        setValueAllSwitchBlueOnionHub((prev) => {
          return { ...prev, ...changeSet };
        });
        message.success('opened ' + changed + ' roles from company');
      }
      else {
        message.info('No role opened');
      }

      regionRef.current?.setSameAsCompany();
      assetRef.current?.setSameAsCompany();
    }
  }

  return (
    <Form
      key={keyStr + 'form'}
      name={keyStr + 'form'}
      onFinish={onFinish}
      onFinishFailed={(v) => {
        //console.warn('onFinishFailed', v);
      }}
      form={form}
    >
      <Row gutter={20}>
        {
          (companyDetails || memberDetails) &&
          <Col span={8}>
            <LabelRequire
              text="Start Date"
              warning={warningText(
                isDateInverted(form.getFieldValue('start_date')?._d, nowDayStr) && 'Today is outside service period',
                isDateInverted(parentDetails?.start_date, form.getFieldValue('start_date')?._d) && 'Before company service start date',
              )}
            />
            <DateInput
              name="start_date"
              id={keyStr + 'start_date'}
              dateInputProps={{
                id: keyStr + 'input_start_date',
                onChange: () => { setFieldsTouchedStatus(true); updateWarning(); },
                //placement:'topLeft',
              }}
              enableAllDate={true}
              dependencies={['expired_date']}
              rules={[
                { required: false, message: 'This field is mandatory' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      userDetails ||
                      !value ||
                      !getFieldValue('expired_date') ||
                      (!!getFieldValue('expired_date') && getFieldValue('expired_date').format('YYYY-MM-DD') >= value.format('YYYY-MM-DD'))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Start date cannot be later than End date'));
                  },
                }),
              ]}
            />
          </Col>
        }
        {
          (companyDetails || memberDetails || userDetails) &&
          <Col span={8}>
            <LabelRequire text="Expire Date" require={!!(userDetails)}
              warning={warningText(
                isDateInverted(nowDayStr, form.getFieldValue('expired_date')?._d) && 'Today is outside service period',
                isDateInverted(form.getFieldValue('expired_date')?._d, parentDetails?.expired_date) && 'After company service expired date',
              )}
            />
            <DateInput
              name="expired_date"
              id={keyStr + 'expired_date'}
              dateInputProps={{
                id: keyStr + 'input_expired_date',
                onChange: () => { setFieldsTouchedStatus(true); updateWarning(); },
              }}
              enableAllDate={true}
              dependencies={['start_date']}
              rules={[
                { required: !!userDetails, message: 'This field is mandatory' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      userDetails ||
                      !value ||
                      !getFieldValue('start_date') ||
                      (!!getFieldValue('start_date') && getFieldValue('start_date').format('YYYY-MM-DD') <= value.format('YYYY-MM-DD'))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Start date cannot be later than End date'));
                  },
                }),
              ]}
            />
          </Col>
        }
        {
          (companyDetails) &&
          <Col span={8} >
            <LabelRequire text="Company Key" />
            <TextInput
              name="company_key"
              id={keyStr + 'company_key'}
              placeholder="Company Key"
              className={styles.internalUserForm}
              inputProps={{
                id: keyStr + 'company_key',
                onChange: () => { setFieldsTouchedStatus(true); },
              }}
              rules={companyDetails && [
                {
                  max: 10,
                  message: 'You have reach the maximum character for this field: 10 characters',
                },
                {
                  pattern: /^[a-z0-9]*$/i,
                  message: 'Include only [a-z/0-9]',
                },
              ]}
            />
          </Col>
        }
        {
          (companyDetails) &&
          <Col span={8} hidden={true}>
            <Form.Item name="is_new_member_need_audit" valuePropName="checked">
              <SwitchInput
                text="Audit New Member "
                id={keyStr + 'is_new_member_need_audit'}
                onChange={(checked: boolean) => {
                  setFieldsTouchedStatus(true);
                }}
              />
            </Form.Item>
          </Col>
        }
        {
          (companyDetails) &&
          <Col span={8}>
            <LabelRequire text="&nbsp;" />
            <Form.Item name="is_accept_public_email" valuePropName="checked">
              <SwitchInput
                text='Accept Other Emails '
                title={`Accept emails not with the company's domain, '@${companyDetails?.domain}'.`}
                id={keyStr + 'is_accept_public_email'}
                onChange={(checked: boolean) => {
                  setFieldsTouchedStatus(true);
                }}
              //className='align-right'
              />
            </Form.Item>
          </Col>
        }
        {
          (companyDetails) &&
          <Col span={8}>
            <LabelRequire text="&nbsp;" />
            <Form.Item name="is_accept_prohibited_email" valuePropName="checked">
              <SwitchInput
                text='Accept All Emails '
                title={'Accept all email domains even if it\'s in the prohibited domain list.'}
                id={keyStr + 'is_accept_prohibited_email'}
                onChange={(checked: boolean) => {
                  setFieldsTouchedStatus(true);
                }}
              //className='align-right'
              />
            </Form.Item>
          </Col>
        }
        {
          (companyDetails) &&
          <Col span={8} >
            <LabelRequire text="Invitation Code" />
            <TextInput
              name="invitation_code"
              id={keyStr + 'invitation_code'}
              placeholder="Invitation Code"
              className={styles.internalUserForm}
              inputProps={{
                id: keyStr + 'invitation_code',
                onChange: () => { setFieldsTouchedStatus(true); },
              }}
              rules={companyDetails && [
                {
                  max: 50,
                  message: 'You have reach the maximum character for this field: 50 characters',
                },
              ]}
            />
          </Col>
        }
        {
          (memberDetails && isSysAdmin()) &&
          <Col span={7} style={{ justifyContent: 'right' }} >
            <LabelRequire text="Company Admin" className='align-right' />
            <Form.Item name="is_admin" valuePropName="checked">
              <SwitchInput
                text=""
                id={keyStr + 'is_admin'}
                onChange={(checked: boolean) => {
                  setFieldsTouchedStatus(true);
                }}
                className='align-right'
              />
            </Form.Item>
          </Col>
        }
        {
          (memberDetails && isSysAdmin()) &&
          <Col span={8}>
            <div>
              <LabelRequire text="Cooling Start Date" />
              <DateInput
                name="cooling_start_date"
                id={keyStr + 'cooling_start_date'}
                dateInputProps={{
                  id: keyStr + 'cooling_start_date',
                  onChange: () => { setFieldsTouchedStatus(true); },
                }}
                enableAllDate={true}
              />
            </div>
          </Col>
        }
        {
          (memberDetails && isSysAdmin()) &&
          <Col
            span={8}
          //style={{ justifyContent: 'left' }}
          >
            <LabelRequire text="Concurrent Number" />
            <Form.Item name="concurrent" valuePropName="checked">
              <Popover
                content={() => {
                  const ca = concurrent.split(',');
                  const caRowNum = ca.map((v, idx) => {
                    return <Row key={'rowNum' + idx} gutter={1} style={{ marginBottom: '5px' }}>
                      <Col span={8}>{listAllConcurrentProduct?.[idx].name}</Col>
                      <Col span={12}>
                        <InputNumber size='small' style={{ color: 'gray' }} value={parseInt(v) || '0'}
                          onChange={(vInp) => {
                            //console.log(vInp);
                            //check if it's a normal number
                            if (('' + vInp) === ('' + parseInt('' + vInp))) {
                              const ca = concurrent.split(',');
                              ca[idx] = '' + (parseInt('' + vInp) || 0);
                              setConcurrent(ca.join(','));
                              setConcurrentUpdated(true);
                            }
                          }}
                          min={0}
                          max={10000}
                        />
                      </Col>
                    </Row>;
                  });
                  return <div style={{ width: 220 }}>{caRowNum}</div>;
                }}
                trigger="click"
                placement="bottomLeft"
                onOpenChange={(visible) => { setConcurrentPopover(visible); }}
                getPopupContainer={(trigger) => (trigger.parentElement || trigger)}   //follow scrolling
              >
                <Input
                  value={concurrent}
                  id={keyStr + 'concurrent'}
                  readOnly
                  style={{
                    cursor: 'pointer',
                    backgroundColor: concurrentPopover ? 'lightgrey' : '#f5f5f5',
                    color: concurrentPopover ? 'gray' : '#aaaaaa',
                  }}

                />
              </Popover>

            </Form.Item>
          </Col>
        }
        {
          (memberDetails && isSysAdmin()) &&
          <Col span={7} style={{ justifyContent: 'right' }}>
            <LabelRequire text="Receive Expiry Notice" className='align-right' />
            <Form.Item name="is_receive_expiry_notify" valuePropName="checked">
              <SwitchInput
                text=""
                id={keyStr + 'is_receive_expiry_notify'}
                onChange={(checked: boolean) => {
                  // setIsolatedRole(checked);
                  setFieldsTouchedStatus(true);
                }}
                className='align-right'
              />
            </Form.Item>
          </Col>
        }
        {
          (memberDetails && isSysAdmin()) && <Col span={8} />
        }
        {
          (memberDetails && isSysAdmin()) && <Col span={8} />
        }
        {
          (memberDetails) &&
          <Col span={7} style={{ justifyContent: 'right' }}>
            {/*isSysAdmin() ? <></> : <LabelRequire text='&nbsp;' className='align-right' />*/}
            {!isSysAdmin() && (<LabelRequire text="&nbsp;" />)}
            <Form.Item name="is_isolate_role" valuePropName="checked">
              <SwitchInput
                text="Isolated Role "
                id={keyStr + 'is_isolate_role'}
                onChange={(checked: boolean) => {
                  setIsolatedRole(checked);
                  setFieldsTouchedStatus(true);
                }}
                className='align-right'
              />
            </Form.Item>
          </Col>
        }
        {
          (userDetails) &&
          <Col span={8} hidden={true}>
            <LabelRequire text="New Member Auditor" />
            <Form.Item name="notifyNewMemberAuditor" valuePropName="checked">
              <SwitchInput
                text=""
                id={keyStr + 'notifyNewMemberAuditor'}
                onChange={(checked: boolean) => {
                  setValueAllSwitchBlueOnionHub((prev) => ({ ...prev, notifyNewMemberAuditor: checked }));
                  setFieldsTouchedStatus(true);
                }}
              />
            </Form.Item>
          </Col>
        }
        {
          (userDetails) &&
          <Col span={8} >
            <LabelRequire text="Member Expiry Notice" />
            <Form.Item name="notifyMemberExpiry" valuePropName="checked">
              <SwitchInput
                text=""
                id={keyStr + 'notifyMemberExpiry'}
                onChange={(checked: boolean) => {
                  setValueAllSwitchBlueOnionHub((prev) => ({ ...prev, notifyMemberExpiry: checked }));
                  setFieldsTouchedStatus(true);
                }}
              />
            </Form.Item>
          </Col>
        }
        {
          (userDetails) &&
          <Col span={8} >
            <LabelRequire text="Cooling Period Receiver" />
            <Form.Item name="notifyCoolingPeriod" valuePropName="checked">
              <SwitchInput
                text=""
                id={keyStr + 'notifyCoolingPeriod'}
                onChange={(checked: boolean) => {
                  setValueAllSwitchBlueOnionHub((prev) => ({ ...prev, notifyCoolingPeriod: checked }));
                  setFieldsTouchedStatus(true);
                }}
              />
            </Form.Item>
          </Col>
        }
      </Row>

      <div style={{ display: (companyDetails || isolatedRole) ? 'flex' : 'none', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <LabelRequire text="Plan Includes" require={!!(companyDetails || isolatedRole)} className="plan-includes-text" />
          <div title='Switch to module view' className={planTag === 'module' ? 'linkText' : 'linkTextUnselected'} style={{ marginLeft: '2em' }} onClick={() => { setPlanTag('module'); }}>Module</div>
          <div title='Switch to data view' className={planTag === 'data' ? 'linkText' : 'linkTextUnselected'} style={{ marginLeft: '1em' }} onClick={() => { setPlanTag('data'); }}>Scope</div>
        </div>

        {
          memberDetails &&
          <div className="linkText" onClick={setSameAsCompany} title='Open all permissions same as the company (except the user roles)'>
            Same as Company
          </div>
        }
      </div>

      <div className="plan-includes" style={{ display: (companyDetails || isolatedRole) && (planTag === 'data') ? '' : 'none' }}>
        <div className="blueOnion-modules-group">
          {
            <RegionEditor
              key={keyStr + 'RegionEditor'}
              ref={regionRef}
              {...{
                companyDetails,
                memberDetails,
                form,
                setFieldsTouchedStatus,
              }}
            />
          }
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-modules-group">
          {
            <AssetEditor
              key={keyStr + 'AssetEditor'}
              ref={assetRef}
              {...{
                companyDetails,
                memberDetails,
                form,
                setFieldsTouchedStatus,
              }}
            />
          }
        </div>
      </div>

      <div className="plan-includes" style={{ display: (companyDetails || isolatedRole) && (planTag === 'module') ? '' : 'none' }}>

        <div className="total-control">
          <Row gutter={20} style={{ display: (companyDetails || memberDetails) ? '' : 'none' }}>
            <Col span={8} >
              <LabelRequire text="&nbsp;" />
              <LabelRequire text="Total Control" />
            </Col>
            <Col span={7} >
              <LabelRequire text="Start Date"
                warning={warningText(
                  isDateInverted(form.getFieldValue('plan_start_date')?._d, nowDayStr) && 'Today is outside plan period',
                  isDateInverted(parentDetails?.plan_start_date, form.getFieldValue('plan_start_date')?._d) && 'Before company plan start date',
                )}
              />
              <DateInput
                name="plan_start_date"
                id={keyStr + 'plan_start_date'}
                dependencies={['plan_end_date']}
                enableAllDate={true}
                rules={(companyDetails || memberDetails) && [
                  { required: false, message: 'This field is mandatory' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        !getFieldValue('plan_end_date') ||
                        (!!getFieldValue('plan_end_date') && getFieldValue('plan_end_date').format('YYYY-MM-DD') >= value.format('YYYY-MM-DD'))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Start date cannot be later than End date'));
                    },
                  }),
                ]}
                dateInputProps={{
                  id: keyStr + 'input_plan_start_date',

                  onChange: () => { setFieldsTouchedStatus(true); updateWarning(); },
                }}
              />
            </Col>
            <Col span={1} >
            </Col>
            <Col span={7} >
              <LabelRequire text="End Date"
                warning={warningText(
                  isDateInverted(nowDayStr, form.getFieldValue('plan_end_date')?._d) && 'Today is outside plan period',
                  isDateInverted(form.getFieldValue('plan_end_date')?._d, parentDetails?.plan_end_date) && 'After company plan end date',
                )}
              />
              <DateInput
                name="plan_end_date"
                id={keyStr + 'plan_end_date'}
                dependencies={['plan_start_date']}
                enableAllDate={true}
                rules={(companyDetails || memberDetails) && [
                  { required: false, message: 'This field is mandatory' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        !getFieldValue('plan_start_date') ||
                        (!!getFieldValue('plan_start_date') && getFieldValue('plan_start_date').format('YYYY-MM-DD') <= value.format('YYYY-MM-DD'))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Start date cannot be later than End date'));
                    },
                  }),
                ]}
                dateInputProps={{
                  id: keyStr + 'input_plan_end_date',
                  onChange: () => { setFieldsTouchedStatus(true); updateWarning(); },
                }}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-modules-group">
          <Row gutter={[20, 20]}>
            <HubsRoleRow varName='productHubs' label='Product Hubs' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='quickSearch' label='Quick Search' {...hubsRoleRowArgs} />
          </Row>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-hub">
          <Row className='tableHeaders' gutter={[20, 20]}>
            <Col span={8}>BlueOnion Hub</Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
          <Row gutter={[20, 20]} className='tableRow'>
            <HubsRoleRow varName='fundHub' label='Fund Hub' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='fundSearch' label='Fund Search' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='fundHubScreener' label='Fund Screener' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='fundComparison' label='Fund\nComparison' {...hubsRoleRowArgs} />
          </Row>
          <Row gutter={[20, 20]} className='tableRow'>
            <HubsRoleRow varName='portfolioHub' label='Portfolio Hub' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='portfolioHubPlus' label='Portfolio Plus' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='portfolioComparison' label='Portfolio\nComparison' {...hubsRoleRowArgs} />
          </Row>
          <Row gutter={[20, 20]} className='tableRow' style={{ borderBottom: 'none', paddingBottom: 0 }}>
            <HubsRoleRow varName='impactHub' label='Impact Hub' {...hubsRoleRowArgs} />
          </Row>
        </div>
        {isEverySwitchFalse ? (
          <div className={styles.errSwitch}>This field is mandatory</div>
        ) : (
          <div className={styles.errSwitchNone}></div>
        )}

        <div className="blueOnion-modules-group">
          <Row gutter={[20, 20]}>
            <HubsRoleRow varName='companyHub' label='Company Hub' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='companySearch' label='Company\nSearch' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='companyDetail' label='Company Detail' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='privateCompanyHub' label='Private\nCompany Hub' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='companyHubScreener' label='Company\nScreener' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='companyComparison' label='Company\nComparison' {...hubsRoleRowArgs} />
          </Row>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-modules-group">
          <Row gutter={[20, 20]}>
            <HubsRoleRow varName='stewardshipHub' label='Stewardship Hub ' {...hubsRoleRowArgs} />
          </Row>
          <div style={{ display: form.getFieldValue('stewardshipHub') ? undefined : 'none' }}>
            <Divider className='panelDivider' style={{ marginBottom: '8px', marginTop: '8px' }} />
            <Row gutter={[20, 20]}>
              <HubsRoleRow varName='stpReviewPro' label='Review Pro' {...hubsRoleRowArgs} />
              <HubsRoleRow varName='stpDueDVault' label='DueD Vault' {...hubsRoleRowArgs} />
              <HubsRoleRow varName='stpFundConnect' label='Fund Connect' {...hubsRoleRowArgs} />
              <HubsRoleRow varName='stpPRIMonitor' label='PRI Monitor' {...hubsRoleRowArgs} />
            </Row>
            {/* <Divider className='panelDivider' style={{ marginBottom: '8px', marginTop: '8px' }} />
            <Row gutter={[20, 20]}>
              <HubsRoleRow varName='stewardshipICBC' label='ICBC' {...hubsRoleRowArgs} />
              <HubsRoleRow varName='stewardshipSCB' label='SCB' {...hubsRoleRowArgs} />
              <HubsRoleRow varName='stewardshipCCBA' label='CCBA' {...hubsRoleRowArgs} />
            </Row> */}
            <Divider className='panelDivider' style={{ marginBottom: '8px' }} />
            <Row gutter={[20, 20]} >
              <HubsRoleRow varName='stewardshipAdmin' label='Admin' {...stewardshipHubsRoleRowArgs} />
              <HubsRoleRow varName='stewardshipApprover' label='Approver' {...stewardshipHubsRoleRowArgs} />
              <HubsRoleRow varName='stewardshipEditor' label='Editor' {...stewardshipHubsRoleRowArgs} />
              <HubsRoleRow varName='stewardshipViewer' label='Viewer' {...stewardshipHubsRoleRowArgs} />
            </Row>
          </div>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-modules-group">
          <Row gutter={[20, 20]}>
            <HubsRoleRow varName='climateHub' label='Climate\nRegulatory' {...hubsRoleRowArgs} />
          </Row>
          <div style={{ display: form.getFieldValue('climateHub') ? undefined : 'none' }}>
            <Divider className='panelDivider' style={{ marginBottom: '8px', marginTop: '8px' }} />
            <Row gutter={[20, 20]}>
              <HubsRoleRow varName='crmMasType' label='CRM MAS' {...hubsRoleRowArgs} />
              <HubsRoleRow varName='crmSfcType' label='CRM SFC' {...hubsRoleRowArgs} />
            </Row>
            <Divider className='panelDivider' style={{ marginBottom: '8px' }} />
            <Row gutter={[20, 20]} >
              <HubsRoleRow varName='crmAdminRole' label='CRM Admin' {...crmHubsRoleRowArgs} />
              <HubsRoleRow varName='crmApproverRole' label='CRM Approver' {...crmHubsRoleRowArgs} />
              <HubsRoleRow varName='crmEditorRole' label='CRM Editor' {...crmHubsRoleRowArgs} />
              <HubsRoleRow varName='crmViewerRole' label='CRM Viewer' {...crmHubsRoleRowArgs} />
            </Row>
          </div>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-modules-group">
          <Row gutter={[20, 20]}>
            <HubsRoleRow varName='crmScenario' label='Climate\nScenario' {...hubsRoleRowArgs} />
          </Row>
          {/* <div style={{ display: form.getFieldValue('crmScenario') ? undefined : 'none' }}> */}
          <div style={{ display: 'none' }}>
            <Divider className='panelDivider' style={{ marginBottom: '8px', marginTop: '8px' }} />
            <Row gutter={[20, 20]} >
              <HubsRoleRow varName='scenarioAdmin' label='Admin' {...scenarioHubsRoleRowArgs} />
              <HubsRoleRow varName='scenarioEditor' label='Editor' {...scenarioHubsRoleRowArgs} />
              <HubsRoleRow varName='scenarioViewer' label='Viewer' {...scenarioHubsRoleRowArgs} />
            </Row>
          </div>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-modules-group">
          <Row gutter={[20, 20]}>
            <HubsRoleRow varName='apiPortal' label='API Portal' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='productKingkey' label='Product Kingkey' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='productTWCS' label='Product TWCS' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='productSME' label='Product SME' {...hubsRoleRowArgs} />
          </Row>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="blueOnion-premium-modules">
          <LabelRequire text="BlueOnion Premium Modules" />
          <Divider />
          <Row gutter={[20, 20]} className='tableRow'>
            <HubsRoleRow varName='premiumSfdr' label='SFDR' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='sfdrPortfolio' label='SFDR Portfolio' {...hubsRoleRowArgs} />
          </Row>
          <Row gutter={[20, 20]} className='tableRow' style={{ borderBottom: 'none', paddingBottom: 0 }}>
            <HubsRoleRow varName='premiumTcfd' label='TCFD' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='premiumUnSdg' label='UN SDG' {...hubsRoleRowArgs} />
            <HubsRoleRow varName='premiumSasb' label='SASB' {...hubsRoleRowArgs} />
          </Row>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className=' blueOnion-modules-group'>
          <div className={styles.checkboxPanel}>
            <LabelRequire text='BAS' />
            <Form.Item name="productBas" valuePropName="checked" className='mainCheckbox'>
              <SwitchInput
                text=''
                checked={!!hubsRoleRowArgs.blueOnionHubRoleFlag?.productBas}
                onChange={(v) => {
                  hubsRoleRowArgs.onRowUpdate('productBas', v);

                  updateGroupStore(v, basStore, setBasStore, ['basFundhouse', 'basJuror', 'basAdmin']);

                  if (v) setBasExpand(true);
                }}
              />
            </Form.Item>
            <ExpandIcon
              key='bas-expand-icon'
              visible={form.getFieldValue('productBas')}
              expandState={form.getFieldValue('productBas') && basExpand}
              setExpandState={setBasExpand}
            />
            <div style={{ display: (form.getFieldValue('productBas') && basExpand) ? '' : 'none' }}>
              <Divider className='panelDivider' />
              <Row gutter={[20, 20]}>
                <HubsRoleRow groupVarName='productBas' varName='basFundhouse' label='BAS Fundhouse' {...hubsRoleRowArgs} />
                <HubsRoleRow groupVarName='productBas' varName='basJuror' label='BAS Juror' {...hubsRoleRowArgs} />
                <HubsRoleRow groupVarName='productBas' varName='basAdmin' label='BAS Admin' {...hubsRoleRowArgs} />
              </Row>
            </div>
          </div>
        </div>
        <div className={styles.errSwitchNone}></div>

        <div className="kiosk-hub">
          <KioskTableEditor
            //companyId={+companyDetails.id} 
            kioskTableData={kioskTableData}
            onUpdateData={onKioskUpdateData}
            productKiosk={ValueAllSwitchBlueOnionHub.productKiosk}
            onUpdateProductKiosk={(checked: boolean) => {
              //console.log('checked',checked);
              setValueAllSwitchBlueOnionHub((prev) => ({ ...prev, productKiosk: checked }));
              setKioskUpdated(true);
              setFieldsTouchedStatus(true);
            }}
            keyId={keyStr}

            {...kioskRowArgs}
          />
        </div>
        <div className={styles.errSwitchNone} style={{ display: 'none' }}></div>

        <div className="kiosk-hub" style={{ display: 'none' }}>
          <LabelRequire text="Kiosk Hub" />
          <Divider />
          <Row gutter={[20, 20]}>
            <Col span={8}>
              <Form.Item name="kioskHomepage" valuePropName="checked">
                <SwitchInput
                  text="Kiosk\nHomepage"
                  id={keyStr + 'kioskHomepage'}
                  onChange={(checked: boolean) => {
                    setValueAllSwitchBlueOnionHub((prev) => ({ ...prev, kioskHomepage: checked }));
                    setFieldsTouchedStatus(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="kioskFundhub" valuePropName="checked">
                <SwitchInput
                  text="Fund Hub"
                  id={keyStr + 'kioskFundhub'}
                  onChange={(checked: boolean) => {
                    setValueAllSwitchBlueOnionHub((prev) => ({ ...prev, kioskFundhub: checked }));
                    setFieldsTouchedStatus(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="kioskPortfoliohub" valuePropName="checked">
                <SwitchInput
                  text="Portfolio Hub"
                  id={keyStr + 'kioskPortfoliohub'}
                  onChange={(checked: boolean) => {
                    setValueAllSwitchBlueOnionHub((prev) => ({ ...prev, kioskPortfoliohub: checked }));
                    setFieldsTouchedStatus(true);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.errSwitchNone} style={{ display: companyDetails ? '' : 'none' }}></div>
        <Row gutter={20} style={{ display: companyDetails ? '' : 'none' }}>
          <Col span={12}>
            <LabelRequire text="Report Quota (per month)" require />
            <TextInput
              name="report_quota"
              id={keyStr + 'report_quota'}
              placeholder="Input Report Quota"
              className={styles.internalUserForm}
              inputProps={{
                id: keyStr + 'input_report_quota',
                onKeyDown: (e) => {
                  if (
                    !/^[0-9]*$/.test(e.key) &&
                    e.key !== 'Backspace' &&
                    e.key !== 'ArrowRight' &&
                    e.key !== 'ArrowLeft' &&
                    !(e.ctrlKey && e.key === 'v') &&
                    !(e.ctrlKey && e.key === 'c') &&
                    !(e.ctrlKey && e.key === 'a') &&
                    !(e.ctrlKey && e.key === 'x')
                  )
                    e.preventDefault();
                },
                onChange: () => { setFieldsTouchedStatus(true); },
              }}

              rules={companyDetails && [
                { required: true, message: 'This field is mandatory' },
                {
                  max: 10,
                  message: 'You have reach the maximum character for this field: 10 characters',
                },
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: 'This field only allows to input numbers',
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <LabelRequire text="Number of User License" require />
            <TextInput
              name="number_of_license"
              id={keyStr + 'number_of_license'}
              placeholder="Input Number Of License"
              className={styles.internalUserForm}
              inputProps={{
                id: keyStr + 'input_number_of_license',
                onKeyDown: (e) => {
                  if (
                    !/^[0-9]*$/.test(e.key) &&
                    e.key !== 'Backspace' &&
                    e.key !== 'ArrowRight' &&
                    e.key !== 'ArrowLeft' &&
                    !(e.ctrlKey && e.key === 'v') &&
                    !(e.ctrlKey && e.key === 'c') &&
                    !(e.ctrlKey && e.key === 'a') &&
                    !(e.ctrlKey && e.key === 'x')
                  )
                    e.preventDefault();
                },
                onChange: () => { setFieldsTouchedStatus(true); },
              }}
              rules={companyDetails && [
                { required: true, message: 'This field is mandatory' },
                {
                  max: 10,
                  message: 'You have reach the maximum character for this field: 10 characters',
                },
              ]}
            />
          </Col>
        </Row>
      </div>

      <Row>
        <Col span={12} className='step-button'>
          <CommonButton
            //onClick={onBack}
            variant="default"
            icon={<ArrowLeftTailIcon className="mr-2" />}
            onClick={() => {
              props.onStepBack?.();
            }}
            idKey={keyStr + 'back'}
            style={{ display: props.onStepBack ? '' : 'none' }}
          >
            Back
          </CommonButton>
        </Col>
        <Col span={12}>
          <div className="group-btn">
            <CommonButton
              onClick={onCancel}
              disabled={isLoading}
              variant="default"
              idKey={keyStr + 'cancel'}
              className="btn-cancel"
              style={{ display: props.onStepBack ? 'none' : '' }}
            >
              Cancel
            </CommonButton>
            <Form.Item shouldUpdate>
              {() => {
                //console.log(form.isFieldsTouched(true),form.isFieldsTouched()); //bug?: all fields are touched at beginning

                return <CommonButton
                  variant="primary"
                  loading={isLoading}
                  htmlType="submit"
                  idKey={keyStr + 'submit'}
                  disabled={
                    //!(form.isFieldsTouched(true) 
                    !(fieldsTouchedStatus || kioskUpdated || concurrentUpdated) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                    (companyDetails && isEverySwitchFalse === true) ||
                    (memberDetails && isolatedRole && isEverySwitchFalse)
                  }
                >
                  Save
                </CommonButton>;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
export default ChangePlanForm;
