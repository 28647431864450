import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';

import { Checkbox, Col, Form, FormInstance, message } from 'antd';

import LabelRequire from 'components/label-require';

import { ReturnPermissionDto, UpdatePermissionDto } from 'services/api-permission.type';

import { useQuery } from 'react-query';
import { getCommonAsset, getCompanyAsset, getMembersAsset, putCompanyAsset, putMembersAsset } from 'services/api-permission.service';
import { companyDetailsProps, memberDetailsProps } from './change-plan-form.type';
import { WarningChar } from 'components/label-require/warning-char';



export type AssetEditorProps = {
  companyDetails?: companyDetailsProps;
  memberDetails?: memberDetailsProps;
  form: FormInstance<any>;
  setFieldsTouchedStatus: (status: boolean) => void;
};

export const AssetEditor = forwardRef(({ companyDetails, memberDetails, form, setFieldsTouchedStatus, ...props }: AssetEditorProps, ref) => {
  const [initAsset, setInitAsset] = useState(false);
  const [allAsset, setAllAsset] = useState<JSX.Element[]>([]);

  const [listObjectAsset, setListObjectAsset] = useState<ReturnPermissionDto[] | undefined>(undefined);
  const [listParentAsset, setListParentAsset] = useState<ReturnPermissionDto[] | undefined>(undefined);

  const [fullAll, setFullAll] = useState(false);
  const [fullNone, setFullNone] = useState(false);

  const [updateAssetStatus, setUpdateAssetStatus] = useState(0);

  const [updated, setUpdated] = useState(false);

  const updateAsset = useCallback(() => {
    setUpdateAssetStatus(prev => prev + 1);
  }, [setUpdateAssetStatus]);

  //interface for ref
  useImperativeHandle(ref, () => ({
    setSameAsCompany: () => {
      //console.log('setSameAsCompany');
      let updateData: any = {};
      let updateCount = 0;

      listParentAsset?.forEach((row) => {
        if (row.is_allow && !form.getFieldValue(`asset_${row.permission_id}`)) {
          updateData[`asset_${row.permission_id}`] = true;
          updateCount++;
        }
      });

      // console.log('updateData.length', updateCount);
      if (updateCount > 0) {
        form.setFieldsValue(updateData);
        updateAsset();
        setFieldsTouchedStatus(true);
        setUpdated(true);
        message.success('added ' + updateCount + ' asset' + (updateCount > 1 ? 's' : '') + ' from company');
      }
      else {
        message.info('No asset is added');
      }
    },
    saveAsset: async (value: any) => {
      if (!updated) return;

      // save regoin
      if (companyDetails || memberDetails) {
        const assetData: UpdatePermissionDto[] = [];
        listAllAsset?.forEach((row) => {
          if (value[`asset_${row.id}`]) {
            assetData.push({ permission_id: +row.id, is_allow: true });
          }
        });
        if (assetData.length >= 0) {   // 0 to clear all
          if (companyDetails) {
            await putCompanyAsset(+companyDetails.id, assetData);
          } else if (memberDetails) {
            await putMembersAsset(+memberDetails.id, assetData);
          }
        }
      }
    },
  }));

  // console.log('main asset editor');

  // load all asset
  const { data: listAllAsset } = useQuery(['getAllAsset'],
    async () => {
      let ret = await getCommonAsset();
      ret.sort((a, b) => {
        return (a.display_name || '') > (b.display_name || '') ? 1 : -1;
      });
      return ret;
    },
    { staleTime: Infinity },
  );

  // load asset data
  useQuery([
    'getObjectAsset',
    companyDetails?.id,
    memberDetails?.id,
    //form,
  ], async () => {
    // console.log('getObjectAsset');
    let ret: ReturnPermissionDto[] = [];
    if (companyDetails?.id) ret = await getCompanyAsset(+companyDetails.id);
    else if (memberDetails?.id) ret = await getMembersAsset(+memberDetails.id);

    setListObjectAsset(ret);

    return ret;
  }, {
    enabled: !!(companyDetails || memberDetails) && !initAsset,
  });

  // load parent asset data
  useQuery([
    'getObjectParentAsset',
    companyDetails?.id,
    memberDetails?.id,
    //form,
  ], async () => {
    // console.log('memberDetails?.company_id', memberDetails?.company?.id);
    let ret: ReturnPermissionDto[] = [];
    if (memberDetails?.company?.id) {
      ret = await getCompanyAsset(+memberDetails?.company?.id);
    }

    setListParentAsset(ret);

    setInitAsset(false);

    return ret;
  }, {
    enabled: !!(memberDetails?.company?.id) && !companyDetails && !initAsset,
  });

  const onChangeCheckbox = useCallback((e: any) => {
    // console.log('onChangeCheckbox', e.target.value);
    setFieldsTouchedStatus(true);
    updateAsset();
    setUpdated(true);
  }, [updateAsset, setFieldsTouchedStatus]);

  const updateStaticAsset = useCallback(() => {
    //set static asset checkbox

    const all_asset = listAllAsset?.map((row, idx) => {
      let parentAllow = !listParentAsset ||
        listParentAsset.find((parent) => parent.permission_id === row.id)?.is_allow;

      let name = row.display_name || row.name;
      let selected = form.getFieldValue(`asset_${row.id}`) || false;

      return <Col key={`col_all_asset_${row.id}`} span={8}
      // style={{ borderTop: '1px solid #eeeeee' }}
      >
        <Form.Item name={`asset_${row.id}`} valuePropName="checked">
          <Checkbox key={'all' + row.id} onChange={onChangeCheckbox} style={{ color: parentAllow ? '' : (selected ? '#FC9536' : '#aaaaaa') }}>
            {(parentAllow || !selected) ? name : <WarningChar warning='* Not allowed by company scope.'>{name}</WarningChar>}
          </Checkbox>
        </Form.Item>
      </Col>;
    }) || [];

    setAllAsset(all_asset);
  }, [listAllAsset, form, listParentAsset, onChangeCheckbox]);

  if (listAllAsset && listObjectAsset && !initAsset) {
    // console.log('initAsset');

    //set fields value for asset
    let fieldChanged: any = {};
    listObjectAsset.forEach((row) => {
      if (row.is_allow) {
        // form.setFieldsValue({ [`asset_${row.permission_id}`]: row.is_allow });
        fieldChanged[`asset_${row.permission_id}`] = !!row.is_allow;
        // hasPermission = true;
      }
    });

    //set static asset checkbox
    updateStaticAsset();

    setInitAsset(true);

    setTimeout(() => {    // timer to remove warning message
      form.setFieldsValue(fieldChanged);

      updateAsset();
    }, 100);
  }

  let select_count = useMemo(
    () => {
      if (!updateAssetStatus) return [];   //hack warning message

      let select_count = 0;

      // console.log('dyanmic asset tag');
      listAllAsset?.forEach((row, idx) => {
        if (form.getFieldValue(`asset_${row.id}`)) {
          select_count++;
        }
      });

      setFullAll(select_count === listAllAsset?.length);
      setFullNone(select_count === 0);

      updateStaticAsset();

      return select_count;
    },
    [form, listAllAsset, updateAssetStatus, updateStaticAsset],
  );

  const onChangeFullAll = (e: any) => {
    let currentValue = !!fullAll;

    let newValues = listAllAsset?.reduce((acc: any, row) => {
      acc[`asset_${row.id}`] = !currentValue;
      return acc;
    }, {});

    form.setFieldsValue(newValues);

    setFullAll(!currentValue);
    setFullNone(currentValue);

    updateAsset();
    setFieldsTouchedStatus(true);
    setUpdated(true);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <LabelRequire text="Assets" />
        <div style={{ marginLeft: '2em', fontSize: '14px' }}>

        </div>
      </div>


      <div key='asset_editor' style={{
        maxHeight: '20em',
        overflowY: 'auto',
        fontSize: '14px',
        height: 'auto',
      }}>



        {/* <div key='full_view_toggle' style={{ marginTop: '1em', textAlign: 'right' }}>
          <span className='linkText' onClick={() => { setFullView(!fullView); }}>{fullView ? '> Hide Full View' : '< Show Full View'}</span>
        </div> */}

        {/* favourite asset */}
        <div key='full_view' >


          {/* all asset */}
          <div key='all_asset_label' style={{ marginTop: '0em', borderBottom: '1px solid #dddddd', display: 'flex', justifyContent: 'space-between' }}>
            <div >
              Selected: &nbsp;
              {select_count || 0} / {listAllAsset?.length}
            </div>
            <div>
              <span style={{ marginRight: '2em' }}></span>
              <Checkbox indeterminate={!fullAll && !fullNone} onChange={onChangeFullAll} checked={fullAll}
                style={{ color: '#3698fc' }}
              >
                <span title='Toggle assets'>All</span>
              </Checkbox>
            </div>

          </div>
          <div key='all_asset' style={{ display: 'flex', flexWrap: 'wrap' }}>
            {allAsset}
          </div>

        </div>
      </div>
    </>
  );
});
