import dayjs from 'dayjs';

export function isDateInverted(dt1: any, dt2: any) {
  if (!dt1 || !dt2) return null;
  if (typeof dt1 !== 'string') dt1 = dayjs(dt1).format('YYYY-MM-DD');
  if (typeof dt2 !== 'string') dt2 = dayjs(dt2).format('YYYY-MM-DD');
  return dt1 > dt2;
}

export function warningText(...args: (string | null | false | undefined)[]) {
  var ret = '';
  args?.forEach((v) => {
    if (v) ret += (ret && '\n') + '* ' + v;
  });
  return ret ? ('Warning\n' + ret) : ret;
}
