import React from 'react';

export const ArrowLeftIcon = ({ fill = '#27272A', ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3741 17.7071C9.98357 18.0976 9.35041 18.0976 8.95988 17.7071L0.959885 9.70711C0.569361 9.31658 0.569361 8.68342 0.959885 8.29289L8.95989 0.292893C9.35041 -0.0976312 9.98358 -0.0976311 10.3741 0.292893C10.7646 0.683417 10.7646 1.31658 10.3741 1.70711L3.08121 9L10.3741 16.2929C10.7646 16.6834 10.7646 17.3166 10.3741 17.7071Z"
        fill={fill}
      />
    </svg>
  );
};
