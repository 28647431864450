import clsx from 'clsx';
import styles from './label-require.module.scss';
import { WarningChar } from './warning-char';

interface LabelRequireProps {
  text: string;
  require?: boolean;
  className?: string;
  warning?: string;
}

const LabelRequire = ({ text, require = false, className, warning }: LabelRequireProps) => {
  return (
    <div className={clsx(styles.labelRequire, className)}>
      <span className={clsx('text-label', styles.text)}>
        {text}
      </span>
      {require && <div className={styles.require}>*</div>}
      {warning ? <WarningChar warning={warning} /> : <></>}
    </div>
  );
};

export default LabelRequire;
