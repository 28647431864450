import { Switch, SwitchProps } from 'antd';
import clsx from 'clsx';

import styles from './switch-standalone.module.scss';
import { WarningChar } from 'components/label-require/warning-char';

interface SwitchInputProps extends SwitchProps {
  text: string;
  className?: string;
  required?: boolean;
  title?: string;
  warning?: string | false | null | undefined;
  autoLabelWidth?: boolean;
}

const SwitchStandalone = ({
  text,
  className,
  required = false,
  warning,
  autoLabelWidth,
  ...props
}: SwitchInputProps) => {
  return (
    <div className={clsx(className, styles.switch)}>
      <Switch {...props} />
      {warning ? (
        <>
          &nbsp;
          <WarningChar warning={warning} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SwitchStandalone;
