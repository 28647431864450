import ChangePasswordForm from 'modules/Auth/change-password-form';
import { IntroCommon } from 'pages/intro';

export default function ChangePasswordPage() {
  return (
    <IntroCommon>
      <ChangePasswordForm />
    </IntroCommon>
  );
}
