import React from 'react';

export const DepartmentAdminIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.6835 9.56522C11.3478 9.56522 13.5076 7.42397 13.5076 4.78261C13.5076 2.14125 11.3478 0 8.6835 0C6.01921 0 3.85938 2.14125 3.85938 4.78261C3.85938 7.42397 6.01921 9.56522 8.6835 9.56522Z"
        fill="#3698FC"
      />
      <path
        d="M11.1283 19.8957C10.6256 19.0329 10.726 17.9702 11.3242 17.2174C10.726 16.4646 10.6256 15.4029 11.1283 14.5391L11.9088 13.2C12.3382 12.4625 13.139 12.0044 13.9987 12.0044C14.1164 12.0044 14.2321 12.013 14.347 12.0292C14.3788 11.9508 14.4193 11.8771 14.4589 11.8025C13.9379 11.5988 13.3754 11.4783 12.7839 11.4783H4.58292C2.05604 11.4783 0 13.5166 0 16.0217V19.3696C0 19.7656 0.324181 20.087 0.723619 20.087H11.2393L11.1283 19.8957Z"
        fill="#3698FC"
      />
      <path
        d="M20.9393 17.6658C20.9586 17.5175 20.9846 17.3702 20.9846 17.2172C20.9846 17.0632 20.9576 16.9168 20.9393 16.7686L21.8086 16.1162C22.0025 15.9708 22.0566 15.7049 21.935 15.4954L21.1545 14.1563C21.0329 13.9478 20.7733 13.8607 20.5495 13.9545L19.549 14.3725C19.3049 14.1879 19.0453 14.0253 18.7588 13.9067L18.6256 12.8535C18.5948 12.6144 18.3902 12.4346 18.1471 12.4346H16.587C16.3438 12.4346 16.1383 12.6144 16.1084 12.8535L15.9753 13.9067C15.6887 14.0253 15.4282 14.1888 15.1851 14.3725L14.1846 13.9545C13.9607 13.8607 13.7012 13.9468 13.5796 14.1563L12.7991 15.4954C12.6775 15.704 12.7315 15.9699 12.9255 16.1162L13.7948 16.7686C13.7755 16.9168 13.7494 17.0641 13.7494 17.2172C13.7494 17.3712 13.7764 17.5175 13.7948 17.6658L12.9255 18.3181C12.7315 18.4635 12.6775 18.7294 12.7991 18.9389L13.5796 20.279C13.7012 20.4875 13.9607 20.5746 14.1846 20.4808L15.1851 20.0619C15.4292 20.2465 15.6887 20.4091 15.9753 20.5277L16.1084 21.5808C16.1383 21.82 16.3429 21.9998 16.586 21.9998H18.1461C18.3893 21.9998 18.5948 21.82 18.6247 21.5808L18.7578 20.5277C19.0444 20.4091 19.3049 20.2455 19.548 20.0619L20.5485 20.4799C20.7724 20.5736 21.0319 20.4875 21.1535 20.278L21.934 18.938C22.0556 18.7294 22.0016 18.4635 21.8076 18.3172L20.9393 17.6658ZM17.3665 19.1302C16.3004 19.1302 15.4369 18.2741 15.4369 17.2172C15.4369 16.1602 16.3004 15.3041 17.3665 15.3041C18.4327 15.3041 19.2962 16.1602 19.2962 17.2172C19.2962 18.2741 18.4327 19.1302 17.3665 19.1302Z"
        fill="#3698FC"
      />
    </svg>
  );
};
