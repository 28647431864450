import { ITab } from 'components/tab-common/tab-common';
import Company from './company';
import Member from './member';

export const listTab: ITab[] = [
  {
    key: '1',
    name: 'Company',
    component: <Company />,
  },
  {
    key: 'Member',
    name: 'All User',
    component: <Member />,
  },
  {
    key: 'CompanyAdmin',
    name: 'Company Admin',
    component: <Member onlyCompanyAdmin={true} />,
  },
];

export const INDEX_CompanyAdmin= 2;   //for list item inserting
