export type ListUserType = {
  companyName: string;
  shortName: string;
  someThing: string;
  totalItem: string;
};

export type PaginationDataType = {
  currentPage?: number;
  totalItem?: number;
  totalPage?: number;
  pageSize: number;
  orderBy?: string;
  order?: string;
  page?: number;
};

export type UserCompanyType = {
  id: string;
  name: string;
  email: string;
  country: string;
  phone: string;
  status: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_by: string;
  postal_code: string;
  start_date: string;
  expired_date: string;
  plan_start_date: string;
  plan_end_date: string;
  is_isolate_role: boolean;
  is_admin: boolean;
  city: string;
  username: string;
  is_receive_expiry_notify: boolean;
  cooling_start_date: string | null;
  account_id: string;

  memberRoles: [
    {
      id: string;
      created_at: string;
      updated_at: string;
      company_id: string;

      start_date: string | undefined;
      end_date: string | undefined;
      is_allow: boolean | undefined;
      is_prohibit: boolean | undefined;
      is_assign_other: boolean | undefined;

      role: {
        id: string;
        name: string;
      };
    },
  ];

  company_id: number;

  company: {
    name: string;

    companyRole: [
      {
        id: string;
        created_at: string;
        updated_at: string;
        company_id: string;
        role: {
          id: string;
          name: string;
        };
      },
    ];
  }

};

/*
export type KioskImportDto = {
  email?: string;
  module?: string;
  region?: string;
  start_date?: string;
  end_date?: string;
}
*/

export type PermissionImportDto = {
  email?: string;
  is_kiosk?: boolean;
  module?: string;
  region?: string;
  start_date?: string;
  end_date?: string;
}

export type UpdateMaxDeviceParamDto = {
  max_device: number;
  blueonion_product_id: number;
  account_id?: number | undefined;
}

export enum SortOrderEnum {
  DESC = 'desc',
  ASC = 'asc',
}

export const renderSorterOrder = (order: string) => {
  if (order === 'descend') return SortOrderEnum.DESC;
  if (order === 'ascend') return SortOrderEnum.ASC;
  return order;
};

export const DummyData = {
  companyName: 'Google Company Limited',
  shortName: 'GG',
  someThing: 'Compnay User',
  totalItem: 8,
  tableData: [
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
    {
      userId: 123456,
      userName: 'yeuemvatvaketquanhukhong',
      email: 'coolboiz@gmail.com',
      country: 'Viet Nam',
      phoneNumber: '5520984137',
      status: 'Active',
    },
  ],
};
