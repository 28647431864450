import { SVGProps } from 'react';

export const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1747 9.67496C13.6303 9.21935 14.369 9.21935 14.8246 9.67496L21.8246 16.675C22.2802 17.1306 22.2802 17.8693 21.8246 18.3249C21.369 18.7805 20.6303 18.7805 20.1747 18.3249L13.9997 12.1498L7.82463 18.3249C7.36902 18.7805 6.63033 18.7805 6.17472 18.3249C5.7191 17.8693 5.7191 17.1306 6.17472 16.675L13.1747 9.67496Z"
        fill="#27272A"
      />
    </svg>
  );
};
