import { useState } from 'react';
import { Layout, Menu, MenuProps } from 'antd';

import './styles.scss';
import { ThreeUsersFillIcon, ThreeUsersOutlinedIcon } from 'components/icons';
import { PATH_EXTERNAL_USER, PATH_INTERNAL_USER, PATH_LOG, PATH_SETTINGS, PATH_TOOLS, PATH_USER_MANAGEMENT } from 'navigation/routes.path';
import { useHistory, useLocation } from 'react-router-dom';
import { Item } from './menu.const';
import { isSysAdmin } from 'redux/has-permission';
import { ToolsFillIcon } from 'components/icons/tools-fill-icon';
import { ToolsOutlinedIcon } from 'components/icons/tools-outlined-icon';

export function SideBar() {
  const { Sider } = Layout;
  const history = useHistory();
  const location = useLocation();

  const determinedItem = (nameMenu: string, arr: string[]) => {
    return arr.filter((item) => nameMenu.includes(item));
  };

  const [nameMenuClick, setNameMenuClick] = useState<string>(location.pathname);

  const items: MenuProps['items'] = [
    Item(
      'User Management',
      PATH_USER_MANAGEMENT,
      !!determinedItem(nameMenuClick, [PATH_EXTERNAL_USER, PATH_INTERNAL_USER]).length ? (
        <ThreeUsersFillIcon fill="#3698FC" />
      ) : (
        <ThreeUsersOutlinedIcon />
      ),
      [
        ...isSysAdmin() ? [Item('Super Admin', PATH_INTERNAL_USER)] : [],
        Item('External User', PATH_EXTERNAL_USER),
        //Item('Log', PATH_LOG),
      ],
    ),
    ...isSysAdmin() ? [
      Item(
        'Tools',
        PATH_TOOLS,
        !!determinedItem(nameMenuClick, [PATH_LOG, PATH_SETTINGS]).length ? (
          <ToolsFillIcon fill="#3698FC" />
        ) : (
          <ToolsOutlinedIcon fill="#4B4B4B" />
        ),
        [
          Item('Settings', PATH_SETTINGS),
          Item('Log', PATH_LOG),
        ],
      ),
    ] : [],
  ];
  const onClick: MenuProps['onClick'] = (e) => {
    if (location.pathname === e.key) return;
    setNameMenuClick(e.key);
    history.push(e.key);
  };

  return (
    <Sider collapsible className="side-bar">
      <Menu
        onClick={onClick}
        selectedKeys={determinedItem(nameMenuClick, [PATH_EXTERNAL_USER, PATH_INTERNAL_USER, PATH_LOG, PATH_SETTINGS])}
        defaultOpenKeys={determinedItem(nameMenuClick, [PATH_LOG, PATH_SETTINGS]).length ? [PATH_TOOLS] : [PATH_USER_MANAGEMENT]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}
