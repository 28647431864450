import { SVGProps } from 'react';

export const CheckIcon = ({ fill = '#3698FC', ...rest }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5161 5.66272C16.7164 5.87968 16.7164 6.23143 16.5161 6.44839L8.31101 15.3373C8.11074 15.5542 7.78604 15.5542 7.58577 15.3373L3.48321 10.8928C3.28294 10.6759 3.28294 10.3241 3.48321 10.1072C3.68348 9.8902 4.00818 9.8902 4.20845 10.1072L7.94839 14.1588L15.7909 5.66272C15.9912 5.44576 16.3159 5.44576 16.5161 5.66272Z"
        fill={fill}
      />
    </svg>
  );
};
