import { useMemo, useState } from 'react';
import { Col, Collapse, Divider, Form, message, RadioChangeEvent, Row } from 'antd';
import { useQuery } from 'react-query';

import { CommonButton } from 'components/common-button';
import { TextInput } from 'components/form-control';
import DateInput from 'components/form-control/date-input';
import {
  AccountingIcon,
  ChevronUpIcon,
  DepartmentAdminIcon,
  ManagerIcon,
  PlusIcon,
  SaleIcon,
  WarningIcon,
} from 'components/icons';
import LabelRequire from 'components/label-require';
import SelectSearch from './select-search/select-search';
import { UserDepartmentTypes } from '../internal-user.const';
import UserDepartmentTags from '../user-department-tag';
import styles from './internal-user.module.scss';
import {
  getCountries,
  getCities,
  createInternalUser,
  importBatchUserList,
} from 'services/create-internal-user-services/create-internal-user.services';
import { errorKeyWord, InternalUserDataType, SelectType } from './create-internal-user.constants';
import { isEmpty } from 'utils/helper';
import { emailRegex, nameRegex } from 'utils/regex';
import { ImportBatchModal, LeavePopup, SuccessPopup } from 'components/modal';
import { PATH_INTERNAL_USER } from 'navigation/routes.path';
import { UserType } from 'services/create-internal-user-services/create-internal-user.type';

const { Panel } = Collapse;

function CreateInternalUser() {
  const [country, setCountry] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isVisibleModalSuccess, setVisibleModalSuccess] = useState<boolean>(false);
  const [isVisibleModalLeave, setVisibleModalLeave] = useState<boolean>(false);
  const [isOpenImportBatchPopup, setOpenImportBatchPopup] = useState<boolean>(false);

  const [formValidate] = Form.useForm();

  const { data: listCountries, isLoading: listCountriesLoading } = useQuery('listCountriesInternalUser', getCountries);
  const { data: listCities, isLoading: listCitiesLoading } = useQuery(
    ['listCitiesInternalUser', country],
    () => getCities(country),
    { enabled: !!country },
  );

  const [userDepartment, setUserDepartment] = useState<string>('BO Admin');

  const handleChangeUserDepartment = (e: RadioChangeEvent) => {
    setUserDepartment(e.target.value);
  };

  const handleSelectCountry = (value: string) => {
    //reset city when reselect country
    if (formValidate.getFieldsValue(['city'])?.city) {
      formValidate.resetFields(['city']);
    }

    if (!listCountries) return;
    const countrySelected = listCountries.find((ele) => ele.name === value);
    if (!countrySelected) return;
    setCountry(countrySelected.iso2);
  };

  const listCountriesData = useMemo(() => {
    return listCountries?.map((ele) => {
      return { label: ele.name, value: ele.name } as SelectType;
    });
  }, [listCountries]);

  const listCitiesData = useMemo(() => {
    return listCities?.map((ele) => {
      return { label: ele.name, value: ele.name } as SelectType;
    });
  }, [listCities]);

  const createInternalUserHandler = async (data: InternalUserDataType) => {
    try {
      setLoading(true);
      const internalUserData: InternalUserDataType = {
        name: data.name.trim(),
        email: data.email.trim(),
        expired_date: data.expired_date.trim(),
        country: data.country?.trim(),
        city: data.city?.trim(),
        postalCode: Number(data.postalCode),
        phone: data.phone?.trim() || undefined,
      };
      await createInternalUser(internalUserData);
      formValidate.resetFields();
      setVisibleModalSuccess(true);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const messageError = error?.response?.data?.message;
      const messageCode = error?.response?.data?.code;
      if (isEmpty(error?.response)) {
        message.error({
          content: 'Please check your internet connection and try again.',
          className: 'custom-class',
          style: {
            textAlign: 'center',
          },
        });
        return;
      }
      if (messageCode.includes(errorKeyWord.userExisted)) {
        formValidate.setFields([
          { name: 'name', errors: ['This username already exists. Please input a new username'] },
        ]);
        return;
      }
      if (messageCode.includes(errorKeyWord.emailExisted)) {
        formValidate.setFields([{ name: 'email', errors: ['This email already exists. Please input a new email'] }]);
        return;
      }
      if (messageCode.includes(errorKeyWord.phoneExisted)) {
        formValidate.setFields([
          { name: 'phone', errors: ['This phone number already exists. Please input a different phone number'] },
        ]);
        return;
      }
      if (messageError.includes(errorKeyWord.phoneError)) {
        formValidate.setFields([{ name: 'phone', errors: ['Phone format is not valid. Please check again'] }]);
        return;
      }

      message.error({
        content: messageError,
        className: 'custom-class',
        style: {
          textAlign: 'center',
        },
      });
    }
  };

  const toggleImportBatchPopup = () => {
    setOpenImportBatchPopup((prev) => !prev);
  };

  const onImportFile = (colsCsv: UserType[]) => {
    return importBatchUserList({ users: colsCsv });
  };

  const toggleSuccessPopup = () => {
    setVisibleModalSuccess((prev) => !prev);
  };

  const toggleLeavePopup = () => {
    setVisibleModalLeave((prev) => !prev);
  };

  const userDepartmentValue: UserDepartmentTypes[] = [
    {
      icon: <DepartmentAdminIcon />,
      name: 'BO Admin',
      value: 'BO Admin',
      disabled: false,
    },
    {
      icon: <SaleIcon />,
      name: 'BO Sales',
      value: 'BO Sales',
      disabled: true,
    },
    {
      icon: <AccountingIcon />,
      name: 'BO Accounting',
      value: 'BO Accounting',
      disabled: true,
    },
    {
      icon: <ManagerIcon />,
      name: 'BO Manager',
      value: 'BO Manager',
      disabled: true,
    },
  ];

  return (
    <div className={styles.addNewUser}>
      <div className={styles.addNewContainer}>
        <div className={styles.addNewForm}>
          <div className={styles.basicInfo}>
            <Collapse
              accordion
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => (
                <ChevronUpIcon style={{ transform: `rotate(${isActive ? '0deg' : '180deg'})` }} />
              )}
            >
              <Panel header="Basic Information" key="1">
                <div className={styles.department}>
                  <LabelRequire text="User Department" require />
                  <Row gutter={[20, 0]}>
                    {userDepartmentValue.map((item: UserDepartmentTypes) => (
                      <Col span={6} key={item.value}>
                        <UserDepartmentTags
                          icon={item.icon}
                          name={item.name}
                          value={item.value}
                          onChange={handleChangeUserDepartment}
                          disabled={item.disabled}
                          checked={userDepartment === item.value}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Panel>
            </Collapse>
          </div>
          <Form
            form={formValidate}
            onFinish={(e) => createInternalUserHandler({ ...e, expired_date: e.expired_date.format('DD/MM/YYYY') })}
          >
            <div className={styles.detailInfo}>
              <Collapse
                accordion
                defaultActiveKey={['2']}
                expandIcon={({ isActive }) => (
                  <ChevronUpIcon style={{ transform: `rotate(${isActive ? '0deg' : '180deg'})` }} />
                )}
              >
                <Panel header="User Detailed Information" key="2">
                  <div className={styles.importUserList}>
                    <div className={styles.importContent}>
                      <WarningIcon />
                      <span>BO admin account can onboard the user ourself by importing the batch user list. </span>
                    </div>
                    <span className={styles.importText} onClick={toggleImportBatchPopup}>
                      Import Batch User List
                    </span>
                  </div>
                  <LabelRequire text="User Name" require />
                  <TextInput
                    name="name"
                    className={styles.internalUserForm}
                    placeholder="Input User Name"
                    rules={[
                      { required: true, message: 'This field is mandatory' },
                      {
                        message: 'This field only allow alphabet characters, numeric, underscore and dot',
                        validator: (_, value) => {
                          if (nameRegex.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              'This field only allow alphabet characters, numeric, underscore and dot',
                            );
                          }
                        },
                      },
                      { max: 100, message: 'You have reach the maximum character for this field: 100 characters' },
                    ]}
                  />
                  <LabelRequire text="User Email" require />
                  <TextInput
                    name="email"
                    placeholder="Input User Email"
                    className={styles.internalUserForm}
                    rules={[
                      { required: true, message: 'This field is mandatory' },
                      {
                        pattern: new RegExp(emailRegex),
                        message: 'Email format is not valid. Please check again',
                      },
                      { max: 100, message: 'You have reach the maximum character for this field: 100 characters' },
                    ]}
                  />
                  <LabelRequire text="Expiry Date" require />
                  <DateInput
                    name={'expired_date'}
                    className={styles.internalUserForm}
                    rules={[{ required: true, message: 'This field is mandatory' }]}
                  />
                  <Row gutter={[20, 0]} style={{ height: '100%' }}>
                    <Col span={12} style={{ height: '100%' }}>
                      <LabelRequire text="Country" />
                      <Form.Item name="country" className={styles.internalUserForm}>
                        <SelectSearch
                          optionsSelect={listCountriesData}
                          loading={listCountriesLoading}
                          onChange={(value) => handleSelectCountry(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <LabelRequire text="Postal Code" />
                      <TextInput
                        name="postalCode"
                        placeholder="Input Postal Code"
                        className={styles.internalUserForm}
                        inputProps={{
                          onKeyDown: (e) => {
                            if (
                              !/^[0-9]*$/.test(e.key) &&
                              e.key !== 'Backspace' &&
                              e.key !== 'ArrowRight' &&
                              e.key !== 'ArrowLeft' &&
                              !(e.ctrlKey && e.key === 'v') &&
                              !(e.ctrlKey && e.key === 'c') &&
                              !(e.ctrlKey && e.key === 'a') &&
                              !(e.ctrlKey && e.key === 'x')
                            )
                              e.preventDefault();
                          },
                        }}
                        rules={[
                          { max: 15, message: 'You have reach the maximum character for this field: 15 characters' },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col span={12}>
                      <LabelRequire text="City" />
                      <Form.Item name="city" className={styles.internalUserForm}>
                        <SelectSearch optionsSelect={listCitiesData} loading={listCitiesLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <LabelRequire text="Phone Number" />
                      <TextInput
                        name="phone"
                        placeholder="Input Phone Number"
                        className={styles.internalUserForm}
                        rules={[
                          { max: 15, message: 'You have reach the maximum character for this field: 15 characters' },
                          { min: 8, message: 'You have reach the minimum character for this field: 8 characters' },
                          {
                            pattern: new RegExp(/^[+]*[(]{0,1}[+]{0,1}[0-9]{1,}[)]{0,1}[0-9]*$/g),
                            message: 'This field only allow input number and these special character: ()+',
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </div>
            <div className={styles.bntBox}>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <CommonButton disabled={loading} onClick={toggleLeavePopup} variant="default">
                    Cancel
                  </CommonButton>
                </Col>
                <Col span={12}>
                  <Form.Item shouldUpdate className="mb-0">
                    {({ getFieldsValue, getFieldsError }) => {
                      const { name, email, expired_date } = getFieldsValue();
                      const isDisabledAddUser =
                        !name ||
                        !email ||
                        !expired_date ||
                        loading ||
                        !!getFieldsError().filter(({ errors }) => errors.length).length;

                      return (
                        <CommonButton
                          onClick={() => formValidate.submit()}
                          loading={loading}
                          disabled={isDisabledAddUser}
                        >
                          <PlusIcon fill="white" height={18} width={18} />
                          Add User
                        </CommonButton>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className={styles.userGuide}>
          <div className={styles.userGuideContent}>
            <Collapse
              accordion
              defaultActiveKey={['3']}
              expandIcon={({ isActive }) => (
                <ChevronUpIcon style={{ transform: `rotate(${isActive ? '0deg' : '180deg'})` }} />
              )}
            >
              <Panel header="Internal User Guide" key="3">
                <div className={styles.note1}>
                  <span className="note">Note 1</span>
                  <p className="text">
                    In this phase, BO admin is the only role to access. So other user types are currently disabled.
                  </p>
                </div>
                <Divider />
                <div>
                  <span className="note">Note 2</span>
                  <p className="text">
                    In the Batch user import template file, in this phase only includes column User name and User email.
                    Does not include other columns such as Country, Postal code...etc
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
      {isVisibleModalSuccess && (
        <SuccessPopup
          togglePopup={toggleSuccessPopup}
          content={'User account has been created successfully!'}
          redirectUrl={PATH_INTERNAL_USER}
        />
      )}
      {isVisibleModalLeave && <LeavePopup togglePopup={toggleLeavePopup} redirectUrl={PATH_INTERNAL_USER} />}
      {isOpenImportBatchPopup && (
        <ImportBatchModal onImportFile={onImportFile} togglePopup={toggleImportBatchPopup} typeUser="internal" />
      )}
    </div>
  );
}

export default CreateInternalUser;
