import { SVGProps } from 'react';

export const SuccessIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M45 4.46505C40.6866 2.18343 35.8797 0.993701 31 1.00003C23.0435 1.00003 15.413 4.16074 9.78687 9.78683C4.16077 15.4129 1 23.0435 1 31C1 38.9565 4.16077 46.5871 9.78687 52.2132C15.413 57.8393 23.0435 61 31 61C38.9565 61 46.5873 57.8393 52.2134 52.2132C57.8395 46.5871 61 38.9565 61 31C60.9975 29.7294 60.9147 28.4602 60.752 27.2"
        stroke="#00AB56"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M59.8115 7.49219L31.0037 35.8542L22.6035 27.3452"
        stroke="#00AB56"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
