import ForgotPassword from 'modules/Auth/forgot-password-form';
import { IntroCommon } from 'pages/intro';

export default function ForgotPasswordPage() {
  return (
    <IntroCommon>
      <ForgotPassword />
    </IntroCommon>
  );
}
