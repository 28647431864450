import { AuthState, Session, AuthStatus, LoginResponse, UserRefreshTokenResponse } from './auth.type';
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'types';
import { checkDateIsValid } from 'utils/date';
import { getCurrentUser, getIsAdmin } from './auth.selectors';
import * as authService from 'services/auth.service';
import * as storage from 'utils/storage';

const SESSION_STORAGE_KEY = 'user-info';

export const storedUser = storage.getItem(SESSION_STORAGE_KEY) as null | Session;

var lastPayload: any = null;
var lastRefreshTime = 0;

const initialState: AuthState = storedUser
  ? {
    status: 'Loading',
    currentUser: storedUser,
    isAdmin: false,
  }
  : {
    status: 'Guest',
    currentUser: null,
    isAdmin: false,
  };

export const storeSession = createAction('auth/storeSession', function prepare(details: LoginResponse) {
  const expireDate = new Date();
  expireDate.setSeconds(expireDate.getSeconds() + (details.expiresIn || details.accessTokenExpire || 0));
  const permissions = details.permissions;
  const accessToken = details.accessToken;

  //console.log('expireDate', expireDate);
  return {
    payload: {
      expiresAt: expireDate.toISOString(),
      accessToken,
      refreshToken: details.refreshToken,
      permissions: permissions,
      username: details.username && details.username.trim(),
    },
  };
});

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<LoginResponse>) => {
      const expireDate = new Date();
      expireDate.setSeconds(expireDate.getSeconds() + (action.payload.expiresIn || action.payload.accessTokenExpire || 0));
      const accessToken = action.payload.accessToken;
      //console.log('action', action);
      state.currentUser = {
        expiresAt: expireDate.toISOString(),
        accessToken,
        username: action.payload.username && action.payload.username.trim(),
        refreshToken: action.payload.refreshToken,
      };
      lastPayload = action.payload;
      storage.saveItem(SESSION_STORAGE_KEY, state.currentUser);
    },
    removeSession: (state, action: PayloadAction<{ isAdmin: boolean }>) => {
      state.status = 'Guest';
      state.isAdmin = action.payload.isAdmin;
      state.currentUser = null;
    },
    setStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeSession, (state, action) => {
      state.status = 'Authenticated';
      state.currentUser = action.payload;
    });
  },
});

export const authReducer = authSlice.reducer;
export const { setUserInfo } = authSlice.actions;

const getCurrentSession = (): ThunkAction<Promise<Session | null>> => (dispatch, getState) => {
  const state = getState();
  //@ts-ignore
  const currentUser = getCurrentUser(state);
  if (!currentUser) {
    return Promise.resolve(null);
  }

  if (checkDateIsValid(new Date(currentUser.expiresAt), undefined, 5)) {
    return Promise.resolve(currentUser);
  }
  dispatch(authActions.logout());
  return Promise.reject(new Error('Something is wrong.'));
};

export const refreshToken = (oldAccessToken: string | undefined): ThunkAction<Promise<Session>> => (dispatch, getState) => {
  const state = getState();
  //@ts-ignore
  const currentUser = getCurrentUser(state);
  if (!currentUser) {
    dispatch(authActions.logout());
    return Promise.reject(new Error('Something is wrong.'));
  }

  //if the access token has changed, use the new token
  if (lastPayload?.accessToken && lastPayload.accessToken !== oldAccessToken) {
    return lastPayload;
  }
  //if last-refresh-token time<10s, wait 10s
  var curTime = (new Date()).getTime();
  if ((curTime - lastRefreshTime) < 10000) {
    var cnt = 0;
    var waitFunc = (resolve: any, reject: any) => {
      setTimeout(() => {
        if (lastPayload?.accessToken && lastPayload.accessToken !== oldAccessToken) {
          resolve(lastPayload);
        }
        else {
          cnt++;
          if (cnt > 10) resolve(lastPayload);
          else resolve(new Promise(waitFunc));
        }
      }, 1000);
    };

    return new Promise(waitFunc);
  }

  lastRefreshTime = curTime;

  const request = authService.getNewToken(currentUser.refreshToken as string);
  request
    .then((res) => {
      if (res) {
        //console.log('getNewToken res', res);
        dispatch(setUserInfo({ ...currentUser, ...res.data }));
      }
    })
    .catch((err) => {
      //console.log('logout');
      dispatch(authActions.logout());
    });

  return (request as Promise<UserRefreshTokenResponse>).then((res) => {
    //console.log('UserRefreshTokenResponse res', res);
    const { payload } = dispatch(
      storeSession({ ...currentUser, ...res.data }),
    );
    storage.saveItem(SESSION_STORAGE_KEY, payload);
    lastPayload = payload;
    return payload;
  });
};
const logout = (): ThunkAction<void> => (dispatch, getState) => {
  dispatch(
    authSlice.actions.removeSession({
      //@ts-ignore
      isAdmin: getIsAdmin(getState()),
    }),
  );
  storage.clearItem(SESSION_STORAGE_KEY);
};

export const authActions = {
  initState: (): ThunkAction<Promise<unknown>> => (dispatch) =>
    dispatch(getCurrentSession()).then((session) =>
      dispatch(authSlice.actions.setStatus(session ? 'Authenticated' : 'Guest')),
    ),
  logout,
};
