import React from 'react';

export const ManagerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.5 12C14.8137 12 17.5 9.31369 17.5 5.99999C17.5 2.68629 14.8137 0 11.5 0C8.18629 0 5.5 2.68629 5.5 5.99999C5.5 9.31369 8.18629 12 11.5 12Z"
        fill="#3698FC"
      />
      <path
        d="M20.039 15.2547C19.0937 14.7698 17.5878 14.0896 15.7871 13.6038C15.5371 13.5335 15.2734 13.6726 15.1865 13.9178L13.1371 19.6353L12.5166 16.532L13.4746 13.658C13.5254 13.5056 13.5 13.3377 13.4053 13.2073C13.3115 13.0769 13.1612 12.9998 13 12.9998H10C9.83885 12.9998 9.68847 13.0769 9.59472 13.2073C9.49999 13.3376 9.47458 13.5056 9.52539 13.658L10.4834 16.532L9.86294 19.6353L7.81352 13.9178C7.72661 13.6726 7.46196 13.5339 7.21291 13.6038C5.41212 14.0896 3.90626 14.7698 2.96098 15.2547C1.44336 16.034 0.5 17.572 0.5 19.2678V19.9998C0.5 21.1028 1.39747 21.9998 2.50001 21.9998H10H13H20.5C21.6025 21.9998 22.5 21.1028 22.5 19.9998V19.2678C22.5 17.572 21.5566 16.034 20.039 15.2547Z"
        fill="#3698FC"
      />
    </svg>
  );
};
