import { SVGProps } from 'react';

export const SecondLoginIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="60" cy="60" r="60" fill="#FBFBFF" />
      <path
        d="M64.163 69.366L65.1183 66.773C65.7015 65.1901 66.671 63.784 67.9138 62.6435C70.2457 60.5036 71.7077 57.431 71.7077 54.0165C71.7077 47.5507 66.4662 42.3091 60.0003 42.3091C53.5345 42.3091 48.293 47.5506 48.293 54.0165C48.293 57.431 49.755 60.5036 52.0868 62.6435C53.3297 63.784 54.2991 65.1901 54.8823 66.773L55.8377 69.366H64.163Z"
        fill="#F7E365"
      />
      <path
        d="M68.3656 45.8262C68.5949 46.8503 68.7166 47.9149 68.7166 49.0082C68.7166 57.0185 62.223 63.5123 54.2125 63.5123C53.7635 63.5123 53.3195 63.4908 52.8809 63.4508C53.7454 64.4244 54.4309 65.5452 54.8832 66.7728L55.8386 69.3658H64.1639L65.1192 66.7728C65.7024 65.1899 66.6719 63.7838 67.9147 62.6433C70.2466 60.5034 71.7086 57.4308 71.7086 54.0163C71.7086 50.8278 70.4332 47.9374 68.3656 45.8262Z"
        fill="#F3D332"
      />
      <path
        d="M64.1628 73.5287H55.8375C55.2628 73.5287 54.7969 73.0628 54.7969 72.4881V70.4068C54.7969 69.8321 55.2628 69.3662 55.8375 69.3662H64.1628C64.7375 69.3662 65.2034 69.8321 65.2034 70.4068V72.4881C65.2034 73.0627 64.7375 73.5287 64.1628 73.5287Z"
        fill="#D3EFFD"
      />
      <path
        d="M64.163 69.3657H61.041C61.6158 69.3657 62.0816 69.8316 62.0816 70.4063V72.4876C62.0816 73.0623 61.6158 73.5282 61.041 73.5282H64.163C64.7378 73.5282 65.2036 73.0623 65.2036 72.4876V70.4063C65.2036 69.8316 64.7378 69.3657 64.163 69.3657Z"
        fill="#B2E5FB"
      />
      <path
        d="M61.2554 77.6913H58.7454C58.1764 77.6913 57.6735 77.3216 57.5038 76.7786L56.4883 73.5288H63.5127L62.4972 76.7786C62.3273 77.3216 61.8244 77.6913 61.2554 77.6913Z"
        fill="#4981F8"
      />
      <path
        d="M75.8691 43.0896V44.9239C75.8691 45.6351 76.4456 46.2116 77.1569 46.2116H90.7115C91.4228 46.2116 91.9993 45.6351 91.9993 44.9239V43.0896C91.9993 40.5034 89.9026 38.4067 87.3164 38.4067H80.5521C77.9658 38.4067 75.8691 40.5034 75.8691 43.0896Z"
        fill="#65A5E1"
      />
      <path
        d="M87.3157 38.4067H83.9336C86.52 38.4067 88.6165 40.5034 88.6165 43.0896V46.2116H90.7108C91.4221 46.2116 91.9986 45.6351 91.9986 44.9239V43.0896C91.9986 40.5034 89.902 38.4067 87.3157 38.4067Z"
        fill="#74BEE7"
      />
      <path
        d="M83.9337 38.4065C86.8074 38.4065 89.137 36.0769 89.137 33.2032C89.137 30.3296 86.8074 28 83.9337 28C81.06 28 78.7305 30.3296 78.7305 33.2032C78.7305 36.0769 81.06 38.4065 83.9337 38.4065Z"
        fill="#FED2A4"
      />
      <path
        d="M86.3807 28.6108C86.7699 29.3401 86.9913 30.1726 86.9913 31.0571C86.9913 33.9307 84.6617 36.2603 81.788 36.2603C80.9035 36.2603 80.071 36.039 79.3418 35.6497C80.2173 37.29 81.9452 38.4067 83.9344 38.4067C86.808 38.4067 89.1377 36.0771 89.1377 33.2035C89.1377 31.2142 88.0209 29.4865 86.3807 28.6108Z"
        fill="#FFBD86"
      />
      <path
        d="M28 43.0896V44.9239C28 45.6351 28.5765 46.2116 29.2878 46.2116H42.8424C43.5536 46.2116 44.1301 45.6351 44.1301 44.9239V43.0896C44.1301 40.5034 42.0335 38.4067 39.4473 38.4067H32.6829C30.0966 38.4067 28 40.5034 28 43.0896Z"
        fill="#AD7FDD"
      />
      <path
        d="M39.4466 38.4067H36.0645C38.6508 38.4067 40.7473 40.5034 40.7473 43.0896V46.2116H42.8417C43.553 46.2116 44.1295 45.6351 44.1295 44.9239V43.0896C44.1296 40.5034 42.033 38.4067 39.4466 38.4067Z"
        fill="#9D66D6"
      />
      <path
        d="M36.0646 38.4065C38.9383 38.4065 41.2678 36.0769 41.2678 33.2032C41.2678 30.3296 38.9383 28 36.0646 28C33.1909 28 30.8613 30.3296 30.8613 33.2032C30.8613 36.0769 33.1909 38.4065 36.0646 38.4065Z"
        fill="#FED2A4"
      />
      <path
        d="M38.5115 28.6108C38.9008 29.3401 39.1222 30.1726 39.1222 31.0571C39.1222 33.9307 36.7925 36.2603 33.9189 36.2603C33.0344 36.2603 32.2019 36.039 31.4727 35.6497C32.3482 37.29 34.076 38.4067 36.0653 38.4067C38.9389 38.4067 41.2685 36.0771 41.2685 33.2035C41.2685 31.2142 40.1519 29.4865 38.5115 28.6108Z"
        fill="#FFBD86"
      />
      <path
        d="M75.8691 88.8782V90.7124C75.8691 91.4237 76.4456 92.0002 77.1569 92.0002H90.7115C91.4228 92.0002 91.9993 91.4237 91.9993 90.7124V88.8782C91.9993 86.2919 89.9026 84.1953 87.3164 84.1953H80.5521C77.9658 84.1953 75.8691 86.2919 75.8691 88.8782Z"
        fill="#E69642"
      />
      <path
        d="M87.3157 84.1953H83.9336C86.52 84.1953 88.6165 86.2919 88.6165 88.8782V92.0002H90.7108C91.4221 92.0002 91.9986 91.4237 91.9986 90.7124V88.8782C91.9986 86.2919 89.902 84.1953 87.3157 84.1953Z"
        fill="#E38523"
      />
      <path
        d="M83.9337 84.1951C86.8074 84.1951 89.137 81.8655 89.137 78.9918C89.137 76.1181 86.8074 73.7886 83.9337 73.7886C81.06 73.7886 78.7305 76.1181 78.7305 78.9918C78.7305 81.8655 81.06 84.1951 83.9337 84.1951Z"
        fill="#FED2A4"
      />
      <path
        d="M86.3807 74.3994C86.7699 75.1288 86.9913 75.9612 86.9913 76.8457C86.9913 79.7193 84.6617 82.0489 81.788 82.0489C80.9035 82.0489 80.071 81.8275 79.3418 81.4383C80.2173 83.0785 81.9452 84.1953 83.9344 84.1953C86.808 84.1953 89.1377 81.8657 89.1377 78.992C89.1377 77.0028 88.0209 75.275 86.3807 74.3994Z"
        fill="#FFBD86"
      />
      <path
        d="M28 88.8782V90.7124C28 91.4237 28.5765 92.0002 29.2878 92.0002H42.8424C43.5536 92.0002 44.1301 91.4237 44.1301 90.7124V88.8782C44.1301 86.2919 42.0335 84.1953 39.4473 84.1953H32.6829C30.0966 84.1953 28 86.2919 28 88.8782Z"
        fill="#E175A5"
      />
      <path
        d="M39.4466 84.1953H36.0645C38.6508 84.1953 40.7473 86.2919 40.7473 88.8782V92.0002H42.8417C43.553 92.0002 44.1295 91.4237 44.1295 90.7124V88.8782C44.1296 86.2919 42.033 84.1953 39.4466 84.1953Z"
        fill="#DE5791"
      />
      <path
        d="M36.0646 84.1951C38.9383 84.1951 41.2678 81.8655 41.2678 78.9918C41.2678 76.1181 38.9383 73.7886 36.0646 73.7886C33.1909 73.7886 30.8613 76.1181 30.8613 78.9918C30.8613 81.8655 33.1909 84.1951 36.0646 84.1951Z"
        fill="#FED2A4"
      />
      <path
        d="M38.5115 74.3994C38.9008 75.1288 39.1222 75.9612 39.1222 76.8457C39.1222 79.7193 36.7925 82.0489 33.9189 82.0489C33.0344 82.0489 32.2019 81.8275 31.4727 81.4383C32.3482 83.0785 34.076 84.1953 36.0653 84.1953C38.9389 84.1953 41.2685 81.8657 41.2685 78.992C41.2685 77.0028 40.1519 75.275 38.5115 74.3994Z"
        fill="#FFBD86"
      />
      <path
        d="M66.7435 58.7201C66.5398 58.2287 65.9751 57.9954 65.4831 58.1971C65.479 58.1987 65.2864 58.2761 64.98 58.3557C65.2874 57.8837 65.6176 57.4106 65.9734 56.9394C66.2948 56.5138 66.2103 55.9081 65.7846 55.5867C65.3594 55.2654 64.7535 55.3498 64.4319 55.7754C63.7698 56.6523 63.191 57.5321 62.6808 58.3996C61.8883 58.1738 61.287 57.6531 60.8568 56.8234C60.6908 56.5033 60.36 56.3022 59.9994 56.3022C59.6388 56.3022 59.308 56.5032 59.142 56.8234C58.7118 57.6532 58.1105 58.1738 57.318 58.3996C56.8078 57.5321 56.229 56.6523 55.5669 55.7754C55.2456 55.3499 54.64 55.2652 54.2141 55.5867C53.7885 55.9081 53.704 56.5137 54.0254 56.9394C54.3813 57.4107 54.7116 57.8839 55.0191 58.3561C54.7131 58.2764 54.5216 58.1994 54.5193 58.1984C54.0279 57.9923 53.4614 58.2236 53.2551 58.7156C53.0488 59.2074 53.2803 59.7734 53.7723 59.9797C54.395 60.2408 55.288 60.4641 56.2548 60.4686C58.5906 64.9368 58.9879 68.8538 59.0308 69.3656H60.9679C61.0113 68.8487 61.4096 64.9337 63.744 60.4683C64.7106 60.4638 65.6038 60.2408 66.2264 59.9798C66.7171 59.7742 66.9471 59.2113 66.7435 58.7201ZM59.9995 64.5167C59.6014 63.2049 59.0389 61.7043 58.2519 60.1222C58.8749 59.8838 59.4744 59.5073 59.9995 58.9441C60.5246 59.5074 61.1241 59.8838 61.7471 60.1222C60.9601 61.7043 60.3976 63.2049 59.9995 64.5167Z"
        fill="#30569F"
      />
      <path
        d="M63.806 29.1384C63.4289 28.7612 62.8174 28.7611 62.4401 29.1384C62.0629 29.5155 62.0629 30.127 62.44 30.5042L64.1735 32.2376H53.497C52.9635 32.2376 52.5312 32.67 52.5312 33.2034C52.5312 33.7367 52.9636 34.1691 53.497 34.1691H64.1734L62.44 35.9026C62.0629 36.2797 62.0629 36.8912 62.44 37.2685C62.6286 37.4571 62.8759 37.5514 63.123 37.5514C63.3701 37.5514 63.6174 37.457 63.806 37.2685L67.188 33.8864C67.5651 33.5092 67.5651 32.8977 67.188 32.5205L63.806 29.1384Z"
        fill="#AD7FDD"
      />
      <path
        d="M90.8622 62.4391C90.4849 62.062 89.8736 62.062 89.4962 62.4391L87.7629 64.1725V53.496C87.7629 52.9626 87.3306 52.5303 86.7972 52.5303C86.2638 52.5303 85.8314 52.9626 85.8314 53.496V64.1725L84.0981 62.4391C83.7208 62.062 83.1094 62.062 82.7321 62.4391C82.3549 62.8163 82.3549 63.4278 82.7321 63.805L86.1142 67.1871C86.2954 67.3683 86.5409 67.47 86.7972 67.47C87.0533 67.47 87.2989 67.3683 87.4802 67.1871L90.8622 63.805C91.2393 63.4278 91.2393 62.8163 90.8622 62.4391Z"
        fill="#65A5E1"
      />
      <path
        d="M66.5031 85.8308H55.8268L57.5601 84.0974C57.9373 83.7203 57.9373 83.1088 57.5601 82.7316C57.1829 82.3544 56.5715 82.3544 56.1941 82.7316L52.8121 86.1137C52.435 86.4908 52.435 87.1023 52.8121 87.4796L56.1941 90.8617C56.3828 91.0503 56.6299 91.1446 56.8771 91.1446C57.1243 91.1446 57.3715 91.0502 57.56 90.8617C57.9373 90.4846 57.9373 89.8731 57.5601 89.4958L55.8266 87.7625H66.5031C67.0366 87.7625 67.4689 87.3301 67.4689 86.7967C67.469 86.2633 67.0366 85.8308 66.5031 85.8308Z"
        fill="#E69642"
      />
      <path
        d="M33.8863 52.8131C33.7051 52.632 33.4596 52.5303 33.2033 52.5303C32.9472 52.5303 32.7016 52.632 32.5203 52.8131L29.1383 56.1953C28.7612 56.5724 28.7612 57.1839 29.1383 57.5611C29.5156 57.9383 30.1269 57.9383 30.5043 57.5611L32.2376 55.8278V66.5043C32.2376 67.0376 32.6699 67.47 33.2033 67.47C33.7367 67.47 34.1691 67.0376 34.1691 66.5043V55.8276L35.9024 57.561C36.0911 57.7496 36.3383 57.8439 36.5854 57.8439C36.8326 57.8439 37.0798 57.7495 37.2684 57.561C37.6456 57.1839 37.6456 56.5724 37.2684 56.1951L33.8863 52.8131Z"
        fill="#E175A5"
      />
    </svg>
  );
};
