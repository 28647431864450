import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './auth/auth.slice';

const rootReducers = combineReducers({
  auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
