
export const importableRoles = {
  'Fund Hub': true,
  'Fund Hub Screener': true,
  'Fund Comparison': true,
  'Portfolio Hub': true,
  'Portfolio Hub Plus': true,
  'Portfolio Comparison': true,
  'Company Hub': true,
  'Company Hub Screener': true,
  'Company Comparison': true,
  'Impact Hub': true,
  'Climate Hub': true,
  'Stewardship Hub': true,
  'SFDR': true,
  'TCFD': true,
  'UN SDG': true,
  'SASB': true,
};
