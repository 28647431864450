import clsx from 'clsx';
import { Form, Input, FormItemProps, InputProps } from 'antd';

import './styles.scss';

interface TextInputProps extends FormItemProps {
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  inputProps?: InputProps;
}
export function TextInput({ placeholder, disabled, className, inputProps, ...props }: TextInputProps) {
  return (
    <Form.Item className={clsx('form-input-wrapper', className)} {...props}>
      <Input {...inputProps} disabled={disabled} placeholder={placeholder} />
    </Form.Item>
  );
}
