import Routes from './navigation/routes';
import packageInfo from '../package.json';

/**
 * @description show build version
 */

// eslint-disable-next-line no-console
console.info('Build version: ' + packageInfo.version);

function App() {
  return <Routes />;
}
export default App;
