import { SVGProps } from 'react';

export const BlueonionBlackIcon = (props: SVGProps<SVGSVGElement>) => {
  const css = '.cls-1{fill:#000}.cls-2{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px}.cls-3{fill:#3783a4}.cls-4{fill:#a6b8c6}.cls-5{fill:#2163af}.cls-6{fill:#e6e4f2}';
  return (
    <svg id='Layer_1' {...props} data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 814.14 209.04'>
      <defs>
        <style> {css} </style>
      </defs>
      <g id='BO_Logo' data-name='BO Logo'>
        <g>
          <path className='cls-1' d='m265.99,170.57c0-.59.17-.71.71-.71h1.46c.54,0,.67.13.67.71v27.9c0,.54-.12.67-.67.67h-1.46c-.54,0-.71-.12-.71-.67v-27.9Z'/>
          <path className='cls-1' d='m278.92,182.41c.58-2.13,3.26-3.72,6.4-3.72,3.85,0,6.57,2.43,6.57,7.36v12.42c0,.54-.17.67-.71.67h-1.21c-.54,0-.71-.12-.71-.67v-11.96c0-3.6-1.76-5.48-4.52-5.48s-5.69,1.59-5.81,6.27v11.17c0,.54-.17.67-.71.67h-1.21c-.54,0-.71-.12-.71-.67v-18.65c0-.54.17-.67.71-.67h1.21c.54,0,.71.12.71.67v2.59Z'/>
          <path className='cls-1' d='m305.02,196l5.73-16.23c.17-.5.38-.63.92-.63h1.42c.54,0,.63.12.42.63l-7.07,18.74c-.17.5-.38.63-.92.63h-.96c-.59,0-.75-.12-.96-.63l-7.07-18.74c-.21-.5-.13-.63.42-.63h1.46c.54,0,.71.12.88.63l5.73,16.23Z'/>
          <path className='cls-1' d='m334.18,188.85v.59c0,.54-.13.67-.71.67h-13.51c.29,4.85,2.63,7.11,6.36,7.11,2.55,0,4.64-1.13,5.81-2.26.38-.25.58-.29.84-.04l.88.84c.29.29.29.5,0,.8-1.51,1.46-3.72,3.01-7.57,3.01-4.98,0-9.08-2.76-9.08-10.41,0-7.19,3.68-10.46,8.53-10.46s8.45,2.76,8.45,10.16Zm-14.18-1h11.42c-.21-4.27-1.88-6.82-5.69-6.82-3.43,0-5.4,2.55-5.73,6.82Z'/>
          <path className='cls-1' d='m351,180.61c.34.25.63.54.17,1l-.84.71c-.46.46-.79.21-1.09,0-1-.75-2.05-1.3-3.85-1.3-2.05,0-3.77.71-3.77,2.76,0,4.6,10.2,2.72,10.2,9.79,0,3.6-2.34,5.98-6.9,5.98-3.39,0-5.52-1.25-6.57-2.43-.21-.25-.34-.54.04-.92l.84-.79c.46-.46.67-.29.88-.08.88.88,2.3,1.88,4.81,1.88s4.27-1.05,4.27-3.6c0-5.1-10.2-3.05-10.2-9.75,0-3.93,3.47-5.19,6.4-5.19s4.52.96,5.6,1.92Z'/>
          <path className='cls-1' d='m360.92,179.14h4.56c.54,0,.71.12.71.67v1c0,.54-.17.67-.71.67h-4.56v11.59c0,3.18,1.38,4.14,3.18,4.14.79,0,1.38-.08,1.92-.21.21-.04.5,0,.5.5v1.3c0,.46-.25.54-.5.59-.67.12-1.38.17-2.13.17-3.52,0-5.56-1.76-5.56-6.4v-11.67h-2.68c-.54,0-.67-.12-.67-.67v-1c0-.54.13-.67.67-.67h2.68l.79-4.43c.17-.46.29-.75.75-.75h.46c.46,0,.59.17.59.71v4.48Z'/>
          <path className='cls-1' d='m382.17,170.57c0-.59.17-.71.71-.71h6.15c6.82,0,10.42,2.59,10.42,8.32,0,4.35-2.13,6.94-6.23,7.86l7.65,12.51c.17.29,0,.59-.33.59h-1.8c-.59,0-.8-.12-1.05-.59l-7.24-12.13c-.46.04-.92.04-1.42.04h-4.02v12c0,.54-.12.67-.67.67h-1.46c-.54,0-.71-.12-.71-.67v-27.9Zm2.84,1.88v11.42h4.02c5.19,0,7.45-1.76,7.45-5.65s-2.26-5.77-7.45-5.77h-4.02Z'/>
          <path className='cls-1' d='m421.54,188.85v.59c0,.54-.13.67-.71.67h-13.51c.29,4.85,2.63,7.11,6.36,7.11,2.55,0,4.64-1.13,5.81-2.26.38-.25.58-.29.84-.04l.88.84c.29.29.29.5,0,.8-1.51,1.46-3.72,3.01-7.57,3.01-4.98,0-9.08-2.76-9.08-10.41,0-7.19,3.68-10.46,8.53-10.46s8.45,2.76,8.45,10.16Zm-14.18-1h11.42c-.21-4.27-1.88-6.82-5.69-6.82-3.43,0-5.4,2.55-5.73,6.82Z'/>
          <path className='cls-1' d='m438.36,180.61c.34.25.63.54.17,1l-.84.71c-.46.46-.79.21-1.09,0-1-.75-2.05-1.3-3.85-1.3-2.05,0-3.77.71-3.77,2.76,0,4.6,10.2,2.72,10.2,9.79,0,3.6-2.34,5.98-6.9,5.98-3.39,0-5.52-1.25-6.57-2.43-.21-.25-.34-.54.04-.92l.84-.79c.46-.46.67-.29.88-.08.88.88,2.3,1.88,4.81,1.88s4.27-1.05,4.27-3.6c0-5.1-10.2-3.05-10.2-9.75,0-3.93,3.47-5.19,6.4-5.19s4.52.96,5.6,1.92Z'/>
          <path className='cls-1' d='m444.55,179.81c0-.54.17-.67.71-.67h1.21c.54,0,.71.12.71.67v2.59c1.46-2.59,3.93-3.72,6.9-3.72,5.15,0,8.57,3.39,8.57,10.37s-3.43,10.5-8.62,10.5c-3.01,0-5.44-1.13-6.86-3.72v11.5c0,.54-.17.67-.71.67h-1.21c-.54,0-.71-.12-.71-.67v-27.52Zm9.2,17.4c3.8,0,6.19-2.84,6.19-8.07s-2.26-8.11-6.23-8.11c-3.72,0-6.57,2.63-6.57,8.03s3.01,8.16,6.61,8.16Z'/>
          <path className='cls-1' d='m476.43,199.55c-5.73,0-9.33-3.89-9.33-10.58s3.68-10.29,9.33-10.29,9.33,3.56,9.33,10.29-3.56,10.58-9.33,10.58Zm0-2.43c4.23,0,6.61-3.18,6.61-8.16s-2.38-7.86-6.61-7.86-6.61,2.89-6.61,7.86,2.43,8.16,6.61,8.16Z'/>
          <path className='cls-1' d='m493.92,182.41c.58-2.13,3.26-3.72,6.4-3.72,3.85,0,6.57,2.43,6.57,7.36v12.42c0,.54-.17.67-.71.67h-1.21c-.54,0-.71-.12-.71-.67v-11.96c0-3.6-1.76-5.48-4.52-5.48s-5.69,1.59-5.81,6.27v11.17c0,.54-.17.67-.71.67h-1.21c-.54,0-.71-.12-.71-.67v-18.65c0-.54.17-.67.71-.67h1.21c.54,0,.71.12.71.67v2.59Z'/>
          <path className='cls-1' d='m524.67,180.61c.34.25.63.54.17,1l-.84.71c-.46.46-.79.21-1.09,0-1-.75-2.05-1.3-3.85-1.3-2.05,0-3.77.71-3.77,2.76,0,4.6,10.2,2.72,10.2,9.79,0,3.6-2.34,5.98-6.9,5.98-3.39,0-5.52-1.25-6.57-2.43-.21-.25-.34-.54.04-.92l.84-.79c.46-.46.67-.29.88-.08.88.88,2.3,1.88,4.81,1.88s4.27-1.05,4.27-3.6c0-5.1-10.2-3.05-10.2-9.75,0-3.93,3.47-5.19,6.4-5.19s4.52.96,5.6,1.92Z'/>
          <path className='cls-1' d='m532.2,170.02c1.13,0,1.76.84,1.76,1.8s-.63,1.72-1.76,1.72-1.8-.75-1.8-1.72.63-1.8,1.8-1.8Zm-1.34,9.79c0-.54.17-.67.71-.67h1.21c.54,0,.71.12.71.67v18.65c0,.54-.17.67-.71.67h-1.21c-.54,0-.71-.12-.71-.67v-18.65Z'/>
          <path className='cls-1' d='m549.73,199.55c-2.93,0-5.31-1.09-6.78-3.56l-.5,2.63c-.17.54-.29.67-.88.67h-.67c-.54,0-.71-.12-.71-.67v-30.16c0-.59.17-.71.71-.71h1.21c.54,0,.71.12.71.71v14.05c1.42-2.72,3.93-3.85,6.94-3.85,5.14,0,8.62,3.39,8.62,10.37s-3.47,10.5-8.66,10.5Zm-.25-2.34c3.76,0,6.19-2.84,6.19-8.07s-2.3-8.11-6.27-8.11c-3.72,0-6.57,2.63-6.57,8.03s3.01,8.16,6.65,8.16Z'/>
          <path className='cls-1' d='m563.96,168.48c0-.59.17-.71.71-.71h1.21c.54,0,.71.12.71.71v29.99c0,.54-.17.67-.71.67h-1.21c-.54,0-.71-.12-.71-.67v-29.99Z'/>
          <path className='cls-1' d='m580.06,195.83l5.9-16.06c.17-.5.42-.63.96-.63h1.46c.54,0,.58.12.42.63l-7.28,19.36-.67,1.88c-1.76,4.93-3.6,6.73-6.11,7.11-.46.08-.88.12-1.34.12-.33,0-.75-.04-1.04-.12-.21-.08-.38-.29-.34-.71l.08-1c.04-.42.17-.59.46-.59.21,0,.54.08.79.08,1.63,0,3.39-.54,5.06-5.35l.5-1.55c-.54-.38-.84-1.42-1.09-2.18l-6.36-17.06c-.17-.5-.08-.63.46-.63h1.51c.54,0,.75.12.92.63l5.69,16.06Z'/>
        </g>
        <g>
          <path className='cls-3' d='m206.69,62.71l1.47,5.72c-15.45,10.02-26,27.65-33.11,44.95-9.17,22.34-15.48,46.69-31.94,64.62-18.63,20.3-49.49,29.02-76.36,21.59C39.89,192.15,11.8,161.99.06,116.19'/>
          <path className='cls-4' d='m175.85,28.8l2.46,4.88c-11.95,11.96-17.97,29.87-20.95,46.83-3.84,21.89-4.7,45.06-15.98,64.35-12.78,21.84-38.9,35.55-64.62,33.92-25.72-1.63-57.06-23.54-76.76-62.65'/>
          <path className='cls-6' d='m142.47,12.96l2.93,3.97c-8.76,12.35-11.34,29.11-11.36,44.58-.04,19.97,2.77,40.63-4.27,59.38-7.97,21.23-29.01,37.21-52.06,39.53C54.65,162.75,23.51,147.92.03,116.13'/>
          <path className='cls-5' d='m106.88,38.72c-2.09-12.12-2.43-25.34,2.19-36.19l-3.38-2.54L3.34,113.66,106.88,38.72Z'/>
          <path fill='#fff' d='m0,116.15c26.6,23.46,56.63,30.71,76.31,24.47,19.68-6.24,34.98-24.03,37.86-44.03,2.54-17.65-7.48-57.68-7.48-57.68L0,116.15Z'/>
          <path className='cls-4' d='m89.46,18.34c2.38,2.1,2.03,2.51,1.18,5.64-.88,3.24-1.4,6.51-1.56,9.87-.36,7.61,1.02,15.13,2.79,22.5,4.17,17.38,9.36,35.29-.8,51.67-19.69,31.74-58.65,19.5-81.86-.52-.29-.25-.72.17-.42.42,20.43,17.63,52.51,30.56,75.26,9.52,11.29-10.44,15.22-24.85,13.15-39.84-2.64-19.07-12.16-37.94-4.68-57.14.04-.09,0-.23-.08-.29-.85-.75-1.71-1.51-2.56-2.26-.29-.25-.71.17-.42.42h0Z'/>
          <path className='cls-4' d='m64.95,45.59c.99.88-.14,9.17.12,10.92,1.03,6.94,4.41,13.66,1.78,20.69-5.75,15.4-22.99,10.66-32.66,2.59-.29-.25-.72.18-.42.42,7.47,6.24,18.64,11.47,27.64,5.06,14.23-10.13.67-25.9,5.02-38.94.03-.1,0-.22-.08-.29-.33-.29-.66-.58-.99-.87-.29-.25-.71.17-.42.42h0Z'/>
        </g>
        <g>
          <path className='cls-1' d='m276.76,128.6v7.05h-11.4V47.51h11.4v35.37c5.51-5.69,11.92-8.54,19.23-8.54,8.67,0,16.06,3.07,22.17,9.2,6.11,6.13,9.17,13.54,9.17,22.2s-3.06,16.07-9.17,22.2c-6.12,6.13-13.51,9.2-22.17,9.2-7.31,0-13.72-2.85-19.23-8.54Zm0-27.27v8.87c.7,3.45,2.46,6.59,5.29,9.42,3.85,3.82,8.5,5.73,13.94,5.73s10.07-1.91,13.91-5.73c3.84-3.82,5.76-8.45,5.76-13.88s-1.92-10.03-5.76-13.88c-3.84-3.86-8.48-5.78-13.91-5.78s-10.08,1.93-13.94,5.78c-2.83,2.83-4.59,5.99-5.29,9.48Z'/>
          <path className='cls-1' d='m353.94,47.45v88.2h-11.46V47.45h11.46Z'/>
          <path className='cls-1' d='m394.49,136.42c-7.16,0-12.93-2.18-17.3-6.56s-6.61-9.84-6.72-16.42v-34.49h11.46v34.05c0,3.27,1.19,6.08,3.58,8.43,2.39,2.35,5.36,3.53,8.93,3.53,3.27,0,6.1-1.17,8.51-3.5,2.4-2.33,3.61-5.15,3.61-8.46v-34.05h11.4v34.49c-.11,6.58-2.33,12.05-6.66,16.42-4.37,4.37-9.97,6.56-16.8,6.56Z'/>
          <path className='cls-1' d='m452.11,122.81c1.91,1.69,5.38,2.53,10.41,2.53,5.43,0,10.08-1.91,13.94-5.73.48-.51,1.45-1.65,2.92-3.42l11.4,4.08c-2.79,3.85-4.81,6.41-6.06,7.66-6.13,6.13-13.53,9.2-22.2,9.2s-16.01-3.07-22.15-9.2c-6.13-6.13-9.2-13.54-9.2-22.2s3.07-16.07,9.2-22.2c6.13-6.13,13.51-9.2,22.15-9.2,7.42,0,13.92,2.24,19.5,6.72.92.77,1.82,1.6,2.7,2.48.37.37,1.41,1.53,3.14,3.47l-5.73,5.78-30.02,30.02Zm19.06-35.26c-1.29-.99-4.17-1.49-8.65-1.49-5.4,0-10.02,1.92-13.86,5.76-3.84,3.84-5.76,8.48-5.76,13.91,0,4.44.5,7.33,1.49,8.65l26.77-26.83Z'/>
          <path className='cls-1' d='m532.05,74.34c8.67,0,16.06,3.07,22.17,9.2,6.12,6.13,9.17,13.54,9.17,22.2s-3.06,16.07-9.17,22.2c-6.11,6.13-13.5,9.2-22.17,9.2s-16.01-3.07-22.15-9.2c-6.13-6.13-9.2-13.54-9.2-22.2s3.07-16.07,9.2-22.2c6.13-6.13,13.51-9.2,22.15-9.2Zm-13.91,17.52c-3.84,3.86-5.76,8.48-5.76,13.88s1.92,10.06,5.76,13.88c3.84,3.82,8.48,5.73,13.91,5.73s10.07-1.91,13.91-5.73c3.84-3.82,5.76-8.45,5.76-13.88s-1.92-10.03-5.76-13.88c-3.84-3.86-8.48-5.78-13.91-5.78s-10.07,1.93-13.91,5.78Z'/>
          <path className='cls-1' d='m588.07,94.17v41.48h-11.46v-56.69h11.46v6.61c2.39-6.46,7.18-9.7,14.38-9.7,6.13,0,11.01,2.18,14.63,6.53,3.62,4.35,5.48,9.82,5.59,16.39v36.85h-11.4v-36.41c0-3.27-1.19-6.07-3.58-8.4-2.39-2.33-5.24-3.5-8.54-3.5s-6.09,1.14-8.48,3.42c-1.32,1.36-2.19,2.5-2.59,3.42Z'/>
          <path className='cls-1' d='m651.64,57.97c1.58,1.54,2.37,3.43,2.37,5.67s-.79,4.13-2.37,5.67c-1.58,1.54-3.47,2.32-5.67,2.32s-4.04-.77-5.62-2.32-2.37-3.43-2.37-5.67.79-4.13,2.37-5.67c1.58-1.54,3.45-2.31,5.62-2.31s4.1.77,5.67,2.31Zm.06,20.99v56.69h-11.4v-56.69h11.4Z'/>
          <path className='cls-1' d='m697.2,74.34c8.67,0,16.06,3.07,22.17,9.2,6.12,6.13,9.17,13.54,9.17,22.2s-3.06,16.07-9.17,22.2c-6.11,6.13-13.5,9.2-22.17,9.2s-16.01-3.07-22.15-9.2-9.2-13.54-9.2-22.2,3.07-16.07,9.2-22.2c6.13-6.13,13.51-9.2,22.15-9.2Zm-13.91,17.52c-3.84,3.86-5.76,8.48-5.76,13.88s1.92,10.06,5.76,13.88c3.84,3.82,8.48,5.73,13.91,5.73s10.07-1.91,13.91-5.73c3.84-3.82,5.76-8.45,5.76-13.88s-1.92-10.03-5.76-13.88c-3.84-3.86-8.48-5.78-13.91-5.78s-10.07,1.93-13.91,5.78Z'/>
          <path className='cls-1' d='m753.23,94.17v41.48h-11.46v-56.69h11.46v6.61c2.39-6.46,7.18-9.7,14.38-9.7,6.13,0,11.01,2.18,14.63,6.53,3.62,4.35,5.48,9.82,5.59,16.39v36.85h-11.4v-36.41c0-3.27-1.19-6.07-3.58-8.4-2.39-2.33-5.24-3.5-8.54-3.5s-6.09,1.14-8.48,3.42c-1.32,1.36-2.19,2.5-2.59,3.42Z'/>
        </g>
      </g>
      <g>
        <path className='cls-1' d='m794.12,48.36c1.1-.19,2.76-.34,4.46-.34,2.33,0,3.91.38,4.99,1.3.89.74,1.39,1.87,1.39,3.24,0,2.09-1.42,3.5-2.93,4.05v.07c1.15.43,1.85,1.56,2.26,3.12.5,2.02.94,3.89,1.27,4.51h-3.05c-.24-.48-.62-1.8-1.08-3.81-.46-2.11-1.22-2.78-2.9-2.83h-1.49v6.65h-2.93v-15.95Zm2.93,7.15h1.75c1.99,0,3.24-1.06,3.24-2.66,0-1.78-1.25-2.59-3.17-2.59-.94,0-1.54.07-1.82.14v5.11Z'/>
        <circle className='cls-2' cx='799.22' cy='56.17' r='13.92'/>
      </g>
    </svg>
  );
};
