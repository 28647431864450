import { getCompanyObjectRoles, getMemberObjectRoles } from '../../../../../services/api-permission.service';
import { ReturnObjectRolesDto } from '../../../../../services/api-permission.type';

export enum ObjectRoleType {
  COMPANY = 'company',
}

export type UpdateBlueOnionHubPlanExpiryDate = {
  role_id: string,
  start_date: string,
  end_date: string,
  is_allow: number,
}

export const loadCompanyObjectRole = async (companyId: number): Promise<ReturnObjectRolesDto[]> => {
  return await getCompanyObjectRoles(companyId);
};

export const loadMemberObjectRole = async (companyId: number): Promise<ReturnObjectRolesDto[]> => {
  return await getMemberObjectRoles(companyId);
};
