import { Avatar, Divider, Form, Skeleton } from 'antd';
import styles from './change-plan-modal.module.scss';
import { ModalBase } from 'components/modal';
import { isNil } from 'utils/helper';
import ChangePlanForm from './change-plan-form';
import { ChangePlanFormProps } from './change-plan-form.type';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { externalUserApi } from 'services/api-external-user.services';

function ChangePlanModal({ setVisible, companyDetails, memberDetails, userDetails, parentDetails, listCompanyRoles, kioskTableData, parentKioskData, refetch, onUpdateData, ...props }: ChangePlanFormProps) {
  const [form] = Form.useForm();
  const router = useHistory();

  const keyStr = companyDetails ? ('company' + companyDetails.id) : ('member' + memberDetails?.id);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    if (props.redirectUrl) {
      setTimeout(function () { if (props.redirectUrl) router.replace(props.redirectUrl); }, 200);
    }
  };

  const { data: accountData } = useQuery(
    [
      'memberAccount',
      memberDetails,
    ], () => {
      if (!memberDetails?.email) return null;

      return externalUserApi.getAccountList({
        search: '"' + memberDetails.email + '"',
      });
    });

  var accountRow: any = memberDetails && accountData?.data?.data?.[0];
  // var memberName = memberDetails?.name || memberDetails?.email?.replace?.(/@.*/, '') || '';

  return (
    <ModalBase
      {...props}
      onCancel={onCancel}
      className={styles.changePlanModal}
      centered
      title={'Change ' + (companyDetails ? 'Company' : (memberDetails ? 'Member' : 'User')) + ' Configuration'}
      forceRender
      key={keyStr + 'ModalBase'}
    >
      <div className="change-plan">
        <div className="company">
          {isNil(companyDetails || memberDetails || userDetails) ? (
            <Skeleton />
          ) : (
            <>
              <Avatar size={60} style={{ backgroundColor: '#E7F4FF', color: '#4B4B4B' }}>
                {(companyDetails || memberDetails || userDetails)?.name?.match(/\b(\w)/g)?.join('')}
              </Avatar>
              <div className="company-info">
                <div className="name">{(memberDetails ? 'Initial user\'s name: ' : '') + (companyDetails?.name || memberDetails?.name || userDetails?.name || '')}</div>
                <div className="displayname">{(memberDetails ? 'Display name: ' : '') + (accountRow?.display_name || '')}</div>
                <div className="email">{(memberDetails ? 'Email: ' : '') + ((memberDetails || userDetails)?.email || '')}</div>
                <div className="username">{(memberDetails ? 'Username: ' : '') + (memberDetails?.username || '')}</div>
                <div className="link">{companyDetails?.domain}</div>
              </div>
            </>
          )}
        </div>
        <Divider />
      </div>
      <ChangePlanForm
        key={'ChangePlanForm' + keyStr}
        setVisible={setVisible}
        companyDetails={companyDetails}
        memberDetails={memberDetails}
        userDetails={userDetails}
        parentDetails={parentDetails}
        listCompanyRoles={listCompanyRoles}
        kioskTableData={kioskTableData}
        parentKioskData={parentKioskData}
        refetch={refetch}
        onUpdateData={onUpdateData}
        onKioskUpdateData={props.onKioskUpdateData}
        redirectUrl={props.redirectUrl}
      />
    </ModalBase>
  );
}
export default ChangePlanModal;
