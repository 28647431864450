import { ModalProps } from 'antd';
import { CommonButton } from 'components/common-button';

import { WarningPopupIcon } from 'components/icons';
import ModalBase from '../modal-base';
import styles from './warning-modal.module.scss';

interface WarningModalProps extends ModalProps {
  togglePopup: () => void;
}

const WarningModal = ({ togglePopup, ...props }: WarningModalProps) => {
  return (
    <ModalBase {...props} visible={true} centered onCancel={togglePopup} className={styles.warningModal}>
      <WarningPopupIcon />
      <div className="text-warning pt-5">You need to select company first before import</div>
      <CommonButton className="button-submit" onClick={togglePopup}>
        OK
      </CommonButton>
    </ModalBase>
  );
};

export default WarningModal;
