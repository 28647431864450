import { InputProps, Select, SelectProps } from 'antd';

import { TextInput } from 'components/form-control';
import { SearchIcon } from 'components/icons';
import styles from './select-search.module.scss';

const { Option } = Select;

export type OptionsSelectType = {
  id?: string;
  name: string;
  disabled?: boolean;
};

interface SelectSearchProps extends SelectProps {
  optionsSelect?: OptionsSelectType[];
  inputSearchProps?: InputProps;
}

const SelectSearch = ({ optionsSelect, inputSearchProps, ...props }: SelectSearchProps) => {
  const renderDropdown = (options: React.ReactElement) => {
    return (
      <>
        <TextInput placeholder="Search" inputProps={{ ...inputSearchProps, prefix: <SearchIcon /> }} />
        {options}
      </>
    );
  };

  return (
    <Select
      {...props}
      className={styles.selectSearch}
      placeholder={props.placeholder == null ? 'Select' : props.placeholder}
      allowClear
      dropdownRender={renderDropdown}
      dropdownClassName={styles.selectSearchDropdown}
      defaultActiveFirstOption
      notFoundContent="No result found"
    >
      {optionsSelect &&
        optionsSelect.map((option, index) => (
          <Option key={`${option}-${index}`} value={option.id} {...(option.disabled ? { disabled: true } : {})}>
            {option.name}
          </Option>
        ))}
    </Select>
  );
};

export default SelectSearch;
