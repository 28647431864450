import { Avatar, Col, Divider, Modal, ModalProps, Row, TablePaginationConfig } from 'antd';
import { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import SortingArrow from 'components/sorting-arrow';
import Table from 'components/table';
import styles from './company-user-modal.module.scss';
import { NoteIcon } from 'components/icons';
import { PaginationDataType, renderSorterOrder, SortOrderEnum, UserCompanyType } from './company-user-modal.const';
import { CustomPagination } from 'components/pagination';
import { StatusDisplayer, StatusType } from './status-displayer';
import { getCompanyUserList } from 'services/api-company-user.service';
import { isEmpty, isNil } from 'utils/helper';
import clsx from 'clsx';
import moment from 'moment';
import { namedMappingRev, roleDefault } from '../change-plan-modal/role-tool';
import { KioskTableData, loadCompanyKioskTable, loadMembersKioskTable } from '../change-plan-modal/kiosk-data';
import ChangePlanModal from '../change-plan-modal';
import { ChangePlanNewData } from '../change-plan-modal/change-plan-form.type';
import { RolesCompanyResponse, ResponseType, RoleType } from 'services/api-external-user.type';
import ImportByTemplateModal from 'components/modal/import-by-template-modal';
import { getKioskRegion } from 'services/api-permission.service';
import { isSysAdmin } from 'redux/has-permission';
import { useRegionMapping } from '../use-region-mapping';
import { importData, verifyData } from '../import-kiosk-permission';
import { loadMemberObjectRole } from '../change-plan-modal/blueonionhub-data';
import { keyBy } from 'lodash';
import dayjs from 'dayjs';

type companyDetailsProps = {
  id: string;
  name: string;
  domain: string;
  plan_start_date: string;
  plan_end_date: string;
  start_date: string;
  expired_date: string;
  fundHub: boolean;
  portfolioHub: boolean;
  report_quota: number;
  number_of_license: number;
};
interface CompanyUserModalProps extends ModalProps {
  setVisible: Dispatch<SetStateAction<boolean>>;
  companyDetails: companyDetailsProps;
  listCompanyRolesData?: ResponseType<RolesCompanyResponse>;
}

export const CompanyUserModal = ({ setVisible, companyDetails, listCompanyRolesData, ...props }: CompanyUserModalProps) => {
  const [paginationData, setPaginationData] = useState<PaginationDataType>({
    currentPage: 1,
    orderBy: 'id',
    order: SortOrderEnum.DESC,
    pageSize: 10,
  });

  const [isOpenImportKisokPopup, setOpenImportKisokPopup] = useState<boolean>(false);

  const [visibleChangePlan, setVisibleChangePlan] = useState<boolean>(false);

  //kisok data

  const [kioskTableData, setKioskTableData] = useState<KioskTableData | null>();
  function onKioskUpdateData(newKioskTableData: KioskTableData) {
    setKioskTableData(newKioskTableData);
  }

  const [companyKioskTableData, setCompanyKioskTableData] = useState<KioskTableData | null>();

  const params = useMemo(
    () => ({
      page: paginationData.currentPage || 0,
      pageSize: 10,
      sortBy: paginationData.orderBy || 'id',
      sortOrder: paginationData.order || 'desc',
      search: '',
      status: '',
      companyId: companyDetails?.id,
    }),
    [paginationData.currentPage, paginationData.order, paginationData.orderBy, companyDetails],
  );

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setPaginationData({
      ...paginationData,
      orderBy: (sorter as SorterResult<UserCompanyType>).field as string,
      order: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
    });
  };

  const onChangePage = (page: number) => {
    setPaginationData((prev) => ({
      ...prev,
      currentPage: page,
    }));
  };
  const onCancel = () => {
    setVisible(false);
    setPaginationData({
      currentPage: 1,
      orderBy: 'id',
      order: SortOrderEnum.DESC,
      pageSize: 10,
    });
  };
  const onShowSizeChange = (current: number, pageSize: number) => {
    setPaginationData((prev) => ({
      ...prev,
      pageSize,
    }));
  };

  const { data, isLoading, refetch } = useQuery(['getListCompanyUser', params], () => getCompanyUserList(params));

  const [regionMapping, updateByRegionData] = useRegionMapping();
  const { data: regionData } = useQuery(['loadRegionForImport'], () => getKioskRegion());
  if (regionData) updateByRegionData(regionData);

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className={styles.renderTitle}>
      <div className={clsx('whitespace-pre-wrap', styles.title)}>{title}</div>
      <SortingArrow order={paginationData.order} orderBy={paginationData.orderBy} sortingFor={sortingFor} />
    </div>
  );

  const [memberDetails, setMemberDetails] = useState({
    id: '',
    name: '',
    username: '',

    ...roleDefault,

    start_date: '',
    expired_date: '',
    plan_start_date: '',
    plan_end_date: '',
    is_isolate_role: false,
    is_admin: false,

    blueOnionHubRole: {},
    company: {},
  });

  const handleMemberChangePlan = (value: UserCompanyType) => {
    setMemberDetails((prev) => ({
      ...prev,
      ...roleDefault,
    }));

    value.memberRoles.forEach((item) => {
      if (item.is_allow && !item.is_prohibit && item.role.name in namedMappingRev) {
        setMemberDetails((prev) => ({
          ...prev,
          [namedMappingRev[item.role.name]]: true,
        }));
      }
    });

    setMemberDetails((prev) => ({
      ...prev,
      id: value.id,
      name: value.name || '',
      username: value.username || '',
      email: value.email,
      start_date: value.start_date,
      expired_date: value.expired_date,
      plan_start_date: value.plan_start_date,
      plan_end_date: value.plan_end_date,
      is_isolate_role: value.is_isolate_role,
      is_admin: value.is_admin,
      is_receive_expiry_notify: value.is_receive_expiry_notify,
      cooling_start_date: value.cooling_start_date,
      account_id: value.account_id,
      handleKey: (new Date()).getTime(),
      company: value.company,
    }));

    setKioskTableData(null);
    loadMembersKioskTable(+value.id, kioskTableData).then((res) => {
      setKioskTableData(res);
      //console.log('members kiosk data loaded', res);

      loadCompanyKioskTable(+value.company_id, companyKioskTableData || kioskTableData).then((res) => {
        setCompanyKioskTableData(res);
        setVisibleChangePlan(true);
      });
    });

    loadMemberObjectRole(+value.id).then((objectRole) => {
      const nameMappedMemberObjectRole = objectRole.map((role) => {
        return { ...role, role_mapped_name: namedMappingRev[role.role_name] };
      });
      setMemberDetails((prev) => ({
        ...prev,
        blueOnionHubRole: keyBy(nameMappedMemberObjectRole, 'role_mapped_name'),
      }));
    });

  };

  async function onUpdateData(newData: ChangePlanNewData) {
    if (newData.roles || newData.memberRestriction) {
      return refetch();
    }
  }

  var isCompanyAdmin = !isSysAdmin();

  function getHubsRoles(): RoleType[] | null {
    return listCompanyRolesData?.data?.blueonionHubRoles?.concat(
      listCompanyRolesData?.data.blueonionHubPremiumRoles,
      listCompanyRolesData?.data.blueonionOtherRoles,
    ) || null;
  }

  const nowDayStr = dayjs().format('YYYY-MM-DD');

  const column: ColumnsType<UserCompanyType> = [
    {
      title: renderTitle('User ID', 'id'),
      dataIndex: 'id',
      align: 'left',
      key: 'id',
      sorter: true,
    },
    {
      title: renderTitle('User Name', 'username'),
      dataIndex: 'username',
      key: 'username',
      sorter: true,
      align: 'left',
      render: (username: string) => <div style={{ maxWidth: '150px' }}>{isNil(username) ? '-' : username}</div>,
    },
    {
      align: 'left',
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 260,
    },
    {
      align: 'left',
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (country: string) => <div>{isNil(country) ? '-' : country}</div>,
    },
    {
      align: 'left',
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => <div>{isNil(phone) ? '-' : phone}</div>,
    },
    {
      align: 'left',
      title: renderTitle('Status', 'status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text: StatusType) => (
        <div className={styles.statusColumn}>
          <StatusDisplayer value={text} />
        </div>
      ),
    },
    /*
    {
      align: 'left',
      title: 'expiry date',
      dataIndex: 'expired_date',
      key: 'expired_date',
    },
    */
    {
      align: 'left',
      title: renderTitle('Expired Date', 'expired_date'),
      dataIndex: 'expired_date',
      key: 'expired_date',
      sorter: true,
      render: (expired_date: string) => (
        <div style={(expired_date && expired_date < nowDayStr) ? { color: '#FF424F' } : {}}>
          {isNil(expired_date) ? '-' : moment(expired_date).format('YYYY-MM-DD')}
        </div>
      ),
    },
    {
      align: 'left',
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'last_login',
      sorter: true,
      render: (last_login: string) => <div>{isNil(last_login) ? '-' : moment(last_login).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    {
      title: 'Action',
      dataIndex: 'changeMemberPlane',
      key: 'changeMemberPlane',
      width: 100,
      fixed: 'right',
      render: (_, data: UserCompanyType) => {
        return (isCompanyAdmin && data.is_admin) ? '' : (
          <div className="changePlan" onClick={() => handleMemberChangePlan(data)}>
            Change Plan
          </div>
        );
      },
    },
  ];

  // console.log('companyDetails', companyDetails);
  // console.log('memberDetails.company', memberDetails.company);

  return (
    <>
      <Modal
        {...props}
        className={styles.companyUserModal}
        centered
        footer={null}
        title="Company User"
        onCancel={onCancel}
        width={1352}
      >
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <div className={styles.companySummaryInformation}>
              <div className={styles.companySummaryInformation}>
                <Avatar size={60} style={{ backgroundColor: '#E7F4FF', color: '#4B4B4B' }}>
                  {companyDetails?.name
                    .match(/\b(\w)/g)
                    ?.join('')
                    .slice(0, 4)}
                </Avatar>
                <div className={styles.companyInformation}>
                  <div className={styles.companyFullName}>{companyDetails?.name}</div>
                  <div className={styles.companyDomain}>{companyDetails?.domain}</div>
                </div>
              </div>
            </div>

          </Col>
          <Col>
            <div className="linkText" onClick={() => { setOpenImportKisokPopup(true); }}>
              Import Permission
            </div>
          </Col>
        </Row>
        <Divider />
        <div className={styles.note}>
          <NoteIcon />
          <p>This is the user lists of {companyDetails?.name}</p>
        </div>
        <Table
          rowKey={'id'}
          pagination={false}
          onChange={onChangeTable}
          columns={column}
          dataSource={data?.data}
          loading={isLoading}
          scroll={{ x: 1000 }}
        />
        {data?.data?.length > 0 && (
          <div className={styles.companyUserPaginationContainer}>
            <p>
              Total Users:<span> {isEmpty(data?.total) ? '-' : data?.total}</span>
            </p>
            <CustomPagination
              total={data?.total ?? 0}
              current={data?.page ?? 1}
              pageSize={10}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
              showSizeChanger={false}
            />
          </div>
        )}
      </Modal>

      {visibleChangePlan && (<ChangePlanModal
        visible={visibleChangePlan}
        setVisible={setVisibleChangePlan}
        //companyDetails={companyDetails}
        memberDetails={memberDetails}
        parentDetails={memberDetails.company}
        // parentDetails={companyDetails}

        listCompanyRoles={listCompanyRolesData}
        kioskTableData={kioskTableData}
        onKioskUpdateData={onKioskUpdateData}
        onUpdateData={onUpdateData}

        parentKioskData={companyKioskTableData}

        //refetch={refetch}
        key={'member-change-plan'}
      //zIndex={2000}
      />
      )}

      {isOpenImportKisokPopup && (
        <ImportByTemplateModal
          title='Import Permission'
          verifyData={async (rows: string[][]) => await verifyData(companyDetails, regionMapping, getHubsRoles(), rows)}
          closePopup={() => { setOpenImportKisokPopup(false); }}
          importData={async (rows: string[][]) => {
            var ret = await importData(companyDetails, regionMapping, getHubsRoles(), rows);
            await refetch();
            return ret;
          }}
          action='upload-import-permission'
          // templateData={[
          //   ['Email-','User name','Expiry date','Display name'],
          //   ['example@blueonion.today-','Elsa Pau','09/29/2022','Elsa Pau'],
          // ]}
          templateName='import-permission-template'
          xlsxTemplateUrl='/template/import-permission-template.xlsx'
          //xlsxSheet={'Sheet1'}    //for some xlsx, number index will cause fail
          csvTemplateUrl='/template/import-permission-template.csv'
        />
      )}


    </>
  );
};
