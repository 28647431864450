export interface ISignIn {
  email: string;
  password: string;
}

export const errorKeyWord = {
  deactivated: 'This account has been deactivated',
  notExist: 'not exist in our records',
  incorrectPassword: 'incorrect password',
};
